import FileSaver from "file-saver";
import XLSX from "xlsx";
import pixelWidth from "string-pixel-width";
import dayjs from "dayjs";


const _autoFitColumns = (json, worksheet, header) => {
    
    const jsonKeys = header ? header : Object.keys(json[0]);

    const objectMaxLength = []
    jsonKeys.forEach((key) => {
        objectMaxLength.push(
            pixelWidth(key, {
                size: 2,
            })
        )
    })

    json.forEach((data, i) => {
        const value = json[i]
        jsonKeys.forEach((key, j) => {
            const l = value[jsonKeys[j]]
                ? pixelWidth(value[jsonKeys[j]], {
                    size: 2,
                })
                : 0
            objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l
        })
    })

    return objectMaxLength.map((w) => {
        return { width: w }
    })
}


export function exportToExcel(apiData, fileName) {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    
    const time = new dayjs().format('DD-MM-YY')
    const workbook = XLSX.utils.book_new()

    apiData.forEach((tab) => {   
        const sheet = XLSX.utils.json_to_sheet(tab.data)        
        const wscols = _autoFitColumns(tab.data, sheet)        
        sheet['!cols'] = wscols

        XLSX.utils.book_append_sheet(workbook, sheet, tab.name);
    });
    
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `${fileName} ${time}${fileExtension}`); 
};