export function getSingleErrorMessage(httpResponse) {

  if (httpResponse.request?.status === 404) {
    if (httpResponse.request.response) {
      const requestData = JSON.parse(httpResponse.request.response);
      const { message } = requestData;

      if (message) {
        return message;
      }
    }
  } else if (httpResponse.request?.status === 400) {
    if (httpResponse.request.response) {
      const request = JSON.parse(httpResponse.request.response);
      return request.length > 0 ? request[0].message : request.message;
    }
  } else if (httpResponse && httpResponse.message) {
    return httpResponse.message;
  }

  return 'Não foi possível realizar a operação';
}