import React, { useState, useEffect } from "react";
import api from "../../services/api";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface IChartData {
  series: number[];
  legendas: string;
  categorias: string;
}
interface MeusProdutosOutrosProps {
  selectedRegion: any;
  startDate: Date | null;
  endDate: Date | null;
}

const QtdVidrosOrcObrasChart: React.FC<MeusProdutosOutrosProps> = ({ selectedRegion, startDate, endDate }) => {
  const [tableLoading, setTableLoading] = useState(false);
  const [charData, setCharData] = useState<IChartData[]>([]);
  console.log(charData)
  const chartOptions: ApexOptions = {
    series: charData.map(s => ({
      name: s.legendas,
      data: s.series 
    })),
    //labels: charData.map(l => l.legendas),
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: '100%',
        toolbar: {show: false},
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: charData?.map(l => l.categorias),
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "K"
          }
        }
      },
      fill: {
        opacity: 1
      
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      },
    
  };
  const formatDate = (date: Date | null): string => {
    return date ? date.toISOString().split("T")[0] : "1900-01-01";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        const { data } = await api.get(`DashboardVidros/QtdVidrosOrcObras?regiao=${selectedRegion?.label}&inicio=${formattedStartDate}&fim=${formattedEndDate}`);
        
        setCharData(data);
      } catch (error) {
        console.error("Erro na chamada da API:", error);
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, [selectedRegion, startDate, endDate]);


  return (
    <div>
      <div>
        <div className="chart-wrap">
          <div id="chart">
            <ReactApexChart
              options={chartOptions}
              series={chartOptions.series}
              type="bar"
              height={350}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QtdVidrosOrcObrasChart;
