import React, { createContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import "./styles.css"

interface Tipo {
  notification: any
}
interface Notificacao {
  (
    responseError: any
  ): any
}

const ToastErrorContext = createContext({} as Tipo);

const ToastContent = ({ title, message, icon }: any) => {
  return (
    <div className="d-flex align-items-center">
      <div className="mr-3">
        {/* <i className={`fas ${icon} fa-2x`} /> */}
      </div>
      <div className="flex-row mt-2 mb-2">
        <strong>{title}</strong>
        {message.map((msg: any, idx: number) => {
          return <div className="mt-2" key={idx}>{msg}</div>
        }
        )}
      </div>
    </div>
  );
};

const ToastErrorProvider: React.FC = ({ children }) => {
  const notification: Notificacao = (responseError) => {

    if (!(responseError instanceof Yup.ValidationError)) {      

      let critico: any = [];
      let alerta: any = [];
      let informacao: any = [];

      console.log(responseError?.request?.response);
      if (responseError?.request?.response) {
        const request = JSON.parse(responseError?.request?.response);

        request.forEach((res: any) => {
          const { message, notificationLevel } = res;

          if (notificationLevel?.description == 'Critical')
            critico.push(message);
          else if (notificationLevel?.description == 'Warning')
            alerta.push(message);
          else if (notificationLevel?.description == 'Information')
            informacao.push(message);
          else
            critico.push(message);
        });

        if (alerta != "") {
          toast.warn(
            <ToastContent
              title="Aviso"
              message={alerta}
              icon="fa-exclamation-circle "
            />,
            {
              icon:<i className={`fas fa-exclamation-circle fa-2x`} />,
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
        if (informacao != "") {
          toast.info(
            <ToastContent
              title=""
              message={informacao}
              icon="fa-exclamation-circle "
            />,
            {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
        if (critico != "") {
          toast.error(
            <ToastContent title="Atenção" message={critico} icon="fa-info-circle " />,
            {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    }
  }

  return (
    <ToastErrorContext.Provider value={{ notification }}>
      {children}
      <ToastContainer />
    </ToastErrorContext.Provider>
  );
};

export { ToastErrorProvider, ToastErrorContext };
