import React, { createContext, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";

interface Tipos {
  warning: any,
  danger: any,
  info: any,
  custom: any,
  alert: any,
  close: () => void,
}

interface Dados {(
  title: string, 
  message: string, 
  onConfirm: () => void,
):any}

const SweetAlertContext = createContext({} as Tipos);

const initialState = {
  alert: null,
};

const SweetAlertProvider:React.FC = ({ children }) => {
  const [state, setState] = useState<any>(initialState);
  
  const close = () => {
    setState({ ...state, alert: null });
  };

  const confirmEvent = async(onConfirm: any) => {
    try {
      await onConfirm();
      close();
    } catch{
      close();
    }
  }

  const closeEvent = async(onClose: any) => {
    try {
      await onClose();
      close();
    } catch{
      close();
    }
  }

  const warning: Dados = (title, message, onConfirm, onClose = close)  => {
    setState({
      ...state,
      alert: (
        <SweetAlert
          show
          warning
          style={{ display: "block" }}
          customClass="alerts"
          title={title}
          confirmBtnBsStyle="warning"
          showCancel         
          openAnim
          onConfirm={() => confirmEvent(onConfirm())}
          onCancel={() => closeEvent(onClose)}
          cancelBtnText="Cancelar"
          confirmBtnText="Confirmar"         
        >
          {message}
        </SweetAlert>
      ),
    });
  };

  const info: Dados = (title, message, onConfirm) => {
    setState({
      ...state,
      alert: (
        <SweetAlert
          show
          info
          style={{ display: "block" }}
          customClass="alerts"
          title={title}
          confirmBtnBsStyle="info"
          showCancel          
          openAnim
          onConfirm={onConfirm}
          onCancel={close}
          cancelBtnText="Cancelar"
          confirmBtnText="Confirmar"
        >
          {message}
        </SweetAlert>
      ),
    });
  };

  const danger: Dados = (title, message, onConfirm) => {      
    setState({
      ...state,      
      alert: (        
        <SweetAlert
          show
          danger
          style={{ display: "block" }}
          customClass="alerts"
          title={title}
          openAnim
          showCancel
          confirmBtnBsStyle="danger"
          onConfirm={onConfirm}
          onCancel={close}
          cancelBtnText="Cancelar"
          confirmBtnText="Confirmar"
          >      
          {message}          
        </SweetAlert>
      ),
    });
  };

  const custom: Dados = (title, message, buttons) => {
    setState({
      ...state,
      alert: (
        <SweetAlert
          show
          info
          style={{ display: "block" }}
          customClass="alerts"
          title={title}
          onConfirm={() => {
            return true;
          }}
          openAnim
          customButtons={<>{buttons}</>}
        >
          {message}
        </SweetAlert>
      ),
    });
  };

  const alert: Dados = (title, message) => {
    setState({
      ...state,
      alert: (
        <SweetAlert
          show
          info
          style={{ display: "block" }}
          customClass="alerts"
          title={title}
          onConfirm={close}
          openAnim
        >
         {message}
        </SweetAlert>
      ),
    });
  };

  return (
    <SweetAlertContext.Provider
      value={{
        warning,
        danger,
        info,
        custom,
        alert,
        close,
      }}
    >
      {children}
      {state?.alert}
    </SweetAlertContext.Provider>
  );
};

export { SweetAlertProvider, SweetAlertContext };
