import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { Select } from "../../../../../components/Form";
import { Row, Button } from "react-bootstrap";

import DataTable from "../../../../../components/DataTable";
import DataNotFound from "../../../../../components/DataNotFound";
import api from "../../../../../services/api";

import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";

import { Form } from "@unform/web";
import { SwitchCheckbox } from "../../../../../components/Form";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import getValidationErrors from "../../../../../helpers/getValidateErrors";

import {
  IListLiberacaoAlumisoft,
  IListAplicabilidade,
} from "../../../../../common/interfaces/liberacoes.interfaces";
import { toast } from "react-toastify";
import { ToastErrorContext } from "../../../../../contexts/toast/toastErrorContext";
import { useAuth } from "../../../../../hooks/auth";

interface IAplicabilidade {
  dados: IListLiberacaoAlumisoft;
}

const TabAplicabilidades: React.FC<IAplicabilidade> = (props) => {
  //Fields Typed
  const DataTableTS: any = DataTable;

  const { user } = useAuth();

  // Refs
  const formRef = useRef<FormHandles>(null);

  // Context
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);
  const sweetAlertContext = useContext(SweetAlertContext);

  //States
  const [tableData, setTableData] = useState<IListAplicabilidade[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [referencias, setReferencias] = useState([]);
  const [reloadDataTable, setReloadDataTable] = useState(false);
  const [filterHomologador, setFilterHomologador] = useState<boolean>(false);

  const message = "Campo obrigatório";
  const schema = Yup.object().shape({
    referencia: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
  });

  useEffect(() => {
    setTableLoading(true);
    Promise.all([
      api.get(`geral/referenciaAplicabilidadeExistente/${filterHomologador}`),
      api.get(`serial/listaAplicabilidadeLiberacao/${props.dados.idSerial}`)
    ]).then((promise: any) => {
      setReferencias(promise.at(0).data);
      setTableData(promise.at(1).data);
    }).catch((err) => toast.error(err.message)).finally(() => setTableLoading(false));
  }, [filterHomologador])

  useEffect(() => {
    api.get(`serial/listaAplicabilidadeLiberacao/${props.dados.idSerial}`)
      .then((response) => setTableData(response.data))
  }, [reloadDataTable]);

  //Handles
  const handleSubmit: SubmitHandler = async (dados) => {
    try {
      formRef.current?.setErrors({});

      await schema.validate(dados, { abortEarly: false });

      var liberacaoAplicabilidade = {
        idEmpresa: dados.referencia.value,
        idSerial: props.dados.idSerial,
        homologador: filterHomologador
      };

      await api.post("serial/salvarAplicabilidade", liberacaoAplicabilidade);
      setReloadDataTable((reload) => !reload);

      toast.success("A ação foi concluída.");
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleDelete = ({ id, empresa }: IListAplicabilidade) => {
    const title = `Excluir "${empresa}"? `;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlertContext.danger(title, message, async () => {
      try {
        var data = {
          idSerial: props.dados.idSerial,
          idEmpresa: id
        }
        await api.post(`serial/removerAplicabilidade`, data);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        setReloadDataTable((reload) => !reload);
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleFiltroHomologador = (filtro: boolean) => {
    setFilterHomologador(filtro);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Empresas",
        accessor: "empresa",
        display: "align-middle",
      },
      {
        Header: "Homologador",
        accessor: "homologador",
        display: "align-middle",
      },
      {
        Header: "Data",
        accessor: "dataAtualizacao",
        display: "align-middle",
        render: (value: any) => {
          return (<>
            {value.dataAtualizacao ? new Date(value.dataAtualizacao).toLocaleDateString("dd/mm/yyyy") : ""}
          </>)
        }
      },
    ],
    []
  );

  if (user?.role.indexOf("ConsultaLiberacao") === -1) {
    const deleteColumn = {
      Header: "Ação",
      accessor: "acao",
      disableSortBy: true,
      display: "align-middle column-table-1b",
      Cell: ({
        row: { original },
      }: {
        row: { original: IListAplicabilidade };
      }) => (
        <Button
          variant="ghost-dark"
          className="p-2"
          onClick={() => handleDelete(original)}
        >
          <i className="far fa-trash-alt icon m-0"></i>
        </Button>
      ),
    }

    columns.push(deleteColumn);
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row px-3 align-items-center">
            <div className="col">
              <h5 className="mb-2">Razão Social:</h5>
              <p className="mb-0">{props.dados.razaoSocial}</p>
            </div>
            <div className="col">
              <h5 className="mb-2">Nome Fantasia:</h5>
              <p className="mb-0">{props.dados.nomeFantasia}</p>
            </div>
            {
               props.dados && props.dados.serial ?
              (
                <div className="col-auto">
                  <h5 className="mb-2">Serial:</h5>
                  <p className="mb-0">{props.dados.serial}</p>
                </div>
              ):
              (
                <div className="col-auto">
                  <h5 className="mb-2">CNPJ:</h5>
                  <p className="mb-0">{props.dados?.documento}</p>
                </div>
              )
         
            }
          </div>
        </div>
      </div>

      {user!.role.indexOf("Liberacao") >= 0 && (
        <Form ref={formRef} onSubmit={handleSubmit} className="mt-3">
          <Row>
            <div className="col">
              <label className="form-label">Tipo de Liberação</label>
              <SwitchCheckbox
                name="homologador"
                id="homologador"
                label="Homologador?"
                style={{ height: "33px" }}
                onChange={(e: any) => handleFiltroHomologador(e.target.checked)}
              />
            </div>
            <p className="mt-2">
              Se o botão "homologador" estiver selecionado, os pacotes
              selecionados abaixo receberão essa marcação.
            </p>
          </Row>
          <Row>
            <div className="col">
              <label className="form-label">Empresas</label>
              <Select
                key={Math.random()}
                name="referencia"
                options={referencias}
                defaultValue={'Selecione uma opção...'}
                placeholder="Selecione uma opção..."
              />
            </div>
            <div className="col-auto">
              <Button
                variant="white"
                style={{ marginTop: 27 }}
                onClick={() => formRef.current?.submitForm()}
              >
                Adicionar
              </Button>
            </div>
          </Row>
        </Form>
      )}
      <Row className="mt-3">
        <div className={"dimmer" + (tableLoading ? " active" : "")}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? " small-box-loader" : "")}>
            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={tableLoading == false ? tableData?.length === 0 : false}
            ></DataNotFound>
          </div>
        </div>
      </Row>
    </>
  );
};

export default TabAplicabilidades;
