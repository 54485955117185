import { useContext, useEffect, useState } from "react";
import ReactSelect from "react-select";
import { getLinhasCemWeb, getProjetistasCemWeb } from "../../../services/projetosCemWeb";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";
import { ToastContext } from "../../../contexts/toast/toastContext";
import { QueryParamsProjetos } from "../../../common/interfaces/projetosCemWeb";
import { FilterParamsProjetosType } from "./ModalProjetos";

interface FiltrosProjetosProps {
  fetchData: (queryParams?: QueryParamsProjetos) => Promise<void>;
  filterParams: FilterParamsProjetosType;
  handleFilterParams: (params: FilterParamsProjetosType) => void;
}

export const FiltrosProjetos: React.FC<FiltrosProjetosProps> = ({fetchData, filterParams, handleFilterParams}) => {
  const toastContext = useContext(ToastContext);
  const [linhasOptions, setLinhasOptions] = useState<{ label: string; value: string }[]>([]);
  const [projetistasOptions, setProjetistasOptions] = useState<{ label: string; value: string }[]>([]);

  const getFilterOptionsData = async () => {
    try {
      const result = await Promise.all([getProjetistasCemWeb(), getLinhasCemWeb()]);

      const formatedProjetistas = result[0].map((projetista) => {
        return {
          label: projetista.nome,
          value: projetista.idProjetista.toString(),
        }
      });

      const formatedLinhas = result[1].map((linha) => {
        return {
          label: linha.descricao,
          value: linha.idLinhaProjetoTipologia.toString(),
        }
      });

      setProjetistasOptions(formatedProjetistas);
      setLinhasOptions(formatedLinhas);
      return;
    } catch (error: any) {
      const message = getSingleErrorMessage(error);
      if (message) {
        toastContext.notification(message, "warn");
      } else {
        toastContext.notification(
          "Ocorreu um erro ao realizar essa operação.",
          "error"
        );
      }
    }
    setProjetistasOptions([]);
    setLinhasOptions([]);
  }

  useEffect(() => {
    getFilterOptionsData();
  }, []);

  return (
    <div className="filtros-projetos">
      <div className="selects-projetos">
        <div className="subheader">
          Projetista:
          <ReactSelect
            name="projetista"
            placeholder="Projetista"
            value={filterParams.projetista}
            options={projetistasOptions}
            isClearable={true}
            className="select-projetos"
            onChange={(value: { label: string, value: string } | null) => {
              handleFilterParams({
                ...filterParams,
                projetista: value ?? undefined
              });

              fetchData({
                Filtrar: filterParams.search,
                IdProjetista: value?.value ? Number(value.value) : undefined,
                IdLinhaProjeto: filterParams.linha?.value ? Number(filterParams.linha.value) : undefined,
              });
            }}
          />
        </div>
        <div className="subheader">
          Linha:
          <ReactSelect
            name="linha"
            placeholder="Linha"
            value={filterParams.linha}
            options={linhasOptions}
            isClearable={true}
            className="select-projetos"
            onChange={(value: { label: string, value: string } | null) => {
              handleFilterParams({
                ...filterParams,
                linha: value ?? undefined
              });
              fetchData({
                Filtrar: filterParams.search,
                IdProjetista: filterParams.projetista?.value ? Number(filterParams.projetista.value) : undefined,
                IdLinhaProjeto: value?.value ? Number(value.value) : undefined,
              });
            }}
          />
        </div>
      </div>
      <div className="searchbox-projetos">
        <input
          className="form-control"
          type="text"
          placeholder="Busca..."
          value={filterParams.search || ""}
          onChange={(e) => {
            handleFilterParams({
              ...filterParams,
              search: e.target.value
            });
          }}
        />
      </div>
    </div>
  )
};