
import { BrowserRouter, Router } from 'react-router-dom';
import { GlobalStyle } from './styles/global';
import history from './helpers/history'


import Routes from './routes';
import AppProvider from './hooks';

export function App() {

  return (

    <BrowserRouter>
      <AppProvider>

        <Router history={history}>
          <Routes />
        </Router>

      </AppProvider>
      <GlobalStyle />
    </BrowserRouter>
  );
}

