import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Modal, Row, Button, Tab, Tabs } from "react-bootstrap";
import { Form } from "@unform/web";
import { toast } from "react-toastify";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";

import DualList from "../../../components/DualList";
import { Input, Select } from "../../../components/Form";
import FilterTable from "../../../components/DataTable/filterTable";
import DataNotFound from "../../../components/DataNotFound";
import api from "../../../services/api";
import getValidationErrors from "../../../helpers/getValidateErrors";
import { ToastContext } from "../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";
import { IListPadrao, IFormPadrao, IPatrocinio } from "../../../common/interfaces/liberacoes.interfaces";


const TabPadraoAlumisoft: React.FC = () => {

  const sistemas = [
    { label: "CEM", value: "CEM" },
    { label: "CEM Smart", value: "CEM Smart" },
    { label: "CEM Mini", value: "CEM Mini" },
    { label: "ONE", value: "ONE" },
    { label: "PAR", value: "PAR" },
  ]

  //Fields Typed
  const DataTableTS: any = FilterTable;

  // Refs
  const formRef = useRef<FormHandles>(null);

  // Context
  const toastContext = useContext(ToastContext);
  const sweetAlertContext = useContext(SweetAlertContext);

  //States
  const [tableData, setTableData] = useState<IListPadrao[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [novo, setNovo] = useState(false);
  const [referencias, setReferencias] = useState([]);
  const [selected, setSelected] = useState<IFormPadrao>({} as IFormPadrao);
  const [searchTerm, setSearchTerm] = useState("");
  const [reloadTable, setReloadTable] = useState(false);
  const [selectedReferenciaId, setSelectedReferenciaId] = useState(0);
  const [patrocinios, setPatrocinios] = useState<IPatrocinio[]>([]);

  const [stateListPacotesAtualizacoes, setStateListPacotesAtualizacoes] = useState<any>([]);
  const [optionsPacotesAtualizacoes, setOptionsPacotesAtualizacoes] = useState([]);

  const [stateListTabelasPrecos, setStateListTabelasPrecos] = useState<any>([]);
  const [optionsTabelasPrecos, setOptionsTabelasPrecos] = useState([]);

  const [stateListAplicabilidade, setStateListAplicabilidade] = useState<any>([]);
  const [optionsAplicabilidade, setOptionsAplicabilidade] = useState([]);

  // Variables 
  const { selectedPacote } = stateListPacotesAtualizacoes;
  const { selectedTabela } = stateListTabelasPrecos;
  const { selectedAplicabilidade } = stateListAplicabilidade;

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      Promise.all([
        api.get('geral/referenciaAplicabilidadeExistente'),
        api.get('geral/selecaoPacote'),
        api.get('geral/SelecaoTabelaPrecoDisponivelDasEmpresas'),
        api.get('geral/ObterPatrociniosExtranet')
      ]).then((response: any) => {
        setOptionsAplicabilidade(response[0].data);
        setReferencias(response[1].data.result);
        setOptionsTabelasPrecos(response[2].data);
        setPatrocinios(response[3].data);
      }).catch((error) => {
        toast.error(error.message)
      }).finally(() => setTableLoading(false));
    }

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);

        const { data } = await api.get("liberacaoPadraoCEM/listaLiberacao");
        setTableData(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    }

    fetchData();
  }, [reloadTable]);

  //Handles
  const handleClose = () => {
    setShow(false);
    setOptionsPacotesAtualizacoes([]);
    setStateListTabelasPrecos([]);
    setStateListAplicabilidade([]);
  }

  const handleNew = () => {
    setNovo(true);
    setSelected({} as IFormPadrao);
    setShow(true);
  }

  const handleSave = () => {
    formRef.current?.submitForm();
  }

  const handleSubmit: SubmitHandler = async (dados) => {
    try {

      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        descricao: Yup.string().required(message),
        sistema: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        patrocinios: Yup.object().nullable()
      });

      await schema.validate(dados, { abortEarly: false });

      dados.id = selected.id;

      await api.post('liberacaoPadraoCEM', dados)
        .then((response) => {
          if (response.data.isValid) {
            toast.success("A ação foi concluída.");
            selected.id = response.data.data;
            setNovo(false);
          } else {
            toast.error(response.data.errors[0].errorMessage);
          }

        }).catch(error => {
          formRef.current?.setErrors(getValidationErrors(error));
          toast.error(getSingleErrorMessage(error))
        }).finally(() => {
          setReloadTable(reload => !reload);
        });

      const { data: { id } } = await api.post('liberacaoPadraoCEM', dados);


    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleEdit = async ({ id }: IListPadrao) => {

    console.log(id);
    const { data } = await api.get(`liberacaoPadraoCEM/liberacao/${id}`);
    setSelected(data);

    const tabelaPrecoDisponivel = await api.get(`liberacaoPadraoCEM/liberacaoTabelaPrecoDisponivel/${id}`);
    setStateListTabelasPrecos(tabelaPrecoDisponivel.data);

    const aplicabilidadeDisponivel = await api.get(`liberacaoPadraoCEM/liberacaoAplicabilidadeDisponivel/${id}`);
    setStateListAplicabilidade(aplicabilidadeDisponivel.data);

    setNovo(false);
    setShow(true);
  };

  const handleDelete = ({ id, descricao, sistema }: IListPadrao) => {
    const title = `Excluir "${descricao} - ${sistema}"? `;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`liberacaoPadraoCEM/${id}`);
        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleChangeReferencia = async ({ value }: any) => {
    const pacoteAtualizacaoDisponivel = await api.get(`liberacaoPadraoCEM/liberacaoPortfolioDisponivel/${selected.id}/${value}`);
    setStateListPacotesAtualizacoes(pacoteAtualizacaoDisponivel.data);

    const pacoteAtualizacaoSelect = await api.get(`liberacaoPadraoCEM/pacoteAtualizacaoSelect/${value}`);
    setOptionsPacotesAtualizacoes(pacoteAtualizacaoSelect.data);

    setSelectedReferenciaId(value);
  };

  const handleChangePacoteAtualizacao = async (selectedPacote: any) => {
    try {
      setStateListPacotesAtualizacoes({ selectedPacote });

      const portfolioPadrao = {
        idLiberacaoPadrao: selected.id,
        idReferencia: selectedReferenciaId,
        idsAtualizacaoPortfolio: selectedPacote,
      };

      await api.post('liberacaoPadraoCEM/liberacaoPortfolio', portfolioPadrao);

    } catch (err) {
      toast.error(getSingleErrorMessage(err));
    }
  };

  const handleChangeTabelaPreco = async (selectedTabela: any) => {
    try {
      setStateListTabelasPrecos({ selectedTabela });

      const tabelaPadrao = {
        idLiberacaoPadrao: selected.id,
        idsTabelaPreco: selectedTabela,
      };

      await api.post('liberacaoPadraoCEM/liberacaoTabelaPreco', tabelaPadrao);

    } catch (err) {
      toast.error(getSingleErrorMessage(err));
    }
  };

  const handleChangeAplicabilidade = async (selectedAplicabilidade: any) => {
    try {
      setStateListAplicabilidade({ selectedAplicabilidade });

      const aplicabilidadePadrao = {
        idLiberacaoPadrao: selected.id,
        idsReferencia: selectedAplicabilidade,
      };

      await api.post('liberacaoPadraoCEM/liberacaoAplicabilidade', aplicabilidadePadrao);
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Liberação",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Sistema",
        accessor: "sistema",
        display: "align-middle",
      },
      {
        Header: "Patrocinio",
        accessor: "patrocinio",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({ row: { original } }: { row: { original: IListPadrao } }) => (
          <>
            <Button
              title="Editar"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleEdit(original)}
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              title="Excluir"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Row>
        <div className="col-auto ml-auto">
          <Button variant="white" onClick={handleNew}>
            Adicionar
          </Button>
        </div>
        <div className="col-auto">
          <input
            className="form-control"
            type="text"
            placeholder="Busca..."
            value={searchTerm || ""}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </Row>
      <Row className="mt-3">
        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' small-box-loader' : '')} >
            {tableData && (
              <DataTableTS
                filter={searchTerm}
                columns={columns}
                data={tableData}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={
                tableLoading == false ? tableData?.length === 0 : false
              }
            ></DataNotFound>
          </div>
        </div>
      </Row>

      {/* Modal Adicionar Liberação Padrão*/}
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{novo ? "Adicionar " : "Editar "} Liberação Padrão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={selected} className="mt-3">
            <div className="card py-2">
              <div className="card-body">
                <Row>
                  <div className="col">
                    <label className="form-label">Nome da Liberação</label>
                    <Input name="descricao" placeholder="Digite o nome..." className="form-control" />
                  </div>
                </Row>
                <Row className="mt-3 mb-2">
                  <div className="col-6">
                    <label className="form-label">Sistema</label>
                    <Select
                      name="sistema"
                      options={sistemas}
                      placeholder="Selecione uma opção..."
                      defaultValue={selected?.sistema}
                    />
                  </div>

                  <div className="col-6">
                    <label className="form-label">Patrocinado</label>
                    <Select
                      name="patrocinio"
                      options={patrocinios}
                      placeholder="Selecione uma opção..."
                      defaultValue={selected?.patrocinio}
                    />
                  </div>
                </Row>
              </div>
            </div>

            <div className="row ">
              <div className="d-flex mt-3">
                <Button variant="primary" onClick={handleSave}>
                  Salvar
                </Button>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-body">
                <Tabs defaultActiveKey="atualizacoes">
                  <Tab eventKey="atualizacoes" title="Pacote Atualizações">
                    <div className="py-4">
                      <Row>
                        <div className="col">
                          <label className="form-label">Referência</label>
                          <Select
                            name="referencia"
                            options={referencias}
                            placeholder="Selecione uma referência..."
                            isDisabled={novo}
                            onChange={(e) => handleChangeReferencia(e)}
                          />
                        </div>
                      </Row>
                      <Row className="mt-4">
                        <DualList
                          selected={selectedPacote}
                          options={optionsPacotesAtualizacoes}
                          onChange={handleChangePacoteAtualizacao}
                          availableHeader="Atualizações Disponíveis"
                          selectedHeader="Atualizações Liberadas"
                        />
                      </Row>
                    </div>
                  </Tab>
                  <Tab eventKey="precos" title="Tabela de Preços" disabled={novo}>
                    <div className="py-4">
                      <DualList
                        selected={selectedTabela}
                        options={optionsTabelasPrecos}
                        onChange={handleChangeTabelaPreco}
                        availableHeader="Tabelas Disponíveis"
                        selectedHeader="Tabelas Liberadas"
                      />
                    </div>
                  </Tab>
                  <Tab eventKey="aplicabilidade" title="Aplicabilidade" disabled={novo}>
                    <div className="py-4">
                      <DualList
                        name="aplicabilidade"
                        selected={selectedAplicabilidade}
                        options={optionsAplicabilidade}
                        onChange={handleChangeAplicabilidade}
                        availableHeader="Aplicabilidades Disponíveis"
                        selectedHeader="Aplicabilidades Liberadas"
                      />
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabPadraoAlumisoft;
