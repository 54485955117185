import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Modal, Spinner, Button, Tabs, Tab } from "react-bootstrap";
import BlockUi from "react-block-ui";
import { ToastContext } from "../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import getValidationErrors from "../../../helpers/getValidateErrors";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";
import * as Yup from "yup";

import DataTable from "../../../components/DataTable";
import DataNotFound from "../../../components/DataNotFound";
import { IListReferenciasLinhasPrenativo } from "../../../common/interfaces/referencias.interfaces";
import api from "../../../services/api";
import { Select } from "../../../components/Form";
import { handleInputChange } from "react-select/src/utils";
import { ToastErrorContext } from "../../../contexts/toast/toastErrorContext";
import { toast } from "react-toastify";

const TabPrenativo: React.FC<any> = ({ selectedLinha, prenativo }) => {

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Fields Typed
  const DataTableTS: any = DataTable;

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);

  //States
  const [tableData, setTableData] = useState<IListReferenciasLinhasPrenativo[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [prenativoSelected, setPrenativoSelected] = useState<any>();
  const [reloadTable, setReloadTable] = useState(false);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);
        const { data } = await api.get(`linha/listaLinhaPrenativo/${selectedLinha.id}`);
        setTableData(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, [reloadTable]);


  //Handles
  const handleDelete = ({ id, descricao }: any) => {
    const title = `Excluir "${descricao}"?`;
    const message = "Esse vínculo será excluído, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`linha/RemoverLinhaPrenativo/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };


  const handleSubmit: SubmitHandler = async (data) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        prenativo: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
      });

      await schema.validate(data, { abortEarly: false });

      //Passou pela validação
      const prenativo = { idLinha: selectedLinha.id, idPrenativo: data.prenativo.value };

      await api.post('linha/salvarLinhaPrenativo', prenativo);
      setReloadTable(reload => !reload);
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Código",
        accessor: "codigo",
        display: "align-middle",
      },
      {
        Header: "Descrição",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Unidade",
        accessor: "unidade",
        display: "align-middle",
      },
      {
        Header: "Tipo",
        accessor: "tipo",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({ row }: { row: any }) => (
          <span className="d-none d-sm-inline">
            <Button
              variant="btn-white"
              onClick={() => handleDelete(row.original)}
              className="btn btn-ghost-dark p-2"
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="content mt-4">
        <div className={"dimmer"}>
          <div className="loader"></div>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="dimmer-content"
          >
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Prenativo</label>
                <Select
                  name="prenativo"
                  placeholder="Selecione uma opção..."
                  options={prenativo}
                  fixDelay
                ></Select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col text-right">
                <Button variant="btn-white" onClick={() => formRef.current?.submitForm()}>
                  Adicionar prenativo
                </Button>
              </div>
            </div>

            <div className="mt-4">
              <BlockUi
                blocking={tableLoading}
                loader={<Spinner animation="border" />}
              >
                {tableData && (
                  <DataTableTS
                    columns={columns}
                    data={tableData}
                    bordered={false}
                    initialState={{
                      sortBy: [
                        {
                          id: "id",
                          desc: false,
                        },
                      ],
                    }}
                  />
                )}
              </BlockUi>
              <DataNotFound
                visible={
                  tableLoading == false ? tableData?.length === 0 : false
                }
              ></DataNotFound>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default TabPrenativo;
