import { useContext, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { createPortal } from "react-dom";
import { IProjetosCemWeb, IPutProjetosCemWeb } from "../../common/interfaces/projetosCemWeb";
import DataNotFound from "../../components/DataNotFound";
import FilterTableApi from "../../components/DataTable/filterTableApi-BuscaExt";
import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";
import { ToastContext } from "../../contexts/toast/toastContext";
import { decodeBase64 } from "../../helpers/decodeBase64";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";
import { useComponenteDesenhador } from "../../hooks/useComponenteDesenhador";
import { putProjetosCemWeb } from "../../services/projetosCemWeb";
import { FiltrosProjetos } from "./ProjetosCemWeb/FiltrosProjetos";
import { FilterParamsProjetosType, ModalProjetos } from "./ProjetosCemWeb/ModalProjetos";
import { useProjetosCemWeb } from "./hook/useProjetosCemWeb";

export const columnsProjetosCemWeb = [
  {
    Header: "Desenho",
    accessor: "imagem",
    display: "align-middle column-table-2b",
    Cell: ({ row: { original } }: { row: { original: IProjetosCemWeb } }) => (
      <div className="tooltip-projeto">
        <img
          height={"70px"}
          width={"70px"}
          className="image-projeto"
          src={original.imagem ?? ""}
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = "https://placehold.co/300x300?text=Imagem";
          }}
          alt="Imagem projeto"
        />
        <div className="tooltip-content">
          <img
            height={"100%"}
            width={"100%"}
            src={original.imagem ?? ""}
            onError={(e: any) => {
              e.target.onerror = null;
              e.target.src = "https://placehold.co/300x300?text=Imagem";
            }}
            alt="Imagem projeto"
          />
        </div>
      </div>
    ),
  },
  {
    Header: "Código",
    accessor: "codigoProjeto",
    display: "align-middle column-table-2b",
  },
  {
    Header: "Descrição",
    accessor: "descricao",
    display: "align-middle",
  },
  {
    Header: "Projetista",
    accessor: "projetista",
    display: "align-middle",
  },
  {
    Header: "Linha",
    accessor: "linha",
    display: "align-middle",
  },
];

const Desenhador: React.FC = () => {
  const toastContext = useContext(ToastContext);
  const sweetAlertContext = useContext(SweetAlertContext);

  const [showModalProjetos, setShowModalProjetos] = useState(false);
  const [dataDesenhador, setDataDesenhador] = useState<{
    imagemBase64: string;
    jsonImagem: string;
    formatoImagemBase64: string;
  }>({
    imagemBase64: "",
    jsonImagem: "",
    formatoImagemBase64: "png"
  });
  const [jsonDesenhador, setJsonDesenhador] = useState("");
  const [idEdit, setIdEdit] = useState<number>();

  const [filterParams, setFilterParams] = useState<FilterParamsProjetosType>({
    search: "",
  });

  const {fetchData, isLoadingData, tableData, handleSubmitProjetos} = useProjetosCemWeb({
    onSuccessSubmit: () => {
      fetchData();
      setIdEdit(undefined);
      setJsonDesenhador("");
    }
  });

  const handleFilterParams = (params: FilterParamsProjetosType) => {
    setFilterParams(params);
  }

  // Desenhador
  const {
    isDesenhadorOpen,
    ComponentePortalDesenhador,
    divPortal,
    toggleDisplayDesenhador,
  } = useComponenteDesenhador({
    loadJSON: jsonDesenhador,
    handleDrawData: (data) => {
      if (data.png64 && data.JSON) {
        const formatedImage = data.png64?.replace("data:image/png;base64,", "");
        const formatedJson = window.btoa(data.JSON ?? "");
        const formatoImagem = "png";

        const formatedDataDesenhador = {
          imagemBase64: formatedImage,
          jsonImagem: formatedJson,
          formatoImagemBase64: formatoImagem
        }

        setDataDesenhador(formatedDataDesenhador);

        if (idEdit) {
          handleSubmitProjetos(formatedDataDesenhador, [idEdit]);
          return;
        }
        setShowModalProjetos(true);

      }
    },
  });

  const columns = useMemo(() => {
    return [
      ...columnsProjetosCemWeb,
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle column-table-2b",
        disableSortBy: true,
        Cell: ({ row: { original } }: { row: { original: IProjetosCemWeb } }) => (
          <span className="d-none d-sm-inline">
            <Button
              variant="ghost-dark"
              onClick={() => handleEdit(original)}
              className="p-2"
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              variant="ghost-dark"
              onClick={() => handleDelete(original)}
              className="p-2">
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ]
  }, []);

  const handleEdit = (data: IProjetosCemWeb) => {
    const formatedJsonDesenhador = decodeBase64(data.jsonBase64);
    setJsonDesenhador(formatedJsonDesenhador);
    setIdEdit(data.idProjeto);
    toggleDisplayDesenhador(true);
  };

  const handleDelete = (data: IProjetosCemWeb) => {
    const title = `Excluir imagem? `;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlertContext.danger(title, message, async () => {
      try {

        const postData: IPutProjetosCemWeb = {
          projetos: [{
            idProjeto: data.idProjeto,
            imagemBase64: null,
            jsonImagem: null,
            formatoImagemBase64: null
          }],
        }
  
        const result = await putProjetosCemWeb(postData);

        if (result) {
          fetchData({
            Filtrar: filterParams.search,
            IdLinhaProjeto: filterParams.linha?.value ? Number(filterParams.linha.value) : undefined,
            IdProjetista: filterParams.projetista?.value ? Number(filterParams.projetista.value) : undefined,
          });
          sweetAlertContext.close();
          return;
        }
        throw new Error("Erro ao excluír imagem.");
      } catch (error: any) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  }

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header page-desenhador">
            <div className="row">
              <h2 className="col-auto page-title">Projetos</h2>

              <span className="col-auto ml-auto">
                <Button variant="white" onClick={() => {
                  toggleDisplayDesenhador(true);
                  setJsonDesenhador("");
                }}>
                  Novo Desenho
                </Button>
              </span>
            </div>
          </div>
          <FiltrosProjetos
            fetchData={fetchData}
            filterParams={filterParams}
            handleFilterParams={handleFilterParams}
          />

          <div className="row">
            <div className="card p-0">
              <div className="card-body">
                <div className={'dimmer' + (isLoadingData ? ' active' : '')}>
                  <div className="loader"></div>
                  <div className={"dimmer-content" + (isLoadingData ? ' medium-box-loader' : '')} >

                    {tableData && (
                      <FilterTableApi
                        filter={filterParams.search}
                        columns={columns}
                        data={tableData}
                        fetchData={(globalFilter: string) => fetchData({
                          IdLinhaProjeto: filterParams.linha?.value ? Number(filterParams.linha.value) : undefined,
                          IdProjetista: filterParams.projetista?.value ? Number(filterParams.projetista.value) : undefined,
                          Filtrar: globalFilter,
                        })}
                        bordered={false}
                        setSelectedRows={() => {
                          //
                        }}
                        initialState={{
                          globalFilter: filterParams.search,
                          pageIndex: 0,
                          pageSize: 10
                        }}
                      />
                    )}

                    <DataNotFound
                      visible={!isLoadingData && tableData?.length === 0}
                    ></DataNotFound>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalProjetos
        show={showModalProjetos}
        onHide={() => {
          setShowModalProjetos(false);
          fetchData();
        }}
        dataDesenhador={dataDesenhador}
        onRefreshData={() => {
          fetchData();
        }}
      />

      {isDesenhadorOpen && divPortal && createPortal(<ComponentePortalDesenhador />, divPortal)}
    </>
  )
}

export default Desenhador;