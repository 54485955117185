import React, {
    useCallback,
    useContext,
    useMemo,
    useState,
    useRef,
  } from "react";
  import { Modal, Button } from "react-bootstrap";
  import { Input } from "../../components/Form";
  import Breadcrumbs from "../../components/Breadcrumbs";
  import PaginationTable from "../../components/DataTable/paginationTable-BuscaExt";
  import DataNotFound from "../../components/DataNotFound";
  import api from "../../services/api";
  
  import { ToastContext } from "../../contexts/toast/toastContext";
  import { ToastErrorContext } from "../../contexts/toast/toastErrorContext";
  import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";
  
  import { Form } from "@unform/web";
  import { SubmitHandler, FormHandles } from "@unform/core";
  import getValidationErrors from "../../helpers/getValidateErrors";
  import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";
  import * as Yup from "yup";
  
  import { IFormTipoAcabamento, IListTipoAcabamento } from "../../common/interfaces/tipoAcabamento.interfaces";
  import { IPaginacao } from "../../common/interfaces/paginacao.interfaces";
  import GlobalFilter from "../../components/DataTable/GlobalFilter";
  import { toast } from "react-toastify";
  
  const TipoAcabamento: React.FC = () => {
  
    // Refs
    const formRef = useRef<FormHandles>(null);
  
    //Constates
    const queryString = require('querystring');
  
    //Fields Typed
    const DataTableTS: any = PaginationTable;
  
    //Context
    const sweetAlertContext = useContext(SweetAlertContext);
    const toastContext = useContext(ToastContext);
    const toastErrorContext = useContext(ToastErrorContext);
  
  
    //States
    const [tableData, setTableData] = useState<IListTipoAcabamento[]>([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState<IFormTipoAcabamento>({} as IFormTipoAcabamento);
    const [novo, setNovo] = useState(false);
    const [reloadGrid, setReloadGrid] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [filter, setFilter] = useState('');
  
    //Callbacks
    const fetchData = useCallback(async (paginacao: IPaginacao) => {
      try {
        const { data: { dados, paginas } } = await api.get(`/tipoAcabamento/lista?${queryString.stringify(paginacao)}`);
  
        setPageCount(paginas);
        setTableData(dados);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    }, []);
  
    //Handles
    const handleClose = () => setShow(false);
  
    const handleNew = () => {
      setSelected({} as IFormTipoAcabamento);
      setNovo(true);
      setShow(true);
    };
  
    const handleEdit = useCallback(async ({ id }) => {
      const { data } = await api.get(`/tipoAcabamento/${id}`);
  
      setSelected(data);
      setShow(true);
      setNovo(false);
    }, []);
  
    const handleDelete = ({ id, descricao }: IListTipoAcabamento) => {
      const title = `Excluir "${descricao}"?`;
      const message = "Esse Tipo de Acabamento será excluído, isso não pode ser desfeito.";
      sweetAlertContext.danger(title, message, async () => {
        try {
          await api.delete(`tipoAcabamento/${id}`);
          setTableData((prevState) =>
            prevState.filter(function (item) {
              return item.id !== id;
            })
          );
          sweetAlertContext.close();
        } catch (error) {
          const message = getSingleErrorMessage(error);
          if (message) {
            toastContext.notification(message, "warn");
          } else {
            toastContext.notification(
              "Ocorreu um erro ao realizar essa operação.",
              "error"
            );
          }
        }
      });
    };
  
    const handleSave = () => {
      formRef.current?.submitForm();
      setNovo(false);
    };
  
    const handleSubmit: SubmitHandler<IFormTipoAcabamento> = async (TipoAcabamento) => {
      try {
        formRef.current?.setErrors({});
  
        const message = "Campo obrigatório";
        const schema = Yup.object().shape({
          descricao: Yup.string().required(message),
        });
        await schema.validate(TipoAcabamento, { abortEarly: false });
  
        //Passou pela validação      
        TipoAcabamento.id = selected.id;
  
        await api.post('tipoAcabamento', TipoAcabamento);
  
        setReloadGrid(reload => !reload);
        setShow(false);
    
        toast.success("A ação foi concluída.");
      } catch (error: any) {
        toast.error(getSingleErrorMessage(error));
        formRef.current?.setErrors(getValidationErrors(error));
      }
    };
  
    const columns = useMemo(
      () => [
  
        {
          Header: "Descrição",
          accessor: "descricao",
          display: "align-middle",
        },
        {
          Header: "Ação",
          accessor: "acao",
          disableSortBy: true,
          display: "align-middle column-table-2b",
          Cell: ({
            row: { original },
          }: {
            row: { original: IListTipoAcabamento };
          }) => (
            <>
              <Button
                title="Editar"
                variant="ghost-dark"
                className="p-2"
                onClick={() => handleEdit(original)}
              >
                <i className="far fa-edit icon m-0"></i>
              </Button>
              <Button
                title="Excluir"
                variant="ghost-dark"
                className="p-2"
                onClick={() => handleDelete(original)}
              >
                <i className="far fa-trash-alt icon m-0"></i>
              </Button>
            </>
          ),
        },
      ],
      []
    );
  
    return (
      <>
        <div className="content">
          <div className="container-xl">
            <div className="page-header">
              <div className="row">
                <Breadcrumbs />
                <span className="col-auto ml-auto">
                  <Button variant="white" onClick={() => handleNew()}>
                    Novo Tipo de Acabamento
                  </Button>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="card p-0">
                <div className="card-body">
                  <div className={'dimmer' + (tableLoading ? ' active' : '')}>
                    <div className="loader"></div>
                    <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
                      {tableData && (
                        <DataTableTS
                          columns={columns}
                          data={tableData}
                          filter={filter}
                          fetchData={fetchData}
                          pageCount={pageCount}
                          bordered={false}
                          reload={reloadGrid}
                          initialState={{
                            sortBy: [
                              {
                                id: "id",
                                desc: false,
                              },
                            ],
                          }}
                        />
                      )}
                      <DataNotFound
                        visible={
                          tableLoading == false ? tableData?.length === 0 : false
                        }
                      ></DataNotFound>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* Modal TipoAcabamento */}
        <Modal show={show} onHide={handleClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>
              {novo ? "Adicionar Tipo de Acabamento" : "Editar Tipo de Acabamento"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form initialData={selected} className="mt-3" ref={formRef} onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group col">
                  <label className="form-label">Descrição</label>
                  <Input
                    type="text"
                    name="descricao"
                    className="form-control"
                    placeholder="Descrição..."
                  />
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  
  export default TipoAcabamento;
  