import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { Modal, Button, Row } from "react-bootstrap";
import { Select } from "../../../../components/Form";

import { ToastContext } from "../../../../contexts/toast/toastContext";
import { ToastErrorContext } from "../../../../contexts/toast/toastErrorContext";
import { SweetAlertContext } from "../../../../contexts/sweetAlert/alertContext";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import getValidationErrors from "../../../../helpers/getValidateErrors";
import { getSingleErrorMessage } from "../../../../helpers/getSingleErrorMessage";
import * as Yup from "yup";

import GlobalFilter from "../../../../components/DataTable/GlobalFilter";
import PaginationTable from "../../../../components/DataTable/paginationTable-BuscaExt";

import { base64toBlob } from "../../../../helpers/utils";
import DataNotFound from "../../../../components/DataNotFound";
import api from "../../../../services/api";

import { IPaginacao } from "../../../../common/interfaces/paginacao.interfaces";
import { IListReferenciasLinhas, IFormReferenciasLinhas } from "../../../../common/interfaces/referencias.interfaces";
import { simOuNao } from "../../../../components/Form/Select/options";
import { toast } from "react-toastify";

const TabLinhas: React.FC<any> = ({ selectedTab }) => {
  // URL Params
  const { pId }: any = useParams();   

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Fields Typed
  const DataTableTS: any = PaginationTable;
  const queryString = require("querystring");

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);

  //States
  const [tableData, setTableData] = useState<IListReferenciasLinhas[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<IFormReferenciasLinhas>(
    {} as IFormReferenciasLinhas
  );
  const [novo, setNovo] = useState(false);
  const [linha, setLinha] = useState<any>([]);
  const [unidadeValor, setUnidadeValor] = useState<any>([]);
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState('');


  //Effects
  useEffect(() => {
    (async () => {
      try {
        if (selectedTab == "linhas" && linha.length == 0) {
          setTableLoading(true);

          const { data } = await api.get("linha/select");
          const unidadeValor = await api.get(
            "unidadeItemEmbalagem/unidadePerfilSelect"
          );

          setLinha(data);
          setUnidadeValor(unidadeValor.data);
        }
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, [selectedTab]);

  //Callbacks
  const fetchData = useCallback(async (paginacao: IPaginacao) => {
    try {
      const {
        data: { dados, paginas },
      } = await api.get(
        `referencia/listaReferenciaLinha?idReferencia=${pId}&${queryString.stringify(
          paginacao
        )}`
      );

      setTableData(dados);
      setPageCount(paginas);
    } catch (error) {
    } finally {
      setTableLoading(false);
    }
  }, []);

  //Handles
  const handleClose = () => setShow(false);

  const handleNew = () => {
    setSelected({} as IFormReferenciasLinhas);
    setNovo(true);
    setShow(true);
  };

  const handleEdit = useCallback(async ({ id }: any) => {
    const { data } = await api.get(`referencia/referenciaLinha/${id}`);

    console.log(data);
    setSelected(data);
    setShow(true);
    setNovo(false);
  }, []);

  const handleDelete = ({ id, linha }: IListReferenciasLinhas) => {
    const title = `Excluir "${linha}"?`;
    const message = "Essa Linha será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`referencia/removerReferenciaLinha/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleSubmit: SubmitHandler<IFormReferenciasLinhas> = async (
    linhas
  ) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        linha: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),

        exclusiva: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),

        impedirSalvar: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
      });

      await schema.validate(linhas, { abortEarly: false });

      //Passou pela validação
      linhas.idReferencia = pId;

      if (selected.id != "") linhas.id = selected.id;

      await api.post("referencia/salvarReferenciaLinha", linhas);

      setShow(false);
      setNovo(false);
      setReloadTable((reload) => !reload);
            
      toast.success("A ação foi concluída.");
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };


  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Linha",
        accessor: "linha",
        display: "align-middle",
      },
      {
        Header: "Logo",
        accessor: "logo",
        display: "align-middle",
        Cell: ({ row: { original } }: { row: { original: any } }) => {
          return (
            original.logo && (
              <img
                height={22}
                src={URL.createObjectURL(base64toBlob(original.logo))}
              />
            )
          );
        },
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle column-table-2b",
        Cell: ({
          row: { original },
        }: {
          row: { original: IListReferenciasLinhas };
        }) => (
          <span className="d-sm-inline">
            <Button
              title="Editar"
              className="btn btn-ghost-dark p-2"
              onClick={() => handleEdit(original)}
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>

            <Button
              title="Excluir"
              className="btn btn-ghost-dark p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="mt-1">
        <Row>
          <span className="col-auto ml-auto">
            <Button variant="white" onClick={() => handleNew()}>
              Nova Linha
            </Button>
          </span>
          <div className="col-auto">
            <GlobalFilter setGlobalFilter={setFilter} />
          </div>
        </Row>
        <Row className="mt-3">
          <div className={'dimmer' + (tableLoading ? ' active' : '')}>
            <div className="loader"></div>
            <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >

              {tableData && (
                <DataTableTS
                  filter={filter}

                  columns={columns}
                  data={tableData}
                  bordered={false}
                  fetchData={fetchData}
                  reload={reloadTable}
                  pageCount={pageCount}
                  initialState={{
                    sortBy: [
                      {
                        id: "id",
                        desc: false,
                      },
                    ],
                  }}
                />
              )}
              <DataNotFound
                visible={
                  tableLoading == false ? tableData?.length === 0 : false
                }
              ></DataNotFound>
            </div>
          </div>
        </Row>
      </div>


      {/* Modal Linha */}
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{novo ? "Adicionar Linha" : "Editar Linha"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={"dimmer"}>
          <div className="loader"></div>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={selected}
            className="dimmer-content"
          >
            <div className="form-row mt-3">
              <div className="form-group col">
                <label className="form-label">Linha</label>
                <Select
                  name="linha"
                  placeholder="Selecione uma opção..."
                  options={linha}
                  defaultValue={selected?.linha}
                  fixDelay
                ></Select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Exlusiva?</label>
                <Select
                  name="exclusiva"
                  placeholder="Selecione uma opção..."
                  options={simOuNao}
                  defaultValue={selected?.exclusiva}
                ></Select>
              </div>
              <div className="form-group col">
                <label className="form-label">Impedir salvar como?</label>
                <Select
                  name="impedirSalvar"
                  placeholder="Selecione uma opção..."
                  options={simOuNao}
                  defaultValue={selected?.impedirSalvar}
                ></Select>
              </div>
            </div>

            <div className="form-row">
            <div className="form-group col">
                <label className="form-label">Gerar Pct tipo referência?</label>
                <Select
                  name="pacoteReferencia"
                  placeholder="Selecione uma opção..."
                  options={simOuNao}
                  defaultValue={selected?.pacoteReferencia}
                ></Select>
              </div>
              <div className="form-group col">
                <label className="form-label">Unidade de Valor do Perfil</label>
                <Select
                  name="unidadeValor"
                  placeholder="Selecione uma opção..."
                  options={unidadeValor}
                  defaultValue={selected?.unidadeValor}
                  isClearable={true}
                ></Select>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabLinhas;
