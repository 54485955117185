import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Nav, Row, Tab } from "react-bootstrap";

import TabAtualizacoes from "./TabAtualizacoes";
import TabAplicabilidades from "./TabAplicabilidades";

interface ILocation {
  pathname: string,
  state: any
}

const AtualizacaoPreference: React.FC = () => {

  // Importação de Dados
  const location: ILocation = useLocation();

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title"><Link to="/liberacoes" className="mr-1">Liberações</Link> / Liberação Preference</h2>
              </div>             
              
              <div className="card mt-3 p-0">
                <div className="card-body">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="atualizacoes"
                  >
                    <Row>
                      <Col className="col-3">
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link
                              className="font-weight-bold"
                              eventKey="atualizacoes"
                            >
                              Pct Atualizações
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              className="font-weight-bold"
                              eventKey="aplicabilidade"
                            >
                              Aplicabilidade
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col>
                        <Tab.Content>
                          <Tab.Pane eventKey="atualizacoes">
                            <TabAtualizacoes dados={location.state} />
                          </Tab.Pane>
                          <Tab.Pane eventKey="aplicabilidade">
                            <TabAplicabilidades dados={location.state} />
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AtualizacaoPreference;
