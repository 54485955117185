import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import ReactSelect from "react-select";

import api from "../../../../services/api";
import DataNotFound from "./../../../../components/DataNotFound";
import { IAplicabilidade } from "./../../../../common/interfaces/produtos.interfaces";
import FilterTableApi from "./../../../../components/DataTable/paginationTable-BuscaExt";
import GlobalFilter from "./../../../../components/DataTable/GlobalFilter";
import { IPaginacao } from "./../../../../common/interfaces/paginacao.interfaces";
import DataTableColumnCheckBox from "./../../../../components/DataTable/DataTableCheckbox";
import { useParams } from "react-router-dom";
import ModalPrevisualizacao from "../ModalPrevisualizacao";
import Placeholder from "../../../../assets/imgs/img-placeholder.png";

const ComponenteTabAplicabilidadeModalAdicionar: React.FC<any> = ({ setTableSelectedRows }) => {

  // URL Params
  const { pId }: any = useParams();

  //Fields Typed
  const DataTableTS: any = FilterTableApi;
  const queryString = require('querystring');

  //States
  const [tableData, setTableData] = useState<IAplicabilidade[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [filterReferencia, setFilterReferencia] = useState<number | undefined>();
  const [referencia, setReferencia] = useState<any>(null);
  const [pageCount, setPageCount] = useState(0);
  const [showAplicavel, setShowAplicavel] = useState(false);
  const [idAplicavel, setIdAplicavel] = useState<any>(null);
  // const [tableSelectedRows, setTableSelectedRows] = useState<number[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const referencias = await api.get('geral/ReferenciaAplicabilidade');
        setReferencia(referencias.data);
      } catch (error) {
        console.log(error);
      } finally {
        setTableLoading(false);
      }
    })();
  }, []); 

  //Callbacks
  const fetchData = useCallback(async (paginacao: IPaginacao) => {
    try {
      setTableLoading(true);
      const { data: { dados, paginas } } = await api.get(`/aplicabilidade/listaComponente?${queryString.stringify(paginacao)}&idComponenteBase=${pId}`);

      setPageCount(paginas);
      setTableData(dados);

    } catch (error) {
    } finally {
      setTableLoading(false);
    }
  }, []);

  //Handle
  const handleFiltroReferencia = (filtro?: number) => {
    setFilterReferencia(filtro);
  };

  const handleCloseAplicavel = () => setShowAplicavel(false);
  
  const handleVisualizarAplicavel = ({ id }: any) => {
    setIdAplicavel(id);
    setShowAplicavel(true);
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "check",
        display: "align-middle text-center column-table-1b",
        Cell: ({ row }: any) => (
          <DataTableColumnCheckBox
            selectProps={row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: "Código",
        accessor: "codigo",
        display: "align-middle",
      },
      {
        Header: "Descrição",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Referencia",
        accessor: "logoReferencia",
        display: "align-middle",
        Cell: ({ row: { original } }: { row: { original: IAplicabilidade }}) => {
          return <img height={22} 
            src={original.linkLogoReferencia}
            onError={(evt: any) => evt.target.src = Placeholder}
            alt="Logo"/>
        },
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({
          row: { original },
        }: {
          row: { original: IAplicabilidade };
        }) => (
          <>
            {original.fichaTecnica &&
              <Button onClick={(e) => {
                e.preventDefault();
                window.open(original.fichaTecnica, '_blank');
              }}
                title="Ficha Técnica" variant="ghost-dark" className="p-2">
                <i className="fas fa-file-download icon m-0"></i>
              </Button>
            }
             <Button title="Visualizar" variant="ghost-dark" className="p-2" target="_blank"
              onClick={() => handleVisualizarAplicavel(original)}>
              <i className="fas fa-external-link-alt icon m-0"></i>
            </Button>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Row>
        <div className="col-3 ml-auto">
          <ReactSelect
            classNamePrefix="react-select"
            name="referencia"
            options={referencia}
            placeholder="Busca por Referência..."
            onChange={(e: any) => handleFiltroReferencia(e.value)}
          />
        </div>
        <div className="col-auto ml-0 pr-0">
          <GlobalFilter setGlobalFilter={setFilter} />
        </div>
      </Row>
      <div
        className={"dimmer" + (tableLoading ? " active" : "")}
        style={tableLoading ? { minHeight: "322px" } : {}}
      >
        <div className="loader"></div>
        <div className="dimmer-content">
          <div className="mt-3">
            {tableData && (
              <DataTableTS
                setSelectedRows={setTableSelectedRows}
                columns={columns}
                fetchData={fetchData}
                pageCount={pageCount}
                filter={filter}
                filterOptional1={filterReferencia}
                data={tableData}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
          </div>
          <DataNotFound
            visible={tableLoading == false ? tableData?.length === 0 : false}
          ></DataNotFound>
        </div>
      </div>

      {/* Modal Apicável */}
      <Modal size="lg" show={showAplicavel} onHide={handleCloseAplicavel}>
        <Modal.Header closeButton>
          <Modal.Title>Pré-visualização</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3 pb-3">
            <ModalPrevisualizacao id={idAplicavel} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAplicavel}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ComponenteTabAplicabilidadeModalAdicionar;
