import React, { useContext, useRef, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";

import { ToastContext } from "../../../contexts/toast/toastContext";
import getValidationErrors from "../../../helpers/getValidateErrors";
import { Select } from "../../../components/Form";
import { exportToExcel } from "../../../helpers/ExportToExcel";
import { situacao, status } from "../../../components/Form/Select/options";
import api from "../../../services/api";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import { toast } from "react-toastify";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";

const TabImagens: React.FC = () => {
  // Refs
  const formRef = useRef<FormHandles>(null);
  const queryString = require('querystring');

  //Context
  const toastContext = useContext(ToastContext);
  const sweetAlertContext = useContext(SweetAlertContext);

  //States  
  const [referencia, setReferencia] = useState([]);
  const [linha, setLinha] = useState([]);
  const [tipoMaterial, setTipoMaterial] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const message = "Campo obrigatório";
  const schema = Yup.object().shape({
    tipoProduto: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    situacao: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    status: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable(),
    referencia: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable(),
    linha: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable(),
  });

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      await api.get('produto/selectTipoMaterial')
        .then(response => {
          setTipoMaterial(response.data);
        }).catch(error => toast.warn(getSingleErrorMessage(error)));
    }

    fetchData();
  }, []);

  //Handles
  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handleTipoMaterialReferencia = async (selectedTipoMaterial: any) => {
    formRef.current?.clearField('referencia');

    const { data } = await api.get(`geral/selecaoReferenciaMaterial/${selectedTipoMaterial.value}`);
    setReferencia(data);
  }

  const handleReferenciaLinha = async (selectedReferencia: any) => {
    formRef.current?.clearField('linha');

    if (selectedReferencia != null) {
      const { data } = await api.get(`linha/select/${selectedReferencia.value}`);
      setLinha(data);
    }
  }

  const handleSubmit: SubmitHandler = async (data) => {
    try {
      setTableLoading(true);
      formRef.current?.setErrors({});

      await schema.validate(data, { abortEarly: false });

      const fileName = data.tipoProduto.label;

      const filtro = {
        referencia: data.referencia?.value,
        linha: data.linha?.value,
        situacao: data.situacao?.value,
        status: data.status?.value
      };

      const dadosExportacao = await api.get(`produto/exportarImagem?tipoMaterial=${data.tipoProduto.value}&${queryString.stringify(filtro)}`);

      if (dadosExportacao.data?.length != 0) {
        var tabDados = [{ data: dadosExportacao.data, name: fileName }];

        tabDados = tabDados.filter((row) => Object.values(row).every((cell) => cell !== null && cell !== ''));

        exportToExcel(tabDados, fileName);

        toast.success("A ação foi concluída.");
      }
      //Pessoal atualizei o ambiente e fiz a correção que faltava envolvendo as imagens que mencionamos na reunião
      else
        sweetAlertContext.alert("Atenção", "Não há dados a serem exportados de acordo com os filtros realizados.");
    } catch (err) {
      console.log(err);
      formRef.current?.setErrors(getValidationErrors(err));
    }
    finally {
      setTableLoading(false);
    }
  };

  return (
    <>
      <div className="mb-3 col pr-0 text-right">
        <span className="d-none d-sm-inline">
          <Button onClick={handleSave} variant="white">
            Exportar
          </Button>
        </span>
      </div>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
            <div className="form-row">
              <div className="col form-group">
                <label className="form-label">Tipo de produto</label>
                <Select
                  name="tipoProduto"
                  placeholder="Selecione um tipo..."
                  options={tipoMaterial}
                  onChange={(e) => handleTipoMaterialReferencia(e)}
                ></Select>
              </div>
            </div>

            <div className="form-row">
              <div className="col form-group">
                <label className="form-label">Situação</label>
                <Select
                  name="situacao"
                  placeholder="Selecione uma opção..."
                  options={situacao}
                  isClearable
                ></Select>
              </div>
            </div>

            <div className="form-row">
              <div className="col form-group">
                <label className="form-label">Status</label>
                <Select
                  name="status"
                  placeholder="Selecione uma opção..."
                  options={status}
                  isClearable
                ></Select>
              </div>
            </div>

            <div className="form-row">
              <div className="col form-group">
                <label className="form-label">Referência</label>
                <Select
                  name="referencia"
                  placeholder="Selecione uma opção..."
                  options={referencia}
                  onChange={(e) => handleReferenciaLinha(e)}
                  isClearable
                ></Select>
              </div>
            </div>

            <div className="form-row">
              <div className="col form-group">
                <label className="form-label">Linha</label>
                <Select
                  name="linha"
                  placeholder="Selecione uma opção..."
                  options={linha}
                  isClearable
                ></Select>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default TabImagens;
