import XLSX from "xlsx";

export function excelToJson(file, callback) {

    var reader = new FileReader();

    reader.onload = function (e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });

        const wsname = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsname];

        const jsonData = XLSX.utils.sheet_to_json(ws);

        callback(jsonData);
    };

    reader.readAsArrayBuffer(file);
}

export function excelToJsonMultiTabs(file, tabs, callback) {

    var reader = new FileReader();

    reader.onload = function (e) {
        const tabDados = [];
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });

        tabs.forEach((tab) => {        
            const wsname = workbook.SheetNames[tab];      
            const ws = workbook.Sheets[wsname];      

            tabDados.push(XLSX.utils.sheet_to_json(ws));
        });

        callback(tabDados);
    };

    reader.readAsArrayBuffer(file);
}