import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { Select } from "../../../../../components/Form";
import { Row, Button } from "react-bootstrap";

import DataTable from "../../../../../components/DataTable";
import DataNotFound from "../../../../../components/DataNotFound";
import api from "../../../../../services/api";

import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import getValidationErrors from "../../../../../helpers/getValidateErrors";

import { IListLiberacaoPreference, IListAplicabilidade } from "../../../../../common/interfaces/liberacoes.interfaces";
import { ToastErrorContext } from "../../../../../contexts/toast/toastErrorContext";
import { useAuth } from "../../../../../hooks/auth";
import { toast } from "react-toastify";

interface IAplicabilidade {
  dados: IListLiberacaoPreference
}

const TabAplicabilidades: React.FC<IAplicabilidade> = (props) => {

  //Fields Typed
  const DataTableTS: any = DataTable;

  const { user } = useAuth();

  // Refs
  const formRef = useRef<FormHandles>(null);

  // Context
  const toastContext = useContext(ToastContext);
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastError = useContext(ToastErrorContext);

  //States
  const [tableData, setTableData] = useState<IListAplicabilidade[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [referencias, setReferencias] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      Promise.all([
        api.get('geral/referenciaAplicabilidadeExistente')
      ]).then((response: any) => {
        setReferencias(response.at(0).data)
      }).catch((err) => toast.error(err.message)).finally(() => setTableLoading(false));
    }

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        const { data } = await api.get(`liberacaoPreference/liberacaoAplicabilidade/${props.dados.serial}`);
        setTableData(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    }

    fetchData();
  }, [reloadTable]);

  //Handles
  const handleSubmit: SubmitHandler = async (dados) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        referencia: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
      });

      await schema.validate(dados, { abortEarly: false });
      
      var liberacaoAplicabilidade = {
        idEmpresa: dados.referencia.value,
        idSerial: props.dados.serial
      };

      dados.Serial = props.dados.serial;
      await api.post('liberacaoPreference/salvarLiberacaoAplicabilidade', dados);

      formRef.current?.clearField('referencia');
      toast.success("A ação foi concluída.");
      setReloadTable(reload => !reload);
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleDelete = ({ id, referencia }: IListAplicabilidade) => {
    const title = `Excluir "${referencia}"? `;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`liberacaoPreference/removerLiberacaoAplicabilidade/${id}`);
        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Empresas",
        accessor: "empresa",
        display: "align-middle",
      },
      {
        Header: "Data",
        accessor: "dataAtualizacao",
        display: "align-middle",
        render: (value: any) => {
          return (<>
            {value.dataAtualizacao ? new Date(value.dataAtualizacao).toLocaleDateString("dd/mm/yyyy") : ""}
          </>)
        }
      },
    ],
    []
  );

  if (user?.role.indexOf("ConsultaLiberacao") === -1) {
    const deleteColumn = {
      Header: "Ação",
      accessor: "acao",
      disableSortBy: true,
      display: "align-middle column-table-1b",
      Cell: ({ row: { original } }: { row: { original: IListAplicabilidade } }) => (
        <Button variant="ghost-dark" className="p-2" onClick={() => handleDelete(original)}>
          <i className="far fa-trash-alt icon m-0"></i>
        </Button>
      ),
    }

    columns.push(deleteColumn);
  }


  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row px-3 align-items-center">
            <div className="col">
              <h5 className="mb-2">Razão Social:</h5>
              <p className="mb-0">{props.dados.razaoSocial}</p>
            </div>
            <div className="col">
              <h5 className="mb-2">Nome Fantasia:</h5>
              <p className="mb-0">{props.dados.nomeFantasia}</p>
            </div>
            <div className="col-auto">
              <h5 className="mb-2">Serial:</h5>
              <p className="mb-0">{props.dados.serial}</p>
            </div>
          </div>
        </div>
      </div>

      {user!.role.indexOf("Liberacao") >= 0 && (
        <Form ref={formRef} onSubmit={handleSubmit} className="mt-3">
          <Row>
            <div className="col">
              <label className="form-label">Empresas</label>
              <Select
                name="referencia"
                options={referencias}
                placeholder="Selecione uma opção..."
              />
            </div>
            <div className="col-auto">
              <Button
                variant="white"
                style={{ marginTop: 27 }}
                onClick={() => formRef.current?.submitForm()}
              >
                Adicionar
              </Button>
            </div>
          </Row>
        </Form>
      )}

      <Row className="mt-3">
        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' small-box-loader' : '')} >

            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={
                tableLoading == false ? tableData?.length === 0 : false
              }
            ></DataNotFound>
          </div>
        </div>
      </Row>
    </>
  );
};

export default TabAplicabilidades;
