import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const Breadcrumbs: React.FC<RouteComponentProps> = props => {

    function isNumeric(n:any) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }
    
    const history = props.history;
    const {pathname} = props.location;
    const pathnames = pathname.split("/").filter(x => x);
    return (       
        <h2 className="col-auto page-title">
            
            {pathnames.map((name: string, index: number) => {
                
                const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                const formatName = name.replace(/([a-z])([A-Z])/g, '$1 $2');

                if(!isNumeric(formatName) || formatName.length >= 6) {
                    var finalName;
                    switch (formatName) {
                        case 'catalogos':
                            finalName = 'catálogos';
                            break;
                        case 'referencias':
                            finalName = 'referências';
                            break;
                        default:
                            finalName = formatName;
                    }
                    return (
                        <span key={index} className="breadcrumb-item" onClick={() => history.push(routeTo)}>{finalName}</span>
                    )
                }    
            })} 
           
        </h2>
    )
}

export default withRouter(Breadcrumbs)