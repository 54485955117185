import React from "react";

import { Form, FormControl, Image, Table, Tab, Tabs, Col, Nav, Row } from "react-bootstrap";
import TabProdutos from "./TabProdutos";
import TabAcabamentos from "./TabAcabamentos";
import TabPrecos from "./TabPrecos";
import TabAplicabilidades from "./TabAplicabilidades";
import TabImagens from "./TabImagens";


const Exportacao: React.FC = () => {
  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title">
                  Exportar
                </h2>
              </div>

            

              <div className="card mt-3 p-0">
                <div className="card-body">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="produtos"
                  >
                    <Row>
                      <Col className="col-3">
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="produtos">Produtos</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="acabamentos">Acabamentos</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="precos">Preços</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>                        
                            <Nav.Link eventKey="aplicabilidades">Aplicabilidades</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="imagens">Imagens</Nav.Link>
                          </Nav.Item>                         
                        </Nav>
                      </Col>
                      <Col>
                        <Tab.Content>
                          <Tab.Pane eventKey="produtos">
                            <TabProdutos/>
                          </Tab.Pane>
                          <Tab.Pane eventKey="acabamentos">
                            <TabAcabamentos/>
                          </Tab.Pane>
                          <Tab.Pane eventKey="precos">
                            <TabPrecos/>
                          </Tab.Pane>
                          <Tab.Pane eventKey="aplicabilidades">
                         <TabAplicabilidades/>
                          </Tab.Pane>
                          <Tab.Pane eventKey="imagens">
                          <TabImagens/>
                          </Tab.Pane>                          
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exportacao;
