import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import { IListAcabamentos, IListLogMaterial } from "../../../common/interfaces/importar.interfaces";
import { File, Select } from "../../../components/Form";
import { exportToExcel } from "../../../helpers/ExportToExcel";
import { excelToJson } from "../../../helpers/ExcelToJson";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import getValidationErrors from "../../../helpers/getValidateErrors";
import DataTable from "../../../components/DataTable";
import DataNotFound from "../../../components/DataNotFound";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";

const TabAcabamento: React.FC = () => {
  // Refs
  const formRef = useRef<FormHandles>(null);

  //Fields Typed
  const DataTableTS: any = DataTable;

  //States
  const [tableData, setTableData] = useState<IListAcabamentos[]>([]);
  const [logImportacao, setLogImportacao] = useState<IListLogMaterial[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [homologar, setHomologar] = useState(true);
  const [disponivel, setDisponivel] = useState(true);
  const [referencias, setReferencias] = useState([]);
  const [show, setShow] = useState(false);

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        const request = await api.get("tratamento/ListaImportacao"); // passar parametro (string referencia)
        setTableData(request.data);

        const { data } = await api.get("geral/ReferenciaTratamentoBase"); //ok backend
        setReferencias(data);

        const acaoPermitida = await api.get('tratamento/AcaoPermitida'); //ok backend
        handleAcaoPermitida(acaoPermitida.data);
      } catch (error) {
      }      
    })();
  }, [reloadTable]);


  //Handles
  const handleClose = () => setShow(false);

  const handleAcaoPermitida = ({ homologar, disponibilizar }: any) => {
    setHomologar(homologar);
    setDisponivel(disponibilizar);
  }

  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handlePlanilhaPadrao = async () => {
    const dadosExportacao = await api.get(`tratamento/ExportarEstrutura`); //ok backend
    exportToExcel([{ data: dadosExportacao.data, name: "ACABAMENTOS" }], "ACABAMENTOS");
  }

  const handleSubmit: SubmitHandler = async (acabamento) => {
    try {
      formRef.current?.setErrors({});

      const SUPPORTED_FORMATS = [
        "application/excel",
        "application/vnd.ms-excel",
        "application/msexcel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        referencia: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        arquivo: Yup.mixed().test(
          "fileFormat",
          "O arquivo deve ser .xls",
          (value) => value && SUPPORTED_FORMATS.includes(value.type)
        ),
      });
      await schema.validate(acabamento, { abortEarly: false });

      //Passou pela validação
      setTableLoading(true);
      const { data: { existeImportacao } } = await api.get(`tratamento/ImportacaoAndamento`); // passar parametro (string referencia)

      if (existeImportacao) {
        sweetAlertContext.warning("Atenção", `Ao importar a planilha, os acabamentos em edição poderão sofrer modificações. Deseja continuar?`, async () => {
           
          await handleImportarPlanilha(acabamento);
        }, async () => {setTableLoading(false);});
      }
      else {
        await handleImportarPlanilha(acabamento);
      }
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleImportarPlanilha = async (acabamento: any) => {

    excelToJson(acabamento.arquivo, async (conteudo: any) => {
      try {
        var arquivo = new FormData();
        arquivo.append("arquivo", acabamento.arquivo);
        arquivo.append("conteudo", JSON.stringify(conteudo));
        arquivo.append("referencia", acabamento.referencia.label);

        const importacao = await api.post(`tratamento/ImportarPlanilha`, arquivo);
        acabamento.id = importacao.data.id;

        if (importacao.data.logs.length == 0) {
          setReloadTable(reload => !reload);
          toast.success("A ação foi concluída.");
        }
        else {
          setLogImportacao(importacao.data.logs);
          setShow(true);
        }
      } catch (err) {
        console.log(err);
        toast.error(getSingleErrorMessage(err));
      }
      finally {
        setTableLoading(false);
      }
    });
  }

  const handleDelete = async ({ id, referencia, situacao }: IListAcabamentos) => {
    const title = `Excluir ${referencia} "${situacao}"?`;
    const message =
      "Os itens na situação " + "Edição" + " que foram importados por meio dessa planilha serão excluídos. Deseja continuar?";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`tratamento/RemoverImportacao/${id}`); //ok backend
        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (err) {
        toast.error(getSingleErrorMessage(err));
      }
    });
  };


  const columns = useMemo(
    () => [
      {
        Header: "Referência",
        accessor: "referencia",
        display: "align-middle",
      },
      {
        Header: "Atualização",
        accessor: "dataCriacao",
        display: "align-middle",
      },
      {
        Header: "Situação",
        accessor: "situacao",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle",
        Cell: ({
          row: { original },
        }: {
          row: { original: IListAcabamentos };
        }) => (
          <span className="d-none d-sm-inline">
            {original.linkArquivo &&
              <Button onClick={(e) => {
                e.preventDefault();
                window.open(original.linkArquivo, '_blank');
              }}
                title="Download" variant="ghost-dark" className="p-2">
                <i className="fas fa-file-download icon m-0"></i>
              </Button>
            }
            {
              
              original.situacao == "Em Edição" &&
                    <Button
                    title="Excluir"
                    variant="ghost-dark"
                    className="p-2"
                    onClick={() => handleDelete(original)}
                  >
                    <i className="far fa-trash-alt icon m-0"></i>
                  </Button>
            }
          
          </span>
        ),
      },
    ], []);

  return (
    <>
      <div className="mb-3 col pr-0 text-right">
        <span className="d-none d-sm-inline">
          <Button className="mr-2" variant="white"
            onClick={handlePlanilhaPadrao}
          >
            <i className="fas fa-file-download"></i> Planilha Padrão
          </Button>          
          <Button onClick={handleSave} variant="white">
            Importar
          </Button>
        </span>
      </div>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col form-group">
            <label className="form-label">Arquivo</label>
            <File name="arquivo" placeholder="Procurar arquivo..." />
          </div>
          <div className="col form-group">
            <label className="form-label">Referência</label>
            <Select
              name="referencia"
              placeholder="Selecione uma referência..."
              options={referencias}
            ></Select>
          </div>
        </div>
      </Form>

      <div className="mt-3">
        <div className={"dimmer" + (tableLoading ? " active" : "")}>
          <div className="loader"></div>
          <div
            className={
              "dimmer-content" + (tableLoading ? " medium-box-loader" : "")
            }
          >
            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                // fetchData={fetchData}
                reload={reloadTable}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={tableLoading == false ? tableData?.length === 0 : false}
            ></DataNotFound>
          </div>
        </div>
      </div>

      {/* Modal Log */}
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            Log de Importação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-vcenter card-table">
            <thead>
              <tr>
                <td className="font-weight-bold">Linha</td>
                <td className="font-weight-bold">Mensagem</td>
              </tr>
            </thead>
            {logImportacao.map((data: IListLogMaterial, index: number) => (
              <tr key={index}>
                <td className="text-muted">{data.numeroLinhaArquivo}</td>
                <td className="text-muted">{data.mensagem}</td>
              </tr>
            ))}
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default TabAcabamento;
