import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import api from "../../../../services/api";
import { base64toBlob } from "../../../../helpers/utils";
import DataTable from "../../../../components/DataTable";
import Collapsible from "react-collapsible";
import {
  IOutrasCaracteristicas,
  IAplicabilidade,
  IVidroCor,
} from "../../../../common/interfaces/produtos.interfaces";
import Placeholder from "../../../../assets/imgs/img-placeholder.png";
import { useAuth } from "../../../../hooks/auth";
import BackButton from "../../../../components/ButtonBack";

interface IVidro {
  dados: any;
}

const ItemVidro: React.FC<IVidro> = (props) => {
  // URL Params
  const { pId }: any = useParams();
  function havePermission(permission: string): boolean {
    var isValid = user?.role.indexOf(permission) !== -1;
    return isValid;
  }
  const { user } = useAuth();

  const [mostrarModalVerMais, setMostrarModalVerMais] = useState(false);
  const [mostrarModalAplicabilidade, setMostrarModalAplicabilidade] =
    useState(false);
  const modalVerMais = () => setMostrarModalVerMais(true);
  const modalAplicabilidade = () => setMostrarModalAplicabilidade(true);
  const handleClose = () => [
    setMostrarModalVerMais(false),
    setMostrarModalAplicabilidade(false),
  ];

  const baseUrlImagem =
    process.env.REACT_APP_API + "figurasicones/vidros/figuras/";

  // States
  const emValidacao = useRef("");
  const [outrasCaracteristica, setOutrasCaracteristica] = useState<
    IOutrasCaracteristicas[]
  >([]);
  const [corVidros, setCorVidro] = useState([]);
  const [logoPreview, setLogoPreview] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modoEdicao, setModoEdicao] = useState(false);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        emValidacao.current =
          props.dados?.situacao?.label == "Em Edição" ||
          props.dados?.situacao?.label == "Em Homologação"
            ? "validacao/"
            : "";
        emValidacao.current =
          props.dados?.situacao?.label == "Em Edição" ||
          props.dados?.situacao?.label == "Em Homologação"
            ? "validacao/"
            : "";
        const outrasCaracteristicas = await api.get(
          `produto/caracteristicaMaterial/${props.dados?.codigo.replace('/', '')}/VIDRO`
        );
        const linhaAplicacao = await api(
          `produto/logoLinhaAplicavel/${pId}/VIDRO`
        );
        const corVidro = await api.get(`vidro/detalheCor/${pId}`);

        setOutrasCaracteristica(outrasCaracteristicas.data);
        setLogoPreview(linhaAplicacao.data);
        setCorVidro(corVidro.data);

        if (user?.role.indexOf("ConsultaProduto") === -1) {
          const modoEdicao = await api.get("produto/modoEdicaoValue");
          setModoEdicao(modoEdicao.data);
        }
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-sm-5 pl-0">
                      <div className="card card-sm">
                        <Image
                          src={
                            props.dados?.figura
                              ? baseUrlImagem +
                                emValidacao.current +
                                props.dados?.figura
                              : Placeholder
                          }
                          onError={(event: any) =>
                            (event.target.src = Placeholder)
                          }
                          className="card-img-top"
                        />
                      </div>
                    </div>
                    <div className="col-sm-7 pl-4 pr-0">
                      <div className="card-body pb-0">
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="mt-3 mb-3">
                              <Image
                                src={
                                  props.dados?.linkLogoReferencia != null
                                    ? props.dados.linkLogoReferencia
                                    : Placeholder
                                }
                                onError={(event: any) =>
                                  (event.target.src = Placeholder)
                                }
                                height="30px"
                                className="font-weight-bold"
                              />
                            </div>
                            <div className="col-sm-12 my-3 p-0">
                              {props.dados?.linkDesenhoTecnico && (
                                <Button
                                  className="btn btn-mof btn-sm mr-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(
                                      props.dados?.linkDesenhoTecnico,
                                      "_blank"
                                    );
                                  }}
                                >
                                  Ficha Técnica
                                  <i className="far fa-file-pdf pl-3"></i>
                                </Button>
                              )}
                            </div>
                            <div className="font-weight-bold">
                              {props.dados?.codigo}
                            </div>
                            <div>{props.dados?.descricao}</div>
                            <div className="row text-muted">
                              <div className="col-sm-12">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="row">
                                      <div className="col-sm-12">
                                        <span className="font-weight-bold">
                                          Tipo do Vidro:
                                        </span>
                                        <span className="ml-1">
                                          {props.dados?.tipoVidro?.label}
                                        </span>
                                      </div>
                                      <div className="col-sm-12">
                                        <span className="font-weight-bold">
                                          Espessura:
                                        </span>
                                        <span className="ml-1">
                                          {props.dados?.espessura}
                                        </span>
                                      </div>
                                      <div className="col-sm-12">
                                        <span className="font-weight-bold">
                                          Cor:
                                        </span>
                                        <span className="ml-1">
                                          {corVidros.map(({ cor }, index) => (
                                            <>{index != 0 ? `, ${cor}` : cor}</>
                                          ))}
                                        </span>
                                      </div>
                                      <div className="col-sm-12">
                                        <span className="font-weight-bold">
                                          Controle Solar:
                                        </span>
                                        <span className="ml-1">
                                          {props.dados?.controleSolar?.label}
                                        </span>
                                      </div>
                                      <div className="col-sm-12">
                                        <span className="font-weight-bold">
                                          Bloqueio Ruido:
                                        </span>
                                        <span className="ml-1">
                                          {props.dados?.bloqueioRuido?.label}
                                        </span>
                                      </div>
                                      <div className="mt-3 col-sm-12">
                                        {modoEdicao && (
                                          <span className="badge badge-xl bg-green-lt text-uppercase h4">
                                            {props.dados?.situacao?.label}
                                          </span>
                                        )}
                                        <span
                                          className={`${
                                            modoEdicao ? "ml-1" : ""
                                          } badge badge-xl bg-azure-lt text-uppercase h4`}
                                        >
                                          {props.dados?.status?.label}
                                        </span>
                                      </div>
                                      <div className="mt-3 col-sm-12">
                                        <Button
                                          onClick={modalVerMais}
                                          variant="white"
                                          className="mr-2"
                                        >
                                          Ver mais
                                        </Button>
                                        {!havePermission("ConsultaProduto") &&
                                          modoEdicao && (
                                            <Link
                                              to={`../../../../produtos/${props.dados.id}/${props.dados.codigo.replace('/', '')}/vidro`}
                                              className="btn btn-white"
                                              title="Editar"
                                            >
                                              <i className="far fa-edit icon m-0"></i>
                                            </Link>
                                          )}
                                      </div>
                                      <div className="mt-3 col-sm-12">
                                        <BackButton />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {logoPreview.length != 0 && (
                    <div className="card row mt-3">
                      <div className="card-header">
                        <h3 className="card-title">APLICÁVEL NAS LINHAS:</h3>
                      </div>
                      <div className="card-body">
                        <div className="col-sm-12">
                          <div className="row">
                            {logoPreview.map((logoReferencia, index) => (
                              <div className="col-sm-3" key={index}>
                                {
                                  <Image
                                    src={URL.createObjectURL(
                                      base64toBlob(logoReferencia)
                                    )}
                                    width="80px"
                                  />
                                }
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {outrasCaracteristica.length != 0 && (
                    <div className="card row mt-3">
                      <div className="card-header">
                        <h3 className="card-title">OUTRAS CARACTERÍSTICAS:</h3>
                      </div>
                      <div className="card-body">
                        <div className="row text-muted">
                          <div className="col-sm-12">
                            <div className="row">
                              {outrasCaracteristica.map(
                                ({ nome, descricao }, index) => (
                                  <div className="col-sm-6" key={index}>
                                    <span className="font-weight-bold">
                                      {nome}:
                                    </span>{" "}
                                    {descricao}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Ver Mais*/}
      <Modal size="lg" show={mostrarModalVerMais} onHide={handleClose}>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Ver Mais</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-vcenter card-table ver-mais">
            <tr>
              <td className="border-0 font-weight-bold min-30">Código: </td>
              <td className="border-0">{props.dados?.codigo}</td>
            </tr>
            <tr>
              <td className="font-weight-bold min-30">Descrição: </td>
              <td className="text-muted">{props.dados?.descricao}</td>
            </tr>
            {props.dados?.codigoInterno && (
              <tr>
                <td className="font-weight-bold min-30">Código Interno: </td>
                <td className="text-muted">{props.dados?.codigoInterno}</td>
              </tr>
            )}
            <tr>
              <td className="font-weight-bold">Referência: </td>
              <td className="text-muted">{props.dados?.referencia?.label}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Linha: </td>
              <td className="text-muted">{props.dados?.linha?.label}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Tipo de Vidro: </td>
              <td className="text-muted">{props.dados?.tipoVidro?.label}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Espessura: </td>
              <td className="text-muted">{props.dados?.espessura}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Peso: </td>
              <td className="text-muted">{props.dados?.peso}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Fator LH: </td>
              <td className="text-muted">{props.dados?.fatorlh}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Arredondamento: </td>
              <td className="text-muted">
                {props.dados?.arredondamento?.label}
              </td>
            </tr>
            {props.dados?.ipi != "" && (
              <tr>
                <td className="font-weight-bold">IPI: </td>
                <td className="text-muted">{props.dados?.ipi}</td>
              </tr>
            )}
            <tr>
              <td className="font-weight-bold">Cores: </td>
              <td className="text-muted">
                {corVidros.map((data: any, index) => (
                  <Collapsible trigger={data.cor} key={index}>
                    <tr>
                      <td className="font-weight-bold">Trans. Térmica: </td>
                      <td className="text-muted">{data.transmissaoTermica}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Reflexão Externa: </td>
                      <td className="text-muted">{data.reflexaoExterior}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Reflexão Interna: </td>
                      <td className="text-muted">{data.reflexaoInterior}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Bloqueio de Calor: </td>
                      <td className="text-muted">{data.bloqueioCalor}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Trans. Lumin.: </td>
                      <td className="text-muted">
                        {data.transmissaoLuminosidade}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Absorção Energética:</td>
                      <td className="text-muted">{data.absorcaoEnergetica}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Fator Solar: </td>
                      <td className="text-muted">{data.fatorSolar}</td>
                    </tr>
                  </Collapsible>
                ))}
              </td>
            </tr>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ItemVidro;
