import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import getValidationErrors from "../../../../../helpers/getValidateErrors";
import DataTable from "../../../../../components/DataTable";
import DataNotFound from "../../../../../components/DataNotFound";
import api from "../../../../../services/api";
import { File } from "../../../../../components/Form";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";

import {
  IListInformativo,
} from "../../../../../common/interfaces/referencias.interfaces";
import { ToastErrorContext } from "../../../../../contexts/toast/toastErrorContext";
import { useParams } from "react-router-dom";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { toast } from "react-toastify";
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";

const TabInformativo: React.FC = () => {

  // URL Params
  const { pId }: any = useParams();

  //Fields Typed
  const DataTableTS: any = DataTable;

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const errorContext = useContext(ToastErrorContext);

  //States
  const [tableData, setTableData] = useState<IListInformativo[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);

  //Context
  const toastError = useContext(ToastErrorContext);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);
        const request = await api.get(`referencia/informativo/${pId}`);
        setTableData(request.data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, [reloadTable]);

  //Handles
  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handleDelete = ({ id }: IListInformativo) => {
    const title = `Excluir Informativo?`;
    const message = "Esse informativo será excluído, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {

        await api.delete(`referencia/removerInformativo/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        toast.error(getSingleErrorMessage(error));
      }
    });
  };

  const handleSubmit: SubmitHandler = async (infomativo, { reset }) => {
    try {
      formRef.current?.setErrors({});

      const SUPPORTED_FORMATS = [
        "text/html"
      ];
      const schema = Yup.object().shape({
        arquivo: Yup.mixed().test(
          "fileFormat",
          "O arquivo deve ser .html",
          (value) => value && SUPPORTED_FORMATS.includes(value.type)
        ),
      });
      await schema.validate(infomativo, { abortEarly: false });
      //Passou pela validação

      var arquivo = new FormData();
      arquivo.append("arquivo", infomativo.arquivo);
      arquivo.append("idReferencia", pId);

      await api.post('referencia/UploadInformativo', arquivo);

      reset();

      setReloadTable(reload => !reload);
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Informativo",
        accessor: "informativo",
        display: "align-middle",
      },
      {
        Header: "Envio",
        accessor: "dataCadastro",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle column-table-2b",
        Cell: ({
          row: { original },
        }: {
          row: { original: IListInformativo };
        }) => (
          <span className="d-none d-sm-inline">
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open(original.urlHTML, '_blank');
              }}
              title="Visualizar Informativo"
              variant="ghost-dark"
              className="btn btn-ghost-dark p-2"
            >
              <i className="fas fa-file-invoice icon m-0"></i>
            </Button>

            <Button
              title="Excluir"
              variant="ghost-dark"
              className="btn btn-ghost-dark p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="mb-3 col pr-0 text-right">
        <span className="d-none d-sm-inline">
          <Button onClick={handleSave} variant="white">
            Importar
          </Button>
        </span>
      </div>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col form-group">
            <label className="form-label">Arquivo</label>
            <File name="arquivo" placeholder="Procurar arquivo..." />
          </div>
        </div>
      </Form>

      <div className="mt-3">
        <div className={"dimmer" + (tableLoading ? " active" : "")}>
          <div className="loader"></div>
          <div
            className={
              "dimmer-content" + (tableLoading ? " medium-box-loader" : "")
            }
          >
            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                // fetchData={fetchData}
                reload={reloadTable}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={tableLoading == false ? tableData?.length === 0 : false}
            ></DataNotFound>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabInformativo;
