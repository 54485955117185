
import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Contact from '../pages/Contact';
import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import Usuarios from '../pages/Usuarios';
import Empresas from '../pages/Empresas';
import EmpresaDetalhes from '../pages/Empresas/Empresa';
import PerfisEmpresas from '../pages/Empresas/Empresa/TabEmpresa/Perfis';
import PerfilEmpresas from '../pages/Empresas/Empresa/TabEmpresa/Perfis/Perfil';
import EsqueceuSuaSenha from '../pages/SignIn/EsqueceuSuaSenha';
import MeuPrimeiroAcesso from '../pages/SignIn/MeuPrimeiroAcesso';
import NovaSenha from '../pages/SignIn/NovaSenha';
import Referencias from '../pages/Referencias';
import ReferenciaDetalhes from '../pages/Referencias/Referencia';
import TabLinhasNovaLinha from '../pages/Referencias/Referencia/TabLinhas/NovaLinha';


import ClassesID from '../pages/ClassesID';
import Cores from '../pages/Cores';
import TipoAcabamento from '../pages/TipoDeAcabamento';
import Acabamento from '../pages/Acabamento';
import Grupos from '../pages/Grupos';
import TiposDeVidros from '../pages/TiposDeVidros';
import Unidades from '../pages/Unidades';
import Produtos from '../pages/Produtos';
import ProdutoDetalhes from '../pages/Produtos/Produto';
import Itens from '../pages/Produtos/Item';
import AplicabilidadeBase from '../pages/Produtos/AplicabilidadeBase';
import Importar from '../pages/Importar';
import Catalogos from '../pages/Catalogos';
import CatalogoDetalhes from '../pages/Catalogos/Catalogo';
import Exportacao from '../pages/Exportacao';
import Liberacoes from '../pages/Liberacoes';
import LiberacaoAlumisoft from '../pages/Liberacoes/TabLiberacaoAlumisoft/Liberacao';
import TabLiberacaoPreference from '../pages/Liberacoes/TabLiberacaoPreference/Atualizacao';
import Linhas from '../pages/Linhas';
import Prenativo from '../pages/Prenativo';
import MinhaConta from '../pages/MinhaConta';
import Ligas from '../pages/Ligas';
import Liga from '../pages/Ligas/Liga';
import Tempera from '../pages/Ligas/Tempera';
import Desenhador from '../pages/Desenhador';


const Routes: React.FC = () => (
    <Switch>
        {/* Rotas Publicas */}
        <Route path="/" component={SignIn} exact  />
        <Route path="/esqueceuSuaSenha" component={EsqueceuSuaSenha} exact  />
        <Route path="/meuPrimeiroAcesso/:pId" component={MeuPrimeiroAcesso} exact  />
        <Route path="/novaSenha/:pId" component={NovaSenha} exact  />
        <Route path="/contact" component={Contact}  />

        {/* Rotas Privadas */}
        <Route path="/dashboard" component={Dashboard} isPrivate  />
        <Route path="/empresas"  component={Empresas} isPrivate exact/>        
        <Route path="/empresas/:pId/:pString" component={EmpresaDetalhes} isPrivate exact />
        <Route path="/empresas/:pId/:pString/perfis" component={PerfisEmpresas} isPrivate exact />       
        <Route path="/empresas/:pId/:pString/perfis/:perfilId/:perfilString" component={PerfilEmpresas} isPrivate exact />       
        <Route path="/empresas/:pId/:pString/perfis/novoPerfil" component={PerfilEmpresas} isPrivate exact />       
        <Route path="/usuarios" exact component={Usuarios} isPrivate  />        
        <Route path="/referencias" exact component={Referencias} isPrivate  />
        <Route path="/referencias/:pId/:pString" exact component={ReferenciaDetalhes} isPrivate  />
        <Route path="/referencias/:pId/:pString/linhas/novaLinha" exact component={TabLinhasNovaLinha} isPrivate  />       
        <Route path="/classesid" exact component={ClassesID} isPrivate  />
        <Route path="/cores" exact component={Cores} isPrivate  />
        <Route path="/TipoAcabamento" exact component={TipoAcabamento} isPrivate  />
        <Route path="/Acabamento" exact component={Acabamento} isPrivate  />
        <Route path="/grupos" exact component={Grupos} isPrivate  />
        <Route path="/tiposDeVidros" exact component={TiposDeVidros} isPrivate  />
        <Route path="/unidades" exact component={Unidades} isPrivate  />
        <Route path="/produtos" exact component={Produtos} isPrivate  />
        <Route path="/produtos/aplicabilidadeBase/:pId?/:pString?" exact component={AplicabilidadeBase} isPrivate  />
        <Route path="/produtos/:pId/:pString/:pTipo?" exact component={ProdutoDetalhes} isPrivate  />
        <Route path="/produtos/item/:pId/:pString/:pTipo?" exact component={Itens} isPrivate  />
        <Route path="/importar" exact component={Importar} isPrivate  />
        <Route path="/catalogos" exact component={Catalogos} isPrivate  />
        <Route path="/catalogos/:pId/:pString" exact component={CatalogoDetalhes} isPrivate  />
        <Route path="/exportacao" exact component={Exportacao} isPrivate  />        
        <Route path="/liberacoes" exact component={Liberacoes} isPrivate  />        
        <Route path="/liberacoes/alumisoft/liberacao" exact component={LiberacaoAlumisoft} isPrivate  />        
        <Route path="/liberacoes/preference/atualizacao" exact component={TabLiberacaoPreference} isPrivate  />         
        <Route path="/linhas" exact component={Linhas} isPrivate  /> 
        <Route path="/prenativo" exact component={Prenativo} isPrivate  /> 
        <Route path="/ligas" exact component={Ligas} isPrivate  /> 
        <Route path="/ligas/:pId/tempera" exact component={Tempera} isPrivate  />
        <Route path="/ligas/:pId/:pString" exact component={Liga} isPrivate  />
        <Route path="/minhaConta" exact component={MinhaConta} isPrivate  /> 
        <Route path="/desenhador" component={Desenhador} isPrivate  />
    </Switch>
)

export default Routes;