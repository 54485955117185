import { useField } from "@unform/core";
import React, { useEffect, useRef, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { CurrencyInputProps } from "react-currency-input-field/dist/components/CurrencyInputProps";

interface InputProps extends CurrencyInputProps {
  name: string;
}

const InputCurrency: React.FC<InputProps> = ({ name, ...rest }) => {
  const { registerField, fieldName, defaultValue, error } = useField(name);
  const inputRef = useRef({ value: defaultValue });
  const [inputValue, setInputValue] = useState(defaultValue);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = inputValue;
    }
  }, [inputValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue: (_, value: string) => {
        setInputValue(value);
      },
      clearValue: () => {
        setInputValue("");
      }
    });
  }, [fieldName, registerField]);

  return (
    <>
      <CurrencyInput        
        name={name}
        defaultValue={defaultValue}
        value={inputValue}
        onChange={(value) => setInputValue(value)}
        {...rest}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
};

export default InputCurrency;
