import React from "react";
import { Form } from "react-bootstrap";
import { Input, Select, InputMask } from "../../../../components/Form";


const TabEmpresa: React.FC = () => {
 
  //Constants
  const estado = [
    {label:"AC", value:"AC"},
    {label:"AL", value:"AL"},
    {label:"AM", value:"AM"},
    {label:"AP", value:"AP"},
    {label:"BA", value:"BA"},
    {label:"CE", value:"CE"},
    {label:"DF", value:"DF"},
    {label:"ES", value:"ES"},
    {label:"GO", value:"GO"},
    {label:"MA", value:"MA"},
    {label:"MG", value:"MG"},
    {label:"MS", value:"MS"},
    {label:"MT", value:"MT"},
    {label:"PA", value:"PA"},
    {label:"PB", value:"PB"},
    {label:"PE", value:"PE"},
    {label:"PI", value:"PI"},
    {label:"PR", value:"PR"},
    {label:"RJ", value:"RJ"},
    {label:"RN", value:"RN"},
    {label:"RS", value:"RS"},
    {label:"RO", value:"RO"},
    {label:"RR", value:"RR"},
    {label:"SC", value:"SC"},
    {label:"SE", value:"SE"},
    {label:"SP", value:"SP"},
    {label:"TO", value:"TO"}    
  ];

    
  return (
    <>
      <Form.Row className="mt-4">
        <Form.Group className="col">
          <Form.Label>CNPJ</Form.Label>          
          <InputMask
            type="text"
            name="cnpj"
            className="form-control"
            placeholder="00.000.000/0000-00"
            mask="99.999.999/9999-99"
          />
        </Form.Group>

        <Form.Group className="col">
          <Form.Label>Razão Social</Form.Label>
          <Input
            type="text"
            name="razao"
            className="form-control"
            placeholder="Razão Social..."
          />
        </Form.Group>

        <Form.Group className="col">
          <Form.Label>Nome Fantasia</Form.Label>
          <Input
            type="text"
            name="fantasia"
            className="form-control"
            placeholder="Nome Fantasia..."
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group className="col">
          <Form.Label>CEP</Form.Label>
          <InputMask
            type="text"
            name="cep"
            className="form-control"
            placeholder="00000-000"
            mask="99999-999"
          />
        </Form.Group>

        <Form.Group className="col">
          <Form.Label>Endereço</Form.Label>
          <Input
            type="text"
            name="endereco"
            className="form-control"
            placeholder="Endereço..."
          />
        </Form.Group>

        <Form.Group className="col-2">
          <Form.Label>Número</Form.Label>
          <Input
            type="text"
            name="numero"
            className="form-control"
            placeholder="Número..."
            maxLength={5}
            onlyNumbers
          />
        </Form.Group>

        <Form.Group className="col-2">
          <Form.Label>Complemento</Form.Label>
          <Input
            type="text"
            name="complemento"
            className="form-control"
            placeholder="Complemento..."
          />
        </Form.Group>
      </Form.Row>

      <Form.Row className="mb-4">
        <Form.Group className="col">
          <Form.Label>Bairro</Form.Label>
          <Input
            type="text"
            name="bairro"
            className="form-control"
            placeholder="Bairro..."
          />
        </Form.Group>

        <Form.Group className="col">
          <Form.Label>Estado</Form.Label>
          <Select name="estado" placeholder="Estado..." options={estado}/>            
        </Form.Group>

        <Form.Group className="col">
          <Form.Label>Cidade</Form.Label>
          <Input
            type="text"
            name="cidade"
            className="form-control"
            placeholder="Cidade..."
          />
        </Form.Group>
      </Form.Row>

     
    </>
  );
};

export default TabEmpresa;
