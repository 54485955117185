import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { ToastContext } from "../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../contexts/sweetAlert/alertContext";
import getValidationErrors from "../../../../helpers/getValidateErrors";
import DataTable from "../../../../components/DataTable";
import DataNotFound from "../../../../components/DataNotFound";
import api from "../../../../services/api";
import { Input, File, Select } from "../../../../components/Form";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import { getSingleErrorMessage } from "../../../../helpers/getSingleErrorMessage";

import {
  IFormTabelaPreco,
  IListTabelaPreco,
  IListLogMaterial,
} from "../../../../common/interfaces/importar.interfaces";
import { excelToJson } from "../../../../helpers/ExcelToJson";
import { ToastErrorContext } from "../../../../contexts/toast/toastErrorContext";
import { toast } from "react-toastify";

interface IModal {
  setNovo: any;
  novo: any;
  setHomologar: any;
  tabela: any;
}

const ModalNovaTabela: React.FC<IModal> = ({ novo, tabela }) => {

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);

  //Fields Typed
  const DataTableTS: any = DataTable;

  //States
  const [show, setShow] = useState(false);
  const [logImportacao, setLogImportacao] = useState<IListLogMaterial[]>([]);
  const [tableData, setTableData] = useState<IListTabelaPreco[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [reloadTable, setReloadTable] = useState(false);
  const [referencias, setReferencias] = useState([]);

  //Constants
  const tiposProdutos = [{ label: "Componentes", value: "COMPONENTE" }, {label: "Vidros", value: "VIDRO"}];


  //Effects
  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);

      if (tabela.id != undefined) {
        await api.get(`tabelaPreco/listaImportacao/${tabela.id}`)
          .then((response: any) => {
            setTableData(response.data);
          }).catch((err) => toast.error(err.message)).finally(() => setTableLoading(false));
      }
    }

    fetchData();
  }, [reloadTable]);

  useEffect(() => {
    const fetchData = async () => {
      
      setTableLoading(true);
      await api.get('geral/selecaoReferenciaTabela')
        .then((response: any) => {
          setReferencias(response.data);
        }).catch((err) => toast.error(err.message)).finally(() => setTableLoading(false));
    }

    fetchData();
  }, []);

  //Handle
  const handleClose = () => setShow(false);

  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handleDelete = async ({ id, nome }: IListTabelaPreco) => {
    const title = `Excluir "${nome}"?`;
    const message = "Esse produto será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`tabelaPreco/removerImportacao/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleSubmit: SubmitHandler = async (novaTabela) => {
    try {
      formRef.current?.setErrors({});
      const SUPPORTED_FORMATS = [
        "application/excel",
        "application/vnd.ms-excel",
        "application/msexcel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        nome: Yup.string().required(message),
        tipoProduto: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        arquivo: Yup.mixed().test(
          "fileFormat",
          "O arquivo deve ser .xls",
          (value) => value && SUPPORTED_FORMATS.includes(value.type)
        ),
      });
      await schema.validate(novaTabela, { abortEarly: false });

      setTableLoading(true);
      await handleImportarPlanilha(novaTabela);
    } catch (err) {
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleImportarPlanilha = async (tabelaPreco: any) => {
    excelToJson(tabelaPreco.arquivo, async (conteudo: any) => {
      try {
        var arquivo = new FormData();
        arquivo.append("arquivo", tabelaPreco.arquivo);
        arquivo.append("conteudo", JSON.stringify(conteudo));
        arquivo.append("descricao", tabelaPreco.nome);
        arquivo.append("tipoMaterial", tabelaPreco.tipoProduto.value)

        if (tabela.id != undefined)
          arquivo.append("id", tabela.id);

        const importacao = await api.post(`tabelaPreco/importarPlanilha`, arquivo);
        tabela.id = importacao.data.id;

        if (importacao.data.logs.length == 0) {
          setReloadTable(reload => !reload);
          toast.success("A ação foi concluída.");
        }
        else {
          setLogImportacao(importacao.data.logs);
          setShow(true);
        }
      } catch (err) {
        toast.error(getSingleErrorMessage(err));
      }
      finally {
        setTableLoading(false);
      }
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "Tipo",
        accessor: "tipoProduto",
        display: "align-middle",
      },
      {
        Header: "Nome",
        accessor: "nome",
        display: "align-middle",
      },
      {
        Header: "Data Atualização",
        accessor: "dataCriacao",
        display: "align-middle",
      },
      {
        Header: "Situação",
        accessor: "situacao",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle  column-table-3b",
        Cell: ({
          row: { original },
        }: {
          row: { original: IListTabelaPreco };
        }) => (
          <span className="d-none d-sm-inline">
            {original.linkArquivo &&
              <Button onClick={(e) => {
                e.preventDefault();
                window.open(original.linkArquivo, '_blank');
              }}
                title="Download" variant="ghost-dark" className="p-2">
                <i className="fas fa-file-download icon m-0"></i>
              </Button>
            }
            <Button
              title="Excluir"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );
  return (
    <>
      <Form ref={formRef} initialData={tabela} onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col form-group">
            <label className="form-label">Nome</label>
            <Input
              disabled={!novo}
              className="form-control"
              type="text"
              name="nome"
              placeholder="Nome..."
            ></Input>
          </div>
        </div>

        <div className="form-row">
          <div className="col form-group">
            <label className="form-label">Tipo de produto</label>
            <Select
              name="tipoProduto"
              placeholder="Selecione um tipo..."
              options={tiposProdutos}
              defaultValue={tabela.tipoProduto}
              isDisabled={!novo}
            ></Select>
          </div>
          <div className="col form-group">
            <label className="form-label">Arquivo</label>
            <File
              type="file"
              name="arquivo"
              placeholder="Procurar arquivo..."
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col form-group text-right">
            <Button onClick={handleSave} variant="white">
              Importar
            </Button>
          </div>
        </div>

        <div className="mt-3">
          <div className={"dimmer" + (tableLoading ? " active" : "")}>
            <div className="loader"></div>
            <div
              className={
                "dimmer-content" + (tableLoading ? " small-box-loader" : "")
              }
            >
              {tableData && (
                <DataTableTS
                  columns={columns}
                  data={tableData}
                  // fetchData={fetchData}
                  reload={reloadTable}
                  bordered={false}
                  initialState={{
                    sortBy: [
                      {
                        id: "id",
                        desc: false,
                      },
                    ],
                  }}
                />
              )}
              <DataNotFound
                visible={
                  tableLoading == false ? tableData?.length === 0 : false
                }
              ></DataNotFound>
            </div>
          </div>
        </div>
      </Form>

      {/* Modal Log */}
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            Log de Importação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-vcenter card-table">
            <thead>
              <tr>
                <td className="font-weight-bold">Linha</td>
                <td className="font-weight-bold">Código</td>
                <td className="font-weight-bold">Mensagem</td>
              </tr>
            </thead>
            {logImportacao.map((data: IListLogMaterial, index: number) => (
              <tr key={index}>
                <td className="text-muted">{data.numeroLinhaArquivo}</td>
                <td className="text-muted">{data.codigo}</td>
                <td className="text-muted">{data.mensagem}</td>
              </tr>
            ))}
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default ModalNovaTabela;
