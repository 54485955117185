import React from 'react';

import { AuthProvider } from './auth';
import { SweetAlertProvider } from '../contexts/sweetAlert/alertContext'
import { ToastProvider } from '../contexts/toast/toastContext'
import { ToastErrorProvider } from '../contexts/toast/toastErrorContext'
import { ProdutoProvider } from '../contexts/produtos/produtosContext';

const AppProvider: React.FC = ({ children }) => (
    <AuthProvider>
        <SweetAlertProvider>
            <ToastProvider>
                <ToastErrorProvider>
                    <ProdutoProvider>
                        {children}
                    </ProdutoProvider>
                </ToastErrorProvider>
            </ToastProvider>
        </SweetAlertProvider>
    </AuthProvider>
)

export default AppProvider;