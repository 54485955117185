import React, { useState, useMemo, useCallback, useContext } from "react";
import { Button } from "react-bootstrap";
import history from "../../helpers/history";

import FilterTableApi from "../../components/DataTable/filterTableApi-BuscaExt";
import DataNotFound from "../../components/DataNotFound";
import api from "../../services/api";

import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";
import { ToastContext } from "../../contexts/toast/toastContext";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";

import { IListEmpresa } from "../../common/interfaces/empresa.interfaces";

const Empresas: React.FC = () => {

  //Fields Typed
  const DataTableTS: any = FilterTableApi;

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);

  //States
  const [tableData, setTableData] = useState<IListEmpresa[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  //Callbacks
  const fetchData = useCallback(async (globalFilter) => {
    try {
      setTableLoading(true);
      const { data } = await api.get(`/empresa/lista/${globalFilter}`);
      
      setTableData(data);
    } catch (error) {
      const message = getSingleErrorMessage(error);
      if (message) {
        toastContext.notification(message, "warn");
      } else {
        toastContext.notification(
          "Ocorreu um erro ao realizar essa operação.",
          "error"
        );
      }
    } finally {
      setTableLoading(false);
    }
  }, []);

  const handleNew = () => {
    history.push(`/empresas/0/novaEmpresa`);
  };

  const handleEdit = ({ id, fantasia }: IListEmpresa) => {
    history.push(`/empresas/${id}/${fantasia}`);
  };

  const handleDelete = ({ id, fantasia }: IListEmpresa) => {
    const title = `Excluir "${fantasia}"? `;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`empresa/${id}`);
        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Razão Social",
        accessor: "razao",
        display: "align-middle",
      },
      {
        Header: "Nome Fantasia",
        accessor: "fantasia",
        display: "align-middle",
      },
      {
        Header: "CNPJ",
        accessor: "cnpj",
        display: "align-middle",
      },
      {
        Header: "Perfil",
        accessor: "perfil",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle column-table-2b",
        disableSortBy: true,
        Cell: ({ row: { original } }: { row: { original: IListEmpresa } }) => (
          <span className="d-none d-sm-inline">
            <Button
              variant="ghost-dark"
              onClick={() => handleEdit(original)}
              className="p-2"
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              variant="ghost-dark"
              onClick={() => handleDelete(original)}
              className="p-2">
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <h2 className="col-auto page-title">Empresas</h2>

              <span className="col-auto ml-auto">
                <Button variant="white" onClick={() => handleNew()}>
                  Nova Empresa
                </Button>
              </span>
              <div className="col-auto ml-0 pr-0">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Busca..."
                  value={searchTerm || ""}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="card p-0">
              <div className="card-body">
                <div className={'dimmer' + (tableLoading ? ' active' : '')}>
                  <div className="loader"></div>
                  <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >

                    {tableData && (
                      <DataTableTS
                        filter={searchTerm}
                        columns={columns}
                        data={tableData}
                        fetchData={fetchData}
                        bordered={false}
                        initialState={{
                          sortBy: [
                            {
                              id: "id",
                              desc: false,
                            },
                          ],
                        }}
                      />
                    )}

                    <DataNotFound
                      visible={
                        tableLoading == false ? tableData?.length === 0 : false
                      }
                    ></DataNotFound>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Empresas;
