import React, { useState } from "react";

import {
  Tab,
  Tabs,
  Col,
  Nav,
  Row,
  Accordion,
} from "react-bootstrap";
import TabLiberacaoAlumisoft from "./TabLiberacaoAlumisoft";
import TabPadraoAlumisoft from "./TabPadraoAlumisoft";
import TabLiberacaoPreference from "./TabLiberacaoPreference";
import TabDiversas from "./TabDiversas";
import { useAuth } from "../../hooks/auth";

const Liberacoes: React.FC = () => {
  const [state, setState] = useState("liberacoes-alumisoft");

  const { havePermission } = useAuth();

  const handleExternalKey = (eventKey: any) => {
    setState(eventKey.target.dataset.rbEventKey);
  };

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title">Liberações</h2>
              </div>

              <div className="card mt-3 p-0">
                <div className="card-body">
                  <Tab.Container
                    id="left-tabs-example"
                    activeKey={state}
                    onSelect={(k: any) => setState(k)}
                  >
                    <Row>
                      <Col className="col-2">
                        <Nav variant="pills" className="flex-column">
                          <Accordion defaultActiveKey="0">
                            <Accordion.Toggle
                              className="font-weight-bold"
                              as={Nav.Link}
                              variant="link"
                              eventKey="0"
                            >
                              Alumisoft
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                              <Nav.Item>
                                <Nav.Link
                                  className="pl-4"
                                  eventKey="liberacoes-alumisoft"
                                  onClick={handleExternalKey}
                                >
                                  Liberações
                                </Nav.Link>
                              </Nav.Item>
                            </Accordion.Collapse>
                            {havePermission(["Liberacao"]) && (
                              <Accordion.Collapse eventKey="0">
                                <Nav.Item>
                                  <Nav.Link
                                    className="pl-4"
                                    eventKey="padrao-alumisoft"
                                    onClick={handleExternalKey}>
                                    Padrão
                                  </Nav.Link>
                                </Nav.Item>
                              </Accordion.Collapse>
                            )}
                          </Accordion>
                          <Nav.Item>
                            <Nav.Link
                              className="font-weight-bold"
                              eventKey="liberacoes-preference"
                            >
                              Preference
                            </Nav.Link>
                          </Nav.Item>
                          {havePermission(["Liberacao"]) && (
                            <Nav.Item>
                              <Nav.Link
                                className="font-weight-bold"
                                eventKey="diversas"
                              >
                                Diversas
                              </Nav.Link>
                            </Nav.Item>
                          )}
                        </Nav>
                      </Col>
                      <Col>
                        <Tab.Content>
                          <Tab.Pane eventKey="liberacoes-alumisoft">
                            <TabLiberacaoAlumisoft />
                          </Tab.Pane>
                          {havePermission(["Liberacao"]) && (
                            <Tab.Pane eventKey="padrao-alumisoft">
                              <TabPadraoAlumisoft />
                            </Tab.Pane>
                          )}
                          <Tab.Pane eventKey="liberacoes-preference">
                            <TabLiberacaoPreference selectedTab={state} />
                          </Tab.Pane>
                          {havePermission(["Liberacao"]) && (
                            <Tab.Pane eventKey="diversas">
                              <TabDiversas />
                            </Tab.Pane>
                          )}
                        </Tab.Content>

                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Liberacoes;
