import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { FileButton, Input } from "../../../../../../components/Form";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from 'yup'
import getValidationErrors from "../../../../../../helpers/getValidateErrors"
import api from "../../../../../../services/api";

import { SweetAlertContext } from "../../../../../../contexts/sweetAlert/alertContext";
import { ProdutoContext } from "../../../../../../contexts/produtos/produtosContext";
import { toast } from "react-toastify";


interface IUploadImagem {
  dados: any,
  setFiguraIcone: any
}

const UploadSVGX: React.FC<IUploadImagem> = (props) => {

  // URL Params
  const { pId }: any = useParams();

  const SUPPORTED_FORMATS = ["svgx"];

  const schema = Yup.object().shape({
    imagem: Yup.mixed()
      .test(
        "fileFormat",
        "O arquivo deve ser .svgx",
        value => {
          return value && SUPPORTED_FORMATS.includes(value.name.split(/[. ]+/).pop());
        }
      )
  });
  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const sweetAlert = useContext(SweetAlertContext);
  const produtoContext = useContext(ProdutoContext);

  // States
  const [imagem, setImagem] = useState(true);
  const [svg, setSvg] = useState<any>();

  // Effects
  useEffect(() => {
    if (props.dados && props.dados.nomeImagemSVGX != "" && props.dados.nomeImagemSVGX != undefined) {
      formRef.current?.setFieldValue('nomeImagem', props.dados.nomeImagemSVGX);
      setImagem(false);
    }
  }, [props.dados]);

  const handleChangeField = () => {
    if (!produtoContext.entrouEmEdicao) {
      produtoContext.setEntrouEmEdicao(true);
    }
  };

  // Handles
  const handleSubmit: SubmitHandler<any> = async (form, { reset }) => {
    try {
      formRef.current?.setErrors({});

      await schema.validate(form, { abortEarly: false });

      const { data: { disponivel } } = await api.get(`superficie/disponivelMercado/${pId}`);

      if (disponivel) {
        produtoContext.setEntrouEmEdicao(true);
        sweetAlert.alert("Atenção", "O material esta disponível em mercado, alteramos a situação para o modo edição, salve o cadastro para continuar com a atualização");
        reset();
      }
      else {
        //Passou pela validação      
        handleChangeField();

        if (form.imagem != null) {
          var arquivo = new FormData();
          arquivo.append("arquivo", form.imagem);
          arquivo.append("nomeArquivo", form.imagem.name);
          arquivo.append("idSuperficie", pId);
          props.setFiguraIcone((state: any) => ({
            ...state,
            nomeImagem: form.imagem.name
          }));

          await upload(arquivo);
        }

        //Coloca o nome do arquivo no campo input text      
        formRef.current?.setFieldValue('nomeImagem', form.imagem.name);
        setImagem(false);
      }

    } catch (err) {
      formRef.current?.setErrors(getValidationErrors(err));
      formRef.current?.clearField('nomeImagem');
      setImagem(true);
    }
  };

  const upload = async (arquivo: any) => {
    let promise = new Promise((resolve, reject) => {
      api.post("/superficie/uploadFiguraSVGX", arquivo)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });

    await toast.promise(promise, {
      pending: "Enviando arquivo...",
      success: "Arquivo enviado com sucesso!",
      error: "Ocorreu um erro ao enviar o arquivo."
    });
  }

  const handleUploadImagem = (e: any) => {
    if (e.target.files?.length != 0) {
      formRef.current?.submitForm()
    } else {
      setImagem(true);
      formRef.current?.clearField('nomeImagem');
      formRef.current?.setErrors({});
    }
  }

  const handleAlterarNome = async (e: any) => {
    if (props.dados.nomeImagemSVGX != e.target.value && e.target.value) {
      const message = `Deseja realmente alterar o nome da Imagem?`;

      sweetAlert.warning(e.target.value, message, async () => {
        handleChangeField();

        await api.post('superficie/alterarNomeFiguraSVGX', {
          idSuperficie: pId,
          nomeArquivo: e.target.value
        }).then(response => {
          if (response.data.isValid) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.errors[0].errorMessage);
          }
        });
        props.dados.nomeImagemSVGX = e.target.value;
      },
        () => {
          formRef.current?.setFieldValue('nomeImagem', props.dados.nomeImagemSVGX);
        });
    }
  }

  return (
    <>
      <img id="svgPreview" src={svg} style={{ maxWidth: "max-content", display: "none" }} />

      <div className="card">
        <div className="card-header h3">SVGX</div>
        <div className="card-body py-4">
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div className="">
              <div className="d-flex align-items-center flex-column">
                <div className="mb-4">
                  <span
                    className="bg-teal text-white avatar mx-2"
                    style={{ fontSize: "0.7rem" }}
                  >
                    <strong>.svgx</strong>
                  </span>
                </div>
                <div className="w-100">
                  <label className="form-label">Nome do Arquivo</label>
                  <div className="input-group mb-2">
                    <Input
                      type="text"
                      name="nomeImagem"
                      className="form-control"
                      placeholder="Nenhum arquivo..."
                      onBlur={handleAlterarNome}
                    />
                    <FileButton name="imagem" onChange={handleUploadImagem} />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default UploadSVGX;