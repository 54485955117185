import React, { useContext, useRef, useState } from "react";
import { Form } from '@unform/web'
import { SubmitHandler, FormHandles } from '@unform/core'
import Logo from "../../../assets/imgs/LogoMof_Cor.svg";
import Input from "../../../components/Form/Input"
import * as Yup from 'yup'
import getValidationErrors from "../../../helpers/getValidateErrors";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";
import { useAuth } from "../../../hooks/auth"
import { ToastContext } from "../../../contexts/toast/toastContext"
import { ILogin } from "../../../common/interfaces/signIn.interfaces";
import { Link } from "react-router-dom";

const Login: React.FC = () => {
  // Refs
  const formRef = useRef<FormHandles>(null);

  //Contexts
  const { signIn, signed } = useAuth();
  const toastContext = useContext(ToastContext);

  //States
  const [loading, setLoading] = useState(false);

  // Handles
  const handleSubmit: SubmitHandler<ILogin> = async (login) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string().email('Digite um e-mail válido.').required('O e-mail é obrigatório.'),
        senha: Yup.string().required('Digite sua senha.')
      });
      await schema.validate(login, { abortEarly: false });

      await signIn(login);

    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
      else{
      toastContext.notification(getSingleErrorMessage(error), "error");
      }      
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form className="card" style={{ height: '100vh' }} ref={formRef} onSubmit={handleSubmit}>
      <div className="flex-fill d-flex flex-column justify-content-center py-4">
        <div className="container-tight py-6">
          <div className="text-center mb-4 mt-4">
            <a href="/">
              <img src={Logo} height="40" alt="" />
            </a>
          </div>
          <div className="card card-md">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Login</h2>
              <div className="mb-3">
                <label className="form-label">E-mail</label>
                <Input type="text" name="email" className="form-control" placeholder="Email..." />
              </div>
              <div className="mb-2">
                <label className="form-label">
                  Senha
                  <span className="form-label-description">
                    <Link to='/EsqueceuSuaSenha' className="link-secondary">Esqueceu sua senha ?</Link>
                  </span>
                </label>
                <div className="form-group">
                  <Input type="password" name="senha" className="form-control" placeholder="Senha..." />
                </div>
              </div>
              <div className="mb-2 mt-4">
                <label className="form-check">
                  <Input type="checkbox" name="lembrarSenha" className="form-check-input" />
                  <span className="form-check-label">Lembrar</span>
                </label>
              </div>
              <div className="form-footer">
                <button type="submit" className={"btn btn-mof btn-block" + (loading ? " disabled" : "")}>
                  {loading ? "ENTRANDO..." : "ENTRAR"}
                </button>
              </div>

            </div>
          </div>
          {/* <div className="text-center text-muted mt-4">
            <a href="../MeuPrimeiroAcesso" className="link-secondary">
              Meu primeiro acesso
            </a>
          </div> */}
          <p className="text-center small mt-4">
            Ao entrar, concordo com os <br /><Link to="#">Termos e Políticas da Alumisoft Sistemas.</Link>
          </p>
        </div>
      </div>
    </Form>
  );

};

export default Login;
