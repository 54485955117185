import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "react-bootstrap";
import { Select } from "../../../components/Form";
import { exportToExcel } from "../../../helpers/ExportToExcel";

import { ToastContext } from "../../../contexts/toast/toastContext";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import getValidationErrors from "../../../helpers/getValidateErrors";
import * as Yup from "yup";

import api from "../../../services/api";

import {
  IFormProdutos,
} from "../../../common/interfaces/exportar.interfaces";
import { situacao, status } from "../../../components/Form/Select/options";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import de from "date-fns/esm/locale/de/index.js";
import { toast } from "react-toastify";

const TabProdutos: React.FC = () => {

  //Fields
  const queryString = require('querystring');

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const toastContext = useContext(ToastContext);
  const sweetAlertContext = useContext(SweetAlertContext);

  //States  
  const [referencia, setReferencia] = useState([]);
  const [linha, setLinha] = useState([]);
  const [tipoMaterial, setTipoMaterial] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  //Effects
  useEffect(() => {
    (async () => {
        const tipoMaterial = await api.get('produto/selectTipoMaterial');
        setTipoMaterial(tipoMaterial.data);
    })();
  }, []);

  //Handles
  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handleTipoMaterialReferencia = async (selectedTipoMaterial: any) => {
    formRef.current?.clearField('referencia');

    const { data } = await api.get(`geral/selecaoReferenciaMaterial/${selectedTipoMaterial.value}`);
    setReferencia(data);
  }

  const handleReferenciaLinha = async (selectedReferencia: any) => {
    formRef.current?.clearField('linha');

    if (selectedReferencia != null) {
      const { data } = await api.get(`linha/select/${selectedReferencia.value}`);
      setLinha(data);
    }
  }

  const handleSubmit: SubmitHandler = async (data) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        tipoProduto: Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        })
          .nullable()
          .required(message),
        situacao: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        status: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable(),
        referencia: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable(),
        linha: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable(),
      });
      await schema.validate(data, { abortEarly: false });
      //Passou pela validação      
      setTableLoading(true);

      const fileName = data.tipoProduto.label;

      const filtro = {
        referencia: data.referencia?.value,
        linha: data.linha?.value,
        situacao: data.situacao?.value,
        status: data.status?.value
      };

      const dadosExportacao = await api.get(`${data.tipoProduto.label}/exportar?${queryString.stringify(filtro)}`);
  
      const tabDados = [] as any;
      if ((dadosExportacao.data?.length !== undefined && dadosExportacao.data?.length !== 0) || 
          (dadosExportacao.data?.vidros?.length !== undefined && dadosExportacao.data?.vidros?.length !== 0)) {
        if (data.tipoProduto.label !== "VIDRO") {
          tabDados.push({ data: dadosExportacao.data, name: data.tipoProduto.label });
          exportToExcel(tabDados, fileName);
        }
        else {
          if (dadosExportacao.data.vidros.length != 0)
          {
            tabDados.push({ data: dadosExportacao.data.vidros, name: data.tipoProduto.label });
          }
            

          if (dadosExportacao.data.cores.length != 0){
            tabDados.push({ data: dadosExportacao.data.cores, name: "COR" });
          }

          exportToExcel(tabDados, fileName);
        }

        toast.success("A ação foi concluída.");
      }
      else
        sweetAlertContext.alert("Atenção", "Não há dados a serem exportados de acordo com os filtros realizados.");
    } catch (err) {
      console.log(err);
      formRef.current?.setErrors(getValidationErrors(err));
    }
    finally {
      setTableLoading(false);
    }
  };

  return (
    <>
      <div className="mb-3 col pr-0 text-right">
        <span className="d-none d-sm-inline">
          <Button onClick={handleSave} variant="white">
            Exportar
          </Button>
        </span>
      </div>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
            <div className="form-row">
              <div className="col form-group">
                <label className="form-label">Tipo de produto</label>
                <Select
                  name="tipoProduto"
                  placeholder="Selecione um tipo..."
                  options={tipoMaterial}
                  onChange={(e) => handleTipoMaterialReferencia(e)}
                ></Select>
              </div>
            </div>

            <div className="form-row">
              <div className="col form-group">
                <label className="form-label">Situação</label>
                <Select
                  name="situacao"
                  placeholder="Selecione uma opção..."
                  options={situacao}
                  isClearable
                ></Select>
              </div>
            </div>

            <div className="form-row">
              <div className="col form-group">
                <label className="form-label">Status</label>
                <Select
                  name="status"
                  placeholder="Selecione uma opção..."
                  options={status}
                  isClearable
                ></Select>
              </div>
            </div>

            <div className="form-row">
              <div className="col form-group">
                <label className="form-label">Referência</label>
                <Select
                  name="referencia"
                  placeholder="Selecione uma opção..."
                  options={referencia}
                  isClearable
                  onChange={(e) => handleReferenciaLinha(e)}
                ></Select>
              </div>
            </div>

            <div className="form-row">
              <div className="col form-group">
                <label className="form-label">Linha</label>
                <Select
                  name="linha"
                  placeholder="Selecione uma opção..."
                  options={linha}
                  isClearable
                ></Select>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default TabProdutos;
