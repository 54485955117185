import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Form, Tab, Nav } from "react-bootstrap";
import { useAuth } from "../../../hooks/auth";

import Breadcrumbs from "../../../components/Breadcrumbs";

import Componente from "./Componente"
import Perfis from "./Perfis"
import Superficies from "./Superficies";
import Vidros from "./Vidros";
import history from "../../../helpers/history";

const Produto: React.FC = () => {

  // URL Params
  const { pId, pTipo }: any = useParams();

  // É um novo produto?
  const novo = (pId == 0);

  // States
  const [tipoProdutos, setTipoProdutos] = useState(pTipo ? pTipo.toUpperCase() : "DEFAULT");
  const [produtoSelecionado, setProdutoSelecionado] = useState(false);
  const { user } = useAuth();

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row align-items-center">
              <Breadcrumbs />

              <Tab.Container
                activeKey={tipoProdutos}
                onSelect={(k: any) => {
                  setProdutoSelecionado(true);
                  setTipoProdutos(k.toUpperCase());            
                }}
              >
                {(novo && !produtoSelecionado) &&
                  <div className="card mt-3">
                    <div className="card-body row">
                      <Form.Label>Tipo de Produto</Form.Label>
                      <Nav className="tabProduto">
                        {user?.role.indexOf("CadastroComponentes") !== -1 && (
                          <Nav.Item>
                            <Nav.Link eventKey="COMPONENTE">Componentes</Nav.Link>
                          </Nav.Item>
                        )}
                        {user?.role.indexOf("CadastroPerfis") !== -1 && (
                          <Nav.Item>
                            <Nav.Link eventKey="PERFIL">Perfis</Nav.Link>
                          </Nav.Item>
                        )}
                        {user?.role.indexOf("CadastroVidros") !== -1 && (
                          <Nav.Item>
                            <Nav.Link eventKey="VIDRO">Vidros</Nav.Link>
                          </Nav.Item>
                        )}
                        {user?.role.indexOf("CadastroSuperficies") !== -1 && (
                          <Nav.Item>
                            <Nav.Link eventKey="SUPERFICIE">Superfícies</Nav.Link>
                          </Nav.Item>
                        )}
                      </Nav>
                    </div>
                  </div>
                }

                <Tab.Content className="px-0">

                  <Tab.Pane eventKey="LOADING">
                    <div className="card mt-3">
                      <div className="dimmer active">
                        <div className="loader" />
                        <div className="dimmer-content medium-box-loader" />
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="DEFAULT">
                    <div className="card mt-3">
                      <div className="card-body row text-center py-5">
                        <i className="fas fa-angle-double-up" style={{ fontSize: 45 }}></i>
                        <h2 className="mt-4">Selecione um tipo de produto para cadastrar</h2>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="COMPONENTE">
                    <Componente tipoProduto={tipoProdutos} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="PERFIL">
                    <Perfis tipoProduto={tipoProdutos} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="VIDRO">
                    <Vidros tipoProduto={tipoProdutos} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="SUPERFICIE">
                    <Superficies tipoProduto={tipoProdutos} />
                  </Tab.Pane>

                </Tab.Content>
              </Tab.Container>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Produto;
