import React from 'react';
import DualListBox from 'react-dual-listbox';

interface TypeList {
    name?: string,
    selected: string[],
    options: {
        value: string,
        label: string
    }[],
    onChange: (selected: string[]) => void,
    availableHeader: string,
    selectedHeader: string,
    className?: string,
}

const DualList: React.FC<TypeList> = ({
    name,
    selected,
    options,
    onChange,
    availableHeader,
    selectedHeader,
    className,
}) => {
 
    return (
        <DualListBox
            name={name}
            className={className}
            canFilter
            filterPlaceholder="Busca..."
            showHeaderLabels
            lang={{
                availableFilterHeader: 'Filtro disponível',
                availableHeader: availableHeader,
                moveAllLeft: 'Mover todos para esquerda',
                moveAllRight: 'Mover todos para direita',
                moveLeft: 'Mover para esquerda',
                moveRight: 'Mover para direita',
                moveBottom: 'Mover para último',
                moveDown: 'Mover para baixo',
                moveUp: 'Mover para cima',
                moveTop: 'Mover para primeiro',
                noAvailableOptions: 'Sem opções disponíveis',
                noSelectedOptions: 'Sem opções selecionadas',
                selectedFilterHeader: 'Filtro selecionado',
                selectedHeader: selectedHeader,
            }}
            options={options}
            selected={selected}
            onChange={onChange}
            icons={{
                moveLeft: <span className="fas fa-angle-left" />,
                moveAllLeft: [
                    <span key={0} className="fas fa-angle-double-left" />,
                    <span key={1} className="ml-2">Todos</span>,
                ],

                moveRight: <span className="fas fa-angle-right" />,
                moveAllRight: [
                    <span key={0} className="mr-2">Todos</span>,
                    <span key={1} className="fas fa-angle-double-right" />,
                ],

                moveDown: <span className="fa fa-chevron-down" />,
                moveUp: <span className="fa fa-chevron-up" />,
                moveTop: <span className="fa fa-double-angle-up" />,
                moveBottom: <span className="fa fa-double-angle-down" />,
            }}
        />
    );
}

export default DualList