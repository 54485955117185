import React, { useContext, useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Input } from "../../components/Form";
import { ToastContext } from "../../contexts/toast/toastContext";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import getValidationErrors from "../../helpers/getValidateErrors";

import * as Yup from "yup";
import { IMinhaContaList, IMinhaContaForm } from "../../common/interfaces/minhaConta.interfaces";
import api from "../../services/api";
import { ToastErrorContext } from "../../contexts/toast/toastErrorContext";
import { toast } from "react-toastify";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";

const MinhaConta: React.FC = () => {
  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);

  //States
  const [usuario, setUsuario] = useState<IMinhaContaList>({} as IMinhaContaList);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('minhaConta/usuario');
      setUsuario(data);
    })();
  }, []);

  // Handles
  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handleSubmit: SubmitHandler = async (minhaConta: IMinhaContaForm, { reset }) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        senhaAtual: Yup.string().required(message),
        novaSenha: Yup.string()
          .matches(
            /^(?=.*?[a-z])/,
            "A senha precisa ter pelo menos uma letra."
          )
          .matches(
            /^(?=.*?[0-9])/,
            "A senha precisa ter pelo menos um número."
          )
          .min(6, 'A senha precisa ter no mínimo 6 caracteres.')
          .required(message),
        confirmarNovaSenha: Yup.string().required(message)
          .oneOf([Yup.ref('novaSenha'), null], 'Senha não confere. Digite a mesma senha nos dois campos.')
      });
      await schema.validate(minhaConta, { abortEarly: false });

      //Passou pela validação      
      await api.post('minhaConta/alterarSenha', minhaConta);
      reset();
      //Toast
      toast.success("A ação foi concluída.");      
    } catch (err) {
      formRef.current?.setErrors(getValidationErrors(err));
      toast.error(getSingleErrorMessage(err));
    }
  };

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <h2 className="col-auto page-title">Minha Conta</h2>
              <span className="col-auto ml-auto pr-0">
                <Button variant="white" onClick={handleSave}>
                  Salvar
                </Button>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="card p-5">
              <div className="card-body">
                <div className="text-center">
                  <span className="avatar avatar-xl mb-3 avatar-rounded">
                    <i className="fas fa-user"></i>
                  </span>
                  <h3 className="m-0 mb-1">{usuario.nome}</h3>
                  <div className="mt-3">
                    <span className="badge bg-green-lt">{usuario.tipo}</span>
                  </div>
                </div>
                <Form className="mt-4" ref={formRef} onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="form-group col">
                      <label className="form-label">Senha Atual</label>
                      <Input
                        type="password"
                        name="senhaAtual"
                        className="form-control"
                        placeholder="Senha Atual..."
                      />
                    </div>

                    <div className="form-group col">
                      <label className="form-label">Nova Senha</label>
                      <Input
                        type="password"
                        name="novaSenha"
                        className="form-control"
                        placeholder="Nova Senha..."
                      />
                    </div>

                    <div className="form-group col">
                      <label className="form-label">Confirmar Nova Senha</label>
                      <Input
                        type="password"
                        name="confirmarNovaSenha"
                        className="form-control"
                        placeholder="Confirmar Nova Senha..."
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mt-4 text-center">
                    <label className="ml-3">
                      Sua senha deve ter no mínimo 6 caracteres entre letras números.
                    </label>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MinhaConta;
