import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Modal, Button } from "react-bootstrap";
import { Input, Select } from "../../components/Form";

import FilterTableApi from "../../components/DataTable/filterTableApi-BuscaExt";
import DataNotFound from "../../components/DataNotFound";
import api from "../../services/api";

import { ToastContext } from "../../contexts/toast/toastContext";
import { ToastErrorContext } from "../../contexts/toast/toastErrorContext";
import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import getValidationErrors from "../../helpers/getValidateErrors";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";
import * as Yup from "yup";

import { IFormClassesId, IListClassesID } from "../../common/interfaces/classesid.interfaces";
import { toast } from "react-toastify";

const ClassesID: React.FC = () => {

  //Constants
  const comum = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Fields Typed
  const DataTableTS: any = FilterTableApi;

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastErrorContext = useContext(ToastErrorContext);

  //States
  const [tableData, setTableData] = useState<IListClassesID[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [unidades, setUnidades] = useState<any>([]);
  const [reloadGrid, setReloadGrid] = useState(false);

  const [novo, setNovo] = useState(false);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<IFormClassesId>({} as IFormClassesId);
  const [searchTerm, setSearchTerm] = useState("");


  //Effects
  useEffect(() => {
    (async () => {
      const { data } = await api.get("classeid/unidadeEmbalagem");
      setUnidades(data);
    })();
  }, []);

  //Callbacks
  const fetchData = useCallback(async (globalFilter) => {
    try {
      const { data } = await api.get(`/classeid/lista/${globalFilter}`);

      setTableData(data);
    } catch (error) {
      const message = getSingleErrorMessage(error);
      if (message) {
        toastContext.notification(message, "warn");
      } else {
        toastContext.notification(
          "Ocorreu um erro ao realizar essa operação.",
          "error"
        );
      }
    } finally {
      setTableLoading(false);
    }
  }, []);

  // Handles
  const handleClose = () => setShow(false);

  const handleNew = () => {
    setNovo(true);
    setSelected({} as IFormClassesId);
    setShow(true);
  };

  const handleEdit = useCallback(async ({ id }) => {
    const { data } = await api.get(`classeid/${id}`);

    setSelected(data);
    setShow(true);
    setNovo(false);
  }, []);

  const handleDelete = async ({ id, descricao }: IListClassesID) => {
    const title = `Excluir "${descricao}"?`;
    const message = "Essa Classe ID será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {

        await api.delete(`classeid/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleSave = () => {
    formRef.current?.submitForm();
    setNovo(false);
  };

  const handleSubmit: SubmitHandler = async (classesID) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        codigo: Yup.string().required(message)
        .max(20, 'O código só pode ter no máximo  20 caracteres.'),
        descricao: Yup.string().required(message)
        .max(100, 'A descrição só pode ter no máximo  100 caracteres.'),
        comum: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        unidade: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
      });
      await schema.validate(classesID, { abortEarly: false });

      //Passou pela validação
      if (classesID.preco == "")
        classesID.preco = 0;

      classesID.id = selected.id;
      await api.post('/classeId', classesID);

      setReloadGrid(reload => !reload);
      setShow(false);

      //Toast
      toast.success("A ação foi concluída.");
    } catch (err) {
      toastErrorContext.notification(err);
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };



  const columns = useMemo(
    () => [
      {
        Header: "Código",
        accessor: "codigo",
        display: "align-middle",
      },
      {
        Header: "Descrição",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Comum",
        accessor: "comum",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({
          row: { original },
        }: {
          row: { original: IListClassesID };
        }) => (
          <>
            <Button
              title="Editar"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleEdit(original)}
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              title="Excluir"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <h2 className="col-auto page-title">Classes ID</h2>
              <span className="col-auto ml-auto">
                <Button variant="white" onClick={() => handleNew()}>
                  Nova Classe
                </Button>
              </span>
              <div className="col-auto ml-0 pr-0">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Busca..."
                  value={searchTerm || ""}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card p-0">
              <div className="card-body">
                <div className={'dimmer' + (tableLoading ? ' active' : '')}>
                  <div className="loader"></div>
                  <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
                    {tableData && (
                      <DataTableTS
                        filter={searchTerm}
                        columns={columns}
                        data={tableData}
                        fetchData={fetchData}
                        reload={reloadGrid}
                        bordered={false}
                        initialState={{
                          sortBy: [
                            {
                              id: "id",
                              desc: false,
                            },
                          ],
                        }}
                      />
                    )}
                    <DataNotFound
                      visible={
                        tableLoading == false ? tableData?.length === 0 : false
                      }
                    ></DataNotFound>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Adicionar ClassesID */}
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {novo ? "Adicionar Classe/ID" : "Editar Classe/ID"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form initialData={selected} className="mt-3" ref={formRef} onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Código</label>
                <Input
                  type="text"
                  name="codigo"
                  className="form-control"
                  placeholder="Código..."
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Descrição</label>
                <Input
                  type="text"
                  name="descricao"
                  className="form-control"
                  placeholder="Descrição..."
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Unidade</label>
                <Select
                  name="unidade"
                  placeholder="Selecione uma opção..."
                  options={unidades}
                  defaultValue={selected?.unidade}
                ></Select>
              </div>
              <div className="form-group col">
                <label className="form-label">Preço</label>
                <Input
                  type="number"
                  name="preco"
                  className="form-control"
                  placeholder="00,00"
                  min="1"
                  step="any"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Classe comum?</label>
                <Select
                  name="comum"
                  placeholder="Selecione uma opção..."
                  options={comum}
                  defaultValue={selected?.comum}
                ></Select>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClassesID;
