import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

interface Props {
  name: string
  label?: string
  onlyNumbers?: boolean;
}

type InputProps = JSX.IntrinsicElements['input'] & Props

export default function Input({ name, label, onlyNumbers, type, ...rest }: InputProps) {

  const inputRef = useRef<HTMLInputElement>(null)

  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: ref => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  const onlynumber = (evt:any) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /^[0-9.]+$/;
    // var regex = /^[0-9.]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  return (
    <>
      <input
        ref={inputRef}
        defaultValue={defaultValue}
        type={type}
        onKeyPress={(e) => {
          if (onlyNumbers) {
            onlynumber(e);
          }
          else if ((type !== "password") && (e.key === 'Enter' || e.key === "NumpadEnter")) {
            e.preventDefault();
            inputRef.current?.blur();
          }
        }}
        {...rest}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  )
}