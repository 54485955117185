export interface IFormComponente {
  id: number;
  tipo: string;
  urlImagem: string;
  status: {
    label: string;
    value: number;
  };
  situacao: {
    label: string;
    value: number;
  };
  codigo: string;
  codigoInterno: string;
  referencia: {
    label: string;
    value: number;
  };
  linha: {
    label: string;
    value: number;
  };
  grupo: {
    label: string;
    value: number;
  };
  cor: {
    label: string;
    value: number;
  };
  descricao: string;
  unidadeItem: {
    label: string;
    value: number;
  };
  unidadeEmbalagem: {
    label: string;
    value: number;
  };
  quantidadeEmbalagem: number;
  otimizacaoBarra: number;
  ipi: number;
}

export interface IFormPerfil {
  id: number;
  tipo: string;
  urlImagem: string;
  status: {
    label: string;
    value: number;
  };
  situacao: {
    label: string;
    value: number;
  };
  codigo: string;
  codigoInterno: string;
  referencia: {
    label: string;
    value: number;
  };
  linha: {
    label: string;
    value: number;
  };
  descricao: string;
  barra1: string;
  barra2: string;
  barra3: string;
  peso: number;
  ipi: number;
  classe: {
    label: string;
    value: number;
  };
  unidadeValorizacao: {
    label: string;
    value: number;
  };
  prenativo: {
    label: string;
    value: number;
  };
  perda: {
    label: string;
    value: number;
  };
  perfil: {
    label: string;
    value: number;
  };
  liga: {
    label: string;
    value: number;
  };
  tempera: {
    label: string;
    value: number;
  };
  wx: number;
  jx: number;
  wy: number;
  jy: number;
  perimetroTotal: number;
  perimetroTratamento: number;
}

export interface IFormSuperficie {
  id: number;
  tipo: string;
  urlImagem: string;
  status: {
    label: string;
    value: number;
  };
  situacao: {
    label: string;
    value: number;
  };
  codigo: string;
  codigoInterno: string;
  referencia: {
    label: string;
    value: number;
  };
  linha: {
    label: string;
    value: number;
  };
  descricao: string;
  cor: {
    label: string;
    value: string;
  };
  unidadeValorizacao: {
    label: string;
    value: string;
  };
  ipi: string;
  grupo: {
    label: string;
    value: string;
  };
  passo: number;
  terminal: {
    label: string;
    value: string;
  };
}

export interface IFormVidro {
  id: number;
  tipo: string;
  urlImagem: string;
  status: {
    label: string;
    value: number;
  };
  situacao: {
    label: string;
    value: number;
  };
  codigo: string;
  codigoInterno: string;
  referencia: {
    label: string;
    value: number;
  };
  linha: {
    label: string;
    value: number;
  };
  descricao: string;
  tipoVidro: {
    label: string;
    value: number;
  };
  espessura: number;
  peso: number;
  fatorLh: number;
  controleSolar: {
    label: string;
    value: number;
  };
  bloqueioRuido: {
    label: string;
    value: number;
  };
  arredondamento: {
    label: string;
    value: number;
  };
  ipi: number;
}

export interface IOutrasCaracteristicas {
  id: number;
  codigo: string;
  tipoMaterial: string;
  nome: string;
  descricao: string;
}

export interface ILinhasAplicacao {
  id: number;
  idMaterial: number;
  linhaAplicacao: {
    label: string;
    value: number;
  };
}

export interface IAplicabilidade {
  id: number;
  idAplicavel: number;
  aplicabilidade: string;
  codigo: string;
  descricao: string;
  fichaTecnica: string;
  linkLogoReferencia: string;
  situacao: string;
  excluida: boolean;
  filtroReferencia: {
    label: string;
    value: string;
  };
}

export interface IFormComposicao {
  id: number;
  idPerfil: number;
  perfil: {
    label: string;
    value: string;
  };
  internoExterno: {
    label: string;
    value: string;
  };
}

export enum ITipoPerfilComposto {
  INTERNO = 1,
  EXTERNO = 2,
  COR_UNICA = 0,
}

export interface IListComposicaoPerfil {
  id: number;
  perfil: string;
  internoExterno: string;
}

export interface IProdutoCor {
  id: number;
  linha: {
    label: string;
    value: string;
  };
}

export interface IVidroCor {
  id: number;
  idVidro: number;
  cor: {
    label: string;
    value: string;
  };
  fatorSolar: number;
  transLumin: number;
  transTermica: number;
  bloqCalor: number;
  reflexaoInterna: number;
  reflexaoExterna: number;
  ativo: boolean;
  absorcaoEnergetica: number;
}

export interface IListProduto {
  lastLimit: number;
  total: number;
  hasMore: boolean;
  threshold: number;
  items: IProdutoItem[];
}

export interface IProdutoItem {
  idSequencial: number;
  id: number;
  idReferencia: number;
  tipoProduto: string;
  codigo: string;
  descricao: string;
  status: string;
  situacao: string;
  situacaoCemMobi: string;
  situacaoAplicabilidadeBase: string;
  nomeImagem: string;
  logoReferencia: string;
  fichaTecnica: string;
  temAplicabilidade: boolean;
  aplicabilidadeBaseEditavel: boolean;
  editavel: boolean;
  aplicabilidades?: IAplicabilidade[];
}

export interface IFiltro {
  busca?: string;
  tipoProduto?: string;
  situacao?: ISelect;
  status: ISelect;
  referencia?: ISelect;
  linha?: ISelect;
}

interface ISelect {
  label: string;
  value: string;
}
