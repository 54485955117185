import React, { useEffect, useState, } from "react";
import { Input, Select, File } from "../../../../components/Form";
import { useParams } from "react-router-dom";
import { simOuNao } from "../../../../components/Form/Select/options";
import api from "../../../../services/api";
import ReactSelect from "react-select";

const TabDados: React.FC<any> = () => {

  //Constants
  const travarMaterial: any = [
    { value: "ALUMINIO", label: "Alumínio" },
    { value: "COMPONENTE", label: "Componentes" },
    { value: "PVC", label: "PVC" },
    { value: "KIT", label: "Kits" },
    { value: "VIDRO", label: "Vidros" }
  ];

  return (
    <div className="content mt-1">
      <div className="form-row p-1 mt-1">
        <label className="form-label">Nível Oficial:</label>
        <Input
          type="text"
          name="descricaoOficial"
          className="form-control"
          placeholder="Nível Oficial:..."
        />
      </div>

      <div className="form-row mt-2">
        <div className="form-group col">
          <label className="form-label">
            Eliminar o portfólio da base de dados do cliente em 07 dias caso
            não tenha a liberação do portfólio para uso na área de liberações:
          </label>
          <Select
            name="eliminarPortfolio"
            placeholder="Selecione uma opção..."
            options={simOuNao}
          ></Select>
        </div>
      </div>

      <div className="form-row mt-1">
        <div className="form-group col">
          <label className="form-label">
            Travar alterações no Nível 1 que seja igual a:
          </label>
          <Select
            name="travarNivel1"
            placeholder="Selecione uma opção..."
            options={travarMaterial}
            isMulti={true}
            fixDelay
          />
        </div>
      </div>

      <div className="form-row mt-1">
        <div className="form-group col">
          <label className="form-label">
            Travar materiais, regras, parametrizações, sistemas opções
            globais, escandallos, tabelas de ferragens, modelos, etc:
          </label>
          <Select
            name="travarPortfolio"
            placeholder="Selecione uma opção..."
            options={simOuNao}
          ></Select>
        </div>
      </div>
    </div>
  );
};

export default TabDados;
