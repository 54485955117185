import React, { useState, useEffect, useContext } from "react";
import { simOuNao, status, situacao } from "../../../../../components/Form/Select/options";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Input, Select, InputDecimal } from "../../../../../components/Form";
import api from "../../../../../services/api";

import { ProdutoContext } from "../../../../../contexts/produtos/produtosContext";
import { toast } from "react-toastify";


const SuperficiesTabGeral: React.FC<any> = ({ selectValues, setLogoLinha, loadingValues }) => {

  // URL Params
  const { pId }: any = useParams();

  // É um novo componente?
  const novo = pId == 0;

  // Contexts
  const produtoContext = useContext(ProdutoContext);

  //States
  const [linha, setLinha] = useState<any>([]);

  
  useEffect(() => {
    if (produtoContext.entrouEmEdicao) {
      selectValues.formRef?.current?.setFieldValue("situacao", situacao[0]);
    }  
  }, [produtoContext.entrouEmEdicao]);

  useEffect(() => {
    produtoContext.setEntrouEmEdicao(false);
  }, []);

  const handleChangeField = () => {
    if (!produtoContext.entrouEmEdicao && !loadingValues) {
      produtoContext.setEntrouEmEdicao(true);
    }
  }

  //Handles
  const handleReferenciaLinha = async ({ value }: any) => {
    selectValues.formRef?.current?.clearField('linha');

    const { data } = await api.get(`linha/select/${value}`);
    setLinha(data);

    handleChangeField();
  }

  const handleLinha = async (e: any) => {
    if (e != undefined) {
      const { data } = await api.get(`linha/logoLinha/${e.value}`);
      setLogoLinha(data);

      handleChangeField();
    }
  }

  

  return (
    <>
      <div className={'dimmer' + (loadingValues ? ' active' : '')}>
        <div className="loader"></div>
        <div className={"dimmer-content" + (loadingValues ? ' medium-box-loader' : '')} >
          <div className="form-row mt-4">
            <div className="form-group col">
              <label className="form-label">Status *</label>
              <Select
                name="status"
                options={status}
                placeholder="Selecione uma opção..."
                defaultValue={novo ? status[0] : ""}
                isDisabled={novo}
                onChange={handleChangeField}
              />
            </div>
            <div className="form-group col">
              <label className="form-label">Situação *</label>
              <Select
                name="situacao"
                options={situacao}
                placeholder="Selecione uma opção..."
                defaultValue={novo ? situacao[0] : ""}
              />
            </div>
            <Form.Group className="col">
              <Form.Label>Código *</Form.Label>
              <Input
                type="text"
                name="codigo"
                className="form-control"
                placeholder="Código..."
                onChange={handleChangeField}
              />
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Código Interno</Form.Label>
              <Input
                type="text"
                name="codigoInterno"
                className="form-control"
                placeholder="Código Interno..."
                onChange={handleChangeField}
              />
            </Form.Group>
          </div>

          <div className="form-row">
            <div className="form-group col">
              <label className="form-label">Referência *</label>
              <Select
                name="referencia"
                options={selectValues.referencia}
                onChange={e => { handleReferenciaLinha(e) }}
                placeholder="Selecione uma opção..."
              />
            </div>
            <div className="form-group col">
              <label className="form-label">Linha *</label>
              <Select
                name="linha"
                options={linha}
                onChange={e => { handleLinha(e) }}
                placeholder="Selecione uma opção..."
              />
            </div>
          </div>

          <Form.Row>
            <Form.Group className="col">
              <Form.Label>Descrição *</Form.Label>
              <Input
                type="text"
                name="descricao"
                className="form-control"
                placeholder="Descrição..."
                onChange={handleChangeField}
              />
            </Form.Group>
          </Form.Row>

          <div className="form-row">
          <div className="form-group col">
              <label className="form-label">Peso *</label>
              <InputDecimal
                type="text"
                name="peso"
                className="form-control"
                placeholder="0,00..."
                displayType="input"
                onChange={handleChangeField}
                thousandSeparator={false}
                decimalSeparator=","
                valueIsNumericString
                decimalScale={2}
                defaultValue={0}
                fixedDecimalScale={true}
                isAllowed={(values: any) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue <= 999;
                }}
              />
            </div>
            <div className="form-group col">
              <label className="form-label">Cor *</label>
              <Select
                name="cor"
                options={selectValues.cor}
                placeholder="Selecione uma opção..."
                onChange={handleChangeField}
              />
            </div>            
            <div className="form-group col">
              <label className="form-label">Unidade Valorização *</label>
              <Select
                name="unidadeValorizacao"
                options={selectValues.unidadeValorizacao}
                placeholder="Selecione uma opção..."
                onChange={handleChangeField}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col">
              <label className="form-label">IPI</label>
              <InputDecimal
                type="text"
                name="ipi"
                className="form-control"
                placeholder="00,00..."
                displayType="input"
                onChange={handleChangeField}
                thousandSeparator={false}
                decimalSeparator=","
                valueIsNumericString
                decimalScale={2}
                fixedDecimalScale={true}
                isAllowed={(values: any) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue <= 99;
                }}
              />
            </div>
            <div className="form-group col">
              <label className="form-label">Grupo *</label>
              <Select
                name="grupo"
                options={selectValues.grupo}
                placeholder="Selecione uma opção..."
                onChange={handleChangeField}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col">
              <label className="form-label">Passo</label>
              <Input
                type="text"
                name="passo"
                className="form-control"
                placeholder="0..."
                maxLength={5}
                onlyNumbers
                onChange={handleChangeField}
              />
            </div>
            <div className="form-group col">
              <label className="form-label">Terminal</label>
              <Select
                name="terminal"
                options={simOuNao}
                placeholder="Selecione uma opção..."
                isClearable
                onChange={handleChangeField}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperficiesTabGeral;
