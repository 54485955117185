import React, { createContext, useState } from 'react';
import { IFiltro } from '../../common/interfaces/produtos.interfaces';

interface IProdutoContext {
  filtro: IFiltro,
  setFiltro: (e: any) => void,
  entrouEmEdicao: boolean,
  setEntrouEmEdicao: (e: boolean) => void,
}

const ProdutoContext = createContext({} as IProdutoContext);

const ProdutoProvider: React.FC = ({ children }) => {

  let initFiltro: IFiltro = {
    status: {
      value: "1",
      label: "Ativos",
    },
  }

  const [filtro, setFiltro] = useState<IFiltro>(initFiltro)
  const [entrouEmEdicao, setEntrouEmEdicao] = useState(false);

  return (
    <ProdutoContext.Provider
      value={{ filtro, setFiltro, entrouEmEdicao, setEntrouEmEdicao }}>
      {children}
    </ProdutoContext.Provider>
  );
}

export { ProdutoProvider, ProdutoContext };