import PropTypes from 'prop-types';

const GlobalFilter = ({ setGlobalFilter, placeholder }) => {
    return (
        <div className="row">
            <div className="col-auto ml-auto" >
                <div className="btn-list pr-0">
                    <span className="d-none d-sm-inline">
                        <input
                            type="text"
                            className="form-control w-100"
                            placeholder={placeholder || "Busca..."}
                            onChange={(e) => {
                                setGlobalFilter(e.target.value || "");
                            }}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

GlobalFilter.propTypes = {
    setGlobalFilter: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

export default GlobalFilter;
