import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { FileButton, Input } from "../../../../../../components/Form";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import getValidationErrors from "../../../../../../helpers/getValidateErrors";
import api from "../../../../../../services/api";

import { SweetAlertContext } from "../../../../../../contexts/sweetAlert/alertContext";
import { ProdutoContext } from "../../../../../../contexts/produtos/produtosContext";
import { toast } from "react-toastify";
import { isNullOrUndefined } from "../../../../../../helpers/validation";

interface IUploadFicha {
  dados: any;
}

const UploadFichaTecnica: React.FC<IUploadFicha> = (props) => {
  // URL Params
  const { pId }: any = useParams();

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const sweetAlert = useContext(SweetAlertContext);
  const produtoContext = useContext(ProdutoContext);

  // States
  const [fichaTecnica, setFichaTecnica] = useState(true);

  // Effects
  useEffect(() => {
    if (
      props.dados &&
      props.dados.nomeFichaTecnica != "" &&
      props.dados.nomeFichaTecnica != undefined
    ) {
      formRef.current?.setFieldValue(
        "nomeFichaTecnica",
        tratarNomeFicha(props.dados.nomeFichaTecnica)
      );
      setFichaTecnica(false);
    }
  }, [props.dados]);

  const tratarNomeFicha = (nome: string): string => {
    if(isNullOrUndefined(nome))
      return '';

    if (nome.indexOf('#') != -1) {
      var nomeFicha = nome.split("#")[1];
      return nomeFicha.replace("#", "");
    }

    return nome;
  }

  // Handles
  const handleSubmit: SubmitHandler<any> = async (form, { reset }) => {
    try {
      formRef.current?.setErrors({});

      const SUPPORTED_FORMATS = [
        "application/pdf",
        "image/jpg",
        "image/jpeg",
        "image/png",
      ];

      const schema = Yup.object().shape({
        fichaTecnica: Yup.mixed().test(
          "fileFormat",
          "O arquivo deve ser .pdf, .jpg ou png.",
          (value) => value && SUPPORTED_FORMATS.includes(value.type)
        ),
      });
      await schema.validate(form, { abortEarly: false });

      const { data: { disponivel } } = await api.get(`componente/disponivelMercado/${pId}`);

      if (disponivel) {
        produtoContext.setEntrouEmEdicao(true);
        sweetAlert.alert("Atenção", "O material esta disponível em mercado, alteramos a situação para o modo edição, salve o cadastro para continuar com a atualização");
        reset();
      }
      else {
        //Passou pela validação
        produtoContext.setEntrouEmEdicao(true);

        if (form.fichaTecnica != null) {
          var arquivo = new FormData();
          arquivo.append("arquivo", form.fichaTecnica);
          arquivo.append("nomeArquivo", form.fichaTecnica.name);
          arquivo.append("idComponente", pId);

          await upload(arquivo);
        }

        formRef.current?.setFieldValue(
          "nomeFichaTecnica",
          form.fichaTecnica.name
        );
        setFichaTecnica(false);
      }
    } catch (err) {
      formRef.current?.setErrors(getValidationErrors(err));
      formRef.current?.clearField("nomeFichaTecnica");
      setFichaTecnica(true);
    }
  };

  const upload = async (arquivo: any) => {
    let promise = new Promise((resolve, reject) => {
      api.post("/componente/uploadDesenhoTecnico", arquivo)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });

    await toast.promise(promise, {
      pending: "Enviando arquivo...",
      success: "Arquivo enviado com sucesso!",
      error: "Ocorreu um erro ao enviar o arquivo."
    });
  }

  const handleUploadFichaTecnica = (e: any) => {
    if (e.target.files?.length != 0) {
      formRef.current?.submitForm();
    } else {
      setFichaTecnica(true);
      formRef.current?.clearField("nomeFichaTecnica");
      formRef.current?.setErrors({});
    }
  };

  const handleAlterarNome = async (e: any) => {
    if (props.dados.nomeFichaTecnica != e.target.value && e.target.value) {
      const message = `Deseja realmente alterar o nome da ficha técnica?`;

      sweetAlert.warning(
        e.target.value,
        message,
        async () => {
          produtoContext.setEntrouEmEdicao(true);

          await api.post("componente/alterarNomeDesenhoTecnico", {
            idComponente: pId,
            nomeArquivo: e.target.value,
          }).then(response => {
            if (response.data.isValid) {
              toast.success(response.data.message);
            } else {
              toast.error(response.data.errors[0].errorMessage);
            }
          });
          props.dados.nomeFichaTecnica = e.target.value;
        },
        () => {
          formRef.current?.setFieldValue(
            "nomeFichaTecnica",
            props.dados.nomeFichaTecnica
          );
        }
      );
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header h3">Ficha Técnica</div>
        <div className="card-body py-4">
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div className="">
              <div className="d-flex align-items-center flex-column">
                <div className="mb-4">
                  <span
                    className="bg-red text-white avatar mx-2"
                    style={{ fontSize: "0.7rem" }}>
                    <strong>.pdf</strong>
                  </span>
                  <span
                    className="bg-blue text-white avatar mx-2"
                    style={{ fontSize: "0.7rem" }}>
                    <strong>.jpg</strong>
                  </span>
                  <span
                    className="bg-azure text-white avatar mx-2"
                    style={{ fontSize: "0.7rem" }}>
                    <strong>.png</strong>
                  </span>
                </div>
                <div className="w-100">
                  <label className="form-label">Nome do Arquivo</label>
                  <div className="input-group mb-2">
                    <Input
                      type="text"
                      name="nomeFichaTecnica"
                      className="form-control"
                      placeholder="Nenhum arquivo..."
                      onBlur={handleAlterarNome}
                    />
                    <FileButton
                      name="fichaTecnica"
                      onChange={handleUploadFichaTecnica}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default UploadFichaTecnica;
