import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import api from "../../../../services/api";
import { base64toBlob } from "../../../../helpers/utils";
import {
  IOutrasCaracteristicas,
  ITipoPerfilComposto,
} from "../../../../common/interfaces/produtos.interfaces";
import Placeholder from "../../../../assets/imgs/img-placeholder.png";
import { useAuth } from "../../../../hooks/auth";
import BackButton from "../../../../components/ButtonBack";

interface IPerfis {
  dados: any;
}

const ItemPerfis: React.FC<IPerfis> = (props) => {
  // URL Params
  const baseUrlImagem =
    process.env.REACT_APP_API + "figurasicones/perfis/figuras/";

  const { pId }: any = useParams();

  const [mostrarModalVerMais, setMostrarModalVerMais] = useState(false);
  const [mostrarModalAplicabilidade, setMostrarModalAplicabilidade] =
    useState(false);

  //Fields Typed
  const { user } = useAuth();

  // States
  const emValidacao = useRef("");
  const [outrasCaracteristica, setOutrasCaracteristica] = useState<
    IOutrasCaracteristicas[]
  >([]);
  const [logoPreview, setLogoPreview] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modoEdicao, setModoEdicao] = useState(false);
  const [perfisCompostos, setPerfisCompostos] = useState([]);

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        emValidacao.current =
          props.dados?.situacao?.label == "Em Edição" ||
          props.dados?.situacao?.label == "Em Homologação"
            ? "validacao/"
            : "";
        const outrasCaracteristicas = await api.get(
          `produto/caracteristicaMaterial/${props.dados?.codigo.replace('/', '')}/PERFIL`
        );
        const linhaAplicacao = await api.get(
          `produto/logoLinhaAplicavel/${pId}/PERFIL`
        );

        setOutrasCaracteristica(outrasCaracteristicas.data);
        setLogoPreview(linhaAplicacao.data);

        var _pefisCompostos = await api.get(
          `perfil/ListarPerfisCompostos/${props.dados.id}`
        );
        setPerfisCompostos(_pefisCompostos.data);

        if (user?.role.indexOf("ConsultaProduto") === -1) {
          const modoEdicao = await api.get("produto/modoEdicaoValue");
          setModoEdicao(modoEdicao.data);
        }
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, []);

  const modalVerMais = () => setMostrarModalVerMais(true);

  const modalAplicabilidade = () => setMostrarModalAplicabilidade(true);

  const handleClose = () => [
    setMostrarModalVerMais(false),
    setMostrarModalAplicabilidade(false),
  ];

  const obterTextoPosTrat = (posTrat: any) => {
    switch (posTrat) {
      case ITipoPerfilComposto.EXTERNO:
        return "Externo";
      case ITipoPerfilComposto.INTERNO:
        return "Interno";
      default:
        return "Cor Única";
    }
  };
  function havePermission(permission: string): boolean {
    var isValid = user?.role.indexOf(permission) !== -1;
    return isValid;
  }

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-sm-5 pl-0">
                      <div className="card card-sm">
                        <Image
                          src={
                            props.dados?.figura
                              ? baseUrlImagem +
                                emValidacao.current +
                                props.dados?.figura
                              : Placeholder
                          }
                          onError={(event: any) =>
                            (event.target.src = Placeholder)
                          }
                          className="card-img-top"
                        />
                      </div>
                    </div>
                    <div className="col-sm-7 pl-4 pr-0">
                      <div className="card-body pb-0">
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="mt-3 mb-3">
                              <Image
                                src={
                                  props.dados?.linkLogoReferencia != null
                                    ? props.dados.linkLogoReferencia
                                    : Placeholder
                                }
                                onError={(event: any) =>
                                  (event.target.src = Placeholder)
                                }
                                height="30px"
                                className="font-weight-bold"
                              />
                            </div>
                            <div className="font-weight-bold">
                              {props.dados?.codigo}
                            </div>
                            <div>{props.dados?.descricao}</div>
                            <div className="row text-muted">
                              <div className="col-sm-12">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <span className="font-weight-bold">
                                      Linha:
                                    </span>
                                    <span className="ml-1">
                                      {props.dados?.linha?.label}
                                    </span>
                                  </div>
                                  <div className="col-sm-12">
                                    <span className="font-weight-bold">
                                      Peso:
                                    </span>
                                    <span className="ml-1">
                                      {props.dados?.peso}
                                    </span>
                                  </div>

                                  <div className="col-sm-12">
                                    <span className="font-weight-bold">
                                      Tamanho da Barra:
                                    </span>
                                    <span className="ml-1">
                                      {props.dados?.barra1} (mm)
                                    </span>
                                    {props.dados?.barra2 != "" && (
                                      <span>, {props.dados?.barra2} (mm)</span>
                                    )}
                                    {props.dados?.barra3 != "" && (
                                      <span>, {props.dados?.barra3} (mm)</span>
                                    )}
                                  </div>

                                  {props.dados?.composto?.value != 0 && (
                                    <div className="col-sm-12">
                                      <span className="font-weight-bold">
                                        Perfil Composto
                                      </span>
                                      <span className="ml-1">
                                        {perfisCompostos.length > 0
                                          ? "Sim"
                                          : "Não"}
                                      </span>
                                    </div>
                                  )}
                                  <div className="mt-3 col-sm-12">
                                    {modoEdicao && (
                                      <span className="badge badge-xl bg-green-lt text-uppercase h4">
                                        {props.dados?.situacao?.label}
                                      </span>
                                    )}
                                    <span
                                      className={`${
                                        modoEdicao ? "ml-1" : ""
                                      } badge badge-xl bg-azure-lt text-uppercase h4`}
                                    >
                                      {props.dados?.status?.label}
                                    </span>
                                  </div>
                                  <div className="mt-3 col-sm-12">
                                    <Button
                                      onClick={modalVerMais}
                                      variant="white"
                                      className="mr-2"
                                    >
                                      Ver mais
                                    </Button>
                                    {!havePermission("ConsultaProduto") &&
                                      modoEdicao && (
                                        <Link
                                          to={`../../../../produtos/${props.dados.id}/${props.dados.codigo.replace('/', '')}/perfil`}
                                          className="btn btn-white"
                                          title="Editar"
                                        >
                                          <i className="far fa-edit icon m-0"></i>
                                        </Link>
                                      )}
                                  </div>
                                  <div className="mt-3 col-sm-12">
                                    <BackButton />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {logoPreview.length != 0 && (
                    <div className="card row mt-3">
                      <div className="card-header">
                        <h3 className="card-title">APLICÁVEL NAS LINHAS:</h3>
                      </div>
                      <div className="card-body">
                        <div className="col-sm-12">
                          <div className="row">
                            {logoPreview.map((logoReferencia, index) => (
                              <div className="col-sm-3" key={index}>
                                {
                                  <Image
                                    src={URL.createObjectURL(
                                      base64toBlob(logoReferencia)
                                    )}
                                    width="80px"
                                  />
                                }
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {outrasCaracteristica.length != 0 && (
                    <div className="card row mt-3">
                      <div className="card-header">
                        <h3 className="card-title">OUTRAS CARACTERÍSTICAS:</h3>
                      </div>
                      <div className="card-body">
                        <div className="row text-muted">
                          <div className="col-sm-12">
                            <div className="row">
                              {outrasCaracteristica.map(
                                ({ nome, descricao }, index) => (
                                  <div className="col-sm-6" key={index}>
                                    <span className="font-weight-bold">
                                      {nome}:
                                    </span>{" "}
                                    {descricao}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Ver Mais*/}
      <Modal size="lg" show={mostrarModalVerMais} onHide={handleClose}>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Ver Mais</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-vcenter card-table ver-mais">
            <tr>
              <td className="border-0 font-weight-bold min-30">Código: </td>
              <td className="border-0">{props.dados?.codigo}</td>
            </tr>
            <tr>
              <td className="font-weight-bold min-30">Descrição: </td>
              <td className="text-muted">{props.dados?.descricao}</td>
            </tr>
            {props.dados?.codigoInterno != "" && (
              <tr>
                <td className="font-weight-bold min-30">Código Interno: </td>
                <td className="text-muted">{props.dados?.codigoInterno}</td>
              </tr>
            )}
            <tr>
              <td className="font-weight-bold">Referência: </td>
              <td className="text-muted">{props.dados?.referencia?.label}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Linha: </td>
              <td className="text-muted">{props.dados?.linha?.label}</td>
            </tr>

            <tr>
              <td className="font-weight-bold">Barras: </td>
              <td className="text-muted">
                <span className="p-0">{props.dados?.barra1} (mm)</span>
                {props.dados?.barra2 != "" && (
                  <span className="p-0">, {props.dados?.barra2} (mm)</span>
                )}
                {props.dados?.barra3 != "" && (
                  <span className="p-0">, {props.dados?.barra3} (mm)</span>
                )}
              </td>
            </tr>

            <tr>
              <td className="font-weight-bold">Peso: </td>
              <td className="text-muted">{props.dados?.peso}</td>
            </tr>
            {props.dados?.ipi != "" && (
              <tr>
                <td className="font-weight-bold">IPI: </td>
                <td className="text-muted">{props.dados?.ipi}</td>
              </tr>
            )}

            <tr>
              <td className="font-weight-bold">Classe / ID: </td>
              <td className="text-muted">{props.dados?.classeId?.label}</td>
            </tr>
            {props.dados?.unidadeValorizacao != "" && (
              <tr>
                <td className="font-weight-bold">Unidade Valorização: </td>
                <td className="text-muted">
                  {props.dados?.unidadeValorizacao?.label}
                </td>
              </tr>
            )}

            <tr>
              <td className="font-weight-bold">Prenativo: </td>
              <td className="text-muted">{props.dados?.prenativo?.label}</td>
            </tr>
            {props.dados?.perda.value && (
              <tr>
                <td className="font-weight-bold">Não considerar Perda: </td>
                <td className="text-muted">{props.dados?.perda?.label}</td>
              </tr>
            )}

            {props.dados?.composto && (
              <tr>
                <td className="font-weight-bold">Perfil Composto: </td>
                <td className="text-muted">{props.dados?.composto.label}</td>
              </tr>
            )}

            {props.dados?.liga != "" && (
              <tr>
                <td className="font-weight-bold">Liga: </td>
                <td className="text-muted">{props.dados?.liga?.label}</td>
              </tr>
            )}
            {props.dados?.terminal && (
              <tr>
                <td className="font-weight-bold">Terminal: </td>
                <td className="text-muted">{props.dados?.terminal?.label}</td>
              </tr>
            )}

            {props.dados?.tempera && (
              <tr>
                <td className="font-weight-bold">Tempera: </td>
                <td className="text-muted">{props.dados?.tempera?.label}</td>
              </tr>
            )}
            {props.dados?.wxt != "" && (
              <tr>
                <td className="font-weight-bold">WX: </td>
                <td className="text-muted">{props.dados?.wxt}</td>
              </tr>
            )}
            {props.dados?.jxt != "" && (
              <tr>
                <td className="font-weight-bold">JX: </td>
                <td className="text-muted">{props.dados?.jxt}</td>
              </tr>
            )}
            {props.dados?.wy != "" && (
              <tr>
                <td className="font-weight-bold">WY: </td>
                <td className="text-muted">{props.dados?.wy}</td>
              </tr>
            )}
            {props.dados?.jy != "" && (
              <tr>
                <td className="font-weight-bold">JY: </td>
                <td className="text-muted">{props.dados?.jy}</td>
              </tr>
            )}
            {props.dados?.perimetroTotal != "" && (
              <tr>
                <td className="font-weight-bold">Perimetro Total: </td>
                <td className="text-muted">{props.dados?.perimetroTotal}</td>
              </tr>
            )}
            {props.dados?.perimetroTratamento != "" && (
              <tr>
                <td className="font-weight-bold">Perimetro de Tratamento: </td>
                <td className="text-muted">
                  {props.dados?.perimetroTratamento}
                </td>
              </tr>
            )}
            {perfisCompostos && (
              <tr>
                <td className="font-weight-bold">Perfil Composto: </td>

                <td className="text-muted compostos">
                  <tr>
                    <td style={{ borderTop: "0px", paddingTop: "0" }}>
                      CODIGO
                    </td>
                    <td style={{ borderTop: "0px", paddingTop: "0" }}>TIPO</td>
                  </tr>
                  {perfisCompostos.map((value: any, index: any) => {
                    return (
                      <tr>
                        <td>{value.perfilCh}</td>
                        <td>{obterTextoPosTrat(value.posTrat)}</td>
                      </tr>
                    );
                  })}
                </td>
              </tr>
            )}
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ItemPerfis;
