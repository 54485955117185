import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Tabs, Tab, Button } from "react-bootstrap";
import Breadcrumbs from "../../../components/Breadcrumbs";

import { ToastContext } from "../../../contexts/toast/toastContext";
import { ToastErrorContext } from "../../../contexts/toast/toastErrorContext";
import history from "../../../helpers/history";

import { Input } from "../../../components/Form";
import { Form } from '@unform/web'
import { SubmitHandler, FormHandles } from '@unform/core'
import getValidationErrors from "../../../helpers/getValidateErrors"
import * as Yup from 'yup'
import api from "../../../services/api";

import TabEmpresa from "./TabEmpresa";
import TabUsuarios from "./TabUsuarios";
import TabRecursos from "./TabRecursos";
import TabReferencias from "./TabReferencias";

import { IFormEmpresa } from "../../../common/interfaces/empresa.interfaces";
import { toast } from "react-toastify";

interface ILocation {
  pathname: string,
  state: any
}

const EmpresaDetalhes: React.FC = () => {

  // Importação de Dados de aba anteriormente aberta
  const location: ILocation = useLocation();
  const abaAnterior = location.state?.previousTab

  // URL Params
  const { pId }: any = useParams();

  // É uma nova empresa?
  const novo = (pId == 0);

  //States
  const [selectedTab, setSelectedTab] = useState<string>('');

  // Refs
  const formRef = useRef<FormHandles>(null);

  // Context  
  const toastContext = useContext(ToastContext);
  const toastErrorContext = useContext(ToastErrorContext);

  const message = "Campo obrigatório";
  const schema = Yup.object().shape({
    cnpj: Yup.string().required(message).matches(/[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/g, { message: 'Campo incompleto', excludeEmptyString: true }),
    razao: Yup.string().required(message),
    fantasia: Yup.string().required(message),
    cep: Yup.string().required(message),
    endereco: Yup.string().required(message),
    numero: Yup.string().required(message),
    bairro: Yup.string().required(message),
    cidade: Yup.string().required(message),
    estado: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).nullable().required(message)
  });

  //Effects
  useEffect(() => {

    const fetchData = async () => {
      if (pId > 0) {
        await api.get(`/empresa/${pId}`)
          .then(response => {
            formRef.current?.setData(response.data);
          }).catch(error => toast.error(error.message));
      }
    }

    fetchData();
  }, []);

  // Handles
  const handleSubmit: SubmitHandler<IFormEmpresa> = async (empresa) => {
    try {
      formRef.current?.setErrors({});


      await schema.validate(empresa, { abortEarly: false });
      empresa.id = pId;

      //Passou pela validação   
      await api.post("/empresa", empresa)
        .then(response => {
          handleResponse(response)
        }).catch(error => {
          toastErrorContext.notification(error.message);
        });

    } catch (err) {
      toastErrorContext.notification(err);
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleResponse = (response: any) => {
    if (response.data.isValid) {
      toast.success(response.data.message);
      if (novo) {
        history.push(`/empresas/${response.data.data.idEmpresa}/${response.data.data.fantasia}`);
      } else {
        history.push(`/empresas`);
      }
    } else {
      toast.error(response.data.errors[0].errorMessage);
    }
  }

  const submit = () => {
    formRef.current?.submitForm();
  };

  const handleOnSelectTabs = (tab: any) => {
    setSelectedTab(tab);
    formRef.current?.setErrors({});
  };

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row align-items-center">
              <Breadcrumbs />

              <span className="col-auto ml-auto pr-0">
                <Button variant="white" onClick={submit}>
                  Salvar
                </Button>
              </span>
              <div className="card mt-3">
                <div className="card-body">
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                  >
                    <Input type="hidden" name="id" />

                    <Tabs
                      defaultActiveKey={!abaAnterior ? "empresa" : abaAnterior}
                      onSelect={handleOnSelectTabs}>
                      <Tab eventKey="empresa" title="Empresa">
                        <TabEmpresa />
                      </Tab>
                      <Tab disabled={novo} eventKey="recursos" title="Recursos">
                        <TabRecursos />
                      </Tab>
                      <Tab disabled={novo} eventKey="referencias" title="Referências">
                        <TabReferencias selectedTab={selectedTab} />
                      </Tab>
                      <Tab disabled={novo} eventKey="usuarios" title="Usuários">
                        <TabUsuarios selectedTab={selectedTab} />
                      </Tab>
                      {/* <Tab disabled={novo} eventKey="historico" title="Histórico">
                        <TabHistorico />
                      </Tab> */}
                    </Tabs>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpresaDetalhes;
