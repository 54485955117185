import React, { useContext, useEffect, useRef, useState } from "react";
import { Tab, Button, Col, Nav, Row } from "react-bootstrap";

import TabDados from "./TabDados";
import TabLinhas from "./TabLinhas";
import TabHomologacao from "./TabHomologacao";
import TabDisponivel from "./TabDisponivel";
import TabPrefsuite from "./TabPrefsuite";
import TabBloqueios from "./TabBloqueios";
import TabInformativos from "./TabPrefsuite/TabInformativos";

import Breadcrumbs from "../../../components/Breadcrumbs";
import { ToastContext } from "../../../contexts/toast/toastContext";
import { ToastErrorContext } from "../../../contexts/toast/toastErrorContext";
import history from "../../../helpers/history";
import { useParams } from "react-router-dom";
import getValidationErrors from "../../../helpers/getValidateErrors";
import * as Yup from "yup";
import { IFormReferencia } from "../../../common/interfaces/referencias.interfaces";
import { Form } from "@unform/web";
import api from "../../../services/api";
import { SubmitHandler, FormHandles } from "@unform/core";
import { toast } from "react-toastify";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";

const Referencia: React.FC = () => {

  // URL Params
  const { pId }: any = useParams();

  //Constants
  const novo = pId == 0;  

  // Refs
  const formRef = useRef<FormHandles>(null);

  // Context
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);

  //States
  const [selectedTab, setSelectedTab] = useState<string>('');

  const SUPPORTED_FORMATS = ['image/svg', "image/svg+xml"];

      const SUPPORTED_ZIPFORMATS = [
        "application/x-zip",
        "application/zip",
        "application/x-zip-compressed",
      ];

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        descricao: Yup.string().required(message).max(20, 'O nome da referência só pode ter no máximo 20 caracteres.'),
        projetista: Yup.string().required(message),
        tipo: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        logo: Yup.mixed().test(
          "fileFormat",
          "O logo deve ser .svg",
          value => !value || SUPPORTED_FORMATS.includes(value?.type)
        ),
        arquivoComum: Yup.mixed().notRequired().test(
          "fileFormat",
          "O arquivo deve ser .zip",
          (value) => (value !== undefined ? SUPPORTED_ZIPFORMATS.includes(value.type) : true)
        ),
      });

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (pId != 0) {
          const { data } = await api.get(`/referencia/${pId}`);
          console.log(data)
          formRef.current?.setData(data); 
        }
      } catch (error) { }
    }

    fetchData();
  }, []);

  // Handles
  const handleSubmit: SubmitHandler<IFormReferencia> = async (referencia) => {
    try {
      formRef.current?.setErrors({});
      
      
      await schema.validate(referencia, { abortEarly: false });
      referencia.id = pId; 

      //Passou pela validação
      const { data: { id } } = await api.post("/referencia", referencia);

      
      if(referencia.logo != null)
      {
          var dataLogo = new FormData();
          dataLogo.append("logo", referencia.logo);      
          dataLogo.append("id", id);

          await api.post("/referencia/uploadLogo", dataLogo);
      }

      if(referencia.arquivoComum != null)
      {
          var dataArquivoComum = new FormData();
          dataArquivoComum.append("arquivoComum", referencia.arquivoComum);      
          dataArquivoComum.append("id", id);

          await api.post("/referencia/uploadArquivoComum", dataArquivoComum);
      }

      toast.success("A ação foi concluída.");

      if (novo) {
        history.push(`/referencias/${id}/${referencia.projetista}`);
      } else {
        history.push(`/referencias`);
      }
    } catch (err) {            
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));      
    }
  };

  const submit = () => {
    formRef.current?.submitForm();
  };

  const handleOnSelectTabs = (tab: any) => {
    formRef.current?.setErrors({});
    setSelectedTab(tab);
  };

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row align-items-center">
              <Breadcrumbs />

              <div className="col pr-0 text-right">
                <span className="d-none d-sm-inline">
                  <Button
                    type="button"
                    variant="white"
                    onClick={submit}
                  >
                    Salvar
                  </Button>
                </span>
              </div>

              <div className="card mt-3 p-0">
                <div className="card-body">
                  <Form ref={formRef} onSubmit={handleSubmit} encType="multipart/form-data">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="dados"
                      onSelect={handleOnSelectTabs}
                    >
                      <Row>
                        <Col className="col-3">
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey="dados">Dados</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="linhas" disabled={novo}>
                                Linhas
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <div className="font-weight-bold">Alumisoft</div>
                              <Nav.Link eventKey="homologacao" disabled={novo} >
                                Homologação
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="disponivel" disabled={novo}>
                                Disponível
                              </Nav.Link>
                            </Nav.Item>                           
                            <div className="font-weight-bold">Prefsuite</div>
                            <Nav.Item>                              
                            </Nav.Item>
                            <Nav.Link eventKey="bloqueios">
                              Bloqueios
                              </Nav.Link>
                          <Nav.Item>
                            <Nav.Link eventKey="informativos">Informativos</Nav.Link>
                          </Nav.Item>
                          </Nav>
                        </Col>
                        <Col>
                          <Tab.Content>
                            <Tab.Pane eventKey="dados">
                              <TabDados />
                            </Tab.Pane>
                            <Tab.Pane eventKey="linhas">
                              <TabLinhas selectedTab={selectedTab} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="homologacao">
                              <TabHomologacao />
                            </Tab.Pane>
                            <Tab.Pane eventKey="disponivel">
                              <TabDisponivel  selectedTab={selectedTab}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="bloqueios">
                              <TabPrefsuite />
                            </Tab.Pane>
                          <Tab.Pane eventKey="informativos">
                          <TabInformativos/>
                          </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referencia;
