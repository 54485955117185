import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
} from "react";
import { Modal, Button } from "react-bootstrap";
import { Input, Select } from "../../components/Form";
import Breadcrumbs from "../../components/Breadcrumbs";

import { ToastContext } from "../../contexts/toast/toastContext";
import { ToastErrorContext } from "../../contexts/toast/toastErrorContext";
import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import getValidationErrors from "../../helpers/getValidateErrors";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";
import * as Yup from "yup";

import FilterTableApi from "../../components/DataTable/filterTableApi-BuscaExt";
import DataNotFound from "../../components/DataNotFound";
import api from "../../services/api";

import { IFormUnidades, IListUnidades } from "../../common/interfaces/unidades.interfaces";
import { toast } from "react-toastify";

const Unidades: React.FC = () => {

  //Constants
  const comum = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Fields Typed
  const DataTableTS: any = FilterTableApi;

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastErrorContext = useContext(ToastErrorContext);


  //States
  const [tableData, setTableData] = useState<IListUnidades[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<IFormUnidades>({} as IFormUnidades);
  const [novo, setNovo] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");


  //Callbacks
  const fetchData = useCallback(async (globalFilter) => {
    try {

      const { data } = await api.get(`/unidadeItemEmbalagem/lista/${globalFilter}`);

      setTableData(data);
    } catch (error) {
    } finally {
      setTableLoading(false);
    }
  }, []);

  const handleEdit = useCallback(async ({ id }) => {
    const { data } = await api.get(`unidadeItemEmbalagem/${id}`);

    setSelected(data);
    setShow(true);
  }, []);


  //Handles
  const handleClose = () => setShow(false);

  const handleNew = () => {
    setNovo(true);
    setSelected({} as IFormUnidades);
    setShow(true);
  };

  const handleDelete = ({ id, descricao }: IListUnidades) => {
    const title = `Excluir "${descricao}"?`;
    const message = "Essa Unidade será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`unidadeItemEmbalagem/${id}`);
        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleSave = () => {
    formRef.current?.submitForm();
    setNovo(false);
  };

  const handleSubmit: SubmitHandler = async (unidades) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        sigla: Yup.string().required(message)
        .max(4, 'A sigla só pode ter no máximo 4 caracteres.'),
        descricao: Yup.string().required(message)
        .max(100, 'A descrição só pode ter no máximo 100 caracteres.'),
      });

      await schema.validate(unidades, { abortEarly: false });

      //Passou pela validação      
      unidades.id = selected.id;
      await api.post('unidadeItemEmbalagem', unidades);

      setReloadTable(reload => !reload);
      setShow(false);

      toast.success("A ação foi concluída.");
    } catch (err) {
      toastErrorContext.notification(err);
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sigla",
        accessor: "sigla",
        display: "align-middle",
      },
      {
        Header: "Descrição",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Comum",
        accessor: "comum",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({
          row: { original },
        }: {
          row: { original: IListUnidades };
        }) => (
          <>
            <Button
              title="Editar"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleEdit(original)}
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              title="Excluir"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <Breadcrumbs />
              <span className="col-auto ml-auto">
                <Button variant="white" onClick={() => handleNew()}>
                  Nova Unidade
                </Button>
              </span>
              <div className="col-auto ml-0 pr-0">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Busca..."
                  value={searchTerm || ""}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card p-0">
              <div className="card-body">
                <div className={'dimmer' + (tableLoading ? ' active' : '')}>
                  <div className="loader"></div>
                  <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
                    {tableData && (
                      <DataTableTS
                        filter={searchTerm}
                        columns={columns}
                        data={tableData}
                        bordered={false}
                        fetchData={fetchData}
                        reload={reloadTable}
                        initialState={{
                          sortBy: [
                            {
                              id: "id",
                              desc: false,
                            },
                          ],
                        }}
                      />
                    )}
                    <DataNotFound
                      visible={
                        tableLoading == false ? tableData?.length === 0 : false
                      }
                    ></DataNotFound>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Unidade */}
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {novo ? "Adicionar Unidade" : "Editar Unidade"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mt-3" initialData={selected} ref={formRef} onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Sigla</label>
                <Input
                  type="text"
                  name="sigla"
                  maxLength={5}
                  className="form-control"
                  placeholder="Sigla..."
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Descrição</label>
                <Input
                  type="text"
                  name="descricao"
                  className="form-control"
                  placeholder="Descrição..."
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Unidade comum?</label>
                <Select
                  name="comum"
                  placeholder="Selecione uma opção..."
                  options={comum}
                  defaultValue={selected?.comum}
                ></Select>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Unidades;