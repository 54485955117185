import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "../../../assets/imgs/LogoMof_Cor.svg";

import api from "../../../services/api";

import { Input } from "../../../components/Form";
import { Form } from "@unform/web";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from 'yup'

import getValidationErrors from "../../../helpers/getValidateErrors";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";

import { useAuth } from "../../../hooks/auth";
import { ToastContext } from "../../../contexts/toast/toastContext";

import { ILogin } from "../../../common/interfaces/signIn.interfaces";


const MeuPrimeiroAcesso: React.FC = () => {

  //Params
  const { pId } = useParams<any>();

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Contexts
  const { signIn } = useAuth();
  const toastContext = useContext(ToastContext);

  //States
  const [loading, setLoading] = useState(false);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        const { email } = await (await api.get<any>(`/login/firstAccess/${pId}`)).data;

        formRef.current?.setFieldValue("email", email);

      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    })();
  }, []);

  //Handles
  const hundlePrimeiroAcesso: SubmitHandler<ILogin> = async (data) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        senha: Yup.string()
          .matches(
            /^(?=.*?[a-z])/,
            "A senha precisa ter pelo menos uma letra."
          )
          .matches(
            /^(?=.*?[0-9])/,
            "A senha precisa ter pelo menos um número."
          )
          .min(6, 'A senha precisa ter no mínimo 6 caracteres.')
          .required('A senha é obrigatória.'),
        confirmarSenha: Yup.string().oneOf([Yup.ref("senha"), null], "Senha não confere. Digite a mesma senha nos dois campos."),
      });

      await schema.validate(data, { abortEarly: false });
      await api.post('/login/firstAccess/', { idPublico: pId, senha: data.senha });

      signIn(data);

    } catch (err) {
      formRef.current?.setErrors(getValidationErrors(err));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form className="card" style={{ height: '100vh' }} ref={formRef} onSubmit={hundlePrimeiroAcesso}>
      <div className="flex-fill d-flex flex-column justify-content-center">
        <div className="container-tight py-6">
          <div className="text-center mb-4 mt-4">
            <a href="/">
              <img src={Logo} height="40" alt="" />
            </a>
          </div>
          <div className="card card-md">
            <div className="card-body">
              <h2 className="card-title text-center mb-3">
                Meu primeiro acesso
              </h2>
              <p className="text-center pt-3 pb-3">
                Crie sua senha para acessar o MOF.
              </p>
              <div className="mb-3">
                <label className="form-label">E-mail</label>
                <Input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email..."
                  disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Senha</label>
                <Input
                  type="password"
                  name="senha"
                  className="form-control"
                  placeholder="Senha..."
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Confirmar Senha</label>
                <Input
                  type="password"
                  name="confirmarSenha"
                  className="form-control"
                  placeholder="Confirmar Senha..."
                />
              </div>
              <div className="small">
                <p>Sua senha precisa ter:</p>
                <ul className="ml-0">
                  <li>Mínimo de 6 caracteres</li>
                  <li>Uma letra</li>
                  <li>Um número</li>
                </ul>
              </div>

              <div className="form-footer">
                <button type="submit" className={"btn btn-mof btn-block" + (loading ? " disabled" : "")}>
                  {loading ? "ENTRANDO..." : "ENTRAR"}
                </button>
              </div>
            </div>
          </div>
          <p className="text-center small mt-4">
            Ao entrar, concordo com os <br /><Link to="#">Termos e Políticas da Alumisoft Sistemas.</Link>
          </p>
        </div>
      </div>
    </Form>
  );
};

export default MeuPrimeiroAcesso;
