import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "react-bootstrap";
import { ToastContext } from "../../../contexts/toast/toastContext";
import getValidationErrors from "../../../helpers/getValidateErrors";
import ReactSelect from "react-select";
import { Select } from "../../../components/Form";
import { exportToExcel } from "../../../helpers/ExportToExcel";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";

import { IFormAplicabilidade } from "../../../common/interfaces/exportar.interfaces";
import { situacao, tipoMaterial } from "../../../components/Form/Select/options";
import api from "../../../services/api";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import { toast } from "react-toastify";

const TabAplicabilidade: React.FC = () => {

  //Requires
  const queryString = require('querystring');

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const toastContext = useContext(ToastContext);
  const sweetAlertContext = useContext(SweetAlertContext);

  //States
  const [referencia, setReferencia] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  //Constants
  const tipoAplicabilidade = [
    { label: "Componentes", value: "COMPONENTE" },
  ];

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get(`geral/referenciaAplicabilidadeExistente`);
      setReferencia(data);
    };

    fetchData();
  }, []);

  //Handles
  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handleSubmit: SubmitHandler = async (aplicabilidade) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        tipoProduto: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          }).nullable().required(message),
        referencia: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        situacao: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
      });
      
      await schema.validate(aplicabilidade, { abortEarly: false });

      setTableLoading(true);
      // const fileName = aplicabilidade.referencia.label;
      const fileName = `Aplicabilidades`;

      const filtro = {
        situacao: aplicabilidade.situacao.value,
        idReferencia: 0,
        tipo: 1
      };

      const dadosExportacao = await api.get(`aplicabilidade/exportar?${queryString.stringify(filtro)}`);

      if (dadosExportacao.data?.length !== 0) {
        const tabDados = [{ data: dadosExportacao.data, name: "APLICABILIDADE" }];
        exportToExcel(tabDados, fileName);

        toast.success("A ação foi concluída.");
      }
      else
        sweetAlertContext.alert("Atenção", "Não há dados a serem exportados de acordo com os filtros realizados.");
    } catch (err) {
      formRef.current?.setErrors(getValidationErrors(err));
    }
    finally {
      setTableLoading(false);
    }
  };

  return (
    <>
      <div className="mb-3 col pr-0 text-right">
        <span className="d-none d-sm-inline">
          <Button onClick={handleSave} variant="white">
            Exportar
          </Button>
        </span>
      </div>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
            <div className="form-row">
              <div className="col form-group">
                <label className="form-label">Situação</label>
                <Select
                  name="situacao"
                  placeholder="Selecione uma opção..."
                  options={situacao}
                  isClearable
                ></Select>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default TabAplicabilidade;
