import { FormHandles, SubmitHandler } from "@unform/core";
import React, { useContext, useRef, useState } from "react";
import Logo from "../../../assets/imgs/LogoMof_Cor.svg";
import api from "../../../services/api";

import { Input } from "../../../components/Form";

import { Form } from '@unform/web'
import * as Yup from 'yup'
import getValidationErrors from "../../../helpers/getValidateErrors";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";

import { ToastContext } from "../../../contexts/toast/toastContext";

const EsqueceuSuaSenha: React.FC = () => {

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const toastContext = useContext(ToastContext);

  //States
  const [loading, setLoading] = useState(false);

  //Handles
  const hundleRecuperarSenha: SubmitHandler<any> = async (data) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string().email('Digite um e-mail válido.').required('O e-mail é obrigatório.'),
      });

      await schema.validate(data, { abortEarly: false });
      var response = await api.post(`/login/RequestResetPassword/${data.email}`);
      
      if(response.data == 200 || response.data == 202){
        toastContext.notification("O Email foi enviado com seus dados de acesso.", "info");
      }else if(response.data == 404){
        toastContext.notification("Usuário não encontrado.", "info");
      }else{
        toastContext.notification("Não foi possivel enviar o email com os dados de acesso", "warn");
      }

      formRef.current?.clearField("email");
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        formRef.current?.setErrors(getValidationErrors(err));
      }
      else {
        const message = getSingleErrorMessage(err);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form className="card" style={{ height: '100vh' }} ref={formRef} onSubmit={hundleRecuperarSenha}>
      <div className="flex-fill d-flex flex-column justify-content-center py-4">
        <div className="container-tight py-6">
          <div className="text-center mb-4 mt-4">
            <a href="/">
              <img src={Logo} height="40" alt="" />
            </a>
          </div>
          <div className="card card-md">
            <div className="card-body">
              <h2 className="card-title text-center mb-3">
                Esqueceu sua senha?
              </h2>
              <p className="text-center pt-3 pb-3">
                Insira seu e-mail no campo abaixo e enviaremos um email com o link para redefinir sua senha.
              </p>
              <div className="mb-3">
                <label className="form-label">E-mail</label>
                <Input
                  type="text"
                  name="email"
                  className="form-control"
                  placeholder="Email..."
                />
              </div>
              <div className="form-footer">
                <button type="submit" className={"btn btn-mof btn-block" + (loading ? " disabled" : "")}>
                  {loading ? "PROCESSANDO..." : "SOLICITAR SENHA"}
                </button>
              </div>
            </div>
          </div>
          {/* <div className="text-center text-muted mt">
            <a href="../MeuPrimeiroAcesso" className="link-secondary">
              Meu primeiro acesso
            </a>
          </div> */}
        </div>
      </div>
    </Form>
  );
};

export default EsqueceuSuaSenha;
