import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 600000, // 5 minutos
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error?.response?.status) {      
      api.defaults.headers.Authorization = '';
      sessionStorage.clear();
      window.location.href = "/";
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;