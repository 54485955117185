import React, { useState, useMemo, useCallback,useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import history from "../../../helpers/history";

import GlobalFilter from "../../../components/DataTable/GlobalFilter";
import PaginationTable from "../../../components/DataTable/paginationTable-BuscaExt";
import DataNotFound from "../../../components/DataNotFound";
import api from "../../../services/api";

import { IListLiberacaoAlumisoft } from "../../../common/interfaces/liberacoes.interfaces";
import { IPaginacao } from "../../../common/interfaces/paginacao.interfaces";
import { el } from "date-fns/locale";

const sistemasCem = [
  { label: 'CEM', value: 'CEM' },
  { label: 'CEMMOBI', value: 'CEMMOBI' },
];

const TabLiberacaoAlumisoft: React.FC = () => {


  //Fields Typed
  const DataTableTS: any = PaginationTable;


  //States
  const [tableData, setTableData] = useState<IListLiberacaoAlumisoft[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [selectedSistema, setSelectedSistema] = useState<string>('CEM');
  const queryString = require('querystring');

  const fetchData = useCallback(async (paginacao: IPaginacao) => {
    try {
      setTableLoading(true);
     

     
    if(selectedSistema === "CEM"){
      let url = `/serial/listaCliente?${queryString.stringify(paginacao)}`
      const { data: { dados, paginas } } = await api.get(url);
          setPageCount(paginas);
          setTableData(dados);
    }
    else{
      let url = `/serial/listaClienteCemMobi`
      const { data: { dados, paginas } } = await api.get(url);
          setPageCount(paginas);
          setTableData(dados);
    }

      
    } catch (error) {
      console.error(error);
    } finally {
      setTableLoading(false);
    }
  }, [selectedSistema]);


  useEffect(() => {
    fetchData({
      pageIndex: 1,
      pageSize: 10,
      sort: "id",         
      desc: false,        
      globalFilter: "",   
    });
  }, [fetchData]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSistema(event.target.value);
  };

  const columns = useMemo(() => {
    const columnsExtranet = [
      {
        Header: "Razão Social",
        accessor: "razaoSocial",
        display: "align-middle",
      },
      {
        Header: "Nome Fantasia",
        accessor: "nomeFantasia",
        display: "align-middle",
      },
      {
        Header: "Produto",
        accessor: "descricaoProduto",
        display: "align-middle",
      },
      {
        Header: "UF",
        accessor: "estado",
        display: "align-middle",
      },
      {
        Header: "Cidade",
        accessor: "cidade",
        display: "align-middle",
      },
      {
        Header: "Serial",
        accessor: "serial",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-1b",
        Cell: ({ row: { original } }: { row: { original: IListLiberacaoAlumisoft } }) => (
          <Button
            title="Editar"
            variant="ghost-dark"
            className="p-2"
            onClick={() => handleEdit(original)}
          >
            <i className="far fa-edit icon m-0"></i>
          </Button>
        ),
      },
    ];

    const columnsCemMobi = [
      {
        Header: "Razão Social",
        accessor: "razaoSocial",
        display: "align-middle",
      },
      {
        Header: "Nome Fantasia",
        accessor: "nomeFantasia",
        display: "align-middle",
      },
      {
        Header: "UF",
        accessor: "estado",
        display: "align-middle",
      },
      {
        Header: "Cidade",
        accessor: "cidade",
        display: "align-middle",
      },
      {
        Header: "Documento",
        accessor: "documento",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-1b",
        Cell: ({ row: { original } }: { row: { original: IListLiberacaoAlumisoft } }) => (
          <Button
            title="Editar"
            variant="ghost-dark"
            className="p-2"
            onClick={() => handleEdit(original)}
          >
            <i className="far fa-edit icon m-0"></i>
          </Button>
        ),
      },
    ];

    if (selectedSistema === 'CEMMOBI') 
      return columnsCemMobi
    else
      return columnsExtranet;

  }, [selectedSistema]);

  const handleEdit = async (dados: IListLiberacaoAlumisoft) => {
  if(dados.serial){
    const serialValue = dados.serial;
    const { data: { idSerial } } = await api.get(`serial/IdSerial/${serialValue}/${dados?.documento}`);
    dados.idSerial = idSerial;

    history.push({
      pathname: "/liberacoes/alumisoft/liberacao",
      state: dados
    });
  }
  else{
    const serialValue = null;
    const documentValue = dados.documento;
    const { data: { idSerial } } = await api.get(`serial/IdSerial/${serialValue}/${documentValue}`);
      dados.idSerial = idSerial;

      history.push({
        pathname: "/liberacoes/alumisoft/liberacao",
        state: dados
      });
  }
    
  };
  return (
    <>
      <Row>
      <div className="col-auto ml-auto">
          <div>
            <select
              name="sistema"
              value={selectedSistema}
              onChange={handleSelectChange}
              className="form-select"
            >
              <option value="" disabled>
                Selecione uma opção...
              </option>
              {sistemasCem.map((sistema) => (
                <option key={sistema.value} value={sistema.value}>
                  {sistema.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-auto">
          <GlobalFilter setGlobalFilter={setFilter} />
        </div>
      </Row>
      <Row className="mt-3">
        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
            {tableData && (
              <DataTableTS
                filter={filter}
                pageCount={pageCount}
                columns={columns}
                data={tableData}
                bordered={false}
                fetchData={fetchData}
                className="tabela-liberacao"
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={
                tableLoading == false ? tableData?.length === 0 : false
              }
            ></DataNotFound>
          </div>
        </div>
      </Row>
    </>
  );
};

export default TabLiberacaoAlumisoft;