import React, { useRef, useState, useEffect, useContext } from "react";
import { Row } from "react-bootstrap";
import File from './../../../../components/Form/File'
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "./../../../../helpers/getValidateErrors";
import { useParams } from "react-router-dom";
import api from "../../../../services/api";
import { ToastContext } from "../../../../contexts/toast/toastContext";

const TabModelos3D: React.FC<any> = ({ nomeGlb, nomeUsdz, setArquivoGlb, setArquivoUsdz }) => {

  // URL Params
  const { pId }: any = useParams();

  // Refs
  const formRefUsdz = useRef<FormHandles>(null);
  const formRefGlb = useRef<FormHandles>(null);

  const toastContext = useContext(ToastContext);
  //Constants
  const novo = pId == 0;

  // Effects
  useEffect(() => {
    setArquivoGlb(nomeGlb);
  }, [nomeGlb]);

  useEffect(() => {
    setArquivoUsdz(nomeUsdz);
  }, [nomeUsdz]);

  const handleGlbSubmit: SubmitHandler<any> = async (arquivo3D) => {
    try {
      formRefGlb.current?.setErrors({});

      const SUPPORTED_FORMATS = ["glb"];

      const schema = Yup.object().shape({

        uploadGlb: Yup.mixed()
          .test(
            "fileFormat",
            "O arquivo deve ser .glb",
            value => {
              return value && SUPPORTED_FORMATS.includes(value.name.split(/[. ]+/).pop());
            }
          )
      });
      await schema.validate(arquivo3D, { abortEarly: false });

      setArquivoGlb("Carregando o arquivo, aguarde...");

      var arquivo = new FormData();
      arquivo.append("arquivo", arquivo3D.uploadGlb);
      arquivo.append("id", pId);

      await api.post('esquadria/UploadGlb', arquivo);

      setArquivoGlb(arquivo3D.uploadGlb.name);
      toastContext.notification("upload glb foi concluído.", "success");
    } catch (err) {
      formRefGlb.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleUsdzSubmit: SubmitHandler<any> = async (arquivo3D) => {
    try {
      formRefUsdz.current?.setErrors({});

      const SUPPORTED_FORMATS = ["usdz"];

      const schema = Yup.object().shape({

        uploadUsdz: Yup.mixed()
          .test(
            "fileFormat",
            "O arquivo deve ser .usdz",
            value => {
              return value && SUPPORTED_FORMATS.includes(value.name.split(/[. ]+/).pop());
            }
          )
      });
      await schema.validate(arquivo3D, { abortEarly: false });

      setArquivoUsdz("Carregando o arquivo, aguarde...");
      var arquivo = new FormData();
      arquivo.append("arquivo", arquivo3D.uploadUsdz);
      arquivo.append("id", pId);

      await api.post('esquadria/UploadUsdz', arquivo);

      setArquivoUsdz(arquivo3D.uploadUsdz.name);
      toastContext.notification("upload usdz foi concluído.", "success");
    } catch (err) {
      formRefUsdz.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleUploadGlb = () => {
    formRefGlb.current?.submitForm();
  }

  const handleUploadUsdz = () => {
    formRefUsdz.current?.submitForm();
  }

  return (
    <>
      <div className="py-4">
        <Row>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto">
                    <span
                      className="bg-blue text-white avatar"
                      style={{ fontSize: "0.7rem" }}
                    >
                      <strong>.glb</strong>
                    </span>
                  </div>
                  <div className="col">
                    <div>Modelo GLB</div>
                    <div className="text-muted">{nomeGlb == null ? "Nenhum arquivo..." : nomeGlb}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto">
                    <span
                      className="bg-azure text-white avatar"
                      style={{ fontSize: "0.7rem" }}
                    >
                      <strong>.usdz</strong>
                    </span>
                  </div>
                  <div className="col">
                    <div>Modelo USDZ</div>
                    <div className="text-muted">{nomeUsdz == null ? "Nenhum arquivo..." : nomeUsdz}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Row className="mt-3">
          <div className="col">
            <Form ref={formRefGlb} onSubmit={handleGlbSubmit} encType="multipart/form-data">
              <File
                name="uploadGlb"
                placeholder="Upload de arquivo .glb"
                onChange={handleUploadGlb}
                disabled={novo}
              />
            </Form>
          </div>
          <div className="col">
            <Form ref={formRefUsdz} onSubmit={handleUsdzSubmit} encType="multipart/form-data">
              <File
                name="uploadUsdz"
                placeholder="Upload de arquivo .usdz"
                onChange={handleUploadUsdz}
                disabled={novo}
              />
            </Form>
          </div>
        </Row >
      </div >
    </>
  );
};

export default TabModelos3D;
