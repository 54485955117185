import React, { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import { NumericFormatProps } from "react-number-format";
import { NumericFormat } from "react-number-format";
interface IInputDecimalProps extends NumericFormatProps {
  name: string;
  prefix?: string;
  suffix?: string;
}

const InputDecimal: React.FC<IInputDecimalProps> = ({
  name,
  prefix,
  suffix,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [valueInput, setValueInput] = useState("");

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: HTMLInputElement) => {
        if (!ref || !ref.value) {
          return "";
        }
        let value = ref.value;

        if (prefix) {
          value = value.replace(prefix, "");
        }
        if (suffix) {
          value = value.replace(suffix, "");
        }

        return value.toString().replace(",", ".");
      },
      setValue: (_, value: any) => {
        if (value) {
          setValueInput(value.toString().replace(".", ","));
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <NumericFormat
        id={name}
        getInputRef={inputRef}
        defaultValue={defaultValue}
        valueIsNumericString
        prefix={prefix}
        suffix={suffix}
        value={valueInput}
        {...rest}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
};
export default InputDecimal;
