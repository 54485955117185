import React, { useEffect, useRef, useState } from "react";
import Logo from "../../../assets/imgs/LogoMof_Cor.svg";
import { Input } from "../../../components/Form";
import getValidationErrors from "../../../helpers/getValidateErrors";
import * as Yup from 'yup'
import { FormHandles, SubmitHandler } from "@unform/core";
import api from "../../../services/api";
import { Form } from "@unform/web";
import { useParams } from "react-router";
import { ILogin } from "../../../common/interfaces/signIn.interfaces";
import { useAuth } from "../../../hooks/auth";
import history from "../../../helpers/history";

const NovaSenha: React.FC = () => {
  //Params
  const { pId } = useParams<any>();


  //Contexts
  const { signIn } = useAuth();

  //Refs
  const formRef = useRef<FormHandles>(null);

  //States
  const [loading, setLoading] = useState(false);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        const { email } = await (await api.get<any>(`/login/emailAccess/${pId}`)).data;

        formRef.current?.setFieldValue("email", email);
      } catch (error) {
        history.push('/');
      }
    })();
  }, []);

  //Handles
  const hundleAlterarSenha: SubmitHandler<ILogin> = async (data) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        senha: Yup.string()
          .matches(
            /^(?=.*?[a-z])/,
            "A senha precisa ter pelo menos uma letra."
          )
          .matches(
            /^(?=.*?[0-9])/,
            "A senha precisa ter pelo menos um número."
          )
          .min(6, 'A senha precisa ter no mínimo 6 caracteres.')
          .required('A senha é obrigatória.'),
        confirmarSenha: Yup.string().oneOf([Yup.ref("senha"), null], "Senha não confere. Digite a mesma senha nos dois campos."),
      });

      console.log(data);

      await schema.validate(data, { abortEarly: false });
      await api.post('/login/ResetPassword/', { idPublico: pId, senha: data.senha });

      signIn(data);

    } catch (err) {
      formRef.current?.setErrors(getValidationErrors(err));      
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form className="card" style={{ height: '100vh' }} ref={formRef} onSubmit={hundleAlterarSenha}>
      <div className="flex-fill d-flex flex-column justify-content-center">
        <div className="container-tight py-6">
          <div className="text-center mb-4 mt-4">
            <a href="/">
              <img src={Logo} height="40" alt="" />
            </a>
          </div>
          <div className="card card-md">
            <div className="card-body">
              <h2 className="card-title text-center mb-3">Nova Senha</h2>
              <p className="text-center pt-3 pb-3">
                Digite sua nova senha para acessar sua conta
              </p>
              <div className="mb-3">
                <label className="form-label">E-mail</label>
                <Input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email..."
                  disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Nova Senha</label>
                <Input
                  type="password"
                  name="senha"
                  className="form-control"
                  placeholder="Senha..."
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Confirmar Nova Senha</label>
                <Input
                  type="password"
                  name="confirmarSenha"
                  className="form-control"
                  placeholder="Confirmar Senha..."
                />
              </div>
              <div className="small">
                <p>Sua senha precisa ter:</p>
                <ul className="ml-0">
                  <li>Mínimo de 6 caracteres</li>
                  <li>Uma letra</li>
                  <li>Um número</li>
                </ul>
              </div>
              <div className="form-footer">
                <button type="submit" className={"btn btn-mof btn-block" + (loading ? " disabled" : "")}>
                  {loading ? "ENTRANDO..." : "CRIAR NOVA SENHA E ENTRAR"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default NovaSenha;
