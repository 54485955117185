import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Tabs, Tab, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Input, Select } from "../../../components/Form";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";
import api from "../../../services/api";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import getValidationErrors from "../../../helpers/getValidateErrors";

import { toast } from "react-toastify";

interface Nev {
    idTempera: number;
    descricao: string;
    perfis: any[]; // Ou substitua 'any[]' pelo tipo correto, se você tiver essa informação.
  }

  interface IFormLiga {
    idLiga: string,
    idTempera: string,
    elasticidade: string,
    tensaoMaxima: string,
    descricao: string,
    tempera: {
        label: string,
        value: string,
    }, 
}
const Liga: React.FC = () => {

  // URL Params
  const { pId }: any = useParams();

  // Refs
  const formRef = useRef<FormHandles>(null);
  const loading = useRef(true);

  //States
  const [temperas, setTempera] = useState([]);
   //Constants
  const novo = pId == 0;

  //Effects

  useEffect(() => {
    (async () => {
      try {
        if (pId != 0) {
          const { data } = await api.get(`liga/Index/${pId}`);
          formRef.current?.setData({
            ...data.result,
            tempera: {
              value: data.result.idTempera,
              label: data.result.tempera, // Adjust this based on your API response
            },
          }); 
        }
  
        const response = await api.get('liga/ObterTempera');
        setTempera(response.data);
  
      } catch (err) {
        toast.error(getSingleErrorMessage(err));
        formRef.current?.setErrors(getValidationErrors(err));
      } finally {
        loading.current = false;
      }
    })();
  }, [pId]);

   const handleSubmit: SubmitHandler<IFormLiga> = async (dados) => {
    try {

      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      
      dados.idLiga = pId;
      dados.idTempera = dados.tempera.value;
      

      if (novo) {
        await api.post('liga/Salvar', dados);
      }
      else{
        await api.post('liga/Salvar', dados);
      }
      

      toast.success("A ação foi concluída.");
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <Breadcrumbs />
              <span className="col-auto ml-auto">
                <Button variant="white" onClick={() => formRef.current?.submitForm()}>Salvar</Button>
              </span>
            </div>
          </div>

          <div className="row">
            <div className="card py-2">
              <div className="card-body">
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <Row>
                    <div className="col-4">
                      <label>Liga</label>
                      <Input type="text" name="descricao" className="form-control" placeholder="Código da Tipologia..." />
                    </div>
                    <div className="col-4">
                      <label className="form-label">Tempera</label>
                      <Select
                        name="tempera"
                        placeholder="Selecione uma opção..."
                        options={ 
                          temperas.map((item: Nev) => ({
                            value: item.idTempera,
                            label: item.descricao
                        }))}
/>
                    </div>
                  </Row>
                  <Row className="mt-3">
                    <div className="col-4">
                      <label>Elasticidade</label>
                      <Input type="text" name="elasticidade" className="form-control" placeholder="Código da Tipologia..." />
                    </div>
                    <div className="col-4">
                      <label>Tensão Maxima</label>
                      <Input type="text" name="tensaoMaxima" className="form-control" placeholder="Código da Tipologia..." />
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Liga;
