import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useRef,
  useCallback,
} from "react";
import { Modal, Row, Button } from "react-bootstrap";
import { SwitchCheckbox } from "../../../../../components/Form";
import ReactSelect from "react-select";

import DataTableColumnCheckBox from "../../../../../components/DataTable/DataTableCheckbox";
import GlobalFilter from "../../../../../components/DataTable/GlobalFilter";
import FilterTable from "../../../../../components/DataTable/filterTable";
import PaginationTable from "../../../../../components/DataTable/paginationTable-BuscaExt";
import DataNotFound from "../../../../../components/DataNotFound";
import api from "../../../../../services/api";

import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";

import {
  IListLiberacaoAlumisoft,
  IListPacote,
  IListPacoteReferencia,
} from "../../../../../common/interfaces/liberacoes.interfaces";
import { IPaginacao } from "../../../../../common/interfaces/paginacao.interfaces";
import { ToastErrorContext } from "../../../../../contexts/toast/toastErrorContext";
import { useAuth } from "../../../../../hooks/auth";
import { toast } from "react-toastify";
import { bool, boolean } from "yup";

interface IAtualizacoes {
  dados: IListLiberacaoAlumisoft;
}
interface DataItem {
  id: number;
  referencia: string;
  linha: string;
  homologador: string;
  tipo: string;
  dataAtualizacao: string;
}
const niveisLiberacao = [
  { label: "Selecione uma opção", value: "SELECIONE" },
  { label: "Nível 01", value: "Nivel1" },
  { label: "Nível 02", value: "Nivel2" },
];
const TabAtualizacoes: React.FC<IAtualizacoes> = (props) => {
  //Fields Typed
  const DataTableTS: any = FilterTable;
  const DataTableTSModal: any = PaginationTable;
  const queryString = require("querystring");
  const { user } = useAuth();
  // Refs
  const formRef = useRef<FormHandles>(null);

  // Context
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);
  const sweetAlertContext = useContext(SweetAlertContext);

  //States
  const [tableData, setTableData] = useState<IListPacote[]>([]);
  const [tablePacoteData, setTablePacoteData] = useState<
    IListPacoteReferencia[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [referencia, setReferencia] = useState<any>(null);
  const [filter, setFilter] = useState("");
  const [filterReferencia, setFilterReferencia] = useState<
    number | undefined
  >();
  const [filterHomologador, setFilterHomologador] = useState<
    boolean | undefined
  >(false);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [reloadDataTable, setReloadDataTable] = useState(false);
  const [selectedNivel, setSelectedNivel] = useState<string>();

  //Effects
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);
        const { data } = await api.get("referencia/PacoteSelect");
        setReferencia(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        if(props.dados.serial){
          const { data } = await api.get<DataItem[]>(
            `serial/ListaPortfolioCEMLiberacao/${props.dados.idSerial}`
          );
          setTableData(data);
        }else{
          const { data } = await api.get<DataItem[]>(
            `serial/ListaPortfolioCEMLiberacao/${props.dados.idSerial}`
          );
  
          let filteredData: DataItem[] = [];
          if (selectedNivel === "Nivel1") {
            filteredData = data.filter(
              (item: DataItem) =>
                item.tipo === "Referência" || item.tipo === "Linha"
            );
          } else if (selectedNivel === "Nivel2") {
            filteredData = data.filter(
              (item: DataItem) => item.tipo === "Tipologia"
            );
          }
  
          setTableData(filteredData);
        }
        
      } catch (error) {
        // Tratamento de erro
      } finally {
        setTableLoading(false);
      }
    };
    setSelectedNivel(selectedNivel)
    fetch();
  }, [reloadDataTable, selectedNivel]);

  //Callbacks
  const fetchData = useCallback(async (paginacao: IPaginacao) => {
    try {
      setTableLoading(true);
      const {
        data: { dados, paginas },
      } = await api.get(
        `serial/listaPortfolioCEMDisponivel?idSerial=${
          props.dados.idSerial
        }&NivelSelected=${selectedNivel}&${queryString.stringify(paginacao)}`
      );
      setPageCount(paginas);
      setTablePacoteData(dados);
    } catch (error) {
    } finally {
      setTableLoading(false);
    }
  }, [selectedNivel]);

  //Handles
  const handleClose = () => {
    setShow(false);
    setFilterReferencia(undefined);
    setFilterHomologador(false);
    setFilter("");
  };

  const handleShow = () => {
    setFilterHomologador(false);
    setShow(true);
  };

  const handleFiltroReferencia = (filtro?: number) => {
    setFilterReferencia(filtro);
  };

  const handleFiltroHomologador = (filtro?: boolean) => {
    setFilterHomologador(filtro);
  };

  const handleSubmit: SubmitHandler = async (dados) => {
    setLoading(true);
    try {
      if (tableSelectedRows.length != 0) {
        dados.ItemsSelecionados = tableSelectedRows;
        dados.idSerial = props.dados.idSerial;
        dados.homologador = filterHomologador;
        dados.EnviarCemMobi = false;

        if (props.dados && props.dados?.documento) dados.EnviarCemMobi = true;

        dados.Inquilino = props.dados.id;
        await api.post("serial/salvarLiberacaoPorfolioCEM", dados);
        setReloadDataTable((reload) => !reload);

        toast.success("A ação foi concluída.");
      }
      setShow(false);
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
    }
    finally{
      setLoading(false);
    }
  };
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedNivel(event.target.value);
  };
  const handleDelete = ({ id, linha, referencia }: IListPacote) => {
    const title = `Excluir "${referencia + " - " + linha}"?`;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlertContext.danger(title, message, async () => {
      try {
        let enviarCemMobi = false;

        if (props.dados && props.dados?.documento) enviarCemMobi = true;

        await api.delete(
          `serial/removerLiberacaoPorfolioCEM/${id}/${props.dados?.id}`
        );

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Tipo",
        accessor: "tipo",
        display: "align-middle",
      },
      {
        Header: "Referencia",
        accessor: "referencia",
        display: "align-middle",
      },
      {
        Header: "Linha",
        accessor: "linha",
        display: "align-middle",
      },
      {
        Header: "Data",
        accessor: "dataAtualizacao",
        display: "align-middle",
        render: (value: any) => {
          return (
            <>
              {value.dataAtualizacao
                ? new Date(value.dataAtualizacao).toLocaleDateString(
                    "dd/mm/yyyy"
                  )
                : ""}
            </>
          );
        },
      },
      {
        Header: "Homologador",
        accessor: "homologador",
        display: "align-middle",
      },
    ];

    if (user?.role.indexOf("ConsultaLiberacao") === -1) {
      const deleteColumn = {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-1b",
        Cell: ({ row: { original } }: { row: { original: IListPacote } }) => (
          <Button
            variant="ghost-dark"
            className="p-2"
            onClick={() => handleDelete(original)}
          >
            <i className="far fa-trash-alt icon m-0"></i>
          </Button>
        ),
      };

      return [...baseColumns, deleteColumn];
    }

    return baseColumns;
  }, [user]);

  const columnsModal = useMemo(
    () => [
      {
        Header: "",
        accessor: "acao",
        display: "align-middle column-table-1b",
        Cell: ({ row }: any) => (
          <DataTableColumnCheckBox
            selectProps={row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: "Tipo",
        accessor: "tipo",
        display: "align-middle",
      },
      {
        Header: "Referência",
        accessor: "referencia",
        display: "align-middle",
      },
      {
        Header: "Linha",
        accessor: "linha",
        display: "align-middle",
      },
      {
        Header: "Data",
        accessor: "dataAtualizacao",
        display: "align-middle",
        render: (value: any) => {
          return (
            <>
              {value.dataAtualizacao
                ? new Date(value.dataAtualizacao).toLocaleDateString(
                    "dd/mm/yyyy"
                  )
                : ""}
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row px-3 align-items-center">
            <div className="col">
              <h5 className="mb-2">Razão Social:</h5>
              <p className="mb-0">{props.dados.razaoSocial}</p>
            </div>
            <div className="col">
              <h5 className="mb-2">Nome Fantasia:</h5>
              <p className="mb-0">{props.dados.nomeFantasia}</p>
            </div>
            {props.dados && props.dados.serial ? (
              <div className="col-auto">
                <h5 className="mb-2">Serial:</h5>
                <p className="mb-0">{props.dados.serial}</p>
              </div>
            ) : (
              <div className="col-auto">
                <h5 className="mb-2">CNPJ:</h5>
                <p className="mb-0">{props.dados?.documento}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {props.dados && props.dados?.documento && (
        <Row className="mt-4">
          <div className="col-auto ml-auto">
            <div>
              <select
                name="niveis"
                value={selectedNivel}
                onChange={handleSelectChange}
                className="form-select"
              >
                {niveisLiberacao.map((nivel) => (
                  <option key={nivel.value} value={nivel.value}>
                    {nivel.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Row>
      )}

{props.dados && props.dados.serial ? (
  <Row className="mt-4">
    <div className="col-auto ml-auto">
      {user!.role.indexOf("Liberacao") >= 0 && (
        <Button variant="white" onClick={handleShow}>
          Adicionar
        </Button>
      )}
    </div>
    <div className="col-auto">
      <input
        className="form-control"
        type="text"
        placeholder="Busca..."
        value={searchTerm || ""}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  </Row>
) : (
  <Row className="mt-4">
    <div className="col-auto ml-auto">
      {selectedNivel === "Nivel1" && (
        <h3>Liberação de Nível 01 - Referência e Linhas</h3>
      )}
      {selectedNivel === "Nivel2" && (
        <h3>Liberação de Nível 02 - Tipologias</h3>
      )}
    </div>
    <div className="col-auto ml-auto">
      {user!.role.indexOf("Liberacao") >= 0 && selectedNivel && (
        <Button variant="white" onClick={handleShow}>
          Adicionar
        </Button>
        
      )}
      </div> 
      <div className="col-auto">
      {
        user!.role.indexOf("Liberacao") >= 0 && selectedNivel && (
       
          <input
            className="form-control"
            type="text"
            placeholder="Busca..."
            value={searchTerm || ""}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )
      }
     
    </div>

  </Row>
)}

      {props.dados && props.dados.serial ? (
        <Row className="mt-3">
          <div className={"dimmer" + (tableLoading ? " active" : "")}>
            <div className="loader"></div>
            <div
              className={
                "dimmer-content" + (tableLoading ? " small-box-loader" : "")
              }
            >
              {tableData && (
                <DataTableTS
                  filter={searchTerm}
                  columns={columns}
                  data={tableData}
                  bordered={false}
                  initialState={{
                    sortBy: [
                      {
                        id: "id",
                        desc: false,
                      },
                    ],
                  }}
                />
              )}
              <DataNotFound
                visible={
                  tableLoading == false ? tableData?.length === 0 : false
                }
              ></DataNotFound>
            </div>
          </div>
        </Row>
      ) : (
        <Row className="mt-3">
          <div className={`dimmer${tableLoading ? " active" : ""}`}>
            <div className="loader"></div>
            <div
              className={`dimmer-content${
                tableLoading ? " small-box-loader" : ""
              }`}
            >
              {selectedNivel === "Nivel1" && (
                <>
                  <h4>Liberação de Nível 01 - Referência</h4>
                  <DataTableTS
                    filter={searchTerm}
                    columns={columns}
                    data={tableData?.filter(
                      (item) => item.tipo === "Referência"
                    )}
                    bordered={false}
                    initialState={{
                      sortBy: [
                        {
                          id: "id",
                          desc: false,
                        },
                      ],
                    }}
                  />
                  <DataNotFound
                    visible={
                      tableLoading === false &&
                      tableData?.filter((item) => item.tipo === "Referência")
                        .length === 0
                    }
                  />

                  <h4>Liberação de Nível 01 - Linha</h4>
                  <DataTableTS
                    filter={searchTerm}
                    columns={columns}
                    data={tableData?.filter((item) => item.tipo === "Linha")}
                    bordered={false}
                    initialState={{
                      sortBy: [
                        {
                          id: "id",
                          desc: false,
                        },
                      ],
                    }}
                  />
                  <DataNotFound
                    visible={
                      tableLoading === false &&
                      tableData?.filter((item) => item.tipo === "Linha")
                        .length === 0
                    }
                  />
                </>
              )}
              {selectedNivel === "Nivel2" && (
                <>
                  <h4>Liberação de Nível 02 - Tipologia</h4>
                  <DataTableTS
                    filter={searchTerm}
                    columns={columns}
                    data={tableData?.filter(
                      (item) => item.tipo === "Tipologia"
                    )}
                    bordered={false}
                    initialState={{
                      sortBy: [
                        {
                          id: "id",
                          desc: false,
                        },
                      ],
                    }}
                  />
                  <DataNotFound
                    visible={
                      tableLoading === false &&
                      tableData?.filter((item) => item.tipo === "Tipologia")
                        .length === 0
                    }
                  />
                </>
              )}
            </div>
          </div>
        </Row>
      )}
      {/* Modal Adicionar Pacote de Atualização*/}
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Pacote de Atualização</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleSubmit} className="mt-3">
            <Row>
              <div className="col">
                <label className="form-label">Tipo de Liberação</label>
                <SwitchCheckbox
                  name="ehHomologador"
                  id="ehHomologador"
                  label="Homologador?"
                  style={{ height: "33px" }}
                  onChange={(e: any) =>
                    handleFiltroHomologador(e.target.checked)
                  }
                />
              </div>
              <p className="mt-2">
                Se o botão "homologador" estiver selecionado, os pacotes
                selecionados abaixo receberão essa marcação.
              </p>
            </Row>
            <Row className="mt-4">
              <label className="form-label col">Selecione os pacotes:</label>
              <div className="col-3">
                <ReactSelect
                  classNamePrefix="react-select"
                  name="referencia"
                  options={referencia}
                  isClearable={true}
                  placeholder="Busca por Referência..."
                  onChange={(e: any) => handleFiltroReferencia(e?.value)}
                />
              </div>
              <div className="col-auto">
                <GlobalFilter setGlobalFilter={setFilter} />
              </div>
            </Row>
            <Row className="mt-3">
              <div className={"dimmer" + (tableLoading ? " active" : "")}>
                <div className="loader"></div>
                <div
                  className={
                    "dimmer-content" + (tableLoading ? " small-box-loader" : "")
                  }
                >
                  {tableData && (
                    <DataTableTSModal
                      setSelectedRows={setTableSelectedRows}
                      pageCount={pageCount}
                      filter={filter}
                      fetchData={fetchData}
                      filterOptional1={filterReferencia}
                      filterOptional2={filterHomologador}
                      columns={columnsModal}
                      data={tablePacoteData}
                      bordered={false}
                      initialState={{
                        sortBy: [
                          {
                            id: "id",
                            desc: false,
                          },
                        ],
                      }}
                    />
                  )}
                  <DataNotFound
                    visible={
                      tableLoading == false
                        ? tablePacoteData?.length === 0
                        : false
                    }
                  ></DataNotFound>
                </div>
              </div>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button
            variant="primary"
            onClick={() => formRef.current?.submitForm()}
          >
             {loading ? "Salvando..." : "Salvar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabAtualizacoes;
