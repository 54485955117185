import React, { useState, useRef, useEffect, useContext } from "react";

import { FileButton, Input } from "../../../../../../components/Form";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from 'yup'
import { useParams } from "react-router-dom";
import getValidationErrors from "../../../../../../helpers/getValidateErrors"
import api from "../../../../../../services/api";

import { SweetAlertContext } from "../../../../../../contexts/sweetAlert/alertContext";
import { ProdutoContext } from "../../../../../../contexts/produtos/produtosContext";
import { toast } from "react-toastify";


interface IUploadMiniatura {
    dados: any,
    setFiguraIcone: any
}

const UploadMiniatura: React.FC<IUploadMiniatura> = (props) => {

    // URL Params
    const { pId }: any = useParams();

    // Refs
    const formRef = useRef<FormHandles>(null);

    //Context
    const sweetAlert = useContext(SweetAlertContext);
    const produtoContext = useContext(ProdutoContext);

    const handleChangeField = () => {
        if (!produtoContext.entrouEmEdicao) {
            produtoContext.setEntrouEmEdicao(true);
        }
    };

    // States
    const [miniatura, setMiniatura] = useState(true);

    // Effects
    useEffect(() => {
        if (props.dados && props.dados.nomeMiniatura != "" && props.dados.nomeMiniatura != undefined) {
            formRef.current?.setFieldValue('nomeMiniatura', props.dados.nomeMiniatura);
            setMiniatura(false);
        }
    }, [props.dados]);


    // Handles
    const handleSubmit: SubmitHandler<any> = async (thumb, { reset }) => {
        try {
            formRef.current?.setErrors({});

            const SUPPORTED_FORMATS = ["image/bmp"];

            // Definição da propriedade para medir a imagem.
            if (SUPPORTED_FORMATS.includes(thumb.miniatura.type)) {
                Object.defineProperty(thumb.miniatura, 'dimensions', {
                    value: await checkDimension(thumb.miniatura)
                });
            }

            const schema = Yup.object().shape({

                miniatura: Yup.mixed()
                    .test(
                        "fileDimension",
                        "A miniatura deve ter 64x64 pixels",
                        (value) => !value.dimensions || value.dimensions[0] === 64 && value.dimensions[1] === 64
                    )
                    .test(
                        "fileFormat",
                        "O arquivo deve ser .bmp",
                        value => value && SUPPORTED_FORMATS.includes(value.type)
                    )
            });
            await schema.validate(thumb, { abortEarly: false });

            const { data: { disponivel } } = await api.get(`perfil/disponivelMercado/${pId}`);

            if (disponivel) {
                produtoContext.setEntrouEmEdicao(true);
                sweetAlert.alert("Atenção", "O material esta disponível em mercado, alteramos a situação para o modo edição, salve o cadastro para continuar com a atualização");
                reset();
            }
            else {
                //Passou pela validação
                handleChangeField();

                if (thumb.miniatura != null) {
                    var arquivo = new FormData();
                    arquivo.append("arquivo", thumb.miniatura);
                    arquivo.append("nomeArquivo", thumb.miniatura.name);
                    arquivo.append("idPerfil", pId);

                    props.setFiguraIcone((state: any) => ({
                        ...state,
                        nomeMiniatura: thumb.miniatura.name
                    }));

                    await upload(arquivo);
                }

                formRef.current?.setFieldValue('nomeMiniatura', thumb.miniatura.name);
                setMiniatura(false);
            }
        } catch (err) {
            formRef.current?.setErrors(getValidationErrors(err));
            formRef.current?.clearField('nomeMiniatura');
            setMiniatura(true);
        }
    };

    const upload = async (arquivo: any) => {
        let promise = new Promise((resolve, reject) => {
            api.post("/perfil/uploadIcone", arquivo)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });

        await toast.promise(promise, {
            pending: "Enviando arquivo...",
            success: "Arquivo enviado com sucesso!",
            error: "Ocorreu um erro ao enviar o arquivo."
        });
    }

    const handleUploadMiniatura = (e: any) => {
        if (e.target.files?.length != 0) {
            formRef.current?.submitForm()
        } else {
            setMiniatura(true);
            formRef.current?.clearField('nomeMiniatura');
            formRef.current?.setErrors({});
        }
    }

    // Função que checa o tamanho da imagem sem exibir em tela.
    function checkDimension(value: any) {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(value);
            reader.onload = () => {
                const img = new (window as any).Image();
                img.src = reader.result;
                img.onload = () => {
                    const width = img.width;
                    const height = img.height;
                    const dimensions = [width, height]
                    resolve(dimensions);
                };
            };
        });
    }

    const handleAlterarNome = async (e: any) => {
        if (props.dados.nomeMiniatura != e.target.value && e.target.value) {
            const message = `Deseja realmente alterar o nome do icone?`;

            sweetAlert.warning(e.target.value, message, async () => {
                handleChangeField();

                await api.post('perfil/alterarNomeIcone', {
                    idPerfil: pId,
                    nomeArquivo: e.target.value
                }).then(response => {
                    if (response.data.isValid) {
                        toast.success(response.data.message);
                    } else {
                        toast.error(response.data.errors[0].errorMessage);
                    }
                });
                props.dados.nomeMiniatura = e.target.value;
            },
                () => {
                    formRef.current?.setFieldValue('nomeMiniatura', props.dados.nomeMiniatura);
                });
        }
    }

    return (
        <>
            <div className="card">
                <div className="card-header h3">Miniatura</div>
                <div className="card-body py-4">
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <div className="">
                            <div className="d-flex align-items-center flex-column">
                                <div className="mb-4">
                                    <span
                                        className="text-white avatar"
                                        style={{ fontSize: "0.7rem", backgroundColor: "#a6a10c" }}
                                    >
                                        <strong>.bmp</strong>
                                    </span>
                                </div>
                                <div className="w-100">
                                    <label className="form-label">Nome do Arquivo</label>
                                    <div className="input-group mb-2">
                                        <Input
                                            type="text"
                                            name="nomeMiniatura"
                                            className="form-control"
                                            placeholder="Nenhum arquivo..."
                                            onBlur={handleAlterarNome}
                                        />
                                        <FileButton name="miniatura" onChange={handleUploadMiniatura} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default UploadMiniatura;