/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {  
  Col,
  Pagination,
  Row,
} from "react-bootstrap";
import { useTable, usePagination, useRowSelect, useGlobalFilter, useSortBy } from "react-table";
import TypeTable from "./components/TypeTable";

function FilterTable({
  columns,
  data,
  setSelectedRows,
  initialState = { pageIndex: 0, pageSize: 20 },
  paginate = true,
  tableRowUpdate = null,
  bordered = true,
  filter
}) {
  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, selectedRowIds },
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      initialState: initialState,
      tableRowUpdate,
    },    
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    if (setSelectedRows) {
      const selectedIds = Object.keys(selectedRowIds);
      var selectedRowsData = selectedIds
        .map((x) => data[x])
        .filter(function (x) {
          return x != null;
        });
      setSelectedRows(selectedRowsData);
    }   

    setGlobalFilter(filter);    
  }, [selectedRowIds, filter]);
  
   
  return (
    <>     
      {rows.length > 0 && (
        <TypeTable
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          page={page}
          rows={rows}
          prepareRow={prepareRow}
          bordered={bordered}
        />
      )}

      {rows.length > 0 && paginate && (
        <>
          <Row className="d-flex flex-row align-items-center pt-1">
            <Col md="auto">
              <Pagination className="m-0">
                <Pagination.First
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
            <Col md="auto">
              <span>
                Página{" "}
                <strong>
                  {pageIndex + 1} de {pageOptions.length}
                </strong>{" "}
              </span>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default FilterTable;
