import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Row, Button } from "react-bootstrap";
import history from "../../../helpers/history";

import GlobalFilter from "../../../components/DataTable/GlobalFilter";
import PaginationTable from "../../../components/DataTable/paginationTable-BuscaExt";
import DataNotFound from "../../../components/DataNotFound";
import api from "../../../services/api";

import { IListLiberacaoPreference } from "../../../common/interfaces/liberacoes.interfaces";
import { IPaginacao } from "../../../common/interfaces/paginacao.interfaces";

const TabLiberacaoPreference: React.FC<any> = ({ selectedTab }: any) => {

  //Fields Typed
  const DataTableTS: any = PaginationTable;
  const queryString = require('querystring');

  //States
  const [tableData, setTableData] = useState<IListLiberacaoPreference[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    (async () => {
      fetchData({ pageIndex: 0, pageSize: 10 } as IPaginacao);
    })();
  }, [selectedTab]);

  //Callbacks
  const fetchData = useCallback(async (paginacao: IPaginacao) => {
    try {
      if (selectedTab == "liberacoes-preference") {
        setTableLoading(true);
                
        const { data: { dados, paginas } } = await api.get(`/liberacaoPreference/listaCliente?${queryString.stringify(paginacao)}`);

        setPageCount(paginas);
        setTableData(dados);
      }
    } catch (error) {
    } finally {
      setTableLoading(false);
    }
  }, [selectedTab]);

  //Handles
  const handleEdit = async (dados: any) => {
    history.push({
      pathname: `/liberacoes/preference/atualizacao`,
      state: dados,
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Razão Social",
        accessor: "razaoSocial",
        display: "align-middle",
      },
      {
        Header: "Nome Fantasia",
        accessor: "nomeFantasia",
        display: "align-middle",
      },
      {
        Header: "Serial",
        accessor: "serial",
        display: "align-middle",
      },
      {
        Header: "Produto",
        accessor: "descricaoProduto",
        display: "align-middle",
      },
      {
        Header: "UF",
        accessor: "estado",
        display: "align-middle",
      },
      {
        Header: "Cidade",
        accessor: "cidade",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle",
        Cell: ({ row: { original } }: { row: { original: any } }) => (
          <Button
            onClick={() => handleEdit(original)}
            variant="ghost-dark"
            className="p-2"
          >
            <i className="far fa-edit icon m-0"></i>
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Row>
        <div className="col-auto ml-auto">
          <GlobalFilter setGlobalFilter={setFilter} />
        </div>
      </Row>
      <Row className="mt-3">
        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
            {tableData && (
              <DataTableTS
                filter={filter}
                pageCount={pageCount}
                columns={columns}
                data={tableData}
                bordered={false}
                fetchData={fetchData}
                className="tabela-liberacao"
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={
                tableLoading == false ? tableData?.length === 0 : false
              }
            ></DataNotFound>
          </div>
        </div>
      </Row>
    </>
  );
};

export default TabLiberacaoPreference;
