import { useContext, useMemo, useState } from "react";
import { IProjetosCemWeb, IPutProjetosCemWeb, ProjetoCemWebType, QueryParamsProjetos } from "../../../common/interfaces/projetosCemWeb";
import { getProjetosCemWeb, putProjetosCemWeb } from "../../../services/projetosCemWeb";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";
import { ToastContext } from "../../../contexts/toast/toastContext";

interface IUseProjetosCemWeb {
  onSuccessSubmit?: () => void;
}

export function useProjetosCemWeb({onSuccessSubmit}: IUseProjetosCemWeb) {
  const toastContext = useContext(ToastContext);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [tableData, setTableData] = useState<IProjetosCemWeb[]>([]);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const fetchData = async (queryParams?: QueryParamsProjetos) => {
    setIsLoadingData(true)
    try {
      const result = await getProjetosCemWeb(queryParams);

      setTableData(result ?? []);
    } catch (error: any) {
      const message = getSingleErrorMessage(error);
      if (message) {
        toastContext.notification(message, "warn");
      } else {
        toastContext.notification(
          "Ocorreu um erro ao realizar essa operação.",
          "error"
        );
      }
    } finally {
      setIsLoadingData(false);
    }
  }

  const handleSubmitProjetos = async (data: {
    imagemBase64: string;
    jsonImagem: string;
    formatoImagemBase64: string;
  }, idsProjetos: number[]) => {
    setIsLoadingSubmit(true);
    try {
      const formatedProjetos: ProjetoCemWebType[] = idsProjetos.map((row) => {
        return {
          idProjeto: row,
          imagemBase64: data.imagemBase64,
          jsonImagem: data.jsonImagem,
          formatoImagemBase64: data.formatoImagemBase64,
        }
      });

      const postData: IPutProjetosCemWeb = {
        projetos: formatedProjetos,
      }

      const result = await putProjetosCemWeb(postData);

      if (result.success) {
        toastContext.notification("Projetos atualizados com sucesso.", "success");
        onSuccessSubmit?.();
        return;
      }
      throw new Error(result?.messages?.[0] ?? "Erro ao salvar");

    } catch (error: any) {
      const message = getSingleErrorMessage(error);
      if (message) {
        toastContext.notification(message, "warn");
      } else {
        toastContext.notification(
          "Ocorreu um erro ao realizar essa operação.",
          "error"
        );
      }
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  const values = useMemo(() => {
    return {
      isLoadingData,
      tableData,
      fetchData,
      isLoadingSubmit,
      handleSubmitProjetos,
    }
  }, [isLoadingData, tableData, isLoadingSubmit]) 

  return values;
}