import { ValidationError } from 'yup';

interface Errors {
  [key: string]: string;
}

export default function getValidationErrors(err:any): Errors {
  const validationErrors: Errors = {};  
    err.inner?.forEach((error: ValidationError) => {      
      if (error.path) {
        validationErrors[error?.path] = error?.message;
      }
    }); 

  return validationErrors;
}