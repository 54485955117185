


function DataNotFoundProducts(props) {
  return (
    <>
      {props.visible == true ? props.visible && (
        <div className="container-xl d-flex flex-column justify-content-center text-center my-4">
          <div className="empty">
            <div className="empty-img mb-3">
              <i className="far fa-grin-beam-sweat text-secondary" style={{ fontSize: 50 }}></i>
            </div>
            <p className="empty-title font-weight-bold">
              Nenhum dado disponível!
            </p>
            {props.children}
          </div>
        </div>
      ) : (
        <div className="container-xl d-flex flex-column justify-content-center text-center my-4">
          <div className="empty">
            <div className="empty-img mb-3">
              <i className="far fa-grin-beam-sweat text-secondary" style={{ fontSize: 50 }}></i>
            </div>
            <p className="empty-title font-weight-bold">
              Selecione o tipo de produto que deseja pesquisar no filtro ao lado!
            </p>
            {props.children}
          </div>
        </div>
      )
      }
    </>
  );
}

export default DataNotFoundProducts;
