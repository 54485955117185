import React from "react";
import { Tab, Col, Nav, Row } from "react-bootstrap";
import TabProdutos from "./TabProdutos";
import TabTabelaDePreco from "./TabTabelaDePreco";
import TabAplicabilidade from "./TabAplicabilidade";
import TabAcabamento from "./TabAcabamentos";
import TabImagens from "./TabImagens";

const Importar: React.FC = () => {
  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title">Importar</h2>
              </div>
              <div className="card mt-3 p-0">
                <div className="card-body">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="produtos">
                    <Row>
                      <Col className="col-3">
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="produtos">Produtos</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="acabamentos">Acabamentos</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tabelaDePrecos">
                              Tabela de Preços
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="aplicabilidade">
                              Aplicabilidade
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="imagens">Imagens</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col>
                        <Tab.Content>
                          <Tab.Pane eventKey="produtos">
                            <TabProdutos />
                          </Tab.Pane>
                          <Tab.Pane eventKey="acabamentos">
                            <TabAcabamento />
                          </Tab.Pane>
                          <Tab.Pane eventKey="tabelaDePrecos">
                            <TabTabelaDePreco />
                          </Tab.Pane>
                          <Tab.Pane eventKey="aplicabilidade">
                            <TabAplicabilidade />
                          </Tab.Pane>
                          <Tab.Pane eventKey="imagens">
                            <TabImagens />
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Importar;
