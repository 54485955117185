import React, { useContext, useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Input } from "../../../../../../components/Form";
import DualList from "../../../../../../components/DualList";
import Breadcrumbs from "../../../../../../components/Breadcrumbs";
import history from "../../../../../../helpers/history";
import api from "../../../../../../services/api";

import { ToastContext } from "../../../../../../contexts/toast/toastContext";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import getValidationErrors from "../../../../../../helpers/getValidateErrors";
import * as Yup from "yup";

import { IFormPerfil } from "../../../../../../common/interfaces/empresa.interfaces";
import { toast } from "react-toastify";

const PerfilEmpresas: React.FC = () => {
  
  //URL Params
  const { perfilId, perfilString }: any = useParams();

  //Contexts
  const toastContext = useContext(ToastContext);

  // Refs
  const formRef = useRef<FormHandles>(null);

  //States
  const [options, setOptions] = useState([]);     
  const [stateList, setStateList] = useState<any>([]);

  //Effects
  useEffect(() => {
    (async () => {
      try {
          const {data} = await api.get('/telasistema/');    
          setOptions(option => option = data);  

          if(perfilId != undefined)
          {
            const tipoEmpresa = await api.get(`/empresaTipo/telasSistemas/${perfilId}`);   
            setStateList(tipoEmpresa.data);
          }
      } catch (error) {
        
      }      
    })();
  },[]);
  
  // Handles
  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handleSubmit: SubmitHandler<IFormPerfil> = async (perfil) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        perfil: Yup.string().required(message),
      });
      await schema.validate(perfil, { abortEarly: false });

      //Passou pela validação
      perfil.itemsSelecionados = selectedOption;
      perfil.id = perfilId;
      
      await api.post('/empresaTipo', perfil);
      
      //Toast
      toast.success("A ação foi concluída.");
      history.goBack();
    } catch (err) {
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };    

  const handleOnChangeList = (selectedOption: any) => {
    setStateList({ selectedOption });
  };

  const { selectedOption } = stateList;

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <Breadcrumbs />
              <span className="col-auto ml-auto">
                <Button variant="white" onClick={handleSave}>
                  Salvar
                </Button>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="card p-0">
              <div className="card-body">
                <Form className="col" ref={formRef} onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="form-group col-6 mb-0">
                      <label className="form-label">Nome do Perfil</label>
                      <Input
                        type="text"
                        name="perfil"
                        className="form-control"
                        placeholder="Nome do perfil..."
                        defaultValue={perfilString}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="form-row">
                    <DualList
                      name="recursos"
                      className="col-12 mb-4"
                      selected={selectedOption}
                      options={options}
                      onChange={handleOnChangeList}
                      availableHeader="Recursos Disponíveis"
                      selectedHeader="Recursos Selecionados"
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerfilEmpresas;
