import React from "react";

const TableBodyRow = ({ row, getRowProps }) => {
  return (
    <tr {...row.getRowProps(getRowProps ? getRowProps(row) : "")}>
      {row.cells.map((cell) => {
        return (
          <td {...cell.getCellProps()} className={`${cell.column.display}` !== 'undefined' ? `${cell.column.display}` : ''} data-label={`${cell.column.label}` !== 'undefined' ? `${cell.column.label}` : ''} >
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};

const TypeTableBody = ({ getTableBodyProps, page, prepareRow, getRowProps }) => {
  return (
    <tbody {...getTableBodyProps()}>
      {page.map((row) => {
        prepareRow(row);
        return <TableBodyRow row={row} key={row.index} getRowProps={getRowProps} />;
      })}
    </tbody>
  );
};

export default TypeTableBody;
