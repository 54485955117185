import React, {
  useEffect,
  useState,
  useRef,
} from "react";
import { Button } from "react-bootstrap";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { toast } from "react-toastify";

import api from "../../../services/api";
import { Select } from "../../../components/Form";
import { exportToExcel } from "../../../helpers/ExportToExcel";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";
import { isNullOrUndefined } from "../../../helpers/validation";

const TabPrecos: React.FC = () => {

  const situacaoDropDown = [
    { label: "Em edição", value: "EM_EDICAO" },
    { label: "Em homologação", value: "EM_HOMOLOGACAO" },
    { label: "Disponível para mercado", value: "DISPONIVEL" },
  ];

  // Refs
  const formRef = useRef<FormHandles>(null);

  //States
  const [nomeTabelas, setNomeTabelasPrecos] = useState([{}]);
  const [tabela, setTabela] = useState<any>();
  const [situacao, setSituacao] = useState<any>();

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      await api.get('tabelaPreco/ObterImportacoesTabelaPreco')
        .then((response) => {
          var nomesTabela = response.data.map((tabela: any) => {
            return { label: tabela.label, id: tabela.value }
          })
          setNomeTabelasPrecos(nomesTabela);
        }).catch((error) => toast.error(error.message));
    }

    fetchData();
  }, []);


  //Handles
  const handleExport = async () => {
    if (!isNullOrUndefined(tabela) && !isNullOrUndefined(situacao)) {

      var promise = new Promise(async (resolve, reject) => {
        await api.get(`tabelaPreco/Exportar?idTabelaPreco=${tabela.id}&situacao=${situacao.value}`)
          .then((response: any) => {
            if (response.data.length > 0) {
              const data = response.data;
              exportToExcel([{ data, name: `TABELA` }], tabela.label);
              resolve(response.data);
            } else {
              toast.info("Não há dados para exportar");
              resolve('');
            }
          }).catch((error) => {
            reject(error)
            toast.error(getSingleErrorMessage(error));
          });
      });

      await toast.promise(promise, {
        pending: "Exportando...",
        success: "Exportado com sucesso!",
      });

    } else {
      toast.info("Selecione uma tabela de preço e uma situação para exportar.");
    }
  }

  return (
    <>
      <div className="mb-3 col pr-0 text-right">
        <span className="d-none d-sm-inline">
          <Button type="button" onClick={handleExport} variant="white">
            Exportar
          </Button>
        </span>
      </div>

      <Form ref={formRef} onSubmit={() => { }}>
        <div className="form-row">
          <div className="col form-group">
            <label className="form-label">Tabela de Preço</label>
            <Select
              name="nomeTabela"
              placeholder="Selecione uma opção..."
              options={nomeTabelas}
              onChange={(value) => setTabela(value)}
              isClearable
            ></Select>
          </div>
        </div>

        <div className="form-row">
          <div className="col form-group">
            <label className="form-label">Situação</label>
            <Select
              name="situacao"
              placeholder="Selecione uma opção..."
              options={situacaoDropDown}
              isClearable
              onChange={(value) => setSituacao(value)}
            ></Select>
          </div>
        </div>
      </Form>
    </>
  );
};

export default TabPrecos;
