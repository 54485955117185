import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { debounce } from "lodash";
import history from "../../helpers/history";

import FilterTable from "../../components/DataTable/filterTable";
import DataNotFound from "../../components/DataNotFound";
import api from "../../services/api";

import { ToastContext } from "../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";

import { IListTipologias } from "../../common/interfaces/catalogo.interfaces";


const Catalogos: React.FC = () => {

  //Fields Typed
  const DataTableTS: any = FilterTable;

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);

  //States
  const [tableData, setTableData] = useState<IListTipologias[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  

  //Effects
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);
        const {data} = await api.get("esquadria/listaEsquadria3D");

        setTableData(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, []);  

  //Handles
  const handleEdit = ({ id, codigo }: IListTipologias) => {
    history.push(`/catalogos/${id}/${codigo}`);
  };

  const handleDelete = ({ id, codigo }: IListTipologias) => {
    const title = `Excluir "${codigo}"?`;
    const message = "Essa tipologia será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {

        await api.delete(`esquadria/removerEsquadria3D/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleNew = () => {
    history.push(`/catalogos/0/novaTipologia`);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Código Tipologia",
        accessor: "codigo",
        display: "align-middle",
      },
      {
        Header: "Linha",
        accessor: "linha",
        display: "align-middle",
      },
      {
        Header: "Referência",
        accessor: "referencia",
        display: "align-middle",
      },
      {
        Header: "Status",
        accessor: "status",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({ row: { original } }: { row: { original: IListTipologias } }) => (
          <>
            <Button
              title="Editar"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleEdit(original)}
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              title="Excluir"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <h2 className="col-auto page-title">Catálogos</h2>
              <span className="col-auto ml-auto">
                <Button onClick={() => handleNew()} variant="white">
                  Nova Tipologia
                </Button>
              </span>
              {/* <span className="col-auto">
                <Button variant="white">
                  Carregar Lista
                </Button>
              </span> */}
              <span className="col-auto pr-0">
              <input
                className="form-control"
                type="text"
                placeholder="Busca..."
                value={searchTerm || ""}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              </span>
            </div>
          </div>

          <div className="row">
            <div className="card p-0">
              <div className="card-body">
                <div className={'dimmer' + (tableLoading ? ' active' : '')}>
                  <div className="loader"></div>
                  <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
                    {tableData && (
                      <DataTableTS
                        columns={columns}
                        data={tableData}
                        filter={searchTerm}
                        bordered={false}
                        initialState={{
                          sortBy: [
                            {
                              id: "id",
                              desc: false,
                            },
                          ],
                        }}
                      />
                    )}
                    <DataNotFound
                      visible={
                        tableLoading == false ? tableData?.length === 0 : false
                      }
                    ></DataNotFound>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Catalogos;
