import React from "react";
import { Form, Table } from "react-bootstrap";

const TabsDadosDaLinha: React.FC = () => {
  return (
    <>
      <div className="col pl-0 pr-0 mt-4 mb-3">
        <Form>
          <Form.Label>Adicionar Logo</Form.Label>
          <Form.File
            id="custom-file"
            label="Adicionar Logo..."
            data-browse="Procurar"
            custom
          />
        </Form>
      </div>

      <Form>
        <Form.Row>
          <Form.Group className="col">
            <Form.Label>Nome da Linha</Form.Label>
            <Form.Control placeholder="Nome da Linha..." />
          </Form.Group>
        </Form.Row>
      </Form>

      <Form className="mt-2">
        {["checkbox"].map((type) => (
          <div key={`inline-${type}`} className="mb-3">
            <Form.Check
              className="p-0"
              inline
              label="EXCLUSIVA"
              name="group1"
              id={`inline-${type}-1`}
            />
            <Form.Check
              className="p-0"
              inline
              label="IMPEDIR SALVAR COMO"
              name="group1"
              id={`inline-${type}-2`}
            />
          </div>
        ))}
      </Form>

      <Form>
        <Form.Group controlId="exampleForm.SelectCustom">
          <Form.Label>Unidade de Valor do Perfil</Form.Label>
          <Form.Control as="select" custom>
            <option>Selecione uma opção</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Form.Control>
        </Form.Group>
      </Form>
    </>
  );
};

export default TabsDadosDaLinha;
