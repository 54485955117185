import React, { useCallback, useContext, useMemo, useState, useRef, useEffect } from "react";
import { Modal, Button, Tabs, Tab, Image } from "react-bootstrap";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";

import { ToastContext } from "../../contexts/toast/toastContext";
import { ToastErrorContext } from "../../contexts/toast/toastErrorContext";
import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";
import { Input, File } from "../../components/Form";
import Breadcrumbs from "../../components/Breadcrumbs";
import getValidationErrors from "../../helpers/getValidateErrors";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";
import GlobalFilter from "../../components/DataTable/GlobalFilter";
import PaginationTable from "../../components/DataTable/paginationTable-BuscaExt";
import DataNotFound from "../../components/DataNotFound";
import api from "../../services/api";
import { base64toBlob } from "../../helpers/utils";
import TabPrenativo from "./TabPrenativo";
import { IPaginacao } from "../../common/interfaces/paginacao.interfaces";
import { IListLinha, IFormLinha } from "../../common/interfaces/linha.interfaces";
import { toast } from "react-toastify";


const Linhas: React.FC = () => {

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Fields Typed
  const DataTableTS: any = PaginationTable;
  const queryString = require('querystring');

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);

  //States
  const [tableData, setTableData] = useState<IListLinha[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [reloadTable, setReloadTable] = useState(false);
  const [selected, setSelected] = useState<IFormLinha>({} as IFormLinha);
  const [linkLogo, setLinkLogo] = useState('');
  const [show, setShow] = useState(false);
  const [novo, setNovo] = useState(false);
  const [prenativo, setPrenativo] = useState<any>([]);
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState('');


  //Callbacks
  const fetchData = useCallback(async (paginacao: IPaginacao) => {
    try {
      const { data: { dados, paginas } } = await api.get(`/linha/lista?${queryString.stringify(paginacao)}`);

      setPageCount(paginas);
      setTableData(dados);
    } catch (error) {
      toast.error(getSingleErrorMessage(error));
    } finally {
      setTableLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("prenativo/select");
        setPrenativo(data);
      } catch (error) {
      }
    })();
  }, []);



  //Handles
  const handleClose = () => setShow(false);

  const handleNew = () => {
    setSelected({} as IFormLinha);
    setLinkLogo('');
    setNovo(true);
    setShow(true);
  };

  const handleChangeImagem = (e: any) => {
    setLinkLogo(URL.createObjectURL(e.target.files?.[0]));   
  }

  const handleEdit = useCallback(async ({ id }) => {
    setLinkLogo('');

    const { data } = await api.get(`linha/${id}`);

    if ((data.logo || '') != '')
      setLinkLogo(URL.createObjectURL(base64toBlob(data.logo)));

    setSelected(data);
    setShow(true);
    setNovo(false);
  }, []);

  const handleDelete = ({ id, descricao }: IListLinha) => {
    const title = `Excluir "${descricao}"?`;
    const message = "Essa Linha será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`linha/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleSubmit: SubmitHandler<IFormLinha> = async (linhas) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        descricao: Yup.string().required(message).max(20, 'O nome da linha só pode ter no máximo 20 caracteres.'),
        arquivo: Yup.mixed().test(
          "fileFormat",
          "O arquivo deve ser .jpg ou .png",
          (value) => !value || (value?.type || "").split("/")[0] === "image"
        ),
      });

      await schema.validate(linhas, { abortEarly: false });

      //Passou pela validação          
      var data = new FormData();
      data.append("logo", linhas.logo);
      data.append("descricao", linhas.descricao);

      if (selected.id != undefined)
        data.append("id", selected.id);

      const { data: { id } } = await api.post('linha', data);
      selected.id = id;

      setReloadTable(reload => !reload);
      !novo && setShow(false);
      setNovo(false);

      toast.success("A ação foi concluída.");
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Linha",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Logo",
        accessor: "logo",
        display: "align-middle",
        Cell: ({
          row: { original },
        }: {
          row: { original: IListLinha };
        }) => {
          return original.logo && <Image height={22} src={URL.createObjectURL(base64toBlob(original.logo))} />
        },
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle column-table-2b",
        Cell: ({
          row: { original },
        }: {
          row: { original: IListLinha };
        }) => (
          <span className="d-sm-inline">
            <Button
              title="Editar"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleEdit(original)}
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>

            <Button
              title="Excluir"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <Breadcrumbs />
              <span className="col-auto ml-auto">
                <Button
                  onClick={() => handleNew()}
                  variant="white"
                >
                  Nova Linha
                </Button>
              </span>
              <div className="col-auto pr-0">
                <GlobalFilter setGlobalFilter={setFilter} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card p-0">
              <div className="card-body">
                <div className={'dimmer' + (tableLoading ? ' active' : '')}>
                  <div className="loader"></div>
                  <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
                    {tableData && (
                      <DataTableTS
                        filter={filter}
                        columns={columns}
                        bordered={false}
                        data={tableData}
                        pageCount={pageCount}
                        fetchData={fetchData}
                        reload={reloadTable}
                        initialState={{
                          sortBy: [
                            {
                              id: "id",
                              desc: false,
                            },
                          ],
                        }}
                      />
                    )}
                    <DataNotFound
                      visible={
                        tableLoading == false ? tableData?.length === 0 : false
                      }
                    ></DataNotFound>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Linha */}
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{novo ? "Adicionar Linha" : "Editar Linha"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={"dimmer"}>
          <div className="loader"></div>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={selected}
            className="dimmer-content"
          >
            <Input type="hidden" name="id" />

            <Tabs defaultActiveKey="dadosDaLinha" id="uncontrolled-tab-example">
              <Tabs eventKey="dadosDaLinha" title="Dados da Linha">
                <div className="col pl-0 pr-0 mt-4 mb-3">
                  <label className="form-label">Logo</label>
                  <File
                    name="logo"
                    onChangeCapture={(e: any) => handleChangeImagem(e)}
                    placeholder="Procurar arquivo de imagem..."
                  />
                </div>

                {linkLogo && (
                  <div className="form-row mt-3">
                    <div className="col">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-auto">
                              <span
                                className="text-white avatar"
                                style={{ fontSize: "0.7rem" }}
                              >
                                <img src={linkLogo} alt={selected.descricao} />
                              </span>
                            </div>
                            <div className="col-6">
                              <div>Arquivo de Imagem</div>
                              <div className="text-muted">{selected.descricao}.jpg</div>
                            </div>
                            <div className="col text-right">
                              <a
                                href={linkLogo}
                                target="_blank"
                                download={selected.descricao + ".jpg"}
                                className="btn btn-ghost-dark p-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                  <line x1="12" y1="11" x2="12" y2="17" />
                                  <polyline points="9 14 12 17 15 14" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="form-row mt-3">
                  <div className="form-group col">
                    <label className="form-label">Nome da Linha</label>
                    <Input
                      type="text"
                      name="descricao"
                      className="form-control"
                      placeholder="Nome da Linha..."
                    />
                  </div>
                </div>
              </Tabs>

              {/* Tab Prenativo */}
              <Tab eventKey="prenativo" disabled={novo} title="Prenativo">
                <TabPrenativo selectedLinha={selected} prenativo={prenativo} />
              </Tab>
            </Tabs>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Linhas;
