import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { SubmitHandler, FormHandles } from "@unform/core";
import { toast } from "react-toastify";

import { ToastContext } from "../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import getValidationErrors from "../../../helpers/getValidateErrors";
import DataTable from "../../../components/DataTable";
import DataNotFound from "../../../components/DataNotFound";
import api from "../../../services/api";
import { File, Select } from "../../../components/Form";
import { ToastErrorContext } from "../../../contexts/toast/toastErrorContext";
import {
  IFormImagens,
  IListImagens,
  IListLogMaterial,
} from "../../../common/interfaces/importar.interfaces";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";


const TabImagens: React.FC = () => {
  //Fields Typed
  const DataTableTS: any = DataTable;

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);

  const SUPPORTED_FORMATS = [
    "application/x-zip",
    "application/zip",
    "application/x-zip-compressed",
  ];

  const message = "Campo obrigatório";
  const schema = Yup.object().shape({
    referencia: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    tipoProduto: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    imagens: Yup.mixed().notRequired().test(
      "fileFormat",
      "O arquivo deve ser .zip",
      (value) => (value !== undefined ? SUPPORTED_FORMATS.includes(value.type) : true)
    ),
    fichaTecnica: Yup.mixed().notRequired().test(
      "fileFormat",
      "O arquivo deve ser .zip",
      (value) => (value !== undefined ? SUPPORTED_FORMATS.includes(value.type) : true)
    ),
  });

  //States
  const [tableData, setTableData] = useState<IListImagens[]>([]);
  const [logImportacao, setLogImportacao] = useState<IListLogMaterial[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [homologar, setHomologar] = useState(true);
  const [disponivel, setDisponivel] = useState(true);
  const [referencias, setReferencias] = useState([]);
  const [tipoSelect, setTipoSelect] = useState<any>();
  const [selected, setSelected] = useState({} as IFormImagens);
  const [show, setShow] = useState(false);
  const [tipoMaterial, setTipoMaterial] = useState([]);

  //Effects
  useEffect(() => {
    const fetchData = async () => {
        setTableLoading(true);

        await Promise.all([
          api.get("produto/ListaImportacaoImagem"),
          api.get('produto/acaoPermitidaImportacaoImagem'),
          api.get('produto/selectTipoMaterial')
        ]).then((response: any) => {
          setTableData(response[0].data);
          handleAcaoPermitida(response[1].data);
          setTipoMaterial(response[2].data);
        }).catch((error: any) => {
          let errorMessage = getSingleErrorMessage(error);
          toast.warn(errorMessage);
        }).finally(() => setTableLoading(false));
    }

    fetchData();
  }, [reloadTable]);

  //Handles
  const handleAcaoPermitida = ({ homologar, disponibilizar }: any) => {
    setHomologar(homologar);
    setDisponivel(disponibilizar);
  }

  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handleClose = () => setShow(false);

  const handleSubmit: SubmitHandler = async (data, { reset }) => {
    try {
      formRef.current?.setErrors({});
      await schema.validate(data, { abortEarly: false });

      setTableLoading(true);

      var arquivo = new FormData();
      arquivo.append("arquivoImagem", data.imagens);
      arquivo.append("arquivoDesenhoTecnico", data.fichaTecnica);
      arquivo.append("tipoMaterial", data.tipoProduto.value);
      arquivo.append("referencia", data.referencia.label);

      const importacao = await api.post(`${data.tipoProduto.label}/importarImagem`, arquivo);
      if (importacao.data.logs.length === 0) {
        setReloadTable(reload => !reload);
        toast.success("A ação foi concluída.");
        reset();
      }
      else {
        setLogImportacao(importacao.data.logs);
        setShow(true);
      }
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
    finally {
      setTableLoading(false);
    }
  };

  const handleDelete = async ({ id, tipoProduto }: IListImagens) => {
    const title = `Excluir "${tipoProduto}"?`;
    const message = "Essa Imagem será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`produto/removerImportacaoImagem/${id}`);
        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        toast.error(getSingleErrorMessage(error));
      }
    });
  };

  const handleHomologar = () => {
    const title = "Homologar";
    const message =
      "Deseja homologar as alterações realizadas, isso não pode ser desfeito.";
    sweetAlertContext.warning(title, message, async () => {
      try {
        await api.post('produto/homologarImportacaoImagem');
        toastContext.notification(
          "A ação foi concluída. Em breve será disponibilzado o pacote de atualização para importação no seu sistema para homologação.",
          "success"
        );
        setReloadTable(reload => !reload);
        sweetAlertContext.close();
      } catch (err) {
        toast.error(getSingleErrorMessage(err));
      }
    });
  };

  const handleDisponivelMercado = () => {
    const title = "Disponível para Mercado";
    const message =
      "Deseja tornar a atualizações disponíveis para uso dos clientes?";
    sweetAlertContext.warning(title, message, async () => {
      try {
        await api.post('produto/disponibilizarImportacaoImagem');

        toastContext.notification(
          "A ação foi concluída. Em breve estará disponível para importação nos sistemas dos clientes.",
          "success"
        );
        setReloadTable(reload => !reload);
        sweetAlertContext.close();
      } catch (err) {
        toast.error(getSingleErrorMessage(err));
      }
    });
  };

  const handleChangeTipoProduto = async (tipoProduto: any) => {
    if (tipoProduto != undefined) {
      formRef.current?.clearField('referencia');
      const referencia = await api.get(`geral/selecaoReferenciaMaterial/${tipoProduto.value}`);

      setReferencias(referencia.data);
      setTipoSelect(tipoProduto.value);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Tipo",
        accessor: "tipoProduto",
        display: "align-middle",
      },
      {
        Header: "Referência",
        accessor: "referencia",
        display: "align-middle",
      },
      {
        Header: "Atualização",
        accessor: "dataCriacao",
        display: "align-middle",
      },
      {
        Header: "Situação",
        accessor: "situacao",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle",
        Cell: ({ row: { original } }: { row: { original: any } }) => (
          <span className="d-none d-sm-inline">
            <Button
              title="Excluir"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="mb-3 col pr-0 text-right">
        <span className="d-none d-sm-inline">
          <Button onClick={handleSave} variant="white">
            Importar
          </Button>
        </span>
      </div>

      <Form ref={formRef} initialData={selected} onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col form-group menu-select-bug">
            <label className="form-label">Tipo de Produto</label>
            <Select
              name="tipoProduto"
              placeholder="Selecione uma opção..."
              options={tipoMaterial}
              onChange={(e) => handleChangeTipoProduto(e)}
              isDisabled={selected.id != null}
              defaultValue={selected?.tipoProduto}
            ></Select>
          </div>
          <div className="col form-group menu-select-bug">
            <label className="form-label">Referência</label>
            <Select
              name="referencia"
              placeholder="Selecione uma referência..."
              options={referencias}
            ></Select>
          </div>
        </div>

        <div className="form-row">
          <div className="col form-group">
            <label className="form-label">Imagens</label>
            <File name="imagens" placeholder="Procurar arquivo..." />
          </div>
        </div>

        {tipoSelect !== 'PERFIL' &&
          <div className="form-row">
            <div className="col form-group">
              <label className="form-label">Ficha Técnica</label>
              <File name="fichaTecnica" placeholder="Procurar arquivo..." />
            </div>
          </div>
        }


      </Form>

      <div className="mt-3">
        <div className={"dimmer" + (tableLoading ? " active" : "")}>
          <div className="loader"></div>
          <div
            className={
              "dimmer-content" + (tableLoading ? " medium-box-loader" : "")
            }
          >
            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                reload={reloadTable}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={tableLoading == false ? tableData?.length === 0 : false}
            ></DataNotFound>
          </div>
        </div>
      </div>

      {/* Modal Log */}
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            Log de Importação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-vcenter card-table">
            <thead>
              <tr>
                <td className="font-weight-bold">Código</td>
                <td className="font-weight-bold">Mensagem</td>
              </tr>
            </thead>
            {logImportacao.map((data: IListLogMaterial, index: number) => (
              <tr key={index}>
                <td className="text-muted">{data.codigo}</td>
                <td className="text-muted">{data.mensagem}</td>
              </tr>
            ))}
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default TabImagens;
