import { useState } from "react";

export function useToggleDisplay() {
	const [isDesenhadorOpen, setIsDesenhadorOpen] = useState(false);

	const toggleDisplayDesenhador = (shouldShow: boolean) => {
		window.scrollTo(0, 0);
		document.querySelectorAll("body > div").forEach((div) => {
			const element = div as HTMLElement;
			if (element.id !== "desenhador") {
				element.style.display = shouldShow ? "none" : "block";
			} else {
				element.style.display = shouldShow ? "block" : "none";
			}
		});
		setIsDesenhadorOpen(shouldShow);
	};

	return { isDesenhadorOpen, toggleDisplayDesenhador, setIsDesenhadorOpen };
}
