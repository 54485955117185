import { AxiosResponse } from "axios";
import { IGetLinhasCemWeb, IGetProjetistaCemWeb, IGetProjetosCemWeb, IPutProjetosCemWeb, IPutProjetosCemWebResponse, QueryParamsProjetos } from "../../common/interfaces/projetosCemWeb";
import api from "../api";


export async function getProjetosCemWeb(params?: QueryParamsProjetos) {
  try {
   const response = await api.get<IGetProjetosCemWeb>("cemweb/projeto", {
    params,
   }).then((response) => response.data);

   return Array.isArray(response.data) ? response.data : [];
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function putProjetosCemWeb(data: IPutProjetosCemWeb) {
  try {
    const result = await api.put<IPutProjetosCemWebResponse>("cemweb/projeto/imagem", data).then((response) => response.data);

    return result;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function getProjetistasCemWeb() {
  try {
    const response = await api.get<IGetProjetistaCemWeb>("cemweb/projeto/projetista").then((response) => response.data);

    return Array.isArray(response.data) ? response.data : [];
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function getLinhasCemWeb() {
  try {
    const response = await api.get<IGetLinhasCemWeb>("cemweb/projeto/linha").then((response) => response.data);

    return Array.isArray(response.data) ? response.data : [];
  } catch (error: any) {
    throw new Error(error);
  }
}