import { useRef, useEffect } from 'react'

import { useField } from '@unform/core'

interface Props {
    name: string
    label?: string
  }
  
type TextAreaProps = JSX.IntrinsicElements['textarea'] & Props;


export default function TextArea({ name, label, rows = 5, cols, ...rest } : TextAreaProps) {
    const textareaRef = useRef(null)
    const { fieldName, defaultValue = '', registerField, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: textareaRef,
            getValue: ref => {
                return ref.current.value
            },
            setValue: (ref, value) => {
                ref.current.value = value
            },
            clearValue: ref => {
                ref.current.value = ''
            },
        })
    }, [fieldName, registerField])



    return (
        <div>
            <textarea
                ref={textareaRef}                
                defaultValue={defaultValue}
                rows={rows}
                cols={cols}
                {...rest}
            />

            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    )
}