import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Modal, Button } from "react-bootstrap";
import { Input, Select } from "../../components/Form";
import Breadcrumbs from "../../components/Breadcrumbs";
import DataTable from "../../components/DataTable";
import DataNotFound from "../../components/DataNotFound";
import { debounce } from "lodash";
import api from "../../services/api";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import getValidationErrors from "../../helpers/getValidateErrors";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";
import * as Yup from "yup";

import { ToastContext } from "../../contexts/toast/toastContext";
import { ToastErrorContext } from "../../contexts/toast/toastErrorContext";
import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";

import { IListTipoVidro, IFormTipoVidro } from "../../common/interfaces/tipoVidros.interfaces";
import { toast } from "react-toastify";

const TipoDeVidro: React.FC = () => {

  //Constants
  const comum = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Fields Typed
  const DataTableTS: any = DataTable;

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastErrorContext = useContext(ToastErrorContext);

  //States
  const [tableData, setTableData] = useState<IListTipoVidro[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [novo, setNovo] = useState(false);
  const [selected, setSelected] = useState<IFormTipoVidro>({} as IFormTipoVidro);
  const [reloadTable, setReloadTable] = useState(false);


  //Effects
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);

        const { data } = await api.get("tipoVidro/lista");

        setTableData(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, [reloadTable]);

  //Handles
  const handleClose = () => setShow(false);

  const handleNew = () => {
    setNovo(true);
    setSelected({} as IFormTipoVidro);
    setShow(true);
  };

  const handleChangeFilter = useCallback(
    debounce(async (e) => {
      const { data } = await api.get(`tipoVidro/lista/${e.target.value}`);
      setTableData(data);
    }, 500),
    []
  );

  const handleEdit = useCallback(async ({ id }) => {
    const { data } = await api.get(`tipoVidro/${id}`)
    setSelected(data);
    setShow(true);
    setNovo(false);
  }, []);

  const handleDelete = ({ id, descricao }: IListTipoVidro) => {
    const title = `Excluir "${descricao}"?`;
    const message = "Esse Vidro será excluído, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`tipoVidro/${id}`);
        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleSubmit: SubmitHandler = async (vidros) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        codigo: Yup.string().required(message)
        .max(20, 'O código só pode ter no máximo  20 caracteres.'),
        descricao: Yup.string().required(message)
        .max(100, 'A descrição só pode ter no máximo  100 caracteres.'),
        comum: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
      });
      await schema.validate(vidros, { abortEarly: false });

      //Passou pela validação
      vidros.id = selected.id;
      await api.post('tipoVidro', vidros);

      setReloadTable(reload => !reload);
      setShow(false);

      //Toast
      toast.success("A ação foi concluída.");
    } catch (err) {
      toastErrorContext.notification(err);
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleSave = () => {
    formRef.current?.submitForm();
    setNovo(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Código",
        accessor: "codigo",
        display: "align-middle",
      },
      {
        Header: "Descrição",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Comum",
        accessor: "comum",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({ row: { original } }: { row: { original: IListTipoVidro } }) => (
          <>
            <Button
              title="Editar"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleEdit(original)}
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              title="Excluir"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <Breadcrumbs />
              <span className="col-auto ml-auto">
                <Button variant="white" onClick={() => handleNew()}>
                  Novo Vidro
                </Button>
              </span>
              <div className="col-auto ml-0 pr-0">
                <div className="btn-list pr-0">
                  <span className="d-none d-sm-inline">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Busca..."
                      onChange={handleChangeFilter}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card p-0">
              <div className="card-body">
                <div className={'dimmer' + (tableLoading ? ' active' : '')}>
                  <div className="loader"></div>
                  <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
                    {tableData && (
                      <DataTableTS
                        columns={columns}
                        data={tableData}
                        bordered={false}
                        initialState={{
                          sortBy: [
                            {
                              id: "id",
                              desc: false,
                            },
                          ],
                        }}
                      />
                    )}
                    <DataNotFound
                      visible={
                        tableLoading == false ? tableData?.length === 0 : false
                      }
                    ></DataNotFound>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Vidro */}
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{novo ? "Adicionar Vidro" : "Editar Vidro"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mt-3" initialData={selected} ref={formRef} onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Código</label>
                <Input
                  type="text"
                  name="codigo"
                  className="form-control"
                  placeholder="Código..."
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Descrição</label>
                <Input
                  type="text"
                  name="descricao"
                  className="form-control"
                  placeholder="Descrição..."
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Classe comum?</label>
                <Select
                  name="comum"
                  placeholder="Selecione uma opção..."
                  options={comum}
                  defaultValue={selected.comum}
                ></Select>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TipoDeVidro;
