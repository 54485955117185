import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import MeusProdutosOutros from "../Graficos/MeusProdutosOutros"
import { useAuth } from "../../hooks/auth";

import MeusProdutosMaisUtilizadosChart from "../Graficos/MeusProdutosMaisUtilizados";
import QtdVidrosOrcObrasChart from "../Graficos/QtdVidrosOrcObras";
import ClientesComUltimaAtualizacaoChart from "../Graficos/ClientesComUltimaAtualizacao";
import EspessuraMaisUtilizadaChart from "../Graficos/EspessuraMaisUtilizadas";

const Dashboard: React.FC = () => {
  const { havePermission } = useAuth();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);

  useEffect(() => {
    console.log("Filters have changed:", selectedRegion, startDate, endDate);
  }, [selectedRegion, startDate, endDate]);

   const handleFilterClick = () => {
    setSelectedRegion(selectedRegion);
    setEndDate(endDate);
    setStartDate(endDate);    
  };
  const handleRegionChange = (selectedOption: any) => {
    setSelectedRegion(selectedOption);
  };
  
  return (
    <>
      {havePermission(["DashboardVidros"]) ? (
        <div className="content d-flex align-items-center justify-content-center">
        <div className="container-xl">
          <div className="page-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="col">
                <h2 className="page-title">Dashboard</h2>
              </div>
              <div className="col-auto d-print-none">
                <div className="btn-list">
                <div>
                  <Select
                    value={selectedRegion}
                    onChange={handleRegionChange}
                    options={[
                      { value: 1, label: "Sul" },
                      { value: 2, label: "Sudeste" },
                      { value: 3, label: "Centro-Oeste" },
                      { value: 4, label: "Nordeste" },
                      { value: 5, label: "Norte" },
                                        ]}
                    placeholder="Selecione a Região"
                    styles={{ 
                      control: (provided) => ({
                        ...provided,
                        minWidth: 200, 
                      }),
                    }}
                  />
                </div>
                 <DatePicker
                    className="btn btn-white"
                      selected={startDate}
                      onChange={(date) => setStartDate(date as Date)}
                      selectsStart
                      startDate={startDate}
                      placeholderText="Data Inicial"
                      dateFormat="dd/MM/yyyy"  // Defina o formato desejado
                    />
       
                 <DatePicker
                      className="btn btn-white"
                      selected={endDate}
                      onChange={(date) => setEndDate(date as Date)}
                      selectsEnd
                      endDate={endDate}
                      placeholderText="Data Final"
                      minDate={startDate}
                      dateFormat="dd/MM/yyyy"  // Defina o formato desejado
                    />
                  <span className="d-none d-sm-inline">
                    <button className="btn btn-white" onClick={handleFilterClick}>
                      <i className="fas fa-tasks"></i> Aplicar Filtro
                    </button>
                  </span>

                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="card">
                  <div className="card-body text-center">
                    <h4 className="card-title">Meus Produtos Outros</h4>
                    <div className="chart-container">
                    <MeusProdutosOutros
                      selectedRegion={selectedRegion}
                      startDate={startDate}
                      endDate={endDate}
                    />
                      </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-4">
                <div className="card">
                  <div className="card-body text-center">
                    <h4 className="card-title">Meus Produtos Mais Utilizados</h4>
                    <div className="chart-container">
                      <MeusProdutosMaisUtilizadosChart
                      selectedRegion={selectedRegion}
                      startDate={startDate}
                      endDate={endDate}
                       />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mb-4">
                <div className="card">
                  <div className="card-body text-center">
                    <h4 className="card-title">Quantidade de Vidros em Obras</h4>
                    <div className="chart-container">
                    <QtdVidrosOrcObrasChart 
                      selectedRegion={selectedRegion}
                      startDate={startDate}
                      endDate={endDate}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-4">
                <div className="card">
                  <div className="card-body text-center">
                    <h4 className="card-title">Espessura Mais Utilizada</h4>
                    <div className="chart-container">
                      <EspessuraMaisUtilizadaChart 
                      selectedRegion={selectedRegion}
                      startDate={startDate}
                      endDate={endDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="card">
                  <div className="card-body text-center">
                    <h4 className="card-title">Clientes com Última Atualização</h4>
                    <div className="chart-container">
                      <ClientesComUltimaAtualizacaoChart 
                      selectedRegion={selectedRegion}
                      startDate={startDate}
                      endDate={endDate}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="content">
          <div className="container-xl">
            <div className="page-header">
              <div className="row">
                <h2 className="col-auto page-title">Home</h2>
              </div>
            </div>
            <div className="row">
              <div className="card p-5">
                <div className="card-body">
                  <div className="text-center">
                    <h3 className="m-0 mb-1">Seja bem-vindo ao MOF - Mídia Oficial do Fornecedor.</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
