import React, {  useEffect, useRef, useState } from "react";
import { Row, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Input } from "../../../components/Form";
import Breadcrumbs from "../../../components/Breadcrumbs";
import api from "../../../services/api";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import getValidationErrors from "../../../helpers/getValidateErrors";

import { toast } from "react-toastify";
import Temperas from "../Temperas";

interface Nev {
    idTempera: number;
    descricao: string;
    perfis: any[]; // Ou substitua 'any[]' pelo tipo correto, se você tiver essa informação.
  }

  interface IFormTempera {
    idTempera: string,
    descricao: string,
}
const Tempera: React.FC = () => {

  // URL Params
  const { pId }: any = useParams();
  const [reloadTemperas, setReloadTemperas] = useState(true);
  // Refs
  const formRef = useRef<FormHandles>(null);
  const loading = useRef(true);

  //Constants
  const novo = pId == 0;
  const status = [
    { label: "Disponivel", value: 1 },
    { label: "Indisponível", value: 2 },
  ];

  //Effects

  useEffect(() => {
    (async () => {
      try {
        if (pId != 0) {
          const { data } = await api.get(`tempera/Index/${pId}`);
          formRef.current?.setData(data.result);
        } 
      } catch (err) {      
        toast.error(getSingleErrorMessage(err));
        formRef.current?.setErrors(getValidationErrors(err));
      } finally {
        loading.current = false;
      }
    })();
  }, [pId]);

   const handleSubmit: SubmitHandler<IFormTempera> = async (dados) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      
      dados.idTempera = pId;
      if (novo) {
        await api.post('tempera/Salvar', dados);
      }
      else{
        await api.post('tempera/Salvar', dados);
      }
      setReloadTemperas(true);
      toast.success("A ação foi concluída.");
      window.location.reload();

    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <Breadcrumbs />
              {/* <span className="col-auto ml-auto">
                <Button variant="white" onClick={() => formRef.current?.submitForm()}>Salvar</Button>
              </span> */}
            </div>
          </div>

          <div className="row">
            <div className="card py-2">
              <div className="card-body">
                <Form ref={formRef} onSubmit={handleSubmit}>
                <Row>
                    <div className="col">
                      <label>Tempera</label>
                      <Input type="text" name="descricao" className="form-control" placeholder="Código da Tempera" />
                    </div>
                    <div className="col-auto ml-auto">
                      <Button variant="white"  style={{ marginTop: 27 }} onClick={() => formRef.current?.submitForm()}>Adicionar</Button>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {reloadTemperas && <Temperas />}
    </>
  );
};

export default Tempera;
