import React from "react";
import Login from "../SignIn/Login";

const SignIn: React.FC = () => {
  return (
    <>  
      <Login />
    </>
  );
};

export default SignIn;