import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import DataNotFound from "../../../../../components/DataNotFound";
import FilterTable from "../../../../../components/DataTable/filterTable";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { ToastContext } from "../../../../../contexts/toast/toastContext";
import history from "../../../../../helpers/history";
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";
import api from "../../../../../services/api";

import { IListPerfil } from "../../../../../common/interfaces/empresa.interfaces";

const PerfisEmpresas: React.FC = () => {

  // URL Params
  const { pId, pString }: any = useParams();

  //Fields Typed
  const DataTableTS: any = FilterTable;

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);

  // States
  const [tableData, setTableData] = useState<IListPerfil[]>();
  const [tableLoading, setTableLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  //Effects
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);

        const { data } = await api.get("/empresaTipo");
        setTableData(data);
      } catch (error) { }
      finally {
        setTableLoading(false);
      }
    })();
  }, []);

  // Handles
  const handleBackToSelectedTab = () => {
    history.push({
      pathname: `/empresas/${pId}/${pString}`,
      state: {previousTab: "recursos"},
    });
  }

  const handleEdit = ({ id, perfil }: IListPerfil) => {
    history.push(`/empresas/${pId}/${pString}/perfis/${id}/${perfil}`);
  };

  const handleNew = () => {
    history.push(`/empresas/${pId}/${pString}/perfis/novoPerfil`);
  };

  const handleDelete = ({ id, perfil }: IListPerfil) => {
    const title = `Excluir "${perfil}"? `;
    const message = `Esse perfil será excluído, isso não pode ser desfeito.`;
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`empresaTipo/${id}`);

        setTableData((prevState) =>
          prevState?.filter(function (item: IListPerfil) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  

  //Grid Columns
  const columns = useMemo(
    () => [
      {
        Header: "Perfil",
        accessor: "perfil",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle column-table-2b",
        disableSortBy: true,
        Cell: ({ row: { original } }: { row: { original: IListPerfil } }) => (
          <>
            <Button
              onClick={() => handleEdit(original)}
              variant="ghost-dark"
              className="p-2"
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              onClick={() => handleDelete(original)}
              variant="ghost-dark"
              className="p-2"
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <h2 className="col-auto page-title">
                <Link to="../../../empresas" className="breadcrumb-item">Empresas</Link>
                <span className="breadcrumb-item" onClick={handleBackToSelectedTab}>{pString}</span>
                <span className="breadcrumb-item">Perfis</span>
              </h2>
              {/* <Breadcrumbs /> */}

              <span className="col-auto ml-auto">
                <Button onClick={() => handleNew()} variant="white">
                  Novo Perfil
                </Button>
              </span>
              <span className="col-auto pr-0">
                <Form.Control
                  placeholder="Busca..."
                  value={searchTerm || ""}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </span>
            </div>
          </div>

          <div className="row">
            <div className="card p-0">
              <div className="card-body">
                <div className={'dimmer' + (tableLoading ? ' active' : '')}>
                  <div className="loader"></div>
                  <div className="dimmer-content medium-box-loader" >

                    {tableData && (
                      <DataTableTS
                        filter={searchTerm}
                        columns={columns}
                        data={tableData}
                        bordered={false}
                        initialState={{
                          sortBy: [
                            {
                              id: "id",
                              desc: false,
                            },
                          ],
                        }}
                      />
                    )}

                    <DataNotFound
                      visible={
                        tableLoading == false ? tableData?.length === 0 : false
                      }
                    ></DataNotFound>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerfisEmpresas;
