import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DualList from "../../../../components/DualList";
import Select from "../../../../components/Form/Select";
import api from "../../../../services/api";

const TabReferencias: React.FC<any> = ({ selectedTab }) => {

  //URL Params
  const { pId }: any = useParams();

  //States
  const [stateList, setStateList] = useState<any>([]);
  const [options, setOptions] = useState([]);
  const [telaSistemaSelect, setTelaSistemaSelect] = useState<any>([]);
  const [referenciaSelect, setReferenciaSelect] = useState<number>(0);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        const selectValues = await api.get('/geral/selecaoTodasReferencias');
        setOptions(option => option = selectValues.data);
      } catch (error) { }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (selectedTab == "referencias") {
          const { data } = await api.get(`/empresa/telasistema/${pId}`);
          setTelaSistemaSelect(data);
        }
      } catch (error) { }
    })();
  }, [selectedTab]);

  // Variables   
  const { selectedOption } = stateList;

  // Handles
  const handleSelectReference = async (value: any) => {

    if (value != null) {
      const { data } = await api.get(`empresa/referenciaDisponivel/${pId}/${value}`);
      setStateList(data);
      setReferenciaSelect(value);
    }
  };

  const handleChangeReferenciasList = async (selectedOption: any) => {

    if (referenciaSelect !== 0) {
      setStateList({ selectedOption });

      const param = { idEmpresa: pId, itensSelecionados: selectedOption, idTelaSistema: referenciaSelect };
      await api.post("/empresa/salvarReferencia", param);
    }
  };

  return (
    <>
      <Form.Row className="mt-4">
        <div className="col">
          <Form.Label>Recursos</Form.Label>
          <Select name="telaSistema" 
                  placeholder="Recursos..."
                  options={telaSistemaSelect}
                  onChange={(e: any) => handleSelectReference(e.value)} >
          </Select>
        </div>
      </Form.Row>
      <hr />
      <DualList
        className="mb-4"
        selected={selectedOption}
        options={options}
        onChange={handleChangeReferenciasList}
        availableHeader="Referências Disponíveis"
        selectedHeader="Referências Selecionadas"
      />
    </>
  );
};

export default TabReferencias;
