import React, { useState, useEffect, useMemo, useRef, useContext, useCallback } from "react";
import { Select, SelectAsync } from "../../../../../components/Form";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import api from "../../../../../services/api";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import getValidationErrors from "../../../../../helpers/getValidateErrors";
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";

import DataTable from "../../../../../components/DataTable";
import DataNotFound from "../../../../../components/DataNotFound";

import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { ProdutoContext } from "../../../../../contexts/produtos/produtosContext";

import { IFormComposicao } from "../../../../../common/interfaces/produtos.interfaces";
import { IListComposicaoPerfil } from "../../../../../common/interfaces/produtos.interfaces";
import { ToastErrorContext } from "../../../../../contexts/toast/toastErrorContext";
import { toast } from "react-toastify";

const PerfilTabComposicao: React.FC<any> = ({ selectedTab }) => {
  const internoExterno = [
    { label: "Interno", value: 'INTERNO' },
    { label: "Externo", value: 'EXTERNO' },
    { label: "Cor Única", value: '' },
  ];

  //Fields
  const DataTableTS: any = DataTable;

  // URL Params
  const { pId }: any = useParams();

  // É novo?
  const novo = (pId == 0);

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);
  const produtoContext = useContext(ProdutoContext);

  //States
  const [tableData, setTableData] = useState<IFormComposicao[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        if (!novo) {
          setTableLoading(true);
          const { data } = await api.get(`perfil/perfilComposto/${pId}`);
          setTableData(data);
        }
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, [reloadTable]);

  const handleOptionsPerfil = useCallback(async (inputValue: string) => {
    try {
      const { data } = await api.get(`perfil/selectPerfilComposto/${pId}/${inputValue}`);
      return data;
    } catch (error) {
      
    } finally {

    }
  }, [])

  // Handles
  const handleSubmit: SubmitHandler<IFormComposicao> = async (
    composicaoPerfil,
    { reset }
  ) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        perfil: Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }).nullable().required(message),
        internoExterno: Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }).nullable().required(message),
      });

      await schema.validate(composicaoPerfil, { abortEarly: false });

      const { data: { disponivel } } = await api.get(`perfil/disponivelMercado/${pId}`);

      if (disponivel) {
        produtoContext.setEntrouEmEdicao(true);
        sweetAlertContext.alert("Atenção", "O material esta disponível em mercado, alteramos a situação para o modo edição, salve o cadastro para continuar com a atualização");
        setReloadTable(reload => !reload);
      }
      else {
        //Passou pela validação      
        composicaoPerfil.idPerfil = pId;
        await api.post('perfil/salvarPerfilComposto', composicaoPerfil);

        setReloadTable(reload => !reload);
        reset();
        toast.success("A ação foi concluída.");
      }
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleDelete = async ({ id, perfil }: IListComposicaoPerfil) => {
    const { data: { disponivel } } = await api.get(`perfil/disponivelMercado/${pId}`);
    if (disponivel) {
      produtoContext.setEntrouEmEdicao(true);
      sweetAlertContext.alert("Atenção", "O material esta disponível em mercado, alteramos a situação para o ição, salve o cadastro para continuar com a atualização");
    }
    else {
      //Alert
      const title = `Excluir "${perfil}"?`;
      const message =
        "Essa composição será excluída, isso não pode ser desfeito.";
      sweetAlertContext.danger(title, message, async () => {
        try {
          await api.delete(`perfil/removerPerfilComposto/${id}`);

          setTableData((prevState) =>
            prevState.filter(function (item) {
              return item.id !== id;
            })
          );
          produtoContext.setEntrouEmEdicao(true);

          sweetAlertContext.close();
        } catch (error) {
          const message = getSingleErrorMessage(error);
          if (message) {
            toastContext.notification(message, "warn");
          } else {
            toastContext.notification(
              "Ocorreu um erro ao realizar essa operação.",
              "error"
            );
          }
        }
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Perfil",
        accessor: "perfil",
        display: "align-middle",
      },
      {
        Header: "Interno / Externo",
        accessor: "internoExterno",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle column-table-1b",
        Cell: ({
          row: { original },
        }: {
          row: { original: IListComposicaoPerfil };
        }) => (
          <Button
            variant="ghost-dark"
            className="p-2"
            onClick={() => handleDelete(original)}
          >
            <i className="far fa-trash-alt icon m-0"></i>
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="row mt-4">
          <div className="form-group col">
            <label className="form-label">Perfil</label>
            <SelectAsync
              name="perfil"
              loadOptions={handleOptionsPerfil}
              placeholder="Digite o código do perfil..."
              isClearable
            />
          </div>

          <div className="form-group col">
            <label className="form-label">Interno / Externo ou Cor Única</label>
            <Select
              name="internoExterno"
              options={internoExterno}
              placeholder="Selecione uma opção..."
            />
          </div>

        </div>

        <div className="row">
          <span className="col-auto ml-auto">
            <Button
              variant="white"
              onClick={() => formRef.current?.submitForm()}
            >
              Adicionar
            </Button>
          </span>
        </div>
      </Form>


      <div
        className={"dimmer" + (tableLoading ? " active" : "")}
        style={tableLoading ? { minHeight: "322px" } : {}}
      >
        <div className="loader"></div>
        <div className="dimmer-content">
          <div className="mt-4">
            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
          </div>
          <DataNotFound
            visible={tableLoading == false ? tableData?.length === 0 : false}
          ></DataNotFound>
        </div>
      </div>
    </>
  );
};
export default PerfilTabComposicao;
