import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/auth";

import {
  Dropdown,
  Form,
  Nav,
  NavDropdown,
  Navbar
} from "react-bootstrap";

import ReactSelect from "react-select";
import Logo from "../../assets/imgs/LogoMof.svg";
import history from "../../helpers/history";
import api from "../../services/api";

const Private: React.FC = (props) => {
  //Contexts
  const { user, signOut, signInChange, havePermission } = useAuth();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (havePermission(["MASTER"])) {
        const { data } = await api.get('geral/selecaoTrocaUsuario');
        setUsers(data);
      }
    }
    fetchData();
  }, [])

  const handleChangeLogin = async ({ value }: any) => {
    signInChange({ idUsuario: value });
    history.push(`/dashboard`);
  }

  const navConfiguracao = (
    <i className="fas fa-cogs mr-1">
      <span className="font-weight-normal font-padrao"> Configuração</span>
    </i>
  );

  return (
    <>
      <header className="navbar navbar-expand-md pb-2">
        <div className="container-xl px-3">
          <a className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pr-0 pr-md-4">
            <img
              alt="MOF"
              src={Logo}
              width="110"
              height="32"
              className="navbar-brand-image"
            />
          </a>
          <div className="navbar-nav flex-row order-md-last user">
            <div className="nav-item selectUsuario mr-3">
              {users?.length != 0 && (
                <Form style={{ zIndex: 1000 }}>
                  <ReactSelect
                    className="text-left"
                    name="usuarios"
                    placeholder="Selecione um usuário..."
                    options={users}
                    onChange={(e): any => handleChangeLogin(e)}
                  ></ReactSelect>
                </Form>
              )}
            </div>

            <div className="nav-item">
              <Dropdown>
                <Dropdown.Toggle variant="none" className="mr-3">
                  <i className="far fa-user icon"></i>
                  {user?.nome}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Link className="dropdown-item" to="/minhaConta">
                    Minha Conta
                  </Link>
                  <Dropdown.Item onClick={signOut}>Sair</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
      <div className="navbar navbar-light pt-0">
        <div className="container-xl">
          <Navbar expand="lg" className="pl-0 pt-0">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
              {havePermission(["DashboardVidros"]) && (
                <Link className="nav-link" to="/dashboard">
                  <i className="fas fa-home mr-1"></i> Home
                </Link>
              )}
                {havePermission(["CadastroVidros", "CadastroPerfis", "CadastroComponentes", "CadastroSuperficies", "ConsultaProduto"]) && (
                  <Link className="nav-link" to="/produtos">
                    <i className="fas fa-cubes mr-1"></i> Produtos
                  </Link>
                )}
                {
                havePermission(["CadastroAcabamentos"]) && (
                  <Link className="nav-link" to="/acabamento">
                    <i className="fas fa-bezier-curve mr-1"></i> Cadastro de Acabamentos
                  </Link>
                )
                }
                {havePermission(["Importacao"]) && (
                  <Link className="nav-link" to="/importar">
                    <i className="fas fa-upload mr-1"></i> Importar
                  </Link>
                )}
                {havePermission(["Exportacao"]) && (
                  <Link className="nav-link" to="/exportacao">
                    <i className="fas fa-download mr-1"></i> Exportar
                  </Link>
                )}
                {havePermission(["Liberacao", "ConsultaLiberacao"]) && (
                  <Link className="nav-link" to="/liberacoes">
                    <i className="fas fa-network-wired mr-1"></i> Liberações
                  </Link>
                )}
                {havePermission(["Configuracao"]) && (
                  <NavDropdown title={navConfiguracao} id="basic-nav-dropdown">
                      <Link className="dropdown-link-color" to="/empresas">
                        <NavDropdown.Item as="span">Empresas</NavDropdown.Item>
                      </Link>

                      <Link className="dropdown-link-color" to="/usuarios">
                        <NavDropdown.Item as="span">
                          Usuários Gerais
                        </NavDropdown.Item>
                      </Link>

                      <Link className="dropdown-link-color" to="/catalogos">
                        <NavDropdown.Item as="span">Catálogos</NavDropdown.Item>
                      </Link>

                      <Link className="dropdown-link-color" to="/classesId">
                        <NavDropdown.Item as="span">
                          Classes / ID
                        </NavDropdown.Item>
                      </Link>

                      <Link className="dropdown-link-color" to="/cores">
                        <NavDropdown.Item as="span">Cores</NavDropdown.Item>
                      </Link>

                      <Link className="dropdown-link-color" to="/TipoAcabamento">
                        <NavDropdown.Item as="span">
                          Tipo de Acabamento
                        </NavDropdown.Item>
                      </Link>

                      <Link className="dropdown-link-color" to="/grupos">
                        <NavDropdown.Item as="span">Grupos</NavDropdown.Item>
                      </Link>

                      <Link className="dropdown-link-color" to="/tiposDeVidros">
                        <NavDropdown.Item as="span">
                          Tipos de Vidro
                        </NavDropdown.Item>
                      </Link>

                      <Link className="dropdown-link-color" to="/unidades">
                        <NavDropdown.Item as="span">Unidades</NavDropdown.Item>
                      </Link>

                      <Link className="dropdown-link-color" to="/referencias">
                        <NavDropdown.Item as="span">Referências</NavDropdown.Item>
                      </Link>

                      <Link className="dropdown-link-color" to="/linhas">
                        <NavDropdown.Item as="span">Linhas</NavDropdown.Item>
                      </Link>

                      <Link className="dropdown-link-color" to="/prenativo">
                        <NavDropdown.Item as="span">Prenativo</NavDropdown.Item>
                      </Link>
                      <Link className="dropdown-link-color" to="/ligas">
                        <NavDropdown.Item as="span">Ligas /Temperas</NavDropdown.Item>
                      </Link>
                  </NavDropdown>
                )}
                {havePermission(["Desenhador"]) && (
                  <Link className="nav-link" to="/desenhador">
                    Desenhador
                  </Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
      <div>{props.children}</div>

      <footer className="footer footer-transparent d-print-none">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-6 text-left">
              <ul className="list-inline list-inline-dots ml-0 mb-0">
                <li className="list-inline-item">
                  <a href="#" className="link-secondary">
                    TERMOS DE USO
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 text-right">
              <ul className="list-inline list-inline-dots mb-0">
                <li className="list-inline-item">
                  <span className="copyright">
                    © {new Date().getFullYear()} - Desenvolvido por{" "}
                    <a href="http://www.alumisoft.com.br/" target="blank">
                      {" "}
                      Alumisoft Sistemas
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Private;
