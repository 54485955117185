import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import {
  Row,
  Button,
} from "react-bootstrap";

import DataTable from "../../../../../components/DataTable";
import DataNotFound from "../../../../../components/DataNotFound";
import api from "../../../../../services/api";

import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import getValidationErrors from "../../../../../helpers/getValidateErrors";

import { IListLiberacaoPreference, IListAtualizacoesPreference } from "../../../../../common/interfaces/liberacoes.interfaces";
import { Select } from "../../../../../components/Form";
import { ToastErrorContext } from "../../../../../contexts/toast/toastErrorContext";
import { useAuth } from "../../../../../hooks/auth";
import { toast } from "react-toastify";

interface IAtualizacoes {
  dados: IListLiberacaoPreference
}

const TabAtualizacoes: React.FC<IAtualizacoes> = (props) => {

  //Fields Typed
  const DataTableTS: any = DataTable;

  const { user } = useAuth();

  // Refs
  const formRef = useRef<FormHandles>(null);

  // Context
  const toastContext = useContext(ToastContext);
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastError = useContext(ToastErrorContext);

  //States
  const [tableData, setTableData] = useState<IListAtualizacoesPreference[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [referencia, setReferencia] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);

  //Constants
  const tiposLiberacao = [
    { label: "Padrão", value: "PADRAO" },
    { label: "Parcial", value: "PARCIAL" },
    { label: "Sem bloqueios", value: "SEM_BLOQUEIO" },
  ]

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);

        const { data } = await api.get('geral/selecaoReferenciaOficial');
        setReferencia(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);

        const { data } = await api.get(`liberacaoPreference/liberacaoPortfolio/${props.dados.serial}`);
        setTableData(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    }

    fetchData();

  }, [reloadTable]);

  //Handles
  const handleSubmit: SubmitHandler = async (dados) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        referencia: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        tipoLiberacao: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
      });

      await schema.validate(dados, { abortEarly: false });

      dados.serial = props.dados.serial;
      await api.post('liberacaoPreference', dados);

      formRef.current?.clearField("referencia");
      setReloadTable(reload => !reload);

      toast.success("A ação foi concluída.");
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleDelete = ({ id, referencia, tipoLiberacao }: IListAtualizacoesPreference) => {
    const title = `Excluir "${referencia} - ${tipoLiberacao}"? `;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`liberacaoPreference/${id}`);
        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Referência",
        accessor: "referencia",
        display: "align-middle",
      },
      {
        Header: "Bloqueio",
        accessor: "tipoLiberacao",
        display: "align-middle",
      },
      {
        Header: "Data",
        accessor: "dataAtualizacao",
        display: "align-middle",
        render: (value: any) => {
          return (<>
            {value.dataAtualizacao ? new Date(value.dataAtualizacao).toLocaleDateString("dd/mm/yyyy") : ""}
          </>)
        }
      },      
    ],
    []
  );

  if (user?.role.indexOf("ConsultaLiberacao") === -1) {
    const deleteColumn = {
      Header: "Ação",
      accessor: "acao",
      disableSortBy: true,
      display: "align-middle column-table-1b",
      Cell: ({ row: { original } }: { row: { original: IListAtualizacoesPreference } }) => (
        <Button variant="ghost-dark" className="p-2" onClick={() => handleDelete(original)}>
          <i className="far fa-trash-alt icon m-0"></i>
        </Button>
      ),    
    }

    columns.push(deleteColumn);
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row px-3 align-items-center">
            <div className="col">
              <h5 className="mb-2">Razão Social:</h5>
              <p className="mb-0">{props.dados.razaoSocial}</p>
            </div>
            <div className="col">
              <h5 className="mb-2">Nome Fantasia:</h5>
              <p className="mb-0">{props.dados.nomeFantasia}</p>
            </div>
            <div className="col-auto">
              <h5 className="mb-2">Serial:</h5>
              <p className="mb-0">{props.dados.serial}</p>
            </div>
          </div>
        </div>
      </div>

      {user!.role.indexOf("Liberacao") >= 0 && (
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row className="mt-4">
          <div className="col">
            <label className="form-label">Referência</label>
            <Select
              name="referencia"
              options={referencia}
              placeholder="Selecione uma opção..."
            />
          </div>

          <div className="col">
            <label className="form-label">Bloqueio</label>
            <Select
              name="tipoLiberacao"
              options={tiposLiberacao}
              placeholder="Selecione uma opção..."
              defaultValue={{ label: "Padrão", value: "PADRAO" }}
            />
          </div>
          <div className="col-auto ml-auto">
            <Button variant="white" style={{ marginTop: 27 }} onClick={() => formRef.current?.submitForm()}>
              Adicionar
            </Button>
          </div>
        </Row>
      </Form>
      )}

      <Row className="mt-3">
        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' small-box-loader' : '')} >

            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={
                tableLoading == false ? tableData?.length === 0 : false
              }
            ></DataNotFound>
          </div>
        </div>
      </Row>
    </>
  );
};

export default TabAtualizacoes;
