import React, { useState, useEffect } from "react";
import api from "../../services/api";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface IChartData {
  series: number[];
  legendas: string[];
}
interface MeusProdutosOutrosProps {
  selectedRegion: any;
  startDate: Date | null;
  endDate: Date | null;
}
const MeusProdutosMaisUtilizadosChart: React.FC<MeusProdutosOutrosProps> = ({ selectedRegion, startDate, endDate }) => {
  const [tableLoading, setTableLoading] = useState(false);
  const [charData, setCharData] = useState<IChartData[]>([]);

  const chartOptions: ApexOptions = {
    series: [
      {
        data: charData?.map(s => s.series),
      },
    ],
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {show: false},
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        dataLabels: {
          position: 'center', // Posiciona o rótulo no centro da barra
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        colors: ['#000'], // Cor do rótulo
      },
    },
    xaxis: {
      categories: charData?.map(l => l.legendas),
    },
  };

  const formatDate = (date: Date | null): string => {
    return date ? date.toISOString().split("T")[0] : "1900-01-01";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        const { data } = await api.get(`DashboardVidros/GetMeusProdutosMaisUtilizados?regiao=${selectedRegion?.label}&inicio=${formattedStartDate}&fim=${formattedEndDate}`);
        
        setCharData(data);
      } catch (error) {
        console.error("Erro na chamada da API:", error);
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, [selectedRegion, startDate, endDate]);

  return (
    <div>
      <div>
        <div className="chart-wrap">
          <div id="chart">
            <ReactApexChart
              options={chartOptions}
              series={chartOptions.series}
              type="bar"
              height={350}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeusProdutosMaisUtilizadosChart;
