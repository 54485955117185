import React from 'react';

const Loading: React.FC = () => {
    return (
        <div
            className={"dimmer active"}
            style={{ minHeight: "322px" }}
        >
            <div className="loader"></div>
            <div className="dimmer-content"></div>
        </div>
    );
}
const EndMessage: React.FC = () => {
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <div className="card-title text-center">Você chegou ao fim da lista de produtos.</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { Loading, EndMessage };