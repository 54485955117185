import React, { createContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import "./styles.css"

interface Tipo {
  notification: any
}

interface Notificacao {(
  message: any,
  type: any,
):any}

const ToastContext = createContext({} as Tipo);

const ToastContent = ({ title, message, icon }:any) => {
  return (
    <div className="d-flex align-items-center">
      <div className="mr-3">
        {/* <i className={`fas ${icon} fa-2x`} /> */}
      </div>
      <div className="flex-row mt-2 mb-2">
        <strong>{title}</strong>
        <div>{message}</div>
      </div>
    </div>

  );
};

const ToastProvider: React.FC = ({ children }) => {
  const notification : Notificacao = (message, type) => {
    if (type === "info") {
      toast.info(
        <ToastContent title="Aviso" message={message} icon="fa-info-circle" />,
        {
          icon:<i className={`fas fa-info-circle fa-2x`} />,
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else if (type === "warn") {
      toast.warn(
        <ToastContent
        title="Aviso"
        message={message}
        icon="fa-exclamation-circle"
        />,
        {
          icon:<i className={`fas fa-exclamation-circle fa-2x`} />,
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else if (type === "error") {
      toast.error(
        <ToastContent
          title="Ops"
          message={message}
          icon="fa-exclamation-circle"
        />,
        {
          icon:<i className={`fas fa-exclamation-circle fa-2x`} />,
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else if (type === "success") {
      toast.success(
        <ToastContent
          title="Sucesso"
          message={message}
          icon="fa-check-circle"
        />,
        {
          icon:<i className={`fas fa-check-circle fa-2x`} />,
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  return (
    <ToastContext.Provider value={{ notification }}>
      {children}
    </ToastContext.Provider>
  );
};

export { ToastProvider, ToastContext };
