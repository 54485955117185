import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Input, TextArea } from "../../../../../components/Form";
import api from "../../../../../services/api";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from 'yup'
import getValidationErrors from "../../../../../helpers/getValidateErrors"
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";

import DataTable from "../../../../../components/DataTable";
import DataNotFound from "../../../../../components/DataNotFound";

import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";

import { IOutrasCaracteristicas } from "../../../../../common/interfaces/produtos.interfaces";
import { ToastErrorContext } from "../../../../../contexts/toast/toastErrorContext";
import { toast } from "react-toastify";

const PerfilTabOutrasCaracteristicas: React.FC<any> = ({setTableData, tableData}) => {

  // URL Params
  const { pId, pString }: any = useParams();

  //Fields
  const DataTableTS: any = DataTable;
  const tipoMaterial = 'PERFIL';
  const novo = (pId == 0);

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);

  //States
  const [tableLoading, setTableLoading] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);

  //Effects
  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!novo) {
          setTableLoading(true);
          const { data } = await api.get(`produto/caracteristicaMaterial/${pString}/${tipoMaterial}`);          
          setTableData(data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setTableLoading(false);
      }
    }

    fetchData();
    
  }, [reloadTable]);

  // Handles
  const handleSubmit: SubmitHandler<IOutrasCaracteristicas> = async (outrasCaracteristicasPerfil, { reset }) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({

        nome: Yup.string().required(message),
        descricao: Yup.string().required(message),
      });
      await schema.validate(outrasCaracteristicasPerfil, { abortEarly: false });

      //Passou pela validação  
      outrasCaracteristicasPerfil.codigo = pString;
      outrasCaracteristicasPerfil.tipoMaterial = tipoMaterial;
      
      await api.post('produto/SalvarCaracteristicaMaterial', outrasCaracteristicasPerfil);

      toast.success("A ação foi concluída.");

      setReloadTable(reload => !reload);
      reset();

    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleDelete = ({ id, nome }: IOutrasCaracteristicas) => {
    const title = `Excluir "${nome}"?`;
    const message = "Essa característica será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`produto/RemoverCaracteristicaMaterial/${id}`);
        setTableData((prevState: any) =>
          prevState.filter(function (item: any) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };


  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "nome",
        display: "align-middle",
      },
      {
        Header: "Descrição",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-1b",
        Cell: ({ row: { original } }: { row: { original: IOutrasCaracteristicas } }) => (

          <Button variant="ghost-dark" className="p-2" onClick={() => handleDelete(original)}>
            <i className="far fa-trash-alt icon m-0"></i>
          </Button>

        ),
      },
    ],
    []
  );

  return (
    <>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="row mt-4">
          <div className="form-group col">
            <label className="form-label">Nome</label>
            <Input
              type="text"
              name="nome"
              className="form-control"
              placeholder="Nome..."
            />
          </div>
        </div>

        <div className="form-group">
          <label className="form-label">Descrição</label>
          <TextArea name="descricao" placeholder="Descrição..." className="form-control" rows={3} />
        </div>

        <div className="row">
          <span className="col-auto ml-auto">
            <Button variant="white" onClick={() => formRef.current?.submitForm()}>Adicionar</Button>
          </span>
        </div>
      </Form>

      <div className={'dimmer' + (tableLoading ? ' active' : '')} style={tableLoading ? { minHeight: "322px" } : {}}>
        <div className="loader"></div>
        <div className="dimmer-content" >
          <div className="mt-4">
            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
          </div>
          <DataNotFound
            visible={
              tableLoading == false ? tableData?.length === 0 : false
            }
          ></DataNotFound>
        </div>
      </div>
    </>
  );
};
export default PerfilTabOutrasCaracteristicas;
