import React, { useRef, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Tab, Tabs, Modal, Image, Spinner } from "react-bootstrap";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import getValidationErrors from "../../../../helpers/getValidateErrors";
import { ToastContext } from "../../../../contexts/toast/toastContext";
import TabGeral from "./TabGeral";
import TabOutrasCaracteristicas from "./TabOutrasCaracteristicas";
import TabLinhasDeAplicacao from "./TabLinhasDeAplicacao";
import TabImagensFichaTecnica from "./TabImagensFichaTecnica";
import TabAplicabilidade from "./TabAplicabilidade";
import { base64toBlob } from "../../../../helpers/utils";
import BackButton from "../../../../components/ButtonBack";
import {
  IFormComponente,
  IOutrasCaracteristicas,
} from "../../../../common/interfaces/produtos.interfaces";
import api from "../../../../services/api";
import { ToastErrorContext } from "../../../../contexts/toast/toastErrorContext";
import history from "../../../../helpers/history";
import Placeholder from "../../../../assets/imgs/img-placeholder.png";
import { toast } from "react-toastify";
import { getSingleErrorMessage } from "../../../../helpers/getSingleErrorMessage";

interface IComponente {
  tipoProduto: string;
}

const Componente: React.FC<IComponente> = (props) => {
  // URL Params
  const { pId }: any = useParams();

  const message = "Campo obrigatório";
  const schema = Yup.object().shape({
    status: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    situacao: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    codigo: Yup.string()
      .required(message)
      .max(20, "O código só pode ter no máximo 20 caracteres."),
    codigoInterno: Yup.string().max(
      20,
      "O código interno só pode ter no máximo 20 caracteres."
    ),
    referencia: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    linha: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    aplicabilidade: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    descricao: Yup.string()
      .required(message)
      .max(100, "O código interno só pode ter no máximo 100 caracteres."),
    grupo: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    cor: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    unidadeItem: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    unidadeEmbalagem: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .required(message),
    quantidadeEmbalagem: Yup.string().required(message),
  });

  // Fields
  const novo = pId == 0;
  const baseUrlImagem = `${process.env.REACT_APP_API}figurasicones/componentes/figuras/`;

  // Refs
  const formRef = useRef<FormHandles>(null);
  // States
  const [loadingSave, setLoadingSave] = useState(false);
  const emValidacao = useRef("");
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const [reloadCompleto, setReloadCompleto] = useState(false);
  const [material, setMaterial] = useState<any>(null);
  const [selectLinhaAplicacao, setSelectLinhaAplicacao] = useState<any>({});
  const [outrasCaracteristica, setOutrasCaracteristica] = useState<
    IOutrasCaracteristicas[]
  >([]);
  const [logoPreview, setLogoPreview] = useState([]);
  const [figuraIcone, setFiguraIcone] = useState<any>({});
  const [selectValues, setSelectValues] = useState<any>({});
  const [logoLinha, setLogoLinha] = useState<any>(null);
  const [loadingValues, setLoadingValues] = useState(false);
  const [ocultarAplicabilidade, setOcultarAplicabilidade] = useState(false);

  // Context
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);

  // Effects
  useEffect(() => {
    const fetchData = async () => {
      if (props.tipoProduto.toUpperCase() === "COMPONENTE") {
        if (!novo) {
          const { data } = await api.get(`componente/${pId}`);
          setMaterial(data);

          emValidacao.current =
            data?.situacao.label == "Em Edição" ||
            data?.situacao.label == "Em Homologação"
              ? "validacao/"
              : "";
          const linhaAplicacao = await api.get(
            `geral/selecaoLinhaAplicacao/${data?.referencia?.value}/COMPONENTE`
          );
          setSelectLinhaAplicacao(linhaAplicacao.data);

          const {
            data: { ocultarAplicabilidade },
          } = await api.get(
            `produto/ReferenciaEmAplicabilidadePropria/${data?.referencia?.value}`
          );
          setOcultarAplicabilidade(ocultarAplicabilidade);
        }
      }
    };

    fetchData();
  }, [props.tipoProduto, reload]);

  useEffect(() => {
    (async () => {
      if (props.tipoProduto.toUpperCase() == "COMPONENTE") {
        setLoadingValues(true);
        const unidade = await api.get("geral/selecaoUnidadeItemEmbalagem");
        const grupo = await api.get("geral/selecaoGrupo");
        const cor = await api.get("geral/selecaoCor");
        const referencia = await api.get(
          "geral/selecaoReferenciaMaterial/COMPONENTE"
        );

        setSelectValues({
          referencia: referencia.data,
          unidade: unidade.data,
          grupo: grupo.data,
          cor: cor.data,
          formRef: formRef,
        });

        setLoadingValues(false);
      }
    })();
  }, [props.tipoProduto, reloadCompleto]);

  useEffect(() => {
    material && formRef.current?.setData(material);
  }, [material]);

  // Handles
  const handleClose = () => setShow(false);

  const handlePreview = () => {
    setShow(true);
  };

  const handleSubmit: SubmitHandler<IFormComponente> = async (componente) => {
    try {
      formRef.current?.setErrors({});

      await schema.validate(componente, { abortEarly: false });

      componente.id = pId;
      const {
        data: { id },
      } = await api.post("componente", componente);

      toast.success("A ação foi concluída.");

      history.push(`/produtos/${id}/${componente.codigo.replace('/', '')}/componente`);
      setReload((reload) => !reload);
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const submit = () => {
    setLoadingSave(true);
    setTimeout(() => {
      setLoadingSave(false);
    }, 500);
    formRef.current?.submitForm();
  };

  return (
    (novo || material) && (
      <>
        <div className="card mt-3">
          <div className="card-body mb-2 ">
            <div className="row">
            <span className="col-auto">
                <BackButton />
              </span>
              <span className="col-auto ml-auto">
                <Button
                  className="mr-2"
                  onClick={() => handlePreview()}
                  variant="white"
                  disabled={novo}
                >
                  Pré-visualização
                </Button>
              </span>
              <span className="col-auto">
                {loadingSave ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                ) : (
                  <Button variant="white" onClick={submit}>
                    Salvar
                  </Button>
                )}
              </span>
            </div>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Tabs defaultActiveKey="geral">
                <Tab eventKey="geral" title="Geral">
                  <TabGeral
                    selectValues={selectValues}
                    setLogoLinha={setLogoLinha}
                    loadingValues={loadingValues}
                  />
                </Tab>
                <Tab
                  disabled={novo}
                  eventKey="outrasCaracteristicas"
                  title="Outras Características"
                >
                  <TabOutrasCaracteristicas
                    setTableData={setOutrasCaracteristica}
                    tableData={outrasCaracteristica}
                  />
                </Tab>
                <Tab
                  disabled={novo}
                  eventKey="linhasDeAplicacao"
                  title="Linhas de Aplicação"
                >
                  <TabLinhasDeAplicacao
                    selectLinhaAplicacao={selectLinhaAplicacao}
                    setLogoPreview={setLogoPreview}
                  />
                </Tab>
                <Tab
                  disabled={novo}
                  eventKey="imagensFichaTecnica"
                  title="Imagens / Ficha Técnica"
                >
                  <TabImagensFichaTecnica
                    setFiguraIcone={setFiguraIcone}
                    figuraIcone={figuraIcone}
                  />
                </Tab>
                {!ocultarAplicabilidade && (
                  <Tab
                    disabled={novo}
                    eventKey="aplicabilidade"
                    title="Aplicabilidade"
                  >
                    <TabAplicabilidade
                      setReload={setReloadCompleto}
                      reload={reloadCompleto}
                    />
                  </Tab>
                )}
              </Tabs>
            </Form>
          </div>
        </div>

        {/* Modal Pré-visualização */}
        <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Pré-visualização</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-3 pb-3">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-sm-6 col-lg-6 pl-0">
                    <div className="card card-sm">
                      <a className="d-block">
                        <Image
                          src={
                            figuraIcone.nomeImagem != null
                              ? baseUrlImagem +
                                emValidacao.current +
                                figuraIcone.nomeImagem
                              : ""
                          }
                          onError={(event: any) =>
                            (event.target.src = Placeholder)
                          }
                          className="card-img-top"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-sm-6 pr-0">
                    <div className="card-body pb-0">
                      <div className="d-flex align-items-center">
                        <div>
                          {logoLinha && (
                            <Image
                              src={URL.createObjectURL(base64toBlob(logoLinha))}
                              onError={(event: any) =>
                                (event.target.src = Placeholder)
                              }
                              width="180px"
                              className="pb-4"
                            />
                          )}
                          <div className="font-weight-bold">
                            {material?.codigo}
                          </div>
                          <div>{material?.descricao}</div>
                          <div className="row text-muted mt-3">
                            <div className="col-sm-12 pb-3">
                              <Button
                                className="btn btn-mof btn-sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open(
                                    material?.linkDesenhoTecnico,
                                    "_blank"
                                  );
                                }}
                              >
                                FICHA TÉCNICA
                                <i className="far fa-file-pdf pl-3"></i>
                              </Button>
                            </div>
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-sm-12">
                                  <span className="font-weight-bold">
                                    Unidade Item:
                                  </span>
                                  <span className="ml-1">
                                    {material?.unidadeItem?.label}
                                  </span>
                                </div>
                                <div className="col-sm-12">
                                  <span className="font-weight-bold">
                                    Unidade Embalagem:
                                  </span>
                                  <span className="ml-1">
                                    {material?.unidadeEmbalagem?.label}
                                  </span>
                                </div>
                                <div className="col-sm-12">
                                  <span className="font-weight-bold">
                                    Quantidade Embalagem:
                                  </span>
                                  <span className="ml-1">
                                    {material?.quantidadeEmbalagem}
                                  </span>
                                </div>
                                <div className="col-sm-12">
                                  <span className="font-weight-bold">Cor:</span>
                                  <span className="ml-1">
                                    {material?.cor?.label}
                                  </span>
                                </div>
                                {material?.otimizacaoBarra != 0 && (
                                  <div className="col-sm-12">
                                    <span className="font-weight-bold">
                                      Tamanho da Barra:
                                    </span>
                                    <span className="ml-1">
                                      {material?.otimizacaoBarra} (mm)
                                    </span>
                                  </div>
                                )}
                                <div className="mt-3 col-sm-12">
                                  <span className="badge badge-xl bg-green-lt text-uppercase h4">
                                    {material?.situacao?.label}
                                  </span>
                                  <span className="ml-1 badge badge-xl bg-azure-lt text-uppercase h4">
                                    {material?.status?.label}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {logoPreview.length != 0 && (
                  <div className="card row mt-3">
                    <div className="card-header">
                      <h3 className="card-title">APLICÁVEL NAS LINHAS:</h3>
                    </div>
                    <div className="card-body">
                      <div className="col-sm-12">
                        <div className="row">
                          {logoPreview.map((logoReferencia, index) => (
                            <div className="col-sm-3" key={index}>
                              {
                                <Image
                                  src={URL.createObjectURL(
                                    base64toBlob(logoReferencia)
                                  )}
                                  onError={(event: any) =>
                                    (event.target.src = Placeholder)
                                  }
                                  width="80px"
                                />
                              }
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {outrasCaracteristica.length != 0 && (
                  <div className="card row mt-3">
                    <div className="card-header">
                      <h3 className="card-title">OUTRAS CARACTERÍSTICAS:</h3>
                    </div>
                    <div className="card-body">
                      <div className="row text-muted">
                        <div className="col-sm-12">
                          <div className="row">
                            {outrasCaracteristica.map(
                              ({ nome, descricao }, index) => (
                                <div className="col-sm-6" key={index}>
                                  <span className="font-weight-bold">
                                    {nome}:
                                  </span>{" "}
                                  {descricao}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  );
};
export default Componente;
