export enum SituacaoAplicabilidade {
    EM_EDICAO = 1,
    EM_HOMOLOGACAO = 2,
    DISPONIVEL = 3
}

export enum SituacaoTabelaPreco {
    EM_EDICAO = 1,
    EM_HOMOLOGACAO = 2,
    DISPONIVEL = 3,
    DESCONSIDERADO = 4,
}

export enum Sistema {
    CEM = 1,
    SMC = 2,
    CMN = 3,
    ONE = 4,
    PAR = 5,
    CL = 6,
    OP = 7
}

export enum Sistema {
  
}