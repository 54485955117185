import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Button, Image } from "react-bootstrap";


import api from "../../services/api";
import Breadcrumbs from "../../components/Breadcrumbs";
import GlobalFilter from "../../components/DataTable/GlobalFilter";
import PaginationTable from "../../components/DataTable/paginationTable-BuscaExt";
import DataNotFound from "../../components/DataNotFound";
import { ToastContext } from "../../contexts/toast/toastContext";
import { ToastErrorContext } from "../../contexts/toast/toastErrorContext";
import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";
import history from "../../helpers/history";
import { IPaginacao } from "../../common/interfaces/paginacao.interfaces";
import { IListReferencias } from "../../common/interfaces/referencias.interfaces";
import Placeholder from '../../assets/imgs/img-placeholder.png'

const Referencias: React.FC = () => {

  //Fields
  const DataTableTS: any = PaginationTable;
  const queryString = require('querystring');

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastErrorContext = useContext(ToastErrorContext);

  //States
  const [tableData, setTableData] = useState<IListReferencias[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [reloadTable, setReloadTable] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState('');

  //Callbacks
  const fetchData = useCallback(async (paginacao: IPaginacao) => {
    try {
      const { data: { dados, paginas } } = await api.get(`/referencia/lista?${queryString.stringify(paginacao)}`);
      setPageCount(paginas);
      setTableData(dados);
    } catch (error) {
      toastErrorContext.notification(error);
    } finally {
      setTableLoading(false);
    }
  }, []);


  //Handles  
  const handleNew = () => {
    history.push(`/referencias/0/novaReferência`);
  };

  const handleEdit = ({ id, descricao }: IListReferencias) => {
    history.push(`referencias/${id}/${descricao}`);
  };

  const handleDelete = ({ id, descricao }: IListReferencias) => {
    const title = `Excluir "${descricao}"?`;
    const message = "Essa Referência será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`referencia/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Referências",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Tipo",
        accessor: "tipo",
        display: "align-middle",
      },
      {
        Header: "Logo",
        accessor: "logo",
        display: "align-middle",
        Cell: ({
          row: { original },
        }: {
          row: { original: IListReferencias };
        }) => {
          return original.logo && <Image 
            height={40} 
            src={original.logo}
            onError={(event: any) => event.target.src = Placeholder}
            />
        },
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({
          row: { original },
        }: {
          row: { original: IListReferencias };
        }) => (
          <>
            <Button
              title="Editar"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleEdit(original)}
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              title="Excluir"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>

          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <Breadcrumbs />
              <span className="col-auto ml-auto">
                <Button variant="white" onClick={() => handleNew()}>
                  Nova Referência
                </Button>
              </span>
              <div className="col-auto pr-0">
                <GlobalFilter setGlobalFilter={setFilter} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card p-0">
              <div className="card-body">
                <div className={'dimmer' + (tableLoading ? ' active' : '')}>
                  <div className="loader"></div>
                  <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
                    {tableData && (
                      <DataTableTS
                        filter={filter}
                        columns={columns}
                        bordered={false}
                        data={tableData}
                        pageCount={pageCount}
                        fetchData={fetchData}
                        reload={reloadTable}
                        initialState={{
                          sortBy: [
                            {
                              id: "id",
                              desc: false,
                            },
                          ],
                        }}
                      />
                    )}
                    <DataNotFound visible={
                        tableLoading == false ? tableData?.length === 0 : false
                      } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referencias;
