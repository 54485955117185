import React, {
    ChangeEvent,
    useRef,
    useEffect,
    useCallback,
    useState,
} from 'react';

import { useField } from '@unform/core';

interface Props {
    name: string;
}



type InputProps = JSX.IntrinsicElements['input'] & Props;

export default function FileButton({ name, onBlur, ...rest }: InputProps) {

    const inputRef = useRef<HTMLInputElement>(null);

    const { fieldName, registerField, defaultValue, error } = useField(name);

    const [file, setFile] = useState<any>('');

    const handleClick = () => {
        inputRef.current?.click();
    };

    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.[0]) {
            setFile(e.target.files?.[0]);
        } else {
            setFile('');
        }
    };

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'files[0]',
            clearValue(ref: HTMLInputElement) {
                ref.value = '';
                setFile('');
            },
            setValue(_: HTMLInputElement, value: string) {
                setFile(value.split('_')[1]);
            },
        });
    }, [fieldName, registerField]);




    return (
        <>
            <button className="btn" type="button" onClick={handleClick} style={{ height: "calc(1.5em + 0.75rem + 2px)", borderTopRightRadius: "3px", borderBottomRightRadius: "3px" }} title="Upload">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="12" y1="11" x2="12" y2="17" /><polyline points="9 14 12 11 15 14" /></svg>
            </button>
            <input type="file" ref={inputRef} onChange={handleUpload} style={{ display: 'none' }} {...rest} />
            {error && <div className="invalid-feedback">{error}</div>}
        </>
    );
};