import React, { useState, useEffect, memo, useMemo, useRef } from "react";
import { Button, Modal, Image, Fade } from "react-bootstrap";
import { Link } from "react-router-dom";
import { reject } from "lodash";
import fs from 'fs';

import Placeholder from "../../../assets/imgs/img-placeholder.png";
import DataTable from "../../../components/DataTable";
import DataNotFound from "../../../components/DataNotFound";
import api from "../../../services/api";
import { IProdutoItem } from "../../../common/interfaces/produtos.interfaces";
import { IAplicabilidade } from "../../../common/interfaces/produtos.interfaces";
import { useAuth } from "../../../hooks/auth";
import { SituacaoAplicabilidade } from '../../../common/interfaces/enums'
import { isNullOrUndefined } from "../../../helpers/validation";

interface PropsItens {
  podeEditar: boolean;
  aplicabilidadeEdicao: boolean;
  modoEdicao: boolean;
  produto: IProdutoItem;
}

const Itens: React.FC<PropsItens> = (props) => {

  //Fields
  const baseUrlImagem = `${process.env.REACT_APP_API}figurasicones/`;
  const random = Math.random();
  const { user } = useAuth();
  // States
  const emValidacao = useRef("");
  const [fade, setFade] = useState(false);
  const [classeStatus, setClasseStatus] = useState("");
  const [classeSituacao, setClasseSituacao] = useState("");
  const [imagem, setImagem] = useState(Placeholder);
  const [mostrarModalAplicabilidade, setMostrarModalAplicabilidade] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState<IAplicabilidade[]>([]);

  const handleClose = () => [setMostrarModalAplicabilidade(false)];
  //Fields Typed
  const DataTableTS: any = DataTable;

  // Effects
  useEffect(() => {
    setFade(props.modoEdicao);

    if ((props.produto.editavel && props.produto.situacao == "Disponível para Mercado") || props.produto.situacao == "Disponível para Mercado")
      emValidacao.current = ""
    else
      emValidacao.current = "/validacao";

    //Tags
    switch (props.produto?.status) {
      case "Ativo":
        setClasseStatus("tag-green");
        break;
      case "Descontinuado":
        setClasseStatus("tag-red");
        break;
      case "Arquivo Morto":
        setClasseStatus("tag-gray");
        break;
      case "Novo Portfólio":
        setClasseStatus("tag-cyan");
        break;
    }

    switch (props.produto?.situacao) {
      case "Disponível para Mercado":
        setClasseSituacao("tag-azure");
        break;
      case "Em Edição":
        setClasseSituacao("tag-orange");
        break;
      case "Em Homologação":
        setClasseSituacao("tag-yellow");
        break;
      case "Indisponível para Mercado":
        setClasseSituacao("tag-gray-dark");
        break;
    }
    
    if (props.produto.aplicabilidades) {

      var aplicabilidades = props.produto.aplicabilidades.map((item: any) => {
        if (item.idEmpresa === user!.idEmpresa)
          return item
      });
      aplicabilidades = reject(aplicabilidades, (item) => item === undefined);
      if (aplicabilidades.length > 0) {
        if (aplicabilidades.find(p => p.situacao === 1)) {
          setClasseSituacao("tag-orange");
        }
        else if (aplicabilidades.find(p => p.situacao === 2)) {
          setClasseSituacao("tag-yellow");
        }
      }
    }

    if (props.produto.nomeImagem !== "") {
      switch (props.produto.tipoProduto) {
        case "COMPONENTE":
          setImagem(`${baseUrlImagem}componentes/figuras${emValidacao.current}/${props.produto.nomeImagem}?v=${random}`);
          break;
        case "PERFIL":
          setImagem(`${baseUrlImagem}perfis/figuras${emValidacao.current}/${props.produto.nomeImagem}?v=${random}`);
          break;
        case "VIDRO":
          setImagem(`${baseUrlImagem}vidros/figuras${emValidacao.current}/${props.produto.nomeImagem}?v=${random}`);
          break;
        case "SUPERFICIE":
          setImagem(`${baseUrlImagem}superficies/figuras${emValidacao.current}/${props.produto.nomeImagem}?v=${random}`);
          break;
      }
    }
    else {
      setImagem(Placeholder);
    }

  }, [props.produto, props.modoEdicao]);

  const modalAplicabilidade = async () => {
    setMostrarModalAplicabilidade(true);
    try {
      const { data } = await api.get(`aplicabilidade/listaAplicabilidade/${props.produto.id}`);
      setTableData(data);
    } catch (error) {

    } finally {
      setTableLoading(false);
    }
  };

  const validaUrl = (url: string) => {

    if(isNullOrUndefined(url))
      return false;

    url = url.toLocaleLowerCase();

    let extensions = ['.pdf', '.jpg', '.jpeg', 'https'];

    if (extensions.some(ext => url.includes(ext))) {
      return true;
    }

    return false;
  }

  const columns = useMemo(
    () => [
      {
        Header: "Código",
        accessor: "codigo",
        display: "align-middle",
      },
      {
        Header: "Descrição",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Referência",
        accessor: "referencia",
        display: "align-middle",
      },
      {
        Header: "Situação",
        accessor: "situacao",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle column-table-2",
        Cell: ({
          row: { original },
        }: {
          row: { original: IAplicabilidade };
        }) => (
          <span className="d-none d-sm-inline">
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open(original.fichaTecnica, "_blank");
              }}
              title="Ficha Técnica"
              variant="ghost-dark"
              className="p-2"
            >
              <i className="fas fa-file-download icon m-0"></i>
            </Button>
            <Button
              title="Visualizar"
              variant="ghost-dark"
              className="p-2"
              target="_blank"
            >
              <i className="fas fa-external-link-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );
  const ObterImagem = (): string => {
    if (props.produto.logoReferencia !== "" && props.produto.logoReferencia !== undefined)
      return props.produto.logoReferencia;
    return Placeholder;
  }

  const obterSituacao = (produto: IProdutoItem): string => {
    if (produto.aplicabilidades?.length === 0 || produto.aplicabilidades == null) {
      return produto.situacao;
    } else {

      if (produto.aplicabilidades && produto.aplicabilidades!.find(p => parseInt(p.situacao) === SituacaoAplicabilidade.EM_EDICAO)) {
        return "Em Edição - Aplicabilidade Base";
      }
      else if (produto.aplicabilidades && produto.aplicabilidades!.find(p => parseInt(p.situacao) === SituacaoAplicabilidade.EM_HOMOLOGACAO))
        return "Em Homologação - Aplicabilidade Base";
      else
        return "Disponivel para Mercado";
    }
  }


  return (
    <>
      <div className="card card-sm">
        <div className="card-body pt-0">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ minHeight: "3rem" }}
          >
            <div className="">
              <Image
                src={ObterImagem()}
                onError={(event: any) => event.target.src = Placeholder}
                height="30px"
                className="card-img-top-fabricante"
              />
            </div>
            <div>
              {props.produto.tipoProduto === "COMPONENTE" && (
                <>
                  {props.produto?.temAplicabilidade && (
                    <Link
                      onClick={modalAplicabilidade}
                      to="#"
                      className="text-muted mr-2"
                      title="Aplicabilidades"
                    >
                      <i
                        className="fas fa-retweet"
                        style={{ transform: "rotateZ(90deg)" }}
                      ></i>
                    </Link>
                  )}
                </>
              )}
              {(validaUrl(props.produto?.fichaTecnica) && props.produto.tipoProduto?.toUpperCase() != "PERFIL") && (
                <span
                  onClick={(event: any) => {
                    window.open(props.produto.fichaTecnica + `?v=${random}`, "_blank");
                  }}
                  className="text-muted mr-2"
                  title="Ficha Técnica"
                  style={{ cursor: 'pointer' }}
                >
                  <i className="far fa-file-alt"></i>
                </span>
              )}
              {(props.podeEditar && props.modoEdicao) &&
                <Fade in={fade} className="ml-1">
                  <Link
                    to={`produtos/${props.produto.id}/${props.produto.codigo.replace('/', '')}/${props.produto.tipoProduto?.toLocaleLowerCase()}`}
                    className="text-muted"
                    title="Editar"
                  >
                    <i className="far fa-edit"></i>
                  </Link>
                </Fade>
              }

              {(props.aplicabilidadeEdicao) &&
                <Fade in={fade} className="ml-1">
                  <Link
                    to={`produtos/aplicabilidadeBase/${props.produto.id}/${props.produto.codigo.replace('/', '')}`}
                    className="text-muted"
                    title="Editar Aplicabilidade">
                    <i className="far fa-edit"></i>
                  </Link>
                </Fade>
              }
            </div>
          </div>
          <div
            style={{
              width: '100%',
              height: '250px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Link
              to={`/produtos/item/${props.produto.id}/${props.produto.codigo.replace('/', '')}/${props.produto.tipoProduto?.toLocaleLowerCase()}`}
              className="d-block mt-2"
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                src={imagem}
                onError={(event: any) => { event.target.src = Placeholder }}
                className="card-img"
                style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
              />
            </Link>
          </div>


          <div className="d-flex flex-column mt-3">
            <div className="subheader">Código:</div>
            <div className="font-weight-bold">{props.produto.codigo}</div>

            <div className="subheader mt-2">Descrição:</div>
            <div className="block-with-text">{props.produto.descricao}</div>

            <div className="tag-produtos ml-auto mt-2">
              <span className={`tag ${classeStatus}`}>
                {props.produto?.status}
              </span>
              {
                  props.podeEditar && (
                    <>
                     {
                        props.produto?.situacao === "Disponível para Mercado" && props.produto?.situacaoCemMobi === "Disponível para Mercado" && (
                          <span className={`tag ${classeSituacao} ml-1`}>
                          Disponivel Mercado
                          </span>
                      )}
                      {props.produto?.situacaoCemMobi !== "Disponível para Mercado" &&  props.produto?.situacao === "Disponível para Mercado" && (
                        <>
                          <span className={`tag ${classeSituacao} ml-1`}>
                          Disponível Cem Delphi
                          </span>
                        </>
                      )}

                      {props.produto?.situacao !== "Disponível para Mercado" && (
                        <span className={`tag ${classeSituacao} ml-1`}>
                        {obterSituacao(props.produto)}
                        </span>
                      )}
                    </>
                  )
                }

              
              {props.aplicabilidadeEdicao &&
                <span className={`tag ${classeSituacao} ml-1`}>
                  {obterSituacao(props.produto)}
                </span>
              }
            </div>
          </div>
        </div>
      </div>

      {/* Modal Aplicabilidade*/}
      <Modal size="xl" show={mostrarModalAplicabilidade} onHide={handleClose}>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Código Aplicáveis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={"dimmer" + (tableLoading ? " active" : "")}>
            <div className="loader"></div>
            <div
              className={
                "dimmer-content" + (tableLoading ? " medium-box-loader" : "")
              }
            >
              {tableData && (
                <DataTableTS
                  columns={columns}
                  data={tableData}
                  bordered={false}
                  initialState={{
                    sortBy: [
                      {
                        id: "id",
                        desc: false,
                      },
                    ],
                  }}
                />
              )}
              <DataNotFound
                visible={
                  tableLoading == false ? tableData?.length === 0 : false
                }
              ></DataNotFound>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(Itens);
