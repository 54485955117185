import React, { useState, useEffect, useMemo, useContext, useRef, useCallback } from "react";
import { Modal, Row, Button, Tab, Tabs } from "react-bootstrap";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import { toast } from "react-toastify";


import { Select } from "../../../components/Form";
import DualList from "../../../components/DualList";
import FilterTable from "../../../components/DataTable/filterTable";
import DataNotFound from "../../../components/DataNotFound";
import api from "../../../services/api";
import { ToastContext } from "../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";
import getValidationErrors from "../../../helpers/getValidateErrors";
import { IListDiversas, IFormDiversas } from "../../../common/interfaces/liberacoes.interfaces";


const TabDiversas: React.FC = () => {

  //Fields Typed
  const DataTableTS: any = FilterTable;

  // Refs
  const formRef = useRef<FormHandles>(null);

  // Context
  const toastContext = useContext(ToastContext);
  const sweetAlertContext = useContext(SweetAlertContext);

  //Constants
  const tipo = [
    { label: "Produtos", value: 1 },
    { label: "Aplicabilidades", value: 2 },
    { label: "Tabela de Preços", value: 3 },
  ]

  const liberacao = [
    { label: "Liberar CEM", value: "LIBERAR_CEM" },
    { label: "Liberar PrefSuite", value: "LIBERAR_PREFSUITE" },
    { label: "Remover liberação CEM", value: "REMOVER_LIBERACAO_CEM" },
    { label: "Remover liberação PrefSuite", value: "REMOVER_LIBERACAO_PREFSUITE" },
  ]

  const sistemaCEM = [
    { label: "CEM", value: "1" },
    { label: "CEM Smart", value: "2" },
    { label: "CEM Mini", value: "3" },
    { label: "ONE", value: "4" },
    { label: "PAR", value: "5" },
  ]

  const sistemaPrefSuite = [
    { label: "Pref Closed", value: "6" },
    { label: "Pref Open", value: "7" },
  ]

  const liberacaoPrefSuite = [
    { label: "Padrão", value: 1 },
    { label: "Parcial", value: 2 },
    { label: "Sem bloqueios", value: 3 },
  ]

  //States
  const [tableData, setTableData] = useState<IListDiversas[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [novo, setNovo] = useState(false);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<IFormDiversas>({} as IFormDiversas);
  const [stateList, setStateList] = useState<any>([]);
  const [pacoteLiberacao, setPacoteLiberacao] = useState([]);
  const [referencias, setReferencias] = useState([]);
  const [pacoteDisponiveis, setPacoteDisponiveis] = useState<any>([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [stateTipo, setStateTipo] = useState(1);
  const [sistema, setSistema] = useState<any>([]);
  const [tipoLiberacao, setTipoLiberacao] = useState('');

  const [stateListTabelasPrecos, setStateListTabelasPrecos] = useState<any>([]);
  const [optionsTabelasPrecos, setOptionsTabelasPrecos] = useState([]);

  const [stateListAplicabilidade, setStateListAplicabilidade] = useState<any>([]);
  const [optionsAplicabilidade, setOptionsAplicabilidade] = useState([]);

  // Variables   
  const { selectedOption } = stateList;
  const { selectedTabela } = stateListTabelasPrecos;
  const { selectedAplicabilidade } = stateListAplicabilidade;

  const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        tipo: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        liberacao: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        sistema: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message)
      });

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);

        const { data } = await api.get("/liberacaoDiversa/listaLiberacao");
        setTableData(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    }

    fetchData();
  }, [reloadTable]);

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);

      Promise.all([
        api.get('geral/referenciaAplicabilidadeExistente'),
        api.get('geral/SelecaoTabelaPrecoDisponivelDasEmpresas')
      ]).then((response: any) => {
        setOptionsAplicabilidade(response.at(0).data)
        setOptionsTabelasPrecos(response.at(1).data)
      }).catch((err) => toast.error(err.message)).finally(() => setTableLoading(false));
    }

    fetchData();
  }, []);

  //Handles
  const handleNew = () => {
    setSelected({} as IFormDiversas);
    setStateTipo(1);
    setNovo(true);
    setShow(true);
  };
  const handleClose = () => {
    setPacoteDisponiveis([]);
    setStateListTabelasPrecos([]);
    setStateListAplicabilidade([]);
    setShow(false);
  }

  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handleSubmit: SubmitHandler = async (dados) => {
    try {
      formRef.current?.setErrors({});

      await schema.validate(dados, { abortEarly: false });

      dados.id = selected.id;
      const { data: { id } } = await api.post('liberacaoDiversa', dados);

      selected.id = id;
      setNovo(false);
      toast.success("A ação foi concluída");
      setReloadTable(reload => !reload);

    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleEdit = useCallback(async ({ id }) => {

    const { data } = await api.get(`liberacaoDiversa/liberacao/${id}`);

    Promise.all([
      api.get(`liberacaoDiversa/liberacaoTabelaPrecoDisponivel/${id}`),
      api.get(`liberacaoDiversa/liberacaoAplicabilidadeDisponivel/${id}`),
      api.get(`liberacaoDiversa/liberacao/${id}`)
    ]).then((response: any) => {
      setStateListTabelasPrecos(response[0].data);
      setStateListAplicabilidade(response[1].data);
    }).catch((err) => toast.error(err.message)).finally(() => setTableLoading(false));
    
    if (data.liberacao.value === 1 || data.liberacao.value === 3)
      handleCarregarConfiguracaoCEM();
    else
      handleCarregarConfiguracaoPrefSuite();

    setStateTipo(data.tipo.value);
    setSelected(data);
    setShow(true);
    setNovo(false);
  }, []);


  const handleChangeReferencia = async (e: any) => {

    if (e?.value != null) {
      if (tipoLiberacao == "CEM") {
        const pacoteAtualizacaoDisponivel = await api.get(`liberacaoDiversa/liberacaoPortfolioDisponivel/${selected.id}/${e.value}`);
        setStateList(pacoteAtualizacaoDisponivel.data);

        const pacoteAtualizacaoSelect = await api.get(`liberacaoDiversa/pacoteAtualizacaoSelect/${e.value}`);
        setPacoteDisponiveis(pacoteAtualizacaoSelect.data);
      }
      else {
        const pacoteAtualizacaoDisponivel = await api.get(`liberacaoDiversa/liberacaoPortfolioPrefSuiteDisponivel/${selected.id}/${e.value}`);
        setStateList(pacoteAtualizacaoDisponivel.data);

        setPacoteDisponiveis(liberacaoPrefSuite);
      }
    }
  };

  const handleDelete = ({ id, liberacao, sistema }: IListDiversas) => {
    const title = `Excluir "${liberacao} - ${sistema}"? `;
    const message = `Após a confirmação isso não pode ser desfeito.`;
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`liberacaoDiversa/${id}`);
        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleDisponibilizar = () => {
    const title = 'Tem certeza?';
    const message = 'Será realizada uma liberação em massa.';
    sweetAlertContext.warning(title, message, async () => {
      try {

        await api.post(`liberacaoDiversa/disponibilizar/${selected.id}`);
        selected.disponivel = true;

        toast.success("A ação foi concluída.");
        sweetAlertContext.close();
      } catch (error) {
        toast.error(getSingleErrorMessage(error));
      }
    });
  }

  const handleChangePacote = async (selectedOption: any) => {
    try {
      setStateList({ selectedOption });

      const portfolioDiversa = {
        idLiberacaoDiversa: selected.id,
        idReferencia: formRef.current?.getFieldValue("referencia")?.value,
        idsAtualizacaoPortfolio: selectedOption,
      };

      if (tipoLiberacao === "CEM") {
        await api.post('liberacaoDiversa/liberacaoPortfolioCEM', portfolioDiversa);
      }
      else {
        await api.post('liberacaoDiversa/liberacaoPortfolioPrefSuite', portfolioDiversa);

        const pacoteAtualizacaoDisponivel = await api.get(`liberacaoDiversa/liberacaoPortfolioPrefSuiteDisponivel/${selected.id}/${portfolioDiversa.idReferencia}`);
        setStateList(pacoteAtualizacaoDisponivel.data);
      }

    } catch (err) {
      toast.error(getSingleErrorMessage(err));
    }
  }

  const handleChangeTabelaPreco = async (selectedTabela: any) => {
    try {
      setStateListTabelasPrecos({ selectedTabela });

      const tabelaDiversa = {
        idLiberacaoDiversa: selected.id,
        idsTabelaPreco: selectedTabela,
      };

      await api.post('liberacaoDiversa/liberacaoTabelaPreco', tabelaDiversa);

    } catch (err) {
      toast.error(getSingleErrorMessage(err));
    }
  };

  const handleChangeAplicabilidade = async (selectedAplicabilidade: any) => {
    try {
      setStateListAplicabilidade({ selectedAplicabilidade });

      const aplicabilidadeDiversa = {
        idLiberacaoDiversa: selected.id,
        idsEmpresas: selectedAplicabilidade,
      };

      await api.post('liberacaoDiversa/liberacaoAplicabilidade', aplicabilidadeDiversa);
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
    }
  };

  const handleChangeTipo = ({ value }: any) => {
    setStateTipo(value);
  }

  const handleChangeLiberacaoSistema = async ({ value }: any) => {
    const liberacaoAlterada = formRef.current?.getFieldValue('liberacao');
    let alteracaoSistema = false;

    if ((value === "LIBERAR_CEM" || value === "REMOVER_LIBERACAO_CEM") &&
      (liberacaoAlterada?.value != "LIBERAR_CEM" && liberacaoAlterada?.value != "REMOVER_LIBERACAO_CEM")) {
      alteracaoSistema = true;
      handleCarregarConfiguracaoCEM();
    }
    else if ((value === "LIBERAR_PREFSUITE" || value === "REMOVER_LIBERACAO_PREFSUITE") &&
      (liberacaoAlterada?.value != "LIBERAR_PREFSUITE" && liberacaoAlterada?.value != "REMOVER_LIBERACAO_PREFSUITE")) {
      alteracaoSistema = true;
      handleCarregarConfiguracaoPrefSuite();
    }

    if (alteracaoSistema) {
      formRef.current?.clearField('sistema');
      formRef.current?.clearField('atualizacaoExistenteCEM');
      formRef.current?.clearField('atualizacaoExistentePrefSuite');
      formRef.current?.clearField('referencia');
      setPacoteDisponiveis([]);
    }
  }

  const handleCarregarConfiguracaoCEM = async () => {
    const referenciaPacote = await api.get('geral/selecaoPacote');
    setReferencias(referenciaPacote.data.result);

    const pacote = await api.get('geral/selecaoPacoteComLinha');
    setPacoteLiberacao(pacote.data);

    setTipoLiberacao("CEM");
    setSistema(sistemaCEM);
  }

  const handleCarregarConfiguracaoPrefSuite = async () => {
    const referenciaPacote = await api.get('geral/selecaoPacotePrefSuite');
    setReferencias(referenciaPacote.data);

    const pacote = await api.get('geral/selecaoPacotePrefSuiteCliente');
    setPacoteLiberacao(pacote.data);

    setTipoLiberacao("PREFSUITE");
    setSistema(sistemaPrefSuite);
  }

  const columns = useMemo(
    () => [
      {
        Header: "Tipo",
        accessor: "tipo",
        display: "align-middle",
      },
      {
        Header: "Liberação",
        accessor: "liberacao",
        display: "align-middle",
      },
      {
        Header: "Sistema",
        accessor: "sistema",
        display: "align-middle",
      },
      {
        Header: "Data de Liberação",
        accessor: "dataLiberacao",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({ row: { original } }: { row: { original: IListDiversas } }) => (
          <>
            <Button
              title="Editar"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleEdit(original)}
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              title="Excluir"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Row>
        <div className="col-auto ml-auto">
          <Button variant="white" onClick={handleNew}>
            Criar Liberação em Massa
          </Button>
        </div>
        <div className="col-auto">
          <input
            className="form-control"
            type="text"
            placeholder="Busca..."
            value={searchTerm || ""}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </Row>
      <Row className="mt-3">
        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
            {tableData && (
              <DataTableTS
                filter={searchTerm}
                columns={columns}
                data={tableData}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={
                tableLoading == false ? tableData?.length === 0 : false
              }
            ></DataNotFound>
          </div>
        </div>
      </Row>

      {/* Modal Liberação em Massa*/}
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{novo ? "Nova " : "Editar "}Liberação em Massa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-auto text-right p-0">
            <Button
              variant="white"
              onClick={handleDisponibilizar}>
              Disponibilizar em Massa
            </Button>
          </div>
          <Form initialData={selected} ref={formRef} onSubmit={handleSubmit} className="mt-3">
            <div className="card">
              <div className="card-body">
                <Row>
                  <div className="col">
                    <label className="form-label">Tipo</label>
                    <Select
                      name="tipo"
                      options={tipo}
                      placeholder="Selecione uma opção..."
                      defaultValue={selected?.tipo ?? tipo[0]}
                      onChange={handleChangeTipo}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Liberação</label>
                    <Select
                      name="liberacao"
                      options={liberacao}
                      placeholder="Selecione uma opção..."
                      defaultValue={selected?.liberacao}
                      onChange={handleChangeLiberacaoSistema}
                    />
                  </div>
                </Row>
                <Row className="mt-3">
                  <div className="col">
                    <label className="form-label">Sistema</label>
                    <Select
                      name="sistema"
                      options={sistema}
                      placeholder="Selecione uma opção..."
                      defaultValue={selected?.sistema}
                    />
                  </div>
                  <div className="col">
                    {(tipoLiberacao != "" &&
                      <label className="form-label">Liberar para quem possuí o Pacote</label>
                    )}
                    {(tipoLiberacao == "CEM" && <Select
                      name="atualizacaoExistenteCEM"
                      options={pacoteLiberacao}
                      placeholder="Selecione uma opção..."
                      isClearable
                      defaultValue={selected?.atualizacaoExistenteCEM}
                    />)}
                    {(tipoLiberacao == "PREFSUITE" && <Select
                      name="atualizacaoExistentePrefSuite"
                      options={pacoteLiberacao}
                      placeholder="Selecione uma opção..."
                      isClearable
                      defaultValue={selected?.atualizacaoExistentePrefSuite}
                    />
                    )}
                  </div>
                </Row>
              </div>
            </div>
            <div className="row">
              <div className="d-flex mt-3">
                <Button variant="primary" onClick={handleSave}>
                  Salvar
                </Button>
              </div>
            </div>
            {(!novo &&
              <div className="card mt-3">
                <div className="card-body">
                  <Tabs defaultActiveKey="atualizacoes">
                    {stateTipo === 1 && (<Tab eventKey="atualizacoes" title="Produtos" active={true}>
                      <div className="py-4">
                        <Row>
                          <div className="col">
                            <label className="form-label">Referência</label>
                            <Select
                              name="referencia"
                              options={referencias}
                              placeholder="Selecione uma referência..."
                              onChange={(e) => handleChangeReferencia(e)}
                            />
                          </div>
                        </Row>
                        <Row className="mt-4">
                          <DualList
                            selected={selectedOption}
                            options={pacoteDisponiveis}
                            onChange={handleChangePacote}
                            availableHeader="Pacotes Disponíveis"
                            selectedHeader="Pacotes a Serem Liberados"
                          />
                        </Row>
                      </div>
                    </Tab>
                    )}
                    {stateTipo === 3 && (<Tab eventKey="precos" title="Tabela de Preços" active={true}>
                      <div className="py-4">
                        <DualList
                          selected={selectedTabela}
                          options={optionsTabelasPrecos}
                          onChange={handleChangeTabelaPreco}
                          availableHeader="Tabelas Disponíveis"
                          selectedHeader="Tabelas Liberadas"
                        />
                      </div>
                    </Tab>
                    )}
                    {stateTipo === 2 && (<Tab eventKey="aplicabilidade" title="Aplicabilidades" active={true}>
                      <div className="py-4">
                        <DualList
                          name="aplicabilidade"
                          selected={selectedAplicabilidade}
                          options={optionsAplicabilidade}
                          onChange={handleChangeAplicabilidade}
                          availableHeader="Aplicabilidades Disponíveis"
                          selectedHeader="Aplicabilidades Liberadas"
                        />
                      </div>
                    </Tab>
                    )}
                  </Tabs>
                </div>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabDiversas;
