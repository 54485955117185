import { useEffect, useRef, useState } from "react";
import { Button, Tab, Tabs, Modal, Image } from "react-bootstrap";
import { base64toBlob } from "../../../../helpers/utils";
import api from "../../../../services/api";

import Placeholder from "../../../../assets/imgs/img-placeholder.png";
import { IOutrasCaracteristicas } from "../../../../common/interfaces/produtos.interfaces";

const ModalPrevisualizacao: React.FC<any> = ({ id }) => {

    //Constants
    const baseUrlImagem = `${process.env.REACT_APP_API}figurasicones/componentes/figuras/`;

    //Refs
    const emValidacao = useRef("");

    //States
    const [figuraIcone, setFiguraIcone] = useState<any>({});
    const [material, setMaterial] = useState<any>(null);
    const [logoPreview, setLogoPreview] = useState([]);
    const [outrasCaracteristica, setOutrasCaracteristica] = useState<IOutrasCaracteristicas[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.get(`componente/${id}`);
            setMaterial(data);
     
            const figuraIcone = await api.get(`componente/ImagemAnexo/${id}`);
            setFiguraIcone(figuraIcone.data);

            const logoLinha = await api(`produto/logoLinhaAplicavel/${id}/COMPONENTE`)
            setLogoPreview(logoLinha.data);

            const outrasCaracteristica = await api.get(`produto/caracteristicaMaterial/${data.Codigo}/COMPONENTE`);
            setOutrasCaracteristica(outrasCaracteristica.data);

            emValidacao.current = (data?.situacao.label == "Em Edição" || data?.situacao.label == "Em Homologação" ? "validacao/" : "");
        };

        fetchData();

    }, []);

    return (
        <div className="row">
            <div className="col-12">
                <div className="row">
                    <div className="col-sm-6 col-lg-6 pl-0">
                        <div className="card card-sm">
                            <a className="d-block">
                                <Image
                                    src={figuraIcone.nomeImagem != null ? baseUrlImagem + emValidacao.current + figuraIcone.nomeImagem : Placeholder}
                                    className="card-img-top"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-6 pr-0">
                        <div className="card-body pb-0">
                            <div className="d-flex align-items-center">
                                <div>
                                    <div className="font-weight-bold">
                                        {material?.codigo}
                                    </div>
                                    <div>{material?.descricao}</div>
                                    <div className="row text-muted mt-3">
                                        <div className="col-sm-12 pb-3">
                                            <Button className="btn btn-mof btn-sm"
                                                onClick={() => {
                                                    window.open(material.linkDesenhoTecnico, '_blank');
                                                }}>
                                                FICHA TÉCNICA
                                                <i className="far fa-file-pdf pl-3"></i>
                                            </Button>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <span className="font-weight-bold">
                                                        Unidade Item:
                                                    </span>
                                                    <span className="ml-1">
                                                        {material?.unidadeItem?.label}
                                                    </span>
                                                </div>
                                                <div className="col-sm-12">
                                                    <span className="font-weight-bold">
                                                        Unidade Embalagem:
                                                    </span>
                                                    <span className="ml-1">
                                                        {material?.unidadeEmbalagem?.label}
                                                    </span>
                                                </div>
                                                <div className="col-sm-12">
                                                    <span className="font-weight-bold">
                                                        Quantidade Embalagem:
                                                    </span>
                                                    <span className="ml-1">
                                                        {material?.quantidadeEmbalagem}
                                                    </span>
                                                </div>
                                                <div className="col-sm-12">
                                                    <span className="font-weight-bold">Cor:</span>
                                                    <span className="ml-1">
                                                        {material?.cor?.label}
                                                    </span>
                                                </div>
                                                {material?.otimizacaoBarra != 0 && (
                                                    <div className="col-sm-12">
                                                        <span className="font-weight-bold">
                                                            Tamanho da Barra:
                                                        </span>
                                                        <span className="ml-1">
                                                            {material?.otimizacaoBarra} (mm)
                                                        </span>
                                                    </div>
                                                )}
                                                <div className="mt-3 col-sm-12">
                                                    <span className="badge badge-xl bg-green-lt text-uppercase h4">
                                                        {material?.situacao?.label}
                                                    </span>
                                                    <span className="ml-1 badge badge-xl bg-azure-lt text-uppercase h4">
                                                        {material?.status?.label}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {logoPreview.length != 0 && (
                <div className="card row mt-3">
                    <div className="card-header">
                        <h3 className="card-title">APLICÁVEL NAS LINHAS:</h3>
                    </div>
                    <div className="card-body">
                        <div className="col-sm-12">
                            <div className="row">
                                {logoPreview.map((logoReferencia, index) => (
                                    <div className="col-sm-3" key={index}>
                                        {
                                            <Image
                                                src={URL.createObjectURL(
                                                    base64toBlob(logoReferencia)
                                                )}
                                                width="80px"
                                            />
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {outrasCaracteristica.length != 0 && (
                <div className="card row mt-3">
                    <div className="card-header">
                        <h3 className="card-title">OUTRAS CARACTERÍSTICAS:</h3>
                    </div>
                    <div className="card-body">
                        <div className="row text-muted">
                            <div className="col-sm-12">
                                <div className="row">
                                    {outrasCaracteristica.map(
                                        ({ nome, descricao }, index) => (
                                            <div className="col-sm-6" key={index}>
                                                <span className="font-weight-bold">
                                                    {nome}:
                                                </span>{" "}
                                                {descricao}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>

    )
}

export default ModalPrevisualizacao;