import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Select } from "../../../../../components/Form";
import api from "../../../../../services/api";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from 'yup'
import getValidationErrors from "../../../../../helpers/getValidateErrors"
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";

import DataTable from "../../../../../components/DataTable";
import DataNotFound from "../../../../../components/DataNotFound";

import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { ProdutoContext } from "../../../../../contexts/produtos/produtosContext";

import { ILinhasAplicacao } from "../../../../../common/interfaces/produtos.interfaces";
import { toast } from "react-toastify";

const PerfilTabLinhasDeAplicacao: React.FC<any> = ({ selectLinhaAplicacao, setLogoPreview }) => {
  
  //Fields
  const DataTableTS: any = DataTable;

  // URL Params
  const { pId }: any = useParams();

  // É um novo perfil?
  const novo = (pId == 0);
  const tipoMaterial = 'PERFIL';

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const produtoContext = useContext(ProdutoContext);


  //States
  const [tableData, setTableData] = useState<ILinhasAplicacao[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        if (!novo) {
          setTableLoading(true);
          const { data } = await api.get(`perfil/linhaAplicacao/${pId}`);
          const previewLogos = await api(`produto/logoLinhaAplicavel/${pId}/${tipoMaterial}`)

          setLogoPreview(previewLogos.data);
          setTableData(data);
        }
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, [reloadTable]);

  //Handles
  const handleSubmit: SubmitHandler<ILinhasAplicacao> = async (linhasAplicacaoPerfil, { reset }) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({

        linhaAplicacao: Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }).nullable().required(message),
      });
      await schema.validate(linhasAplicacaoPerfil, { abortEarly: false });

      //Passou pela validação  
      linhasAplicacaoPerfil.idMaterial = pId;
      await api.post('perfil/salvarLinhaAplicacao', linhasAplicacaoPerfil);

      setReloadTable(reload => !reload);
      reset();

      produtoContext.setEntrouEmEdicao(true);

      toast.success("A ação foi concluída.");

    } catch (err) {
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleDelete = ({ id, linhaAplicacao }: ILinhasAplicacao) => {
    const title = `Excluir "${linhaAplicacao}"?`;
    const message = "Essa aplicação de linha será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`perfil/removerLinhaAplicacao/${id}`);
        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        produtoContext.setEntrouEmEdicao(true);
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Linhas",
        accessor: "linhaAplicacao",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-1b",
        Cell: ({ row: { original } }: { row: { original: ILinhasAplicacao } }) => (

          <Button variant="ghost-dark" className="p-2" onClick={() => handleDelete(original)}>
            <i className="far fa-trash-alt icon m-0"></i>
          </Button>

        ),
      },
    ],
    []
  );

  return (
    <>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="row mt-4">
          <div className="form-group col">
            <label className="form-label">Linhas de Aplicação</label>
            <Select
              name="linhaAplicacao"
              options={selectLinhaAplicacao}
              placeholder="Selecione uma opção..."
            />
          </div>

          <div className="col-auto">
            <Button variant="white" style={{ marginTop: 27 }} onClick={() => formRef.current?.submitForm()}>Adicionar</Button>
          </div>
        </div>
      </Form>

      <div className={'dimmer' + (tableLoading ? ' active' : '')} style={tableLoading ? { minHeight: "322px" } : {}}>
        <div className="loader"></div>
        <div className="dimmer-content" >
          <div className="mt-4">
            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
          </div>
          <DataNotFound
            visible={
              tableLoading == false ? tableData?.length === 0 : false
            }
          ></DataNotFound>
        </div>
      </div>
    </>
  );
};
export default PerfilTabLinhasDeAplicacao;
