import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import DualList from "../../../../components/DualList";
import FilterTable from "../../../../components/DataTable/filterTable";
import DataNotFound from "../../../../components/DataNotFound";

import { ToastContext } from "../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../contexts/sweetAlert/alertContext";
import { ToastErrorContext } from "../../../../contexts/toast/toastErrorContext";

import { Form } from "@unform/web";
import { Input, Select } from "../../../../components/Form";
import { SubmitHandler, FormHandles } from "@unform/core";
import getValidationErrors from "../../../../helpers/getValidateErrors";
import { getSingleErrorMessage } from "../../../../helpers/getSingleErrorMessage";
import * as Yup from "yup";
import api from "../../../../services/api";

import { IListUsuario } from "../../../../common/interfaces/empresa.interfaces";
import { IFormUsuario } from "../../../../common/interfaces/usuario.interfaces";
import { toast } from "react-toastify";

const TabUsuarios: React.FC<any> = ({ selectedTab }) => {
  //Params
  const { pId }: any = useParams();

  //Fields Typed
  const DataTableTS: any = FilterTable;

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);

  // Refs
  const formRef = useRef<FormHandles>(null);

  //States
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<IFormUsuario>({} as IFormUsuario);
  const [novo, setNovo] = useState(false);
  const [tableData, setTableData] = useState<IListUsuario[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [refreshDataTable, setRefreshDataTable] = useState(false);
  const [options, setOptions] = useState([]);
  const [stateList, setStateList] = useState({ selectedOption: [] });

  //Effects
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);

        const { data } = await api.get(`/usuario/lista/${pId}`);
        setTableData(data);

      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      } finally {
        setTableLoading(false);
      }
    })();
  }, [refreshDataTable]);

  useEffect(() => {
    const fetchData = () => {
      setTableLoading(true);
      if (selectedTab == "usuarios") {
        api.get(`/usuario/telaSistemaDisponivel/${pId}`)
          .then((response: any) => setOptions(response.data))
          .catch(error => handleError(error))
          .finally(() => setTableLoading(false));
      }
    }

    fetchData();

  }, [selectedTab]);


  const handleError = (error: any) => {
    const message = getSingleErrorMessage(error);
    if (message) {
      toastContext.notification(message, "warn");
    } else {
      toastContext.notification(
        "Ocorreu um erro ao realizar essa operação.",
        "error"
      );
    }
  }


  //Handles
  const handleEdit = useCallback(async (original) => {
    const { data } = await api.get(`/usuario/telaSistemaLiberada/${original.id}`)
    const usuarioEdit = await api.get(`/usuario/${original.id}`)
    setStateList(data);
    setSelected(usuarioEdit.data);
    setShow(true);
  }, []);

  const handleNew = () => {
    setStateList({ selectedOption: [] });
    setSelected({} as IFormUsuario);
    setNovo(true);
    setShow(true);
  };

  const handleClose = () => {
    setSelected({} as IFormUsuario);
    setNovo(false);
    setShow(false);
  };

  const handleSave = () => {
    formRef.current?.submitForm();
    setNovo(false);
  };

  const handleDelete = ({ id, usuario }: IListUsuario) => {
    //Alert
    const title = `Excluir "${usuario}"?`;
    const message = "Esse usuário será excluído, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`usuario/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleSend = ({ id, usuario, email }: IListUsuario) => {
    const title = `Enviar dados para "${usuario}"?`;
    const message = `Os dados de acesso serão enviados ao e-mail cadastrado.`;
    sweetAlertContext.warning(title, message, async () => {
      try {
        var response = await api.post(`usuario/enviarDadosAcesso/${id}`);
        sweetAlertContext.close();
        if (response.data == 200 || response.data == 202) {
          toastContext.notification("O Email foi enviado com seus dados de acesso.", "info");
        } else {
          toastContext.notification("Não foi possivel enviar o email com os dados de acesso", "warn");
        }
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  // Varíaveis da busca
  const [searchTerm, setSearchTerm] = useState("");

  const tipoUsuario = [
    { value: 1, label: "Master" },
    { value: 2, label: "Administrador" },
    { value: 4, label: "Usuário MOF" },
  ]

  const onChangeList = (selectedOption: any) => {
    setStateList({ selectedOption });
  };

  const { selectedOption } = stateList;

  const handleSubmit: SubmitHandler<IFormUsuario> = async (usuario) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        nome: Yup.string().required(message),
        email: Yup.string().email("Digite um e-mail válido.").required(message),
        serial: Yup.string().required(message),
        tipo: Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }).nullable().required(message)
      });
      await schema.validate(usuario, { abortEarly: false });

      //Passou pela validação
      usuario.itensSelecionados = selectedOption;
      usuario.idEmpresa = pId;
      usuario.id = selected.id;

      await api.post('/usuario', usuario);

      setRefreshDataTable(state => !state);
      setShow(false);
      //Toast
      toast.success("A ação foi concluída.");
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Usuário",
        accessor: "usuario",
        display: "align-middle",
      },
      {
        Header: "E-mail",
        accessor: "email",
        display: "align-middle",
      },
      {
        Header: "Tipo",
        accessor: "tipo",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle column-table-3b",
        disableSortBy: true,
        Cell: ({ row: { original } }: { row: { original: IListUsuario } }) => (
          <span className="d-sm-inline">
            <Button
              title="Enviar dados de acesso"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleSend(original)}
            >
              <i className="far fa-share-square icon m-0"></i>
            </Button>
            <Button
              title="Editar"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleEdit(original)}
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              title="Excluir"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="card-body mt-4 mb-2 p-0">
        <div className="row">
          <div className="col-sm-8 btn-list">
            <span className="d-none d-sm-inline">
              <Button variant="white" onClick={() => handleNew()}>
                Novo Usuário
              </Button>
            </span>
          </div>
          <div className="col-auto ml-auto">
            <div className="btn-list">
              <span className="d-none d-sm-inline">
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Busca..."
                    value={searchTerm || ""}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' small-box-loader' : '')} >
            {tableData && (
              <DataTableTS
                filter={searchTerm}
                columns={columns}
                data={tableData}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={tableLoading == false ? tableData?.length === 0 : false}
            ></DataNotFound>
          </div>
        </div>
      </div>

      {/* Modal Usuário */}
      <Modal size="xl" show={show} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{novo ? "Novo Usuário" : "Editar Usuário"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mt-3" ref={formRef} onSubmit={handleSubmit}>
            <Input type="hidden" name="id" />
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Nome</label>
                <Input
                  type="text"
                  name="nome"
                  className="form-control"
                  placeholder="Nome do usuário..."
                  defaultValue={selected?.nome}
                />
              </div>
              <div className="form-group col">
                <label className="form-label">E-mail</label>
                <Input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="E-mail do usuário..."
                  defaultValue={selected?.email}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Nº de Série - Sistema CEM</label>
                <Input
                  type="text"
                  name="serial"
                  className="form-control"
                  placeholder="Número de série..."
                  defaultValue={selected?.serial}
                />
              </div>
              <div className="form-group col">
                <label className="form-label">Tipo</label>
                <Select name="tipo" options={tipoUsuario} defaultValue={selected?.tipo}></Select>
              </div>
            </div>
            <div className="form-row">
              <DualList
                name="recursos"
                className="col-12 my-4"
                selected={selectedOption}
                options={options}
                onChange={onChangeList}
                availableHeader="Recursos Disponíveis"
                selectedHeader="Recursos Selecionados"
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabUsuarios;
