import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Form,
  FormControl,
  Image,
  Modal,
  Table,
  Spinner,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import BlockUi from "react-block-ui";
import history from "../../../../../../helpers/history";

import DataTable from "../../../../../../components/DataTable";
import DataNotFound from "../../../../../../components/DataNotFound";
import api from "../../../../../../services/api";

const TabsDadosDaLinha: React.FC = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //States
  //Fields Typed
  const DataTableTS: any = DataTable;

  //States
  const [tableData, setTableData] = useState<[]>([]);
  const [tableLoading, setTableLoading] = useState(false);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);

        const request = await api.get("usuario/geral");
        setTableData(request.data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, []);

  //Handles
  const handleEdit = ({ id }: any) => {
    history.push(`/empresas/${id}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Código",
        accessor: "codigo",
        display: "align-middle",
      },
      {
        Header: "Descrição",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Unidade",
        accessor: "unidade",
        display: "align-middle",
      },
      {
        Header: "Tipo",
        accessor: "tipo",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle",
        Cell: ({ row: { original } }: { row: { original: any } }) => (
          <span className="d-none d-sm-inline">
            <Button
              onClick={() => handleEdit(original)}
              className="btn btn-ghost-dark p-2"
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Link to="/empresas" className="btn btn-ghost-dark p-2">
              <i className="far fa-trash-alt icon m-0"></i>
            </Link>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="mt-4 mb-3 col pr-0 text-right">
        <span className="d-none d-sm-inline">
          <a className="btn btn-white mr-2">Gerar Prenativos</a>
        </span>
        <span className="d-none d-sm-inline">
          <Button
            className="btn btn-white"
            variant="primary"
            onClick={handleShow}
          >
            Novo Prenativo
          </Button>
        </span>
      </div>
      <BlockUi blocking={tableLoading} loader={<Spinner animation="border" />}>
        {tableData && (
          <DataTableTS
            columns={columns}
            data={tableData}
            bordered={false}
            initialState={{
              sortBy: [
                {
                  id: "id",
                  desc: false,
                },
              ],
            }}
          />
        )}
      </BlockUi>
      <DataNotFound visible={tableData?.length === 0}></DataNotFound>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>NOVO PRENATIVO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mt-3">
            <Form.Row>
              <Form.Group className="col">
                <Form.Label>Código</Form.Label>
                <Form.Control placeholder="Código..." />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className="col">
                <Form.Label>Descrição</Form.Label>
                <Form.Control placeholder="Descrição..." />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className="col">
                <Form.Label>Unidade</Form.Label>
                <Form.Control as="select" custom>
                  <option>Selecione uma opção</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="col">
                <Form.Label>Tipo</Form.Label>
                <Form.Control as="select" custom>
                  <option>Selecione uma opção</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabsDadosDaLinha;
