import React from "react";
import {
  Form,
  FormControl,
  Image,
  Table,
  Tabs,
  Modal,
  Button,
  Tab,
} from "react-bootstrap";

import TabsDadosDaLinha from "./TabsDadosDaLinha";
import TabsPrenativo from "./TabsPrenativo";

const TabLinhasTabs: React.FC = () => {
  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h2 className="page-title">
                  Configurações - Referências - Nova Referência - Nova Linha
                </h2>
              </div>

              <div className="col pr-0 text-right">
                <span className="d-none d-sm-inline">
                <a className="btn btn-white mr-2">Voltar</a>
                  <a className="btn btn-white">Salvar</a>
                </span>
              </div>

              <div className="card mt-3 p-0">
                <div className="card-body">
                  <Tabs
                    defaultActiveKey="dadosDaLinha"
                    id="uncontrolled-tab-example"
                  >
                    <Tabs eventKey="dadosDaLinha" title="Dados da Linha">
                      <TabsDadosDaLinha/>
                    </Tabs>
                    <Tab eventKey="prenativo" title="Prenativo">
                      <TabsPrenativo/>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabLinhasTabs;
