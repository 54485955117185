import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Modal, Button, Row, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";

import { ToastErrorContext } from "../../../../contexts/toast/toastErrorContext";
import { SweetAlertContext } from "../../../../contexts/sweetAlert/alertContext";
import { Input, Select, File } from "../../../../components/Form";
import getValidationErrors from "../../../../helpers/getValidateErrors";
import { base64toBlob } from "../../../../helpers/utils";
import FilterTableApi from "../../../../components/DataTable/filterTableApi-BuscaExt";
import DataNotFound from "../../../../components/DataNotFound";
import api from "../../../../services/api";
import { IListReferenciasClasseId } from "../../../../common/interfaces/referencias.interfaces";
import { simOuNao } from "../../../../components/Form/Select/options";
import Placeholder from "../../../../assets/imgs/img-placeholder.png";
import { toast } from "react-toastify";

const TabDados: React.FC = () => {
  //Params
  const { pId, pString }: any = useParams();

  const tipo = [
    { label: "Sistemista", value: "Sistemista" },
    { label: "Fabricante", value: "Fabricante" },
  ];

  const tiposMaterial = [
    { label: "Perfis", value: "PERFIL" },
    { label: "Componentes", value: "COMPONENTE" },
    { label: "Vidros", value: "VIDRO" },
    { label: "Superficies", value: "SUPERFICIE" },
    { label: "Kits", value: "KIT" },
  ];

  const ocultarCadastro = [
    { label: "NÃO", value: 0 },
    { label: "TIPOLOGIA", value: 1 },
    { label: "TIPOLOGIA MATERIAL", value: 2 },
  ];

  const forcaAtualizacao = [
    { label: "NÃO", value: 0 },
    { label: "SIM", value: 1 },
  ];

  //Fields Typed
  const DataTableTS: any = FilterTableApi;

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastErrorContext = useContext(ToastErrorContext);

  //States
  const [tableData, setTableData] = useState<IListReferenciasClasseId[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableLoadingClasseId, setTableLoadingClasseId] = useState(true);
  const [show, setShow] = useState(false);
  const [novo, setNovo] = useState(false);
  const [linkLogo, setLinkLogo] = useState("");
  const [linkArquivoComum, setLinkArquivoComum] = useState("");
  const [selectClasseId, setSelectClasseId] = useState<any>(null);
  const [reloadTableClasseId, setReloadTableClasseId] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [empresaAplicabilidades, setEmpresaAplicabilidades] =
    useState<any>(null);

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        setNovo(pId == 0);
        const {
          data: { logo },
        } = await api.get(`referencia/logo/${pId}`);

        if ((logo || "") != "") setLinkLogo(logo);

        const {
          data: { arquivoComum },
        } = await api.get(`referencia/arquivoComum/${pId}`);

        if ((arquivoComum || "") != "")
          setLinkArquivoComum(URL.createObjectURL(base64toBlob(arquivoComum)));

        const { data } = await api.get(`referencia/listaEmpresaAplicabilidade`);
        setEmpresaAplicabilidades(data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, []);

  //Callbacks
  const fetchClasseId = useCallback(async (globalFilter) => {
    try {
      setTableLoadingClasseId(true);

      const { data } = await api.get(
        `/referencia/listaClasseId/${pId}/${globalFilter}`
      );
      setTableData(data);
    } catch (error) {
    } finally {
      setTableLoadingClasseId(false);
    }
  }, []);

  //Handles
  const handleClose = () => setShow(false);

  const handleSelectClasseID = async () => {
    if (selectClasseId == null) {
      const { data } = await api.get("classeId/selectValuesComum");
      setSelectClasseId(data);
    }
  };

  const handleNewClasseId = () => {
    handleSelectClasseID();
    setShow(true);
  };

  const handleDelete = ({ id, classeId }: IListReferenciasClasseId) => {
    const title = `Excluir "${classeId}"?`;
    const message = "Essa Classe ID será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`referencia/classeId/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        toastErrorContext.notification(error);
      }
    });
  };

  const handleSubmit: SubmitHandler = async (classeId) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        classe: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
      });

      await schema.validate(classeId, { abortEarly: false });

      //Passou pela validação
      classeId.idReferencia = pId;
      await api.post("referencia/classeId", classeId);

      setReloadTableClasseId((reload) => !reload);
      setShow(false);

      toast.success("A ação foi concluída.");
    } catch (err) {
      toastErrorContext.notification(err);
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleSave = () => {
    formRef.current?.submitForm();
    setNovo(false);
  };

  const handleChangeImagem = (e: any) => {
    setLinkLogo(URL.createObjectURL(e.target.files?.[0]));
  };

  const handleChangeArquivoComum = (e: any) => {
    const SUPPORTED_FORMATS = [
      "application/x-zip",
      "application/zip",
      "application/x-zip-compressed",
    ];

    if (SUPPORTED_FORMATS.includes(e.target.files?.[0]?.type))
      setLinkArquivoComum(URL.createObjectURL(e.target.files?.[0]));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Classe",
        accessor: "classeId",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle column-table-2b",
        Cell: ({
          row: { original },
        }: {
          row: { original: IListReferenciasClasseId };
        }) => (
          <span className="d-sm-inline">
            <Button
              title="Excluir"
              className="btn btn-ghost-dark p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="content mt-1">
        <div className="form-row p-1">
          <label className="form-label">Logo</label>
          <File
            name="logo"
            placeholder="Procurar arquivo de imagem..."
            onChangeCapture={(e: any) => handleChangeImagem(e)}
          />
        </div>

        {linkLogo && (
          <div className="form-row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-auto">
                      <span
                        className="text-white avatar"
                        style={{ fontSize: "0.7rem" }}
                      >
                        <Image
                          src={linkLogo}
                          alt={pString}
                          onError={(event: any) =>
                            (event.target.src = Placeholder)
                          }
                        />
                      </span>
                    </div>
                    <div className="col-6">
                      <div>Arquivo de Imagem</div>
                      <div className="text-muted">{pString}</div>
                    </div>
                    <div className="col text-right">
                      <a
                        href={linkLogo}
                        target="_blank"
                        download={pString + ".svg"}
                        className="btn btn-ghost-dark p-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                          <line x1="12" y1="11" x2="12" y2="17" />
                          <polyline points="9 14 12 17 15 14" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="form-row p-1 mt-2">
          <label className="form-label">Nome da Referência</label>
          <Input
            type="text"
            name="descricao"
            className="form-control"
            placeholder="Nome da Referência..."
          />
        </div>
        <div className="form-row p-1 mt-2">
          <label className="form-label">Projetista</label>
          <Input
            type="text"
            name="projetista"
            className="form-control"
            placeholder="Projetista..."
          />
        </div>

        <div className="form-row mt-2">
          <div className="form-group col-6">
            <label className="form-label">Ocultar Cadastro</label>
            <Select
              name="ocultarCadastro"
              options={ocultarCadastro}
              placeholder="Selecione uma opção..."
              defaultValue={0}
            />
          </div>
          <div className="form-group col-6">
            <label className="form-label">Forçar atualização</label>
            <Select
              name="forcarAtualizacao"
              options={forcaAtualizacao}
              placeholder="Selecione uma opção..."
              defaultValue={0}
            />
          </div>
        </div>

        <div className="form-row mt-2">
          <div className="form-group col">
            <label className="form-label">Tipo</label>
            <Select
              name="tipo"
              options={tipo}
              placeholder="Selecione uma opção..."
            ></Select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col">
            <label className="form-label">Tipo de material</label>
            <Select
              name="tipoMaterial"
              placeholder="Selecione uma opção..."
              options={tiposMaterial}
              isMulti={true}
            />
          </div>
        </div>

        <div className="form-row p-1">
          <label className="form-label">Arquivo Comum</label>
          <File
            name="arquivoComum"
            placeholder="Procurar arquivo comum..."
            onChangeCapture={(e: any) => handleChangeArquivoComum(e)}
          />
        </div>

        {linkArquivoComum && (
          <div className="form-row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-auto">
                      <span
                        className="text-white avatar"
                        style={{ fontSize: "0.7rem" }}
                      >
                        <svg
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g data-name="1" id="_1">
                            <path d="M381.43,450H152.56a50.17,50.17,0,0,1-50.11-50.11V98.11A50.17,50.17,0,0,1,152.56,48h150.1a15,15,0,0,1,10.61,4.39L427.15,166.27a15,15,0,0,1,4.39,10.61v223A50.17,50.17,0,0,1,381.43,450ZM152.56,78a20.13,20.13,0,0,0-20.11,20.11V399.89A20.13,20.13,0,0,0,152.56,420H381.43a20.13,20.13,0,0,0,20.11-20.11V183.09L296.45,78Z" />
                            <path d="M416.54,191.88H337.77a50.17,50.17,0,0,1-50.11-50.11V63a15,15,0,0,1,30,0v78.77a20.13,20.13,0,0,0,20.11,20.11h78.77a15,15,0,1,1,0,30Z" />
                            <path d="M204.26,339.9h36c4,0,6.22,4.77,6.22,9s-2.25,9-6.22,9H183.87c-4.24,0-6-2.25-6-5.42a15.38,15.38,0,0,1,2.25-7.28l37.33-66.06H186.78c-4,0-6.22-4.23-6.22-9,0-4.36,1.85-9,6.22-9h51.1c4,0,6,2.25,6,5.43a15.73,15.73,0,0,1-2.25,7.28Z" />
                            <path d="M254.82,267.36c0-4.36,5.16-6.22,10.32-6.22s10.33,1.86,10.33,6.22v84.19c0,4.24-5.16,6.35-10.33,6.35s-10.32-2.11-10.32-6.35Z" />
                            <path d="M289.76,267.36c0-3.31,3.05-6.22,7.68-6.22h27.4c17.47,0,31.24,8.21,31.24,30.45v.66c0,22.24-14.3,30.71-32.56,30.71H310.41v28.59c0,4.24-5.16,6.35-10.32,6.35s-10.33-2.11-10.33-6.35Zm20.65,11.78v27.93h13.11c7.41,0,11.91-4.23,11.91-13.23v-1.46c0-9-4.5-13.24-11.91-13.24Z" />
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div className="col-6">
                      <div>Arquivo comum</div>
                      <div className="text-muted">Anexado</div>
                    </div>
                    <div className="col text-right">
                      <a
                        href={linkArquivoComum}
                        target="_blank"
                        download="ArquivoComum.zip"
                        className="btn btn-ghost-dark p-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                          <line x1="12" y1="11" x2="12" y2="17" />
                          <polyline points="9 14 12 17 15 14" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="form-row mt-2">
          <div className="form-group col">
            <label className="form-label">
              Aplicabilidades no PCT de atualização
            </label>
            <Select
              name="disponibilizarAplicabilidade"
              options={simOuNao}
              placeholder="Selecione uma opção..."
            ></Select>
          </div>
        </div>
        <div className="form-row mt-2">
          <div className="form-group col">
            <label className="form-label">Aplicabilidade da Empresa</label>
            <Select
              name="empresaAplicabilidade"
              options={empresaAplicabilidades}
              placeholder="Selecione uma opção..."
              isClearable
            />
          </div>
        </div>

        {!novo && (
          <div className="mt-3 form-row p-1">
            <h2 className="page-title">Lista Classe ID</h2>
            <span className="col-auto ml-auto">
              <Button
                className="mr-2"
                onClick={() => handleNewClasseId()}
                variant="white"
              >
                Nova Classe
              </Button>
            </span>
            <div className="col-auto ml-0 pr-0">
              <input
                className="form-control"
                type="text"
                placeholder="Busca..."
                value={searchTerm || ""}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        )}

        {!novo && (
          <Row className="mt-3">
            <div className={"dimmer" + (tableLoadingClasseId ? " active" : "")}>
              <div className="loader"></div>
              <div
                className={
                  "dimmer-content" +
                  (tableLoadingClasseId ? " small-box-loader" : "")
                }
              >
                {tableData && (
                  <DataTableTS
                    filter={searchTerm}
                    columns={columns}
                    data={tableData}
                    bordered={false}
                    fetchData={fetchClasseId}
                    reload={reloadTableClasseId}
                    initialState={{
                      sortBy: [
                        {
                          id: "id",
                          desc: false,
                        },
                      ],
                    }}
                  />
                )}
                <DataNotFound
                  visible={
                    tableLoadingClasseId == false
                      ? tableData?.length === 0
                      : false
                  }
                ></DataNotFound>
              </div>
            </div>
          </Row>
        )}
      </div>

      {/* Modal Adicionar ClassesID */}
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Classe/ID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mt-3" ref={formRef} onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col">
                <label className="form-label">Classe / ID</label>
                <Select
                  name="classeId"
                  placeholder="Selecione uma opção..."
                  options={selectClasseId}
                ></Select>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabDados;
