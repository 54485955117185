import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { ToastContext } from "../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import DataTable from "../../../components/DataTable";
import DataNotFound from "../../../components/DataNotFound";
import api from "../../../services/api";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";
import ModalNovaTabela from "./ModalNovaTabela";
import { exportToExcel } from "../../../helpers/ExportToExcel";

import {
  IFormTabelaPreco,
  IListTabelaPreco,
} from "../../../common/interfaces/importar.interfaces";
import { toast } from "react-toastify";

const TabTabelaDePreco: React.FC = () => {

  //Fields Typed
  const DataTableTS: any = DataTable;

  //States
  const [show, setShow] = useState(false);
  const [novo, setNovo] = useState(true);
  const [tableData, setTableData] = useState<IListTabelaPreco[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [homologar, setHomologar] = useState(false);
  const [disponivel, setDisponivel] = useState(false);
  const [selected, setSelected] = useState<IFormTabelaPreco>();
  const [tipoMaterial, setTipoMaterial] = useState([]);
  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      const tipoMaterial = await api.get("produto/selectTipoMaterial");
        setTipoMaterial(tipoMaterial.data);
      setTableLoading(true);
      Promise.all([
        api.get("tabelaPreco/lista"),
        api.get('tabelaPreco/acaoPermitida')
      ]).then((response: any) => {
        setTableData(response[0].data);
        handleAcaoPermitida(response[1].data);
      }).catch((err) => toast.error(err.message)).finally(() => setTableLoading(false));
    }

    fetchData();
  }, [reloadTable]);

  const handleAcaoPermitida = ({ homologar, disponibilizar }: any) => {
    setHomologar(homologar);
    setDisponivel(disponibilizar);
  }

  const handleClose = () => {
    setReloadTable(reload => !reload);
    setShow(false);
  }

  const handleShow = () => {
    setSelected({} as IFormTabelaPreco);
    setShow(true);
    setNovo(true);
  };

  const handleEdit = async ({ id }: IListTabelaPreco) => {
    const { data } = await api.get(`tabelaPreco/${id}`);
    setSelected(data);
    setNovo(false);
    setShow(true);
  };

  const handleDelete = async ({ id, nome }: IListTabelaPreco) => {

    const { data: { existe } } = await api.get(`tabelaPreco/ExisteLiberacao/${id}`);

    const title = `Excluir "${nome}"?`;
    const message = existe ? "Existem clientes com a tabela de preços liberada para uso. Tem certeza que deseja eliminar?" :
      "Esse tabela será excluída, isso não pode ser desfeito.";

    sweetAlertContext.danger(title, message, async () => {
      try {
        var response = await api.delete(`tabelaPreco/${id}`);
        toastContext.notification(response.data, "error");
        sweetAlertContext.close();
        setReloadTable(reload => !reload);

      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "error");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleHomologar = () => {
    const title = "Homologar";
    const message = "Deseja homologar as alterações realizadas, isso não pode ser desfeito.";
    sweetAlertContext.warning(title, message, async () => {
      try {
        await api.post('tabelaPreco/homologarImportacao');
        toastContext.notification("A ação foi concluída. Em breve será disponibilzado o pacote de atualização para importação no seu sistema para homologação.", "success");
        setReloadTable(reload => !reload);
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleDisponivelMercado = () => {
    const title = "Disponível para Mercado";
    const message = "Deseja disponibilizar as tabelas de preços para uso dos clientes?";
    sweetAlertContext.warning(title, message, async () => {
      try {
        await api.post('tabelaPreco/disponibilizarImportacao');
        toastContext.notification("A ação foi concluída. Em breve estará disponível para importação nos sistemas dos clientes.", "success");

        setReloadTable(reload => !reload);
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };


  const handlePlanilhaPadrao = async () => {
    const dadosExportacao = await api.get(`tabelaPreco/exportarEstrutura`);
    exportToExcel([{ data: dadosExportacao.data, name: "TABELA DE PREÇOS" }], "TABELA DE PREÇOS");
  }
  const handlePlanilhaPadraoVidro = async () => {
    const dadosExportacao = await api.get(`tabelaPreco/exportarEstruturaVidro`);
    exportToExcel([{ data: dadosExportacao.data, name: "TABELA DE PREÇOS" }], "TABELA DE PREÇOS Vidros");
  }

  const columns = useMemo(
    () => [
      {
        Header: "Tipo",
        accessor: "tipoProduto",
        display: "align-middle",
      },
      {
        Header: "Nome",
        accessor: "nome",
        display: "align-middle",
      },
      {
        Header: "Atualização",
        accessor: "dataAtualizacao",
        display: "align-middle",
        render: (value: any) => {
          return (<>
            {value.dataAtualizacao ? new Date(value.dataAtualizacao).toLocaleDateString("dd/mm/yyyy") : ""}
          </>)
        }
      },
      {
        Header: "Situação",
        accessor: "situacao",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle column-table-2b",
        Cell: ({
          row: { original },
        }: {
          row: { original: IListTabelaPreco };
        }) => (
          <span className="d-none d-sm-inline">
            <Button
              title="Editar"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleEdit(original)}
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              title="Excluir"
              variant="ghost-dark"
              className="p-2"
              onClick={(event) => {
                event.preventDefault();
                handleDelete(original);
              }}>
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="mb-3 col pr-0 text-right">
        <span className="d-none d-sm-inline">
          <Button className="mr-2" variant="white"
            onClick={handlePlanilhaPadrao}>
            <i className="fas fa-file-download"></i> Planilha Padrão Componentes
          </Button>
          <Button className="mr-2" variant="white"
            onClick={handlePlanilhaPadraoVidro}>
            <i className="fas fa-file-download"></i> Planilha Padrão Vidros
          </Button>
          <Button className="mr-2" variant="white" onClick={handleShow}>
            Nova Tabela
          </Button>
          <Button onClick={handleHomologar} variant="white" className="mr-2" disabled={!homologar}>
            Homologar
          </Button>
          <Button onClick={handleDisponivelMercado} variant="white" disabled={!disponivel}>
            Disponível Mercado
          </Button>
        </span>
      </div>

      <div className="mt-3">
        <div className={"dimmer" + (tableLoading ? " active" : "")}>
          <div className="loader"></div>
          <div
            className={
              "dimmer-content" + (tableLoading ? " medium-box-loader" : "")
            }
          >
            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                reload={reloadTable}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={tableLoading == false ? tableData?.length === 0 : false}
            ></DataNotFound>
          </div>
        </div>
      </div>

      {/* Modal Nova Tabela */}
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{novo ? "Nova Tabela" : "Editar Tabela"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalNovaTabela setNovo={setNovo} novo={novo} tabela={selected} setHomologar={setHomologar} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabTabelaDePreco;
