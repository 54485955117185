import { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { columnsProjetosCemWeb } from "..";
import { IProjetosCemWeb } from "../../../common/interfaces/projetosCemWeb";
import DataNotFound from "../../../components/DataNotFound";
import FilterTableApi from "../../../components/DataTable/filterTableApi-BuscaExt";
import { useProjetosCemWeb } from "../hook/useProjetosCemWeb";
import { FiltrosProjetos } from "./FiltrosProjetos";

export type FilterParamsProjetosType = {
  projetista?: { label: string; value: string };
  linha?: { label: string; value: string };
  search: string;
}

interface ModalProjetosProps {
  show: boolean;
  onHide: () => void;
  onRefreshData: () => void;
  dataDesenhador: {
    imagemBase64: string;
    jsonImagem: string;
    formatoImagemBase64: string;
  };
}

export const ModalProjetos: React.FC<ModalProjetosProps> = ({ show, onHide, dataDesenhador, onRefreshData }) => {
  const [rowsSelecteds, setRowsSelecteds] = useState<number[]>([]);
  const {fetchData, isLoadingData, tableData, handleSubmitProjetos, isLoadingSubmit } = useProjetosCemWeb({
    onSuccessSubmit: () => {
      onHide();
      setRowsSelecteds([]);
      onRefreshData();
    }
  });

  const [filterParams, setFilterParams] = useState<FilterParamsProjetosType>({
    search: "",
  });

  const handleFilterParams = (params: FilterParamsProjetosType) => {
    setFilterParams(params);
  }

  const columns = useMemo(
    () => [
      {
        Header: "Selecionar",
        accessor: "selecionar",
        display: "align-middle checkbox-column",
        Cell: ({ row: { original } }: { row: { original: IProjetosCemWeb } }) => (
          <div className="checkbox-column-content">
            <input
              type="checkbox"
              value={original.idProjeto}
              className="custom-checkbox-projetos"
              checked={rowsSelecteds.includes(original.idProjeto)}
              onChange={(e) => {
                if (e.target.checked) {
                  setRowsSelecteds((prev) => [...prev, original.idProjeto]);
                } else {
                  setRowsSelecteds((prev) => prev.filter((id) => id !== original.idProjeto));
                }
              }}
            />
          </div>
        ),
      },
      ...columnsProjetosCemWeb,
    ],
    [rowsSelecteds]
  );

  return (
    <Modal size="xl" show={show} onHide={onHide} className="modal-projetos">
      <Modal.Header closeButton>
        <Modal.Title>Projetos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="card p-0">
            <div className="card-body">
              <FiltrosProjetos 
                fetchData={fetchData}
                filterParams={filterParams}
                handleFilterParams={handleFilterParams}
              />

              <div className={'dimmer' + (isLoadingData ? ' active' : '')}>
                <div className="loader"></div>
                <div className={"dimmer-content" + (isLoadingData ? ' medium-box-loader' : '')} >

                  {tableData && (
                    <FilterTableApi
                      filter={filterParams.search}
                      columns={columns}
                      data={tableData}
                      fetchData={(globalFilter: string) => fetchData({
                        IdLinhaProjeto: filterParams.linha?.value ? Number(filterParams.linha.value) : undefined,
                        IdProjetista: filterParams.projetista?.value ? Number(filterParams.projetista.value) : undefined,
                        Filtrar: globalFilter,
                      })}
                      bordered={false}
                      setSelectedRows={() => {
                        //
                      }}
                      initialState={{
                        globalFilter: filterParams.search,
                        pageIndex: 0,
                        pageSize: 10
                      }}
                    />
                  )}

                  <DataNotFound
                    visible={!isLoadingData && tableData?.length === 0}
                  ></DataNotFound>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fechar
        </Button>
        <Button variant="primary" onClick={() => {
          handleSubmitProjetos(dataDesenhador, rowsSelecteds);
        }} disabled={isLoadingSubmit}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}