import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import {
  Input,
  InputDecimal,
  Select,
  SwitchCheckbox,
} from "../../../../../components/Form";
import api from "../../../../../services/api";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import getValidationErrors from "../../../../../helpers/getValidateErrors";
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";

import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { ToastErrorContext } from "../../../../../contexts/toast/toastErrorContext";
import { ProdutoContext } from "../../../../../contexts/produtos/produtosContext";

import DataTable from "../../../../../components/DataTable";
import DataNotFound from "../../../../../components/DataNotFound";

import { IVidroCor } from "../../../../../common/interfaces/produtos.interfaces";
import { toast } from "react-toastify";

const VidrosTabProdutoCor: React.FC<any> = ({ selectedTab, setReloadCor }) => {
  //Fields
  const DataTableTS: any = DataTable;

  // URL Params
  const { pId }: any = useParams();

  // É novo?
  const novo = pId == 0;

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);
  const produtoContext = useContext(ProdutoContext);

  //States
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<IVidroCor>({} as IVidroCor);
  const [cores, setCores] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        if (!novo) {
          setTableLoading(true);
          const { data } = await api.get(`/vidro/listaVidroCor/${pId}`);
          setTableData(data);
          setReloadCor((reload: boolean) => !reload);
        }
      } catch (error) {
      } finally {
        setTableLoading(false);
        return;
      }
    })();
  }, [reloadTable]);

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        if (selectedTab == "produtoCor" && cores.length == 0) {
          const { data } = await api.get("geral/selecaoCor/VIDRO");
          setCores(data);
        }
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, [selectedTab]);

  useEffect(() => {
    if (Object.keys(selected).length !== 0) {
      formRef.current?.setData(selected);
    }
  }, [selected]);

  // Handles
  const handleSubmit: SubmitHandler<IVidroCor> = async (vidroCor) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        cor: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
      });
      await schema.validate(vidroCor, { abortEarly: false });

      vidroCor.id = selected.id;
      vidroCor.idVidro = pId;
      const {
        data: { id },
      } = await api.post("vidro/salvarVidroCor", vidroCor);
      selected.id = id;

      setReloadTable((reload) => !reload);

      produtoContext.setEntrouEmEdicao(true);

      toast.success("A ação foi concluída.");
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleClose = () => {
    setSelected({} as IVidroCor);
    setShow(false);
  };

  const handleNew = () => {
    setShow(true);
  };

  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handleEdit = async ({ id }: any) => {
    const { data } = await api.get(`vidro/vidroCor/${id}`);
    setShow(true);
    setSelected(data);
  };

  const handleDelete = async ({ id, cor }: IVidroCor) => {
    const title = `Excluir "${cor}"?`;
    const message = "Essa cor será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`vidro/removerVidroCor/${id}`);

        setTableData((prevState) =>
          prevState.filter(function (item: any) {
            return item.id !== id;
          })
        );
        setReloadCor((reload: boolean) => !reload);

        produtoContext.setEntrouEmEdicao(true);

        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handleDecimalFormat = (value: any) => {
    return value?.toString().replace(".", ",");
  };

  const columns = useMemo(
    () => [
      {
        Header: "Cor",
        accessor: "cor",
        display: "align-middle",
      },
      {
        Header: "FS",
        accessor: "fatorSolar",
        display: "align-middle",
        Cell: ({ row }: any) => {
          return handleDecimalFormat(row.original?.fatorSolar);
        },
      },
      {
        Header: "TL",
        accessor: "transLumin",
        display: "align-middle",
        Cell: ({ row }: any) => {
          return handleDecimalFormat(row.original?.transLumin);
        },
      },
      {
        Header: "TT",
        accessor: "transTermica",
        display: "align-middle",
        Cell: ({ row }: any) => {
          return handleDecimalFormat(row.original?.transTermica);
        },
      },
      {
        Header: "AE",
        accessor: "absorcaoEnergetica",
        display: "align-middle",
        Cell: ({ row }: any) => {
          if (
            row.original &&
            typeof row.original.absorcaoEnergetica === "number"
          ) {
            return handleDecimalFormat(row.original.absorcaoEnergetica);
          } else {
            return 0;
          }
        },
      },
      {
        Header: "BC",
        accessor: "bloqCalor",
        display: "align-middle",
        Cell: ({ row }: any) => {
          return handleDecimalFormat(row.original?.bloqCalor);
        },
      },
      {
        Header: "Refl. Int.",
        accessor: "reflexaoInterior",
        display: "align-middle",
        Cell: ({ row }: any) => {
          return handleDecimalFormat(row.original?.reflexaoInterior);
        },
      },
      {
        Header: "Refl. Ext.",
        accessor: "reflexaoExterior",
        display: "align-middle",
        Cell: ({ row }: any) => {
          return handleDecimalFormat(row.original?.reflexaoExterior);
        },
      },
      {
        Header: "Ativo",
        accessor: "ativo",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({ row: { original } }: { row: { original: IVidroCor } }) => (
          <>
            <Button
              onClick={() => handleEdit(original)}
              variant="ghost-dark"
              className="p-2"
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              onClick={() => handleDelete(original)}
              variant="ghost-dark"
              className="p-2"
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="row mt-4">
        <span className="col-auto ml-auto">
          <Button variant="white" onClick={handleNew}>
            Nova Cor
          </Button>
        </span>
      </div>

      <div
        className={"dimmer" + (tableLoading ? " active" : "")}
        style={tableLoading ? { minHeight: "322px" } : {}}
      >
        <div className="loader"></div>
        <div className="dimmer-content">
          <div className="mt-4">
            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
          </div>
          <DataNotFound
            visible={tableLoading == false ? tableData?.length === 0 : false}
          ></DataNotFound>
        </div>
      </div>

      {/* Modal Produto / Cor */}
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Produto / Cor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={selected}>
            <div className="row mt-3">
              <div className="form-group col">
                <label className="form-label">Cor *</label>
                <Select
                  name="cor"
                  options={cores}
                  defaultValue={selected.cor}
                  placeholder="Selecione uma cor..."
                />
              </div>
              <div className="form-group col">
                <label className="form-label">Fator Solar</label>
                <InputDecimal
                  type="text"
                  name="fatorSolar"
                  className="form-control"
                  placeholder="FS..."
                  thousandSeparator={false}
                  decimalSeparator=","
                  valueIsNumericString
                  decimalScale={2}
                  fixedDecimalScale={true}
                  isAllowed={(values: any) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue <= 999;
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label">Absorção Energética</label>
                <InputDecimal
                  type="text"
                  name="absorcaoEnergetica"
                  className="form-control"
                  placeholder="AE..."
                  thousandSeparator={false}
                  decimalSeparator=","
                  valueIsNumericString
                  decimalScale={2}
                  fixedDecimalScale={true}
                  isAllowed={(values: any) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue <= 999;
                  }}
                />
              </div>
              <div className="form-group col">
                <label className="form-label">Trans. Térmica</label>
                <InputDecimal
                  type="text"
                  name="transmissaoTermica"
                  className="form-control"
                  placeholder="TT..."
                  thousandSeparator={false}
                  decimalSeparator=","
                  valueIsNumericString
                  decimalScale={2}
                  fixedDecimalScale={true}
                  isAllowed={(values: any) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue <= 999;
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label">Trans. Lumin.</label>
                <InputDecimal
                  type="text"
                  name="transmissaoLuminosidade"
                  className="form-control"
                  placeholder="TL..."
                  thousandSeparator={false}
                  decimalSeparator=","
                  valueIsNumericString
                  decimalScale={2}
                  fixedDecimalScale={true}
                  isAllowed={(values: any) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue <= 999;
                  }}
                />
              </div>
              <div className="form-group col">
                <label className="form-label">Bloqueio de Calor</label>
                <InputDecimal
                  type="text"
                  name="bloqueioCalor"
                  className="form-control"
                  placeholder="BC..."
                  thousandSeparator={false}
                  decimalSeparator=","
                  valueIsNumericString
                  decimalScale={2}
                  fixedDecimalScale={true}
                  isAllowed={(values: any) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue <= 999;
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label">Reflexão Interna</label>
                <InputDecimal
                  type="text"
                  name="reflexaoInterior"
                  className="form-control"
                  placeholder="Refl. Int..."
                  thousandSeparator={false}
                  decimalSeparator=","
                  valueIsNumericString
                  decimalScale={2}
                  fixedDecimalScale={true}
                  isAllowed={(values: any) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue <= 999;
                  }}
                />
              </div>
              <div className="form-group col">
                <label className="form-label">Reflexão Externa</label>
                <InputDecimal
                  type="text"
                  name="reflexaoExterior"
                  className="form-control"
                  placeholder="Refl. Ext..."
                  thousandSeparator={false}
                  decimalSeparator=","
                  valueIsNumericString
                  decimalScale={2}
                  fixedDecimalScale={true}
                  isAllowed={(values: any) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue <= 999;
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label">Status</label>
                <SwitchCheckbox
                  name="ativo"
                  id="ativo"
                  label="Ativo?"
                  defaultChecked={
                    selected.id == undefined ? true : selected.ativo
                  }
                  style={{ height: "33px" }}
                />
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default VidrosTabProdutoCor;
