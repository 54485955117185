import React, { useState, useEffect, useMemo, useCallback, useContext, useRef } from "react";
import { Button } from "react-bootstrap";
import history from "../../../helpers/history";
import { toast } from "react-toastify";
import FilterTable from "../../../components/DataTable/filterTable";
import DataNotFound from "../../../components/DataNotFound";
import api from "../../../services/api";
import { FormHandles } from "@unform/core";
import { ToastContext } from "../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";
import getValidationErrors from "../../../helpers/getValidateErrors";
interface IListTempera {
  idTempera: number,
  descricao: string
}

const Temperas: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  //   //Fields Typed
  const DataTableTS: any = FilterTable;

  //   //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);

  //   //States
  const [tableData, setTableData] = useState<IListTempera[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");


  //Effects
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);
        const {data} = await api.get("tempera/Index");
        setTableData(data);
      } catch (err) {
        toast.error(getSingleErrorMessage(err));
        formRef.current?.setErrors(getValidationErrors(err));
      } finally {
        setTableLoading(false);
      }
    })();
  }, []);

  //   //Handles
  const handleEdit = ({ idTempera, descricao }: IListTempera) => {
    history.push(`/ligas/temperas/${idTempera}/${descricao}`);
  };

  const handleDelete = ({ idTempera, descricao }: IListTempera) => {
    const title = `Excluir "${descricao}"?`;
    const message = "Essa Tempera será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {

        await api.delete(`tempera/Remover/${idTempera}`);

        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.idTempera !== idTempera;
          })
        );
        sweetAlertContext.close();
      } catch (err) {
        toast.error(getSingleErrorMessage(err));
        formRef.current?.setErrors(getValidationErrors(err));
      }
    });
  };

  const columns = useMemo(
    () => [

      {
        Header: "Tempera",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({ row: { original } }: { row: { original: IListTempera } }) => (
          <>
            <Button
              title="Editar"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleEdit(original)}
            >
              <i className="far fa-edit icon m-0"></i>
            </Button>
            <Button
              title="Excluir"
              variant="ghost-dark"
              className="p-2"
              onClick={() => handleDelete(original)}
            >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <span className="col-auto ml-auto pr-0 ">
        <input
          className="form-control"
          type="text"
          placeholder="Busca..."
          value={searchTerm || ""}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </span>
    </div>
          </div >

  <div className="row">
    <div className="card p-0">
      <div className="card-body">
        <div className={'dimmer' + (tableLoading ? ' active' : '')}>
          <div className="loader"></div>
          <div className={"dimmer-content" + (tableLoading ? ' medium-box-loader' : '')} >
            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                filter={searchTerm}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={
                tableLoading == false ? tableData?.length === 0 : false
              }
            ></DataNotFound>
          </div>
        </div>
      </div>
    </div>
  </div>
        </div >
      </div >
    </>
  );
};

export default Temperas;
