import React, { useState, useEffect } from "react";
import api from "../../services/api";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface IChartData{
   series : number;
   legendas: string;
}

interface MeusProdutosOutrosProps {
  selectedRegion: any;
  startDate: Date | null;
  endDate: Date | null;
}
const MeusProdutosOutrosChart: React.FC<MeusProdutosOutrosProps> = ({ selectedRegion, startDate, endDate }) => {
  const [tableLoading, setTableLoading] = useState(false);
  const [charData, setChartData] = useState<IChartData[]>([]);
  
  const chartOptions : ApexOptions = {
    series: charData?.map(s => s.series),
    labels: charData?.map(l => l.legendas),
    chart: {
      width: 380,
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: 'left',
      offsetY: 0,
      height: 230,
    },
  };
  const formatDate = (date: Date | null): string => {
    return date ? date.toISOString().split("T")[0] : "1900-01-01";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        const { data } = await api.get(`DashboardVidros/GetMeusVidrosXOutros?regiao=${selectedRegion?.label}&inicio=${formattedStartDate}&fim=${formattedEndDate}`);
        
        setChartData(data);
      } catch (error) {
        console.error("Erro na chamada da API:", error);
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, [selectedRegion, startDate, endDate]);
  return (
    <div>
      {tableLoading ? (
        <p>Carregando...</p>
      ) : (
        <div>
          <div className="chart-wrap">
            <div id="chart">
              <ReactApexChart
                options={chartOptions}
                series={chartOptions.series}
                type="donut"
                height={350}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeusProdutosOutrosChart;
