import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Tabs, Tab, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Input, Select } from "../../../components/Form";
import Breadcrumbs from "../../../components/Breadcrumbs";
import history from "../../../helpers/history";
import api from "../../../services/api";

import { ToastContext } from "../../../contexts/toast/toastContext";
import { ToastErrorContext } from "../../../contexts/toast/toastErrorContext";

import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import getValidationErrors from "../../../helpers/getValidateErrors";
import * as Yup from "yup";

import { IFormTipologias } from "../../../common/interfaces/catalogo.interfaces";

import TabModelos3D from "./TabModelos3D";
import TabPerfis from "./TabPerfis";
import TabComponentes from "./TabComponentes";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import { toast } from "react-toastify";


const Catalogo: React.FC = () => {

  // URL Params
  const { pId }: any = useParams();

  // Refs
  const formRef = useRef<FormHandles>(null);
  const loading = useRef(true);

  // Context
  const toastContext = useContext(ToastContext);
  const toastErrorContext = useContext(ToastErrorContext);
  const sweetAlertContext = useContext(SweetAlertContext);

  //States
  const [arquivoGlb, setArquivoGlb] = useState<any>(null);
  const [arquivoUsdz, setArquivoUsdz] = useState<any>(null);
  const [referencias, setReferencias] = useState([]);
  const [linhas, setLinhas] = useState([]);
  const [bloqueiaStatus, setBloqueiaStatus] = useState(false);

  //Constants
  const novo = pId == 0;

  const status = [
    { label: "Disponivel", value: 1 },
    { label: "Indisponível", value: 2 },
  ];

  //Effects
  useEffect(() => {
    (async () => {
      try {
        if (pId != 0) {
          const { data } = await api.get(`/esquadria/esquadria3D/${pId}`);
          formRef.current?.setData(data);

          setArquivoGlb(data.nomeArquivoGLB);
          setArquivoUsdz(data.nomeArquivoUSDZ);
        }

        const referencia = await api.get('referencia/select');
        setReferencias(referencia.data);
      } catch (error) {
      } finally {
        loading.current = false;
      }
    })();
  }, []);

  useEffect(() => {
    if (!loading.current) {
      sweetAlertContext.alert('Alerta', 'Para alterar o status para "Disponível" é necessário que existam os arquivos .glb e .usdz.');
      formRef.current?.setFieldValue('status', { label: "Indisponivel", value: 2 });
    }
  }, [bloqueiaStatus]);

  // Handles

  const handleReferenciaLinha = async ({ value }: any) => {
    formRef.current?.clearField('linha');
    const { data } = await api.get(`linha/select/${value}`);
    setLinhas(data);
  }

  const handleChangeStatus = ({ value }: any) => {
    if (value == 1 && (arquivoGlb == null || arquivoUsdz == null))
      setBloqueiaStatus(reload => !reload);
  }

  const handleSubmit: SubmitHandler<IFormTipologias> = async (dados) => {
    try {
      formRef.current?.setErrors({});

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        codigo: Yup.string().required(message),
        descricao: Yup.string().required(message),
        referencia: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        linha: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        status: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
      });
      await schema.validate(dados, { abortEarly: false });

      //Passou pela validação     
      dados.id = pId;
      const { data: { id } } = await api.post('esquadria/salvarEsquadria3D', dados);

      if (novo) {
        history.push(`/catalogos/${id}/${dados.codigo}`);
      }

      toast.success("A ação foi concluída.");
    } catch (err) {
      toastErrorContext.notification(err);
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            <div className="row">
              <Breadcrumbs />
              <span className="col-auto ml-auto">
                <Button variant="white" onClick={() => formRef.current?.submitForm()}>Salvar</Button>
              </span>
            </div>
          </div>

          <div className="row">
            <div className="card py-2">
              <div className="card-body">
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <Row>
                    <div className="col-4">
                      <label>Código</label>
                      <Input type="text" name="codigo" disabled={!novo} className="form-control" placeholder="Código da Tipologia..." />
                    </div>
                    <div className="col-8">
                      <label>Descrição</label>
                      <Input type="text" name="descricao" className="form-control" placeholder="Descrição..." />
                    </div>
                  </Row>
                  <Row className="mt-3">
                    <div className="col mb-2">
                      <label className="form-label">Referência</label>
                      <Select
                        name="referencia"
                        placeholder="Selecione uma opção..."
                        options={referencias}
                        onChange={handleReferenciaLinha}
                      />
                    </div>
                    <div className="col mb-2">
                      <label className="form-label">Linha</label>
                      <Select
                        name="linha"
                        placeholder="Selecione uma opção..."
                        options={linhas}
                      />
                    </div>
                    <div className="col mb-2">
                      <label className="form-label">Status</label>
                      <Select
                        name="status"
                        placeholder="Selecione uma opção..."
                        options={status}
                        onChange={handleChangeStatus}
                        defaultValue={{ label: "Indisponível", value: 2 }}
                      />
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>

          <Row className="mt-3">
            <div className="card px-2">
              <div className="card-body">
                <Tabs
                  defaultActiveKey="modelos"
                >
                  <Tab eventKey="modelos" title="Modelos 3D" disabled={novo}>
                    <TabModelos3D nomeGlb={arquivoGlb} nomeUsdz={arquivoUsdz} setArquivoGlb={setArquivoGlb} setArquivoUsdz={setArquivoUsdz} />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Row>

        </div>
      </div>
    </>
  );
};

export default Catalogo;
