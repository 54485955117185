/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, forwardRef } from "react";
import {
  Col,
  Pagination,
  Row,
} from "react-bootstrap";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useRowSelect,
  useAsyncDebounce
} from "react-table";
import TypeTable from "./components/TypeTable";


function PaginationTable({
  columns,
  data,
  filter,
  filterOptional1 = '',
  filterOptional2 = '',
  fetchData,
  pageCount: controlledPageCount,
  setSelectedRows,
  reload = false,
  paginate = true,
  tableRowUpdate = null,
  bordered = true,
  defaultPageSize = 10,
  className
}) {
  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { sortBy, pageIndex, pageSize, globalFilter, selectedRowIds },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, sortBy: [], pageSize: defaultPageSize },
      tableRowUpdate,
      manualPagination: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
      autoResetSelectedRows: false,
      getRowId: (row) => row.id
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,   
  );

  const [globalFilterOptional1, setGlobalFilterOptional1] = useState('');
  const [globalFilterOptional2, setGlobalFilterOptional2] = useState('');
  const [row, setRow] = useState([]);

  useEffect(() => {
    if (setSelectedRows) {
      const selectedIds = Object.keys(selectedRowIds);      
      setSelectedRows(selectedIds);
    }  

    setGlobalFilter(filter);
    setGlobalFilterOptional1(filterOptional1);
    setGlobalFilterOptional2(filterOptional2);    

  }, [selectedRowIds, filter, filterOptional1, filterOptional2]);

  const onFetchDataDebounced = useAsyncDebounce(fetchData, 300);

  useEffect(() => {

    let sort = '';
    let desc = false;

    if (sortBy.length > 0) {
      sort = sortBy[0].id;
      desc = sortBy[0].desc;
    }

    onFetchDataDebounced({
      pageIndex,
      pageSize,
      sort,
      desc,
      globalFilter,
      globalFilterOptional1,
      globalFilterOptional2,
    });
  }, [pageIndex, pageSize, sortBy, globalFilter, globalFilterOptional1, globalFilterOptional2, reload]);

  return (
    <>
      {page.length > 0 && (
        <TypeTable
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          page={page}
          rows={rows}
          prepareRow={prepareRow}
          bordered={bordered}
          className={className}
        />
      )}

      {page.length > 0 && paginate && (
        <>
          <Row className="d-flex flex-row align-items-center pt-1">
            <Col md="auto">
              <Pagination className="m-0">
                <Pagination.First
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
            <Col md="auto">
              <span>
                Página{" "}
                <strong>
                  {pageIndex + 1} de {pageOptions.length}
                </strong>{" "}
              </span>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default PaginationTable;
