import React, { useState, useEffect, useMemo, useContext, useCallback } from "react";
import { Button, Row } from "react-bootstrap";

import { IListPacoteDisponivel } from "../../../../common/interfaces/referencias.interfaces";
import { useParams } from "react-router-dom";

import { ToastContext } from "../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../contexts/sweetAlert/alertContext";
import { getSingleErrorMessage } from "../../../../helpers/getSingleErrorMessage";

import FilterTableApi from "../../../../components/DataTable/filterTableApi-BuscaExt";
import DataNotFound from "../../../../components/DataNotFound";
import api from "../../../../services/api";
import async from "react-select/async";
import { AxiosResponse } from "axios";
import { IResult } from "../../../../common/interfaces/IResult";
import { toast } from "react-toastify";

const TabDisponivel: React.FC<any> = ({ selectedTab }) => {

  //Params
  const { pId }: any = useParams();

  //Fields Typed
  const DataTableTS: any = FilterTableApi;

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);

  //States
  const [tableData, setTableData] = useState<IListPacoteDisponivel[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableReloadTable, setReloadTable] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");


  //Effects
  useEffect(() => {
    if (selectedTab == "disponivel")
      setReloadTable(reload => !reload);
  }, [selectedTab]);

  //Callbacks
  const fetchData = useCallback(async (globalFilter) => {
    try {
      const { data } = await api.get(`/referencia/listaDisponivelPortfolioCEM/${pId}/${globalFilter}`);
      setTableData(data);
    } catch (error) {
    } finally {
      setTableLoading(false);
    }
  }, []);

  //Handles
  const handleDelete = async ({ id, tipo, linha }: IListPacoteDisponivel) => {
    const title = `Excluir pacote "${tipo}"${tipo == 'Referência' ? '?' : ' - "' + linha + '"?'}`;
    const message = "Esse pacote será excluído, isso não pode ser desfeito.";

    const { data: { alerta } } = await api.get(`referencia/acaoAlertaRemoverPacote/${id}`);

    if (alerta) {
      sweetAlertContext.warning("Atenção", `Este pacote está liberado para uso de clientes. Deseja eliminar mesmo assim?`, async () => {
        await handleRemoverPacote(id);
        sweetAlertContext.close();
      });
    }
    else
      sweetAlertContext.danger(title, message, async () => {
        try {
          handleRemoverPacote(id);
          sweetAlertContext.close();
        } catch (error) {
          toastContext.notification(message, "warn");
        }
      });
  }

  const handleRemoverPacote = async (id: any) => {
    let PORTFOLIO_DISPONIVEL = true;

    api.post(`/referencia/RemoverAtualizacaoPortfolioCEM/${id}/${PORTFOLIO_DISPONIVEL}`)
      .then((response: AxiosResponse<IResult>) => {
        if (response.data.isValid) {
          setTableData((prevState) =>
            prevState.filter(function (item) {
              return item.id !== id;
            }));
          toast.success(response.data.message)
        } else {
          toast.error(response.data.errors[0])
        }
      }).catch((error) => toast.error(error.message)).finally(() => setTableLoading(false));
  }

  const columns = useMemo(
    () => [
      {
        Header: "Tipo",
        accessor: "tipo",
        display: "align-middle",
      },
      {
        Header: "Possui arquivo Comum",
        accessor: "arquivoComum",
        display: "align-middle",
      },
      {
        Header: "Linha",
        accessor: "linha",
        display: "align-middle",
      },
      {
        Header: "Disponibilização",
        accessor: "atualizacao",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-2b",
        Cell: ({ row: { original } }: { row: { original: IListPacoteDisponivel } }) => (
          <span className="d-none d-sm-inline">
            <Button variant="ghost-dark" className="p-2" onClick={() => handleDelete(original)} >
              <i className="far fa-trash-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="mt-1">
        <Row>
          <div className="col-auto ml-auto pr-0">
            <input
              className="form-control"
              type="text"
              placeholder="Busca..."
              value={searchTerm || ""}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </Row>
        <Row className="mt-3">
          <div className={'dimmer' + (tableLoading ? ' active' : '')}>
            <div className="loader"></div>
            <div className={"dimmer-content" + (tableLoading ? ' small-box-loader' : '')} >
              {tableData && (
                <DataTableTS
                  filter={searchTerm}
                  columns={columns}
                  data={tableData}
                  bordered={false}
                  fetchData={fetchData}
                  reload={tableReloadTable}
                  initialState={{
                    sortBy: [
                      {
                        id: "id",
                        desc: false,
                      },
                    ],
                  }}
                />
              )}
              <DataNotFound
                visible={
                  tableLoading == false ? tableData?.length === 0 : false
                }
              ></DataNotFound>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default TabDisponivel;
