import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import api from "../../../services/api";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import DataTable from "../../../components/DataTable";
import DataNotFound from "../../../components/DataNotFound";
import { ToastContext } from "../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import { IAplicabilidade } from "../../../common/interfaces/produtos.interfaces";
import ModalAplicabilidade from "./ModalAplicabilidade";
import ModalPrevisualizacao from "./ModalPrevisualizacao";
import history from "../../../helpers/history";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Placeholder from "../../../assets/imgs/img-placeholder.png";

const AplicabilidadeBase: React.FC = () => {

  // URL Params
  const { pId }: any = useParams();

  //Fields
  const DataTableTS: any = DataTable;

  // Refs
  const formRef = useRef<FormHandles>(null);

  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);

  //States
  const [tableData, setTableData] = useState<IAplicabilidade[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [showAplicavel, setShowAplicavel] = useState(false);
  const [idAplicavel, setIdAplicavel] = useState<any>(null);

  //Rows Modal Aplicabilidade
  const [tableSelectedRows, setTableSelectedRows] = useState<any>([]);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        if (pId === undefined)
          history.push(`/produtos`);
        
        const { data } = await api.get(`aplicabilidade/listaAplicabilidadeBase/${pId}`);
        setTableData(data);
        
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, [reloadTable, pId]);

  //Handle
  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handleSubmit: SubmitHandler<any> = async () => {

    try {

      var aplicabilidade = { idComponente: pId, IdsAplicabilidade: tableSelectedRows };
      await api.post('aplicabilidade/SalvarComponenteBaseAplicavel', aplicabilidade);

      setReloadTable(reload => !reload);
      setShow(false);

      toast.success("A ação foi concluída.");
    } catch (erro: any) {
      toast.error(getSingleErrorMessage(erro))
    }
  };

  const handleClose = () => setShow(false);

  const handleNew = () => setShow(true);

  const handleCloseAplicavel = () => setShowAplicavel(false);

  const handleVisualizarAplicavel = ({ idAplicavel, codigo }: any) => {
    setIdAplicavel(idAplicavel);
    setShowAplicavel(true);
  }

  const handleDelete = async ({ id, descricao }: IAplicabilidade) => {
    const title = `Excluir "${descricao}"?`;
    const message =
      "Essa aplicabilidade será excluída, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`aplicabilidade/RemoverAplicabilidadeBase/${id}`);
        setReloadTable(reload => !reload);
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Código",
        accessor: "codigo",
        display: "align-middle",
      },
      {
        Header: "Descrição",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Referencia",
        accessor: "linkLogoReferencia",
        display: "align-middle",
        Cell: ({
          row: { original },
        }: {
          row: { original: IAplicabilidade };
        }) => {
          return <img height={22} 
            src={original.linkLogoReferencia} 
            onError={(event: any) => event.target.src = Placeholder}
            alt="logo"/>
        },
      },
      {
        Header: "Situação",
        accessor: "situacao",
        display: "align-middle",
        Cell: ({ row: { original } }: { row: { original: IAplicabilidade } }) => {
          if(original.excluida){
            return <>Marcado para Exclusão</>
          }else{
            return <>{original.situacao}</>
          }
        }
      },
      {
        Header: "Ação",
        accessor: "acao",
        disableSortBy: true,
        display: "align-middle column-table-3b",
        Cell: ({ row: { original } }: { row: { original: IAplicabilidade } }) => (
          <>
            {original.fichaTecnica &&
              <Button onClick={(e) => {
                e.preventDefault();
                window.open(original.fichaTecnica, '_blank');
              }}
                title="Ficha Técnica" variant="ghost-dark" className="p-2">
                <i className="fas fa-file-download icon m-0"></i>
              </Button>
            }
            <Button title="Visualizar" variant="ghost-dark" className="p-2" target="_blank"
              onClick={() => handleVisualizarAplicavel(original)}>
              <i className="fas fa-external-link-alt icon m-0"></i>
            </Button>
            {!original.excluida &&
              <Button
                title="Excluir"
                variant="ghost-dark"
                className="p-2"
                onClick={() => handleDelete(original)}
              >
                <i className="far fa-trash-alt icon m-0"></i>
              </Button>
            }
          </>
        ),
      },
    ],
    []
  );

  return (
    <div className="content">
      <div className="container-xl">
        <Breadcrumbs />
        <div className="page-header">
          <div className="row align-items-center">
            <div className="row">
              <div className="col-12">
                <div className="card mt-3">
                  <div className="card-body mb-2 ">
                    <div className="row">
                      <div className="row mt-2">
                        <div className="col-auto ml-auto">
                          <Button
                            variant="white"
                            style={{ marginTop: 27 }}
                            onClick={() => handleNew()}
                          >
                            Adicionar
                          </Button>
                        </div>
                      </div>
                      <div
                        className={"dimmer" + (tableLoading ? " active" : "")}
                        style={tableLoading ? { minHeight: "322px" } : {}}
                      >
                        <div className="loader"></div>
                        <div className="dimmer-content">
                          <div className="mt-4">
                            {tableData && (
                              <DataTableTS
                                columns={columns}
                                data={tableData}
                                bordered={false}
                                initialState={{
                                  sortBy: [
                                    {
                                      id: "id",
                                      desc: false,
                                    },
                                  ],
                                }}
                                getRowProps={({ original }: { original: IAplicabilidade }) => ({
                                  style: {
                                    background: original.excluida ? 'rgb(214 57 57 / 20%)' : '',
                                  },
                                })}
                              />
                            )}
                          </div>
                          <DataNotFound
                            visible={tableLoading == false ? tableData?.length === 0 : false}
                          ></DataNotFound>
                        </div>
                      </div>

                      {/* Modal Aplicabilidade */}
                      <Modal size="xl" show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Adicionar Aplicabilidade</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form className="mt-2" ref={formRef} onSubmit={handleSubmit}>
                            <ModalAplicabilidade setTableSelectedRows={setTableSelectedRows} />
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Fechar
                          </Button>
                          <Button variant="primary" onClick={handleSave}>
                            Salvar
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      {/* Modal Apicável */}
                      <Modal size="lg" show={showAplicavel} onHide={handleCloseAplicavel}>
                        <Modal.Header closeButton>
                          <Modal.Title>Pré-visualização</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="mt-2">
                          <ModalPrevisualizacao id={idAplicavel} />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleCloseAplicavel}>
                            Fechar
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AplicabilidadeBase;
