import React, {
    ChangeEvent,
    useRef,
    useEffect,
    useCallback,
    useState,
} from 'react';

import { useField } from '@unform/core';

interface Props {
    name: string;
    placeholder: string;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

export default function File({ name, placeholder, ...rest }: InputProps) {

    const inputRef = useRef<HTMLInputElement>(null);

    const { fieldName, registerField, defaultValue, error } = useField(name);

    const [file, setFile] = useState(defaultValue);

    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {

        if (e.target.files?.[0]) {
            setFile(e.target.files?.[0]);
        } else {
            setFile(null);
        }
    };

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'files[0]',
            clearValue(ref: HTMLInputElement) {
                ref.value = '';
                setFile(null);
            },
            setValue(_: HTMLInputElement, value: string) {
                setFile(value);
            },
        });
    }, [fieldName, registerField]);

    return (
        <>
            <div className="custom custom-file" style={{zIndex: 0}}>
                <input type="file" ref={inputRef} className="custom-file-input" onChange={handleUpload} {...rest} />
                <label data-browse="Procurar" htmlFor="custom-file" className="custom-file-label" style={{ zIndex: 0 }}>
                    {file ? file.name : placeholder}
                </label>
            </div> 
            {error && <div className="invalid-feedback">{error}</div>}           
        </>
    );
};