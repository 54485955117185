import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../../services/api";

import UploadMiniatura from "./UploadMiniatura";
import UploadImagem from "./UploadImagem";
import UploadFichaTecnica from "./UploadFichaTecnica";
import UploadSVGX from "./UploadSVGX";

import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { getSingleErrorMessage } from "../../../../../helpers/getSingleErrorMessage";

const PerfilTabImagens: React.FC<any> = ({setFiguraIcone, figuraIcone}) => {

  // URL Params
  const { pId }: any = useParams();

  // É um novo Perfil?
  const novo = (pId == 0);

  // Context  
  const toastContext = useContext(ToastContext);  

  // Effects
  useEffect(() => {
    (async () => {
      try {
        if (!novo) {
          const { data } = await api.get(`perfil/ImagemAnexo/${pId}`);
          setFiguraIcone(data);
        }
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    })();
  }, [pId]);

  return (
    <>
      <div className="row mt-4 mb-2">
        <div className="col-4">
          <UploadMiniatura dados={figuraIcone} setFiguraIcone={setFiguraIcone} />
        </div>
        <div className="col-4">
          <UploadImagem dados={figuraIcone} setFiguraIcone={setFiguraIcone} />
        </div>
        <div className="col-4">
          <UploadSVGX dados={figuraIcone} setFiguraIcone={setFiguraIcone} />
        </div>
        {/* <div className="col-4">
          <UploadFichaTecnica dados={figuraIcone} />
        </div> */}
      </div>

    </>
  );
};
export default PerfilTabImagens;
