import React, { useState, useEffect } from "react";
import api from "../../services/api";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface MeusProdutosOutrosProps {
  selectedRegion: any;
  startDate: Date | null;
  endDate: Date | null;
}

const ClientesComUltimaAtualizacaoChart: React.FC<MeusProdutosOutrosProps> = ({ selectedRegion, startDate, endDate }) => {
  const [tableLoading, setTableLoading] = useState(false);
  const [charData, setCharData] = useState<number[]>([]);
  
  const chartOptions: ApexOptions = {
        series: charData,
          chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '22px'
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
        labels: ['Average Results'],
      };
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);
        const { data } = await api.get("DashboardVidros/ClientesComUltimaAtualizacao", {
          params: {
            region: selectedRegion?.value, // Adjust the property name based on your API requirements
            startDate,
            endDate,
          },
        });
        setCharData([data[0]?.series || []]);
       } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, []);
  return (
    <div>
      <div>
        <div className="chart-wrap d-flex align-items-center justify-content-center">
          <div id="chart">
            <ReactApexChart
              options={chartOptions}
              series={chartOptions.series}
              type="radialBar"
              height={400}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientesComUltimaAtualizacaoChart;
