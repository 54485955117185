import React from "react";

const DataTableColumnCheckBox = ({ selectProps }) => {
  const props = selectProps;
  delete props.indeterminate;
  return (
    <>
      <div className="form-check">
        <label className="form-check-label">
          <input type="checkbox" className="form-check-input" {...props} />
          <span className="form-check-sign">
            <span className="check"></span>
          </span>
        </label>
      </div>
    </>
  );
};

export default DataTableColumnCheckBox;