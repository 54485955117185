import React, { useRef, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { useField } from "@unform/core";
import "./styles.css"

interface OptionType {
    [key: string]: any;
}

interface SelectProps {
    name: string;
    options?: OptionType[];
    isClearable?: boolean;
    placeholder?: string;
    className?: string;
    defaultValue?: any;
    value?: any;
    onChange?: (e: React.ChangeEvent) => void;
    loadOptions: (inputValue: string) => Promise<any>;
    isDisabled?: any;
}

const SelectAsync: React.FC<SelectProps> = ({ name, options, placeholder, className, onChange, loadOptions, defaultValue, value, isDisabled, isClearable, ...rest }) => {
    const { fieldName, registerField, error } = useField(name);
    const selectRef = useRef(null);


    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref) => {
                return ref.select.select.props.value;
            },
            setValue: (ref, value: any) => {
                ref.select.select.setValue(value.value != null || (Array.isArray(value) && value.length != 0) ? value || null : null);
            },
            clearValue: (ref) => {
                ref.select.select.clearValue();
            }
        });
    }, [fieldName, registerField]);


    return (
        <>
            <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                loadingMessage={() => "Buscando..."}
                noOptionsMessage={() => "Digite algo no campo acima para popular a lista."}
                classNamePrefix="react-select"
                ref={selectRef}
                defaultValue={defaultValue}
                options={options}
                onChange={onChange}
                isClearable={isClearable}
                placeholder={placeholder}
                value={value}
                isDisabled={isDisabled}
                {...rest}
            />

            {error && <div className="invalid-feedback">{error}</div>}

        </>
    );
};

export default SelectAsync;
